require.config({
    paths: {
        'sdk': uiBuilderSDKPath + '/sdk',
    }
});

define(['sdk'], BackendlessUI => BackendlessUI.startApp());

define('./functions/74ce0ae175a6ebbf849380f73bae038c/code.js', () => { 

BackendlessUI.Functions.Custom['fn_74ce0ae175a6ebbf849380f73bae038c'] = function fn_74ce0ae175a6ebbf849380f73bae038c(AddOnsDataBlock) {
  AddOnsDataBlock['Blog'] = false;
  AddOnsDataBlock['BLOG_Amount'] = 0;
  AddOnsDataBlock['SAGE_Amount'] = 0;
  AddOnsDataBlock['Memberships'] = false;
  AddOnsDataBlock['Memberships_Amount'] = 0;
  AddOnsDataBlock['ECommerce'] = false;
  AddOnsDataBlock['ECommerce_BaseAmount'] = 0;
  AddOnsDataBlock['ECommerce_Amount'] = 0;
  AddOnsDataBlock['ECommerce_ItemsTotal'] = 0;
  AddOnsDataBlock['Products'] = 0;
  AddOnsDataBlock['LiveChat'] = false;
  AddOnsDataBlock['LiveChat_Amount'] = 0;
  AddOnsDataBlock['SocialMedia'] = false;
  AddOnsDataBlock['SocialMedia_Amount'] = 0;
  AddOnsDataBlock['Forms'] = false;
  AddOnsDataBlock['Forms_Amount'] = 0;
  AddOnsDataBlock['Gallery'] = false;
  AddOnsDataBlock['Galleries_Amount'] = 0;
  AddOnsDataBlock['Reservations'] = false;
  AddOnsDataBlock['Reservations_Amount'] = 0;
  AddOnsDataBlock['Search'] = false;
  AddOnsDataBlock['Search_Amount'] = 0;
  AddOnsDataBlock['Analytics'] = false;
  AddOnsDataBlock['Analytics_Amount'] = 0;

return AddOnsDataBlock
}

})
define('./functions/9bf22c0cc05e214a1e9ea9cba345d6c5/code.js', () => { 

BackendlessUI.Functions.Custom['fn_9bf22c0cc05e214a1e9ea9cba345d6c5'] = async function fn_9bf22c0cc05e214a1e9ea9cba345d6c5(appData) {
var user;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  Backendless.setCurrentUserToken(((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('HPUserCookie')));
  appData['currentUser'] = user;
  user = (await Backendless.UserService.getCurrentUser(false));
  appData['companyNamesLIST'] = (getObjectProperty(((await Backendless.Data.of('Users').loadRelations((getObjectProperty((getObjectProperty(appData, 'currentUser')), 'objectId')), Backendless.LoadRelationsQueryBuilder.create().setRelationName('Linked_Companies').setPageSize(10).setOffset(0)))[0]), 'Company_Name'));
  appData['currentCompanyObjectId'] = (getObjectProperty(((await Backendless.Data.of('Users').loadRelations((getObjectProperty((getObjectProperty(appData, 'currentUser')), 'objectId')), Backendless.LoadRelationsQueryBuilder.create().setRelationName('Linked_Companies').setPageSize(1).setOffset(0)))[0]), 'objectId'));

return appData
}

})
define('./functions/dbd71bed9687ed5ebe76593799f34fac/code.js', () => { 

BackendlessUI.Functions.Custom['fn_dbd71bed9687ed5ebe76593799f34fac'] = function fn_dbd71bed9687ed5ebe76593799f34fac(CurrentProjectsInProgress, EstimatedHoursGuesstimate, NumOfCustomWebpages, NumOfDynamicWebpages, WebpageComplexity) {
var ProjectCostTotal, MaxHoursPerMonthRush, MaxHoursPerMonthNormal, MaxHoursPerMonthTotal, VarMaxHoursPerWeekRush, VarMaxHoursPerWeekNormal, MaxHoursPerWeekTotal;

/**
 * Describe this function...
 */
async function do_something() {
}


  VarMaxHoursPerWeekNormal = 50;
  VarMaxHoursPerWeekRush = 10;
  ProjectCostTotal = 0;
  MaxHoursPerMonthNormal = VarMaxHoursPerWeekNormal * 4;
  MaxHoursPerMonthRush = VarMaxHoursPerWeekRush * 4;
  MaxHoursPerWeekTotal = VarMaxHoursPerWeekNormal + VarMaxHoursPerWeekRush;
  MaxHoursPerMonthTotal = MaxHoursPerMonthNormal + MaxHoursPerMonthRush;
  ProjectCostTotal = NumOfCustomWebpages * NumOfDynamicWebpages * Math.E;

return ProjectCostTotal
}

})
define('./pages/404/components/902c9641de4ec9e73427e7192a118d14/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('', null);

  },  
/* handler:onClick *//* content */
}));

define('./pages/Home_Backup/components/d1d9de3c08188cf9ff4108b1614be69a/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var AppURL, AppBaseURL;


  AppURL = (await (async function() {
  	return window.location.href
  })());
  AppBaseURL = AppURL.slice(0, 22);
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', [({ [`textLink`]: 'Home',[`urlLink`]: '#home',[`active`]: true }), ({ [`textLink`]: 'Services',[`urlLink`]: '#services',[`active`]: false }), ({ [`textLink`]: 'Portfolio',[`urlLink`]: '#portfolio',[`active`]: false }), ({ [`textLink`]: 'Project Estimator',[`urlLink`]: String('?page=webestimator'),[`active`]: false }), ({ [`textLink`]: 'Contact',[`urlLink`]: '#contact',[`active`]: false }), ({ [`textLink`]: 'Login',[`urlLink`]: '#login',[`active`]: false })]);

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/Home_Backup/components/4494147e47463208b1e17aafe36cda16/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var textLink, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  textLink = (getObjectProperty(___arguments.context.dataModel, 'textLink'));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')) == textLink) {
      item['active'] = true;
    } else {
      item['active'] = false;
    }
  ; return item;}))));
  if (textLink == 'Home') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')));
  } else if (textLink == 'Services') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')));
  } else if (textLink == 'TestimonialsX') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')));
  } else if (textLink == 'Portfolio') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')));
  } else if (textLink == 'Team') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')));
  } else if (textLink == 'Contact') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0ee4f7a2f9f93096442f303878b88406')));
  } else if (textLink == 'Project Estimator') {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('projectestimator', undefined);
  } else if (textLink == 'Login') {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 990) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 0;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.dataModel, 'active')) {
    if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) == true) {
      return ['link', 'nav__link', 'nav__link_active', null, 'boldmenuitem'];
    } else if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) != true) {
      return ['link', 'nav__link', 'nav__link_active', 'whiteshadowtext', null, 'boldmenuitem'];
    }
  } else if ((getObjectProperty(___arguments.context.dataModel, 'active')) != true) {
    if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) == true) {
      return ['link', 'nav__link', null, null];
    } else if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) != true) {
      return ['link', 'nav__link', 'shadowtext', null];
    }
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Home_Backup/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onResize */
  ['onResize'](___arguments) {
    var leftSlideBy, left, j, half, galleryList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 990) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 0;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 'auto';
  }
  galleryList = (getObjectProperty(___arguments.context.appData, 'galleryList'));
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) >= 1200) {
    ___arguments.context.appData['leftSlideBy'] = 240;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 1199) {
    ___arguments.context.appData['leftSlideBy'] = 200;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 990) {
    ___arguments.context.appData['leftSlideBy'] = 260;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768) {
    ___arguments.context.appData['leftSlideBy'] = 370;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576) {
    ___arguments.context.appData['leftSlideBy'] = 250;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
    ___arguments.context.appData['leftSlideBy'] = 100;
  }
  leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
  half = Math.floor(galleryList.length / 2);
  ___arguments.context.pageData['leftStart'] = (-15 - half * leftSlideBy);
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 576) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 120);
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 480) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 130);
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 15);
  }
  left = (getObjectProperty(___arguments.context.pageData, 'leftStart'));
  var j_list = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('c376c79acf1e16de2463e38439c34656'));
  for (var j_index in j_list) {
    j = j_list[j_index];
    j['opacity'] = 1;
    j['left'] = left;
    if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
      j['active'] = true;
    } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
      j['active'] = true;
    } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
      j['active'] = true;
    } else if (left == 0 && leftSlideBy == 100) {
      j['active'] = true;
    } else {
      j['active'] = false;
    }
    left = (typeof left == 'number' ? left : 0) + leftSlideBy;
  }

  },
  /* handler:onResize */
  /* handler:onScroll */
  async ['onScroll'](___arguments) {
    var a, item, team, gallery, home2, features, testimonials, contact, topOnViewport, header, elem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function topOnViewport2(elem, header) {
  topOnViewport = (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', elem)) - ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight'))) + (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) - ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', header)));
  return topOnViewport
}


  contact = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0ee4f7a2f9f93096442f303878b88406')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  team = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  gallery = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  testimonials = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  features = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  home2 = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  if (___arguments.scrollTop - ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) < home2) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b'))))) < features && ___arguments.scrollTop > home2) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < testimonials && ___arguments.scrollTop > features + home2) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < gallery && ___arguments.scrollTop > testimonials + features + home2) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < team && ___arguments.scrollTop > testimonials + features + home2 + gallery) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < contact && ___arguments.scrollTop > testimonials + features + home2 + gallery + team) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0ee4f7a2f9f93096442f303878b88406')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop > ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) / 3) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('f5f6c87f71d4ee4f6b9b334b92f88f31'))['opacity'] = 0.3;
  } else if (___arguments.scrollTop > ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) / 10) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('f5f6c87f71d4ee4f6b9b334b92f88f31'))['opacity'] = 0.5;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('f5f6c87f71d4ee4f6b9b334b92f88f31'))['opacity'] = 1;
  }
  if (___arguments.scrollTop) {
    ___arguments.context.pageData['scrollTop'] = true;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['box-shadow'] = '0px 2px 4px rgba(0, 0, 0, 0.1)';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['height'] = '60px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-top'] = '7px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '-5px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '45px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#777777';
  } else {
    ___arguments.context.pageData['scrollTop'] = false;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['box-shadow'] = 'none';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['height'] = '80px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['background-color'] = 'transparent';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-top'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '80px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#ffffff';
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 400) {
    a = 150;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 500) {
    a = (typeof a == 'number' ? a : 0) + 200;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 600) {
    a = (typeof a == 'number' ? a : 0) + 300;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 700) {
    a = (typeof a == 'number' ? a : 0) + 400;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 800) {
    a = (typeof a == 'number' ? a : 0) + 500;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 900) {
    a = (typeof a == 'number' ? a : 0) + 600;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 1050) {
    a = (typeof a == 'number' ? a : 0) + 700;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 990 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 1050) {
    if (___arguments.scrollTop >= ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) - a)) {
      ___arguments.context.appData['featuresInFocus'] = true;
    }
    if (___arguments.scrollTop >= ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) - a)) {
      ___arguments.context.appData['testimonialsInFocus'] = true;
    }
    if (___arguments.scrollTop >= ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')))) - a)) {
      ___arguments.context.appData['teamInFocus'] = true;
    }
  } else {
    ___arguments.context.appData['featuresInFocus'] = true;
    ___arguments.context.appData['testimonialsInFocus'] = true;
    ___arguments.context.appData['teamInFocus'] = true;
  }

  },
  /* handler:onScroll */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var teamList, testimonialsList, appData, galleryList, featuresList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await (async function() {
  	  kofiWidgetOverlay.draw('hyperpixels', {
  	    'type': 'floating-chat',
  	    'floating-chat.donateButton.text': 'Donate',
  	    'floating-chat.donateButton.background-color': '#000000',
  	    'floating-chat.donateButton.text-color': '#fff'
  	  });
  })();
  appData = (await (async function() {
  	const data = {
  	  "features": [
  	    {
  	      "id": "f1",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-1.jpg",
  	      "title": "Amazing Experience",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f2",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-2.jpg",
  	      "title": "24/7 Support",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f3",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-3.jpg",
  	      "title": "Real-time",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f4",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-4.jpg",
  	      "title": "Easy To Use",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f5",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-5.jpg",
  	      "title": "Free Updates",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f6",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-6.jpg",
  	      "title": "All Platforms",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    }
  	  ],
  	  "gallery": [
  	    {
  	      "id": "g1",
  	      "image": "https://app.webmojo.io/api/files/web/Hyperpixels/images/Clay County Seal - Final(Full-Transparent).png"
  	    },
  	    {
  	      "id": "g2",
  	      "image": "https://app.webmojo.io/api/files/web/Hyperpixels/images/Calvary Logo (Full).png"
  	    },
  	    {
  	      "id": "g3",
  	      "image": "https://app.webmojo.io/api/files/web/Hyperpixels/images/Aspen Hill Kennels - Final (Full).png"
  	    },
  	    {
  	      "id": "g4",
  	      "image": "https://app.webmojo.io/api/files/web/Hyperpixels/images/Act Leasing Services Logo (No Box - On White - FINAL).png"
  	    },
  	    {
  	      "id": "g5",
  	      "image": "https://app.webmojo.io/api/files/web/Hyperpixels/images/Titanium Fitness Logo 2 (Final).png"
  	    },
  	    {
  	      "id": "g6",
  	      "image": "https://app.webmojo.io/api/files/web/Hyperpixels/images/ACCO Website.png"
  	    }
  	  ],
  	  "team": [
  	    {
  	      "id": "t1",
  	      "name": "Joan Doe",
  	      "career": "Founder - CEO",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo1.jpg",
  	      "socialMedia": {
  	        "social": [
  	          {
  	            "url": "#",
  	            "name": "facebook"
  	          },
  	          {
  	            "url": "#",
  	            "name": "github"
  	          },
  	          {
  	            "url": "#",
  	            "name": "linkedin"
  	          },
  	          {
  	            "url": "",
  	            "name": "twitter"
  	          }
  	        ]
  	      }
  	    },
  	    {
  	      "id": "t2",
  	      "name": "John Doe",
  	      "career": "Co-founder",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo2.jpg",
  	      "socialMedia": {
  	        "social": [
  	          {
  	            "url": "#",
  	            "name": "facebook"
  	          },
  	          {
  	            "url": "",
  	            "name": "github"
  	          },
  	          {
  	            "url": "",
  	            "name": "linkedin"
  	          },
  	          {
  	            "url": "#",
  	            "name": "twitter"
  	          }
  	        ]
  	      }
  	    },
  	    {
  	      "id": "t3",
  	      "name": "Joan Doe",
  	      "career": "Co-founder",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo3.jpg",
  	      "socialMedia": {
  	        "social": [
  	          {
  	            "url": "#",
  	            "name": "facebook"
  	          },
  	          {
  	            "url": "#",
  	            "name": "github"
  	          },
  	          {
  	            "url": "#",
  	            "name": "linkedin"
  	          },
  	          {
  	            "url": "",
  	            "name": "twitter"
  	          }
  	        ]
  	      }
  	    },
  	    {
  	      "id": "t4",
  	      "name": "John Doe",
  	      "career": "Financial Manager",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo4.jpg",
  	      "socialMedia": {
  	        "social": [
  	          {
  	            "url": "#",
  	            "name": "facebook"
  	          },
  	          {
  	            "url": "",
  	            "name": "github"
  	          },
  	          {
  	            "url": "#",
  	            "name": "linkedin"
  	          },
  	          {
  	            "url": "",
  	            "name": "twitter"
  	          }
  	        ]
  	      }
  	    }
  	  ],
  	  "testimonials": [
  	    {
  	      "id": "tm1",
  	      "author": "Jane Doe",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/testimonials/auth1.jpg"
  	    },
  	    {
  	      "id": "tm2",
  	      "author": "John Doe",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/testimonials/auth2.jpg"
  	    },
  	    {
  	      "id": "tm3",
  	      "author": "Jane Doe",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/testimonials/auth3.jpg"
  	    },
  	    {
  	      "id": "tm4",
  	      "author": "John Doe",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/testimonials/item4.jpg"
  	    }
  	  ]
  	}

  	return data
  })());
  galleryList = (getObjectProperty(appData, 'gallery'));
  ___arguments.context.appData['galleryList'] = galleryList;
  ___arguments.context.pageData['MessageSent'] = false;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('153bb1d13492b519dc0717b333c20947', testimonialsList);

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/Home_Backup/components/fefec6b6560f11b1a4c62ed6ad39d36c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52')), 'height')) == 349) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 0;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 349;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Home_Backup/components/153bb1d13492b519dc0717b333c20947/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
      return ((___arguments.context.appData['testimonialsInFocus']) ? ['testimonials__items-block', 'testimonials__items-block_in-focus'] : ['testimonials__items-block'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Home_Backup/components/c376c79acf1e16de2463e38439c34656/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var item, leftSlideBy, left, newGalleryList, half, firstList, galleryList, lastList, idGalleryItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  await new Promise(r => setTimeout(r, 1000 || 0));
  galleryList = (getObjectProperty(___arguments.context.appData, 'galleryList'));
  idGalleryItem = 1;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c376c79acf1e16de2463e38439c34656', (await Promise.all(galleryList.map(async item => {  item['id'] = idGalleryItem;
    idGalleryItem = (typeof idGalleryItem == 'number' ? idGalleryItem : 0) + 1;
  ; return item;}))));
  ___arguments.context.appData['leftSlideBy'] = 240;
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 1199) {
    ___arguments.context.appData['leftSlideBy'] = 200;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 990) {
    ___arguments.context.appData['leftSlideBy'] = 260;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768) {
    ___arguments.context.appData['leftSlideBy'] = 370;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576) {
    ___arguments.context.appData['leftSlideBy'] = 250;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
    ___arguments.context.appData['leftSlideBy'] = 100;
  }
  half = Math.floor(galleryList.length / 2);
  lastList = galleryList.slice(((half + 1) - 1), galleryList.length);
  firstList = galleryList.slice(0, half);
  newGalleryList = (JSON.parse((JSON.stringify(((addItemToList(lastList, galleryList)).concat(firstList))))));
  leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
  ___arguments.context.pageData['leftStart'] = (-15 - half * leftSlideBy);
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 576) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 120);
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 480) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 130);
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 15);
  }
  left = (getObjectProperty(___arguments.context.pageData, 'leftStart'));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c376c79acf1e16de2463e38439c34656', (await Promise.all(newGalleryList.map(async item => {  item['opacity'] = 1;
    item['left'] = left;
    if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
      item['active'] = true;
    } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
      item['active'] = true;
    } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
      item['active'] = true;
    } else if (left == 0 && leftSlideBy == 100) {
      item['active'] = true;
    } else {
      item['active'] = false;
    }
    left = (typeof left == 'number' ? left : 0) + leftSlideBy;
  ; return item;}))));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/Home_Backup/components/e23154e2b40dc5a8ac7f9a7e34ff980f/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 480) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e23154e2b40dc5a8ac7f9a7e34ff980f'))['left'] = (String(getObjectProperty(___arguments.context.dataModel, 'left')) + String('px'));
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e23154e2b40dc5a8ac7f9a7e34ff980f'))['left'] = (String(getObjectProperty(___arguments.context.dataModel, 'left')) + String('%'));
  }
  ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e23154e2b40dc5a8ac7f9a7e34ff980f'))['opacity'] = (getObjectProperty(___arguments.context.dataModel, 'opacity'));
  if (getObjectProperty(___arguments.context.dataModel, 'active')) {
    return ['g-slider__item', 'g-slider__item_center'];
  }

  return ['g-slider__item']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Home_Backup/components/108f6ec347effdbce289a49d625d03af/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['isOpenModal'] = true;
  ___arguments.context.pageData['counterSlide2'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e23154e2b40dc5a8ac7f9a7e34ff980f'), 'id'));
  ___arguments.context.pageData['currentSlide'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e23154e2b40dc5a8ac7f9a7e34ff980f'), 'id'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Home_Backup/components/26a1309c25831a8af67d5fe98587c773/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    var leftSlideBy, minValue, card, left, j, maxValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  maxValue = 0;
  minValue = 0;
  leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
  if ((typeof (getObjectProperty(___arguments.context.pageData, 'stopClick')) === 'undefined') || ((new Date()).valueOf()) > (getObjectProperty(___arguments.context.pageData, 'stopClick')) + 300) {
    ___arguments.context.pageData['stopClick'] = ((new Date()).valueOf());
    var j_list = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('c376c79acf1e16de2463e38439c34656'));
    for (var j_index in j_list) {
      j = j_list[j_index];
      j['opacity'] = 1;
      j['left'] = ((getObjectProperty(j, 'left')) + leftSlideBy);
      left = (getObjectProperty(j, 'left'));
      if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
        j['active'] = true;
      } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
        j['active'] = true;
      } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
        j['active'] = true;
      } else if (left == 0 && leftSlideBy == 100) {
        j['active'] = true;
      } else {
        j['active'] = false;
      }
      if (maxValue < left) {
        maxValue = left;
        card = j;
      }
      if (minValue > left) {
        minValue = left;
      }
    }
    card['opacity'] = 0;
    card['left'] = (minValue - leftSlideBy);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Home_Backup/components/0ecc200a34ec908bf97bef91588de587/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var leftSlideBy, maxValue, card, left, j, minValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  maxValue = 0;
  minValue = 0;
  leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
  if ((typeof (getObjectProperty(___arguments.context.pageData, 'stopClick')) === 'undefined') || ((new Date()).valueOf()) > (getObjectProperty(___arguments.context.pageData, 'stopClick')) + 300) {
    ___arguments.context.pageData['stopClick'] = ((new Date()).valueOf());
    var j_list = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('c376c79acf1e16de2463e38439c34656'));
    for (var j_index in j_list) {
      j = j_list[j_index];
      j['left'] = ((getObjectProperty(j, 'left')) - leftSlideBy);
      left = (getObjectProperty(j, 'left'));
      j['opacity'] = 1;
      if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
        j['active'] = true;
      } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
        j['active'] = true;
      } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
        j['active'] = true;
      } else if (left == 0 && leftSlideBy == 100) {
        j['active'] = true;
      } else {
        j['active'] = false;
      }
      if (minValue > left) {
        minValue = left;
        card = j;
      }
      if (maxValue < left) {
        maxValue = left;
      }
    }
    card['opacity'] = 0;
    card['left'] = (maxValue + leftSlideBy);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Home_Backup/components/4bc8650348b45a9267097b43aff505c6/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('4bc8650348b45a9267097b43aff505c6', (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e5ac9c8ad984738efc349d07b4dc74b1'), 'socialMedia')), 'social')));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/Home_Backup/components/1fdde5c76ea432e90eb67a18533b2dac/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes, nameSocial;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['team-card__link', 'link'];
  nameSocial = (getObjectProperty(___arguments.context.dataModel, 'name'));
  if ((getObjectProperty(___arguments.context.dataModel, 'url')) == '') {
    addItemToList(classes, 'team-card__link_hide');
  }
  if (nameSocial == 'facebook') {
    addItemToList(classes, 'fab fa-facebook-f');
  }
  if (nameSocial == 'github') {
    addItemToList(classes, 'fab fa-github');
  }
  if (nameSocial == 'linkedin') {
    addItemToList(classes, 'fab fa-linkedin-in');
  }
  if (nameSocial == 'twitter') {
    addItemToList(classes, 'fab fa-twitter');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Home_Backup/components/b6f27fbafe317fe16572dfeaf1fb0698/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
      return ((___arguments.context.appData['teamInFocus']) ? ['team__cards-block', 'team__cards-block_in-focus'] : ['team__cards-block'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Home_Backup/components/d2551aa8d85f0b8224661c4aaaa53f32/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var firstName, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  firstName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName'));
  regExp = (new RegExp('.{2,}', 'g'));
  if (firstName.length <= 50) {
    ___arguments.context.pageData['firstNameValue'] = firstName;
  } else if (firstName.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['firstName'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['firstName'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'firstNameValue')), 0));
  }
  if ((regExp.test(firstName)) == false && firstName != '' || firstName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length < 2 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = false;
  } else if ((regExp.test(firstName)) == false && firstName == '' || firstName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length == 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Home_Backup/components/3ddf6adfe3e580cc962a62234bf2e2d5/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-first-name'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Home_Backup/components/f5bd0a2c6f0bde798ea8df133e9e7e3c/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var lastName, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  lastName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName'));
  regExp = (new RegExp('.{2,}', 'g'));
  if (lastName.length <= 50) {
    ___arguments.context.pageData['lastNameValue'] = lastName;
  } else if (lastName.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['lastName'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['lastName'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'lastNameValue')), 0));
  }
  if ((regExp.test(lastName)) == false && lastName != '' || lastName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length < 2 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = false;
  } else if ((regExp.test(lastName)) == false && lastName == '' || lastName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length == 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Home_Backup/components/557e31a13073b4848ca61837b9e70a38/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-last-name'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Home_Backup/components/cdc2459c0f1ac842997b621a04744c0c/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var email, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  email = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'email'));
  regExp = (new RegExp('[a-zA-Z0-9.\\-_+]{1,}@[^\\W\\.][a-zA-Z0-9\\-]{0,}\\.[a-zA-Z]{1,}', 'g'));
  if (email.length <= 50) {
    ___arguments.context.pageData['emailValue'] = email;
  } else if (email.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['email'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['email'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'emailValue')), 0));
  }
  if ((regExp.test(email)) == false && email != '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = false;
  } else if ((regExp.test(email)) == false && email == '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Home_Backup/components/556317981b853c56d8e8e837e5b16c9e/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-email'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Home_Backup/components/d64247a69fb82b1b932f6a1191167d68/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-phone'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Home_Backup/components/1712009c62182acf71f1e4e777ac98dd/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var phone, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  phone = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone'));
  regExp = (new RegExp('^[0-9+\\(\\)\\-\\s]+$', 'g'));
  if (phone.length <= 50) {
    ___arguments.context.pageData['phoneValue'] = phone;
  } else if (phone.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['phone'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['phone'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'phoneValue')), 0));
  }
  if (phone.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone')).length < 5 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = true;
  } else if ((regExp.test(phone)) && phone.length >= 5) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = false;
  } else if (phone == '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = true;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Home_Backup/components/275519e4f1ff2a5c0d556bd7bfaaf6c5/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-website'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Home_Backup/components/ddb987ac0b1a2e02086b043645e7decd/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var website, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  website = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website'));
  regExp = (new RegExp('[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)', 'gi'));
  if (website.length <= 249) {
    ___arguments.context.pageData['websiteValue'] = website;
  } else if (website.length > 249) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['website'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['website'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'websiteValue')), 0));
  }
  if ((regExp.test(website)) == false && website != '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = true;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = false;
  }
  if ((regExp.test((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website')))) == true && website.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website')).length) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Home_Backup/components/b17c55c60f4dcdf2ae8fc66b95b63932/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var subject, regExp, firstName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  subject = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject'));
  regExp = (new RegExp('.{4,}', 'g'));
  if (subject.length <= 300) {
    ___arguments.context.pageData['subjectValue'] = subject;
  } else if (subject.length > 300) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['subject'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['subject'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'subjectValue')), 0));
  }
  if ((regExp.test(subject)) == false && subject != '' || subject.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length < 4 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = false;
  } else if ((regExp.test(subject)) == false && subject == '' || subject.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length == 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Home_Backup/components/a38dcbb7299151a66062e5a8eaaf984c/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-subject'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Home_Backup/components/101b130a0c5ff2588aa5637fc5fcdb27/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-message'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage']) {
    addItemToList(classes, 'c-form__label_alert');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Home_Backup/components/6eb697a67eb600c29f860957a2ac3b3e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, message, subject, website, phone, email, lastName, firstName, checkStatusMessage, checkStatusSubject, checkStatusWebsite, checkStatusPhone, checkStatusEmail, checkStatusLastName, checkStatusFirstName, statusMessage, statusSubject, statusWebsite, statusPhone, statusEmail, statusLastName, statusFirstName, company, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function ensureArray(items) {
  return Array.isArray(items) ? items : [items]
}

/**
 * Describe this function...
 */
async function firstNameValidation() {
  regExp = (new RegExp('.{2,}', 'g'));
  if (!(regExp.test(firstName)) || firstName == '' || firstName == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusFirsName() {
  if (!(getObjectProperty(statusFirstName, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('d2551aa8d85f0b8224661c4aaaa53f32')));
    if (firstName == '' || firstName == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function lastNameValidation() {
  regExp = (new RegExp('.{2,}', 'g'));
  if (!(regExp.test(lastName)) || lastName == '' || lastName == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusLastName2() {
  if (!(getObjectProperty(statusLastName, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f5bd0a2c6f0bde798ea8df133e9e7e3c')));
    if (lastName == '' || lastName == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function emailValidation() {
  regExp = (new RegExp('[a-zA-Z0-9.\\-_+]{1,}@[^\\W\\.][a-zA-Z0-9\\-]{0,}\\.[a-zA-Z]{1,}', 'g'));
  if (!(regExp.test(email)) || email == '' || email == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function phoneValidation() {
  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'hintPhone')) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusEmail2() {
  if (!(getObjectProperty(statusEmail, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('cdc2459c0f1ac842997b621a04744c0c')));
    if (email == '' || email == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function websiteValidation() {
  regExp = (new RegExp('[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)', 'gi'));
  if (!(regExp.test(website)) && website != '' && website != null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function subjectValidation() {
  regExp = (new RegExp('.{4,}', 'g'));
  if (!(regExp.test(subject)) || subject == '' || subject == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusPhone2() {
  if (!(getObjectProperty(statusPhone, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('1712009c62182acf71f1e4e777ac98dd')));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = true;
    return false;
  }
  return true
}

/**
 * Describe this function...
 */
async function messageValidation() {
  if (message == '' || message == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusWebsite2() {
  if (!(getObjectProperty(statusWebsite, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('ddb987ac0b1a2e02086b043645e7decd')));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = true;
    return false;
  }
  return true
}

/**
 * Describe this function...
 */
async function checkStatusSubject2() {
  if (!(getObjectProperty(statusSubject, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b17c55c60f4dcdf2ae8fc66b95b63932')));
    if (subject == '' || subject == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function checkStatusMessage2() {
  if (!(getObjectProperty(statusMessage, 'status'))) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage'] = true;
    return false;
  }
  return true
}


  firstName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName'));
  lastName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName'));
  email = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'email'));
  phone = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone'));
  company = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'company'));
  website = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website'));
  subject = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject'));
  message = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'message'));
  statusFirstName = await firstNameValidation();
  statusLastName = await lastNameValidation();
  statusEmail = await emailValidation();
  statusPhone = await phoneValidation();
  statusWebsite = await websiteValidation();
  statusSubject = await subjectValidation();
  statusMessage = await messageValidation();
  checkStatusMessage = await checkStatusMessage2();
  checkStatusSubject = await checkStatusSubject2();
  checkStatusWebsite = await checkStatusWebsite2();
  checkStatusPhone = await checkStatusPhone2();
  checkStatusEmail = await checkStatusEmail2();
  checkStatusLastName = await checkStatusLastName2();
  checkStatusFirstName = await checkStatusFirsName();
  if (checkStatusFirstName != false && checkStatusLastName != false && checkStatusEmail != false && checkStatusPhone != false && checkStatusWebsite != false && checkStatusSubject != false && checkStatusMessage != false) {
    await Backendless.Messaging.sendEmail('New contact from the Hyperpixels Website', new Backendless.Bodyparts({ htmlmessage: ([['From: ',firstName,' ',lastName].join(''),'<br>Email: ',email,'<br> Phone: ',phone,'<br> Website: ',website,'<br> Subject: ',subject,'<br> Message: <br>',message].join('')) }), ensureArray('mike@hyperpixels.net'), undefined);
    ___arguments.context.pageData['MessageSent'] = true;
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b65aa795e4d6a994b3ddd6f68533c80d', false);
    try {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('df8b067a885dcda84a91376a3c4c5c7c', true);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7224592bf6676a81e607676ddbc597e1', false);

    } catch (error) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('df8b067a885dcda84a91376a3c4c5c7c', false);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7224592bf6676a81e607676ddbc597e1', true);
      console.log(error);

    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Home_Backup/components/58c7b83b0c67da4ff3bef93451af2162/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var message, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  message = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'message'));
  regExp = (new RegExp('.{1,}', 'g'));
  if (message.length <= 249) {
    ___arguments.context.pageData['messageValue'] = message;
  } else if (message.length > 249) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['message'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['message'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'messageValue')), 0));
  }
  if ((regExp.test(message)) == false && message == '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage'] = true;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Home_Backup/components/dc8e2162b0174608379cfba7549be8db/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Home_Backup/components/251dd247af0d2f6ebe6ccde3866c89b5/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isOpenModal')) {
    return ['modal', 'open'];
  }

  return ['modal']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Home_Backup/components/70dd1dff3e215deab9dcc3e29a6566e6/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async onMounted(___arguments) {
    var counter, data, gallery, item, listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setIdItem(data) {
  counter = 1;
  return await Promise.all(data.map(async item => {  item['id'] = counter;
  counter = (typeof counter == 'number' ? counter : 0) + 1;
; return item;}))
}


  await new Promise(r => setTimeout(r, 1000 || 0));
  gallery = (getObjectProperty(___arguments.context.appData, 'galleryList'));
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', (await setIdItem(gallery)));
  listLength = gallery.length;
  ___arguments.context.pageData['totalSlide'] = listLength;
  ___arguments.context.pageData['currentSlide'] = 1;

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/Home_Backup/components/bb37f7327e62a50eb06507a61d6d1fc6/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == (getObjectProperty(___arguments.context.dataModel, 'id'))) {
    return ['modal__slider-item', 'active'];
  }

  return ['modal__slider-item']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Home_Backup/components/831fddd4bb7ced9296de1aa550213577/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) > 1) {
    ___arguments.context.pageData['counterSlide2'] = ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == 1 ? listLength : (getObjectProperty(___arguments.context.pageData, 'counterSlide2')) - 1);
  }
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ___arguments.context.pageData['currentSlide'] = (getObjectProperty(___arguments.context.pageData, 'counterSlide2'));

  },
  /* handler:onClick */
  /* handler:onMouseOut */
  onMouseOut(___arguments) {
    var listLength;


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('831fddd4bb7ced9296de1aa550213577'))['opacity'] = '0';

  },
  /* handler:onMouseOut */
  /* handler:onMouseOver */
  onMouseOver(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == 1) {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('831fddd4bb7ced9296de1aa550213577'))['opacity'] = '0';
  } else {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('831fddd4bb7ced9296de1aa550213577'))['opacity'] = '1';
  }

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./pages/Home_Backup/components/dba21793ecfc81f1424a1ce6ad99a8b3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) < listLength) {
    ___arguments.context.pageData['counterSlide2'] = ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == listLength ? 1 : (getObjectProperty(___arguments.context.pageData, 'counterSlide2')) + 1);
  }
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ___arguments.context.pageData['currentSlide'] = (getObjectProperty(___arguments.context.pageData, 'counterSlide2'));

  },
  /* handler:onClick */
  /* handler:onMouseOut */
  onMouseOut(___arguments) {
    var listLength;


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('dba21793ecfc81f1424a1ce6ad99a8b3'))['opacity'] = '0';

  },
  /* handler:onMouseOut */
  /* handler:onMouseOver */
  onMouseOver(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == listLength) {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('dba21793ecfc81f1424a1ce6ad99a8b3'))['opacity'] = '0';
  } else {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('dba21793ecfc81f1424a1ce6ad99a8b3'))['opacity'] = '1';
  }

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./pages/Home_Backup/components/31c87ba294176e587b91b87c58e68a82/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Home_Backup/components/16b9cb3fdc9bf1919465a18c77a6e467/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Home_Backup/components/3607af0d8419be43455fc4ac16f9cc33/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var company;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  company = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'company'));
  if (company.length <= 100) {
    ___arguments.context.pageData['companyValue'] = company;
  } else if (company.length > 100) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['company'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['company'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'companyValue')), 0));
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Home_Backup/components/30ea90dd05af67628fd821d5003c3d3d/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  onMounted(___arguments) {
      ___arguments.context.pageData['firstNameValue'] = '';
  ___arguments.context.pageData['lastNameValue'] = '';
  ___arguments.context.pageData['emailValue'] = '';
  ___arguments.context.pageData['phoneValue'] = '';
  ___arguments.context.pageData['companyValue'] = '';
  ___arguments.context.pageData['websiteValue'] = '';
  ___arguments.context.pageData['subjectValue'] = '';
  ___arguments.context.pageData['messageValue'] = '';

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/Home_Backup/components/ba557046fb5348f79a8a94c342388c04/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'MessageSent'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/Home_Backup/components/2d06b571aa5bcd13df8e8426dd1c5849/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2d06b571aa5bcd13df8e8426dd1c5849'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2d06b571aa5bcd13df8e8426dd1c5849'))['color'] = 'white';

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/Home_Backup/components/14e3a2a815e855eaa285739b916320ed/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  async ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('2d06b571aa5bcd13df8e8426dd1c5849'))['text'] = 'hello@hyperpixels.net';
  await (async function() {
  	var a = document.getElementsByClassName('emailuslink'); //or grab it by tagname etc
  	a[0].href = "mailto:hello@hyperpixels.net"
  })();

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  async ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('2d06b571aa5bcd13df8e8426dd1c5849'))['text'] = 'Email Us';
  await (async function() {
  	var a = document.getElementsByClassName('emailuslink'); //or grab it by tagname etc
  	a[0].href = "mailto:undefined"
  })();

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/blankpage/components/d1d9de3c08188cf9ff4108b1614be69a/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var AppURL, AppBaseURL;


  AppURL = (await (async function() {
  	return window.location.href
  })());
  AppBaseURL = AppURL.slice(0, 22);
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', [({ [`textLink`]: 'Home',[`urlLink`]: '#home',[`active`]: true }), ({ [`textLink`]: 'Services',[`urlLink`]: '#services',[`active`]: false }), ({ [`textLink`]: 'Portfolio',[`urlLink`]: '#portfolio',[`active`]: false }), ({ [`textLink`]: 'Project Estimator',[`urlLink`]: String('?page=webestimator'),[`active`]: false }), ({ [`textLink`]: 'Contact',[`urlLink`]: '#contact',[`active`]: false }), ({ [`textLink`]: 'Login',[`urlLink`]: '#login',[`active`]: false })]);

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/blankpage/components/4494147e47463208b1e17aafe36cda16/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var textLink, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  textLink = (getObjectProperty(___arguments.context.dataModel, 'textLink'));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')) == textLink) {
      item['active'] = true;
    } else {
      item['active'] = false;
    }
  ; return item;}))));
  if (textLink == 'Home') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')));
  } else if (textLink == 'Services') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')));
  } else if (textLink == 'TestimonialsX') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')));
  } else if (textLink == 'Portfolio') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')));
  } else if (textLink == 'Team') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')));
  } else if (textLink == 'Contact') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0ee4f7a2f9f93096442f303878b88406')));
  } else if (textLink == 'Project Estimator') {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('projectestimator', undefined);
  } else if (textLink == 'Login') {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 990) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 0;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.dataModel, 'active')) {
    if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) == true) {
      return ['link', 'nav__link', 'nav__link_active', null, 'boldmenuitem'];
    } else if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) != true) {
      return ['link', 'nav__link', 'nav__link_active', 'whiteshadowtext', null, 'boldmenuitem'];
    }
  } else if ((getObjectProperty(___arguments.context.dataModel, 'active')) != true) {
    if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) == true) {
      return ['link', 'nav__link', null, null];
    } else if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) != true) {
      return ['link', 'nav__link', 'shadowtext', null];
    }
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/blankpage/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onResize */
  ['onResize'](___arguments) {
    var leftSlideBy, left, j, half, galleryList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 990) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 0;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 'auto';
  }
  galleryList = (getObjectProperty(___arguments.context.appData, 'galleryList'));
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) >= 1200) {
    ___arguments.context.appData['leftSlideBy'] = 240;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 1199) {
    ___arguments.context.appData['leftSlideBy'] = 200;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 990) {
    ___arguments.context.appData['leftSlideBy'] = 260;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768) {
    ___arguments.context.appData['leftSlideBy'] = 370;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576) {
    ___arguments.context.appData['leftSlideBy'] = 250;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
    ___arguments.context.appData['leftSlideBy'] = 100;
  }
  leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
  half = Math.floor(galleryList.length / 2);
  ___arguments.context.pageData['leftStart'] = (-15 - half * leftSlideBy);
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 576) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 120);
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 480) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 130);
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 15);
  }
  left = (getObjectProperty(___arguments.context.pageData, 'leftStart'));
  var j_list = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('c376c79acf1e16de2463e38439c34656'));
  for (var j_index in j_list) {
    j = j_list[j_index];
    j['opacity'] = 1;
    j['left'] = left;
    if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
      j['active'] = true;
    } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
      j['active'] = true;
    } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
      j['active'] = true;
    } else if (left == 0 && leftSlideBy == 100) {
      j['active'] = true;
    } else {
      j['active'] = false;
    }
    left = (typeof left == 'number' ? left : 0) + leftSlideBy;
  }

  },
  /* handler:onResize */
  /* handler:onScroll */
  async ['onScroll'](___arguments) {
    var a, item, team, gallery, home2, features, testimonials, contact, topOnViewport, header, elem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function topOnViewport2(elem, header) {
  topOnViewport = (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', elem)) - ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight'))) + (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) - ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', header)));
  return topOnViewport
}


  contact = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0ee4f7a2f9f93096442f303878b88406')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  team = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  gallery = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  testimonials = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  features = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  home2 = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  if (___arguments.scrollTop - ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) < home2) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b'))))) < features && ___arguments.scrollTop > home2) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < testimonials && ___arguments.scrollTop > features + home2) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < gallery && ___arguments.scrollTop > testimonials + features + home2) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < team && ___arguments.scrollTop > testimonials + features + home2 + gallery) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < contact && ___arguments.scrollTop > testimonials + features + home2 + gallery + team) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0ee4f7a2f9f93096442f303878b88406')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop > ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) / 3) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('f5f6c87f71d4ee4f6b9b334b92f88f31'))['opacity'] = 0.3;
  } else if (___arguments.scrollTop > ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) / 10) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('f5f6c87f71d4ee4f6b9b334b92f88f31'))['opacity'] = 0.5;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('f5f6c87f71d4ee4f6b9b334b92f88f31'))['opacity'] = 1;
  }
  if (___arguments.scrollTop) {
    ___arguments.context.pageData['scrollTop'] = true;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['box-shadow'] = '0px 2px 4px rgba(0, 0, 0, 0.1)';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['height'] = '60px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-top'] = '7px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '-5px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '45px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#777777';
  } else {
    ___arguments.context.pageData['scrollTop'] = false;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['box-shadow'] = 'none';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['height'] = '80px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['background-color'] = 'transparent';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-top'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '80px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#ffffff';
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 400) {
    a = 150;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 500) {
    a = (typeof a == 'number' ? a : 0) + 200;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 600) {
    a = (typeof a == 'number' ? a : 0) + 300;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 700) {
    a = (typeof a == 'number' ? a : 0) + 400;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 800) {
    a = (typeof a == 'number' ? a : 0) + 500;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 900) {
    a = (typeof a == 'number' ? a : 0) + 600;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 1050) {
    a = (typeof a == 'number' ? a : 0) + 700;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 990 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 1050) {
    if (___arguments.scrollTop >= ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) - a)) {
      ___arguments.context.appData['featuresInFocus'] = true;
    }
    if (___arguments.scrollTop >= ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) - a)) {
      ___arguments.context.appData['testimonialsInFocus'] = true;
    }
    if (___arguments.scrollTop >= ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')))) - a)) {
      ___arguments.context.appData['teamInFocus'] = true;
    }
  } else {
    ___arguments.context.appData['featuresInFocus'] = true;
    ___arguments.context.appData['testimonialsInFocus'] = true;
    ___arguments.context.appData['teamInFocus'] = true;
  }

  },
  /* handler:onScroll */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var teamList, testimonialsList, appData, galleryList, featuresList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await (async function() {
  	  kofiWidgetOverlay.draw('hyperpixels', {
  	    'type': 'floating-chat',
  	    'floating-chat.donateButton.text': 'Donate',
  	    'floating-chat.donateButton.background-color': '#000000',
  	    'floating-chat.donateButton.text-color': '#fff'
  	  });
  })();
  appData = (await (async function() {
  	const data = {
  	  "features": [
  	    {
  	      "id": "f1",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-1.jpg",
  	      "title": "Amazing Experience",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f2",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-2.jpg",
  	      "title": "24/7 Support",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f3",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-3.jpg",
  	      "title": "Real-time",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f4",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-4.jpg",
  	      "title": "Easy To Use",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f5",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-5.jpg",
  	      "title": "Free Updates",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f6",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-6.jpg",
  	      "title": "All Platforms",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    }
  	  ],
  	  "gallery": [
  	    {
  	      "id": "g1",
  	      "image": "https://app.webmojo.io/api/files/web/Hyperpixels/images/Clay County Seal - Final(Full-Transparent).png"
  	    },
  	    {
  	      "id": "g2",
  	      "image": "https://app.webmojo.io/api/files/web/Hyperpixels/images/Calvary Logo (Full).png"
  	    },
  	    {
  	      "id": "g3",
  	      "image": "https://app.webmojo.io/api/files/web/Hyperpixels/images/Aspen Hill Kennels - Final (Full).png"
  	    },
  	    {
  	      "id": "g4",
  	      "image": "https://app.webmojo.io/api/files/web/Hyperpixels/images/Act Leasing Services Logo (No Box - On White - FINAL).png"
  	    },
  	    {
  	      "id": "g5",
  	      "image": "https://app.webmojo.io/api/files/web/Hyperpixels/images/Titanium Fitness Logo 2 (Final).png"
  	    },
  	    {
  	      "id": "g6",
  	      "image": "https://app.webmojo.io/api/files/web/Hyperpixels/images/ACCO Website.png"
  	    }
  	  ],
  	  "team": [
  	    {
  	      "id": "t1",
  	      "name": "Joan Doe",
  	      "career": "Founder - CEO",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo1.jpg",
  	      "socialMedia": {
  	        "social": [
  	          {
  	            "url": "#",
  	            "name": "facebook"
  	          },
  	          {
  	            "url": "#",
  	            "name": "github"
  	          },
  	          {
  	            "url": "#",
  	            "name": "linkedin"
  	          },
  	          {
  	            "url": "",
  	            "name": "twitter"
  	          }
  	        ]
  	      }
  	    },
  	    {
  	      "id": "t2",
  	      "name": "John Doe",
  	      "career": "Co-founder",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo2.jpg",
  	      "socialMedia": {
  	        "social": [
  	          {
  	            "url": "#",
  	            "name": "facebook"
  	          },
  	          {
  	            "url": "",
  	            "name": "github"
  	          },
  	          {
  	            "url": "",
  	            "name": "linkedin"
  	          },
  	          {
  	            "url": "#",
  	            "name": "twitter"
  	          }
  	        ]
  	      }
  	    },
  	    {
  	      "id": "t3",
  	      "name": "Joan Doe",
  	      "career": "Co-founder",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo3.jpg",
  	      "socialMedia": {
  	        "social": [
  	          {
  	            "url": "#",
  	            "name": "facebook"
  	          },
  	          {
  	            "url": "#",
  	            "name": "github"
  	          },
  	          {
  	            "url": "#",
  	            "name": "linkedin"
  	          },
  	          {
  	            "url": "",
  	            "name": "twitter"
  	          }
  	        ]
  	      }
  	    },
  	    {
  	      "id": "t4",
  	      "name": "John Doe",
  	      "career": "Financial Manager",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo4.jpg",
  	      "socialMedia": {
  	        "social": [
  	          {
  	            "url": "#",
  	            "name": "facebook"
  	          },
  	          {
  	            "url": "",
  	            "name": "github"
  	          },
  	          {
  	            "url": "#",
  	            "name": "linkedin"
  	          },
  	          {
  	            "url": "",
  	            "name": "twitter"
  	          }
  	        ]
  	      }
  	    }
  	  ],
  	  "testimonials": [
  	    {
  	      "id": "tm1",
  	      "author": "Jane Doe",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/testimonials/auth1.jpg"
  	    },
  	    {
  	      "id": "tm2",
  	      "author": "John Doe",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/testimonials/auth2.jpg"
  	    },
  	    {
  	      "id": "tm3",
  	      "author": "Jane Doe",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/testimonials/auth3.jpg"
  	    },
  	    {
  	      "id": "tm4",
  	      "author": "John Doe",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/testimonials/item4.jpg"
  	    }
  	  ]
  	}

  	return data
  })());
  galleryList = (getObjectProperty(appData, 'gallery'));
  ___arguments.context.appData['galleryList'] = galleryList;
  ___arguments.context.pageData['MessageSent'] = false;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('153bb1d13492b519dc0717b333c20947', testimonialsList);

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/blankpage/components/fefec6b6560f11b1a4c62ed6ad39d36c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52')), 'height')) == 349) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 0;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 349;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/blankpage/components/153bb1d13492b519dc0717b333c20947/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
      return ((___arguments.context.appData['testimonialsInFocus']) ? ['testimonials__items-block', 'testimonials__items-block_in-focus'] : ['testimonials__items-block'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/blankpage/components/c376c79acf1e16de2463e38439c34656/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var item, leftSlideBy, left, newGalleryList, half, firstList, galleryList, lastList, idGalleryItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  await new Promise(r => setTimeout(r, 1000 || 0));
  galleryList = (getObjectProperty(___arguments.context.appData, 'galleryList'));
  idGalleryItem = 1;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c376c79acf1e16de2463e38439c34656', (await Promise.all(galleryList.map(async item => {  item['id'] = idGalleryItem;
    idGalleryItem = (typeof idGalleryItem == 'number' ? idGalleryItem : 0) + 1;
  ; return item;}))));
  ___arguments.context.appData['leftSlideBy'] = 240;
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 1199) {
    ___arguments.context.appData['leftSlideBy'] = 200;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 990) {
    ___arguments.context.appData['leftSlideBy'] = 260;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768) {
    ___arguments.context.appData['leftSlideBy'] = 370;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576) {
    ___arguments.context.appData['leftSlideBy'] = 250;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
    ___arguments.context.appData['leftSlideBy'] = 100;
  }
  half = Math.floor(galleryList.length / 2);
  lastList = galleryList.slice(((half + 1) - 1), galleryList.length);
  firstList = galleryList.slice(0, half);
  newGalleryList = (JSON.parse((JSON.stringify(((addItemToList(lastList, galleryList)).concat(firstList))))));
  leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
  ___arguments.context.pageData['leftStart'] = (-15 - half * leftSlideBy);
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 576) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 120);
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 480) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 130);
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 15);
  }
  left = (getObjectProperty(___arguments.context.pageData, 'leftStart'));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c376c79acf1e16de2463e38439c34656', (await Promise.all(newGalleryList.map(async item => {  item['opacity'] = 1;
    item['left'] = left;
    if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
      item['active'] = true;
    } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
      item['active'] = true;
    } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
      item['active'] = true;
    } else if (left == 0 && leftSlideBy == 100) {
      item['active'] = true;
    } else {
      item['active'] = false;
    }
    left = (typeof left == 'number' ? left : 0) + leftSlideBy;
  ; return item;}))));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/blankpage/components/e23154e2b40dc5a8ac7f9a7e34ff980f/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 480) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e23154e2b40dc5a8ac7f9a7e34ff980f'))['left'] = (String(getObjectProperty(___arguments.context.dataModel, 'left')) + String('px'));
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e23154e2b40dc5a8ac7f9a7e34ff980f'))['left'] = (String(getObjectProperty(___arguments.context.dataModel, 'left')) + String('%'));
  }
  ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e23154e2b40dc5a8ac7f9a7e34ff980f'))['opacity'] = (getObjectProperty(___arguments.context.dataModel, 'opacity'));
  if (getObjectProperty(___arguments.context.dataModel, 'active')) {
    return ['g-slider__item', 'g-slider__item_center'];
  }

  return ['g-slider__item']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/blankpage/components/108f6ec347effdbce289a49d625d03af/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['isOpenModal'] = true;
  ___arguments.context.pageData['counterSlide2'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e23154e2b40dc5a8ac7f9a7e34ff980f'), 'id'));
  ___arguments.context.pageData['currentSlide'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e23154e2b40dc5a8ac7f9a7e34ff980f'), 'id'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/blankpage/components/26a1309c25831a8af67d5fe98587c773/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    var leftSlideBy, minValue, card, left, j, maxValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  maxValue = 0;
  minValue = 0;
  leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
  if ((typeof (getObjectProperty(___arguments.context.pageData, 'stopClick')) === 'undefined') || ((new Date()).valueOf()) > (getObjectProperty(___arguments.context.pageData, 'stopClick')) + 300) {
    ___arguments.context.pageData['stopClick'] = ((new Date()).valueOf());
    var j_list = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('c376c79acf1e16de2463e38439c34656'));
    for (var j_index in j_list) {
      j = j_list[j_index];
      j['opacity'] = 1;
      j['left'] = ((getObjectProperty(j, 'left')) + leftSlideBy);
      left = (getObjectProperty(j, 'left'));
      if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
        j['active'] = true;
      } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
        j['active'] = true;
      } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
        j['active'] = true;
      } else if (left == 0 && leftSlideBy == 100) {
        j['active'] = true;
      } else {
        j['active'] = false;
      }
      if (maxValue < left) {
        maxValue = left;
        card = j;
      }
      if (minValue > left) {
        minValue = left;
      }
    }
    card['opacity'] = 0;
    card['left'] = (minValue - leftSlideBy);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/blankpage/components/0ecc200a34ec908bf97bef91588de587/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var leftSlideBy, maxValue, card, left, j, minValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  maxValue = 0;
  minValue = 0;
  leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
  if ((typeof (getObjectProperty(___arguments.context.pageData, 'stopClick')) === 'undefined') || ((new Date()).valueOf()) > (getObjectProperty(___arguments.context.pageData, 'stopClick')) + 300) {
    ___arguments.context.pageData['stopClick'] = ((new Date()).valueOf());
    var j_list = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('c376c79acf1e16de2463e38439c34656'));
    for (var j_index in j_list) {
      j = j_list[j_index];
      j['left'] = ((getObjectProperty(j, 'left')) - leftSlideBy);
      left = (getObjectProperty(j, 'left'));
      j['opacity'] = 1;
      if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
        j['active'] = true;
      } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
        j['active'] = true;
      } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
        j['active'] = true;
      } else if (left == 0 && leftSlideBy == 100) {
        j['active'] = true;
      } else {
        j['active'] = false;
      }
      if (minValue > left) {
        minValue = left;
        card = j;
      }
      if (maxValue < left) {
        maxValue = left;
      }
    }
    card['opacity'] = 0;
    card['left'] = (maxValue + leftSlideBy);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/blankpage/components/4bc8650348b45a9267097b43aff505c6/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('4bc8650348b45a9267097b43aff505c6', (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e5ac9c8ad984738efc349d07b4dc74b1'), 'socialMedia')), 'social')));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/blankpage/components/1fdde5c76ea432e90eb67a18533b2dac/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes, nameSocial;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['team-card__link', 'link'];
  nameSocial = (getObjectProperty(___arguments.context.dataModel, 'name'));
  if ((getObjectProperty(___arguments.context.dataModel, 'url')) == '') {
    addItemToList(classes, 'team-card__link_hide');
  }
  if (nameSocial == 'facebook') {
    addItemToList(classes, 'fab fa-facebook-f');
  }
  if (nameSocial == 'github') {
    addItemToList(classes, 'fab fa-github');
  }
  if (nameSocial == 'linkedin') {
    addItemToList(classes, 'fab fa-linkedin-in');
  }
  if (nameSocial == 'twitter') {
    addItemToList(classes, 'fab fa-twitter');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/blankpage/components/b6f27fbafe317fe16572dfeaf1fb0698/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
      return ((___arguments.context.appData['teamInFocus']) ? ['team__cards-block', 'team__cards-block_in-focus'] : ['team__cards-block'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/blankpage/components/d2551aa8d85f0b8224661c4aaaa53f32/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var firstName, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  firstName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName'));
  regExp = (new RegExp('.{2,}', 'g'));
  if (firstName.length <= 50) {
    ___arguments.context.pageData['firstNameValue'] = firstName;
  } else if (firstName.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['firstName'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['firstName'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'firstNameValue')), 0));
  }
  if ((regExp.test(firstName)) == false && firstName != '' || firstName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length < 2 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = false;
  } else if ((regExp.test(firstName)) == false && firstName == '' || firstName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length == 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/blankpage/components/3ddf6adfe3e580cc962a62234bf2e2d5/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-first-name'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/blankpage/components/f5bd0a2c6f0bde798ea8df133e9e7e3c/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var lastName, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  lastName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName'));
  regExp = (new RegExp('.{2,}', 'g'));
  if (lastName.length <= 50) {
    ___arguments.context.pageData['lastNameValue'] = lastName;
  } else if (lastName.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['lastName'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['lastName'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'lastNameValue')), 0));
  }
  if ((regExp.test(lastName)) == false && lastName != '' || lastName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length < 2 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = false;
  } else if ((regExp.test(lastName)) == false && lastName == '' || lastName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length == 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/blankpage/components/557e31a13073b4848ca61837b9e70a38/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-last-name'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/blankpage/components/cdc2459c0f1ac842997b621a04744c0c/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var email, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  email = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'email'));
  regExp = (new RegExp('[a-zA-Z0-9.\\-_+]{1,}@[^\\W\\.][a-zA-Z0-9\\-]{0,}\\.[a-zA-Z]{1,}', 'g'));
  if (email.length <= 50) {
    ___arguments.context.pageData['emailValue'] = email;
  } else if (email.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['email'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['email'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'emailValue')), 0));
  }
  if ((regExp.test(email)) == false && email != '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = false;
  } else if ((regExp.test(email)) == false && email == '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/blankpage/components/556317981b853c56d8e8e837e5b16c9e/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-email'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/blankpage/components/d64247a69fb82b1b932f6a1191167d68/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-phone'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/blankpage/components/1712009c62182acf71f1e4e777ac98dd/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var phone, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  phone = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone'));
  regExp = (new RegExp('^[0-9+\\(\\)\\-\\s]+$', 'g'));
  if (phone.length <= 50) {
    ___arguments.context.pageData['phoneValue'] = phone;
  } else if (phone.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['phone'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['phone'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'phoneValue')), 0));
  }
  if (phone.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone')).length < 5 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = true;
  } else if ((regExp.test(phone)) && phone.length >= 5) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = false;
  } else if (phone == '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = true;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/blankpage/components/275519e4f1ff2a5c0d556bd7bfaaf6c5/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-website'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/blankpage/components/ddb987ac0b1a2e02086b043645e7decd/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var website, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  website = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website'));
  regExp = (new RegExp('[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)', 'gi'));
  if (website.length <= 249) {
    ___arguments.context.pageData['websiteValue'] = website;
  } else if (website.length > 249) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['website'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['website'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'websiteValue')), 0));
  }
  if ((regExp.test(website)) == false && website != '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = true;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = false;
  }
  if ((regExp.test((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website')))) == true && website.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website')).length) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/blankpage/components/b17c55c60f4dcdf2ae8fc66b95b63932/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var subject, regExp, firstName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  subject = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject'));
  regExp = (new RegExp('.{4,}', 'g'));
  if (subject.length <= 300) {
    ___arguments.context.pageData['subjectValue'] = subject;
  } else if (subject.length > 300) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['subject'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['subject'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'subjectValue')), 0));
  }
  if ((regExp.test(subject)) == false && subject != '' || subject.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length < 4 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = false;
  } else if ((regExp.test(subject)) == false && subject == '' || subject.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length == 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/blankpage/components/a38dcbb7299151a66062e5a8eaaf984c/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-subject'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/blankpage/components/101b130a0c5ff2588aa5637fc5fcdb27/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-message'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage']) {
    addItemToList(classes, 'c-form__label_alert');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/blankpage/components/6eb697a67eb600c29f860957a2ac3b3e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, message, subject, website, phone, email, lastName, firstName, checkStatusMessage, checkStatusSubject, checkStatusWebsite, checkStatusPhone, checkStatusEmail, checkStatusLastName, checkStatusFirstName, statusMessage, statusSubject, statusWebsite, statusPhone, statusEmail, statusLastName, statusFirstName, company, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function ensureArray(items) {
  return Array.isArray(items) ? items : [items]
}

/**
 * Describe this function...
 */
async function firstNameValidation() {
  regExp = (new RegExp('.{2,}', 'g'));
  if (!(regExp.test(firstName)) || firstName == '' || firstName == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusFirsName() {
  if (!(getObjectProperty(statusFirstName, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('d2551aa8d85f0b8224661c4aaaa53f32')));
    if (firstName == '' || firstName == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function lastNameValidation() {
  regExp = (new RegExp('.{2,}', 'g'));
  if (!(regExp.test(lastName)) || lastName == '' || lastName == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusLastName2() {
  if (!(getObjectProperty(statusLastName, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f5bd0a2c6f0bde798ea8df133e9e7e3c')));
    if (lastName == '' || lastName == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function emailValidation() {
  regExp = (new RegExp('[a-zA-Z0-9.\\-_+]{1,}@[^\\W\\.][a-zA-Z0-9\\-]{0,}\\.[a-zA-Z]{1,}', 'g'));
  if (!(regExp.test(email)) || email == '' || email == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function phoneValidation() {
  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'hintPhone')) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusEmail2() {
  if (!(getObjectProperty(statusEmail, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('cdc2459c0f1ac842997b621a04744c0c')));
    if (email == '' || email == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function websiteValidation() {
  regExp = (new RegExp('[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)', 'gi'));
  if (!(regExp.test(website)) && website != '' && website != null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function subjectValidation() {
  regExp = (new RegExp('.{4,}', 'g'));
  if (!(regExp.test(subject)) || subject == '' || subject == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusPhone2() {
  if (!(getObjectProperty(statusPhone, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('1712009c62182acf71f1e4e777ac98dd')));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = true;
    return false;
  }
  return true
}

/**
 * Describe this function...
 */
async function messageValidation() {
  if (message == '' || message == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusWebsite2() {
  if (!(getObjectProperty(statusWebsite, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('ddb987ac0b1a2e02086b043645e7decd')));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = true;
    return false;
  }
  return true
}

/**
 * Describe this function...
 */
async function checkStatusSubject2() {
  if (!(getObjectProperty(statusSubject, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b17c55c60f4dcdf2ae8fc66b95b63932')));
    if (subject == '' || subject == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function checkStatusMessage2() {
  if (!(getObjectProperty(statusMessage, 'status'))) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage'] = true;
    return false;
  }
  return true
}


  firstName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName'));
  lastName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName'));
  email = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'email'));
  phone = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone'));
  company = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'company'));
  website = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website'));
  subject = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject'));
  message = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'message'));
  statusFirstName = await firstNameValidation();
  statusLastName = await lastNameValidation();
  statusEmail = await emailValidation();
  statusPhone = await phoneValidation();
  statusWebsite = await websiteValidation();
  statusSubject = await subjectValidation();
  statusMessage = await messageValidation();
  checkStatusMessage = await checkStatusMessage2();
  checkStatusSubject = await checkStatusSubject2();
  checkStatusWebsite = await checkStatusWebsite2();
  checkStatusPhone = await checkStatusPhone2();
  checkStatusEmail = await checkStatusEmail2();
  checkStatusLastName = await checkStatusLastName2();
  checkStatusFirstName = await checkStatusFirsName();
  if (checkStatusFirstName != false && checkStatusLastName != false && checkStatusEmail != false && checkStatusPhone != false && checkStatusWebsite != false && checkStatusSubject != false && checkStatusMessage != false) {
    await Backendless.Messaging.sendEmail('New contact from the Hyperpixels Website', new Backendless.Bodyparts({ htmlmessage: ([['From: ',firstName,' ',lastName].join(''),'<br>Email: ',email,'<br> Phone: ',phone,'<br> Website: ',website,'<br> Subject: ',subject,'<br> Message: <br>',message].join('')) }), ensureArray('mike@hyperpixels.net'), undefined);
    ___arguments.context.pageData['MessageSent'] = true;
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b65aa795e4d6a994b3ddd6f68533c80d', false);
    try {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('df8b067a885dcda84a91376a3c4c5c7c', true);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7224592bf6676a81e607676ddbc597e1', false);

    } catch (error) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('df8b067a885dcda84a91376a3c4c5c7c', false);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7224592bf6676a81e607676ddbc597e1', true);
      console.log(error);

    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/blankpage/components/58c7b83b0c67da4ff3bef93451af2162/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var message, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  message = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'message'));
  regExp = (new RegExp('.{1,}', 'g'));
  if (message.length <= 249) {
    ___arguments.context.pageData['messageValue'] = message;
  } else if (message.length > 249) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['message'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['message'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'messageValue')), 0));
  }
  if ((regExp.test(message)) == false && message == '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage'] = true;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/blankpage/components/dc8e2162b0174608379cfba7549be8db/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/blankpage/components/251dd247af0d2f6ebe6ccde3866c89b5/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isOpenModal')) {
    return ['modal', 'open'];
  }

  return ['modal']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/blankpage/components/70dd1dff3e215deab9dcc3e29a6566e6/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async onMounted(___arguments) {
    var counter, data, gallery, item, listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setIdItem(data) {
  counter = 1;
  return await Promise.all(data.map(async item => {  item['id'] = counter;
  counter = (typeof counter == 'number' ? counter : 0) + 1;
; return item;}))
}


  await new Promise(r => setTimeout(r, 1000 || 0));
  gallery = (getObjectProperty(___arguments.context.appData, 'galleryList'));
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', (await setIdItem(gallery)));
  listLength = gallery.length;
  ___arguments.context.pageData['totalSlide'] = listLength;
  ___arguments.context.pageData['currentSlide'] = 1;

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/blankpage/components/bb37f7327e62a50eb06507a61d6d1fc6/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == (getObjectProperty(___arguments.context.dataModel, 'id'))) {
    return ['modal__slider-item', 'active'];
  }

  return ['modal__slider-item']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/blankpage/components/831fddd4bb7ced9296de1aa550213577/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) > 1) {
    ___arguments.context.pageData['counterSlide2'] = ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == 1 ? listLength : (getObjectProperty(___arguments.context.pageData, 'counterSlide2')) - 1);
  }
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ___arguments.context.pageData['currentSlide'] = (getObjectProperty(___arguments.context.pageData, 'counterSlide2'));

  },
  /* handler:onClick */
  /* handler:onMouseOut */
  onMouseOut(___arguments) {
    var listLength;


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('831fddd4bb7ced9296de1aa550213577'))['opacity'] = '0';

  },
  /* handler:onMouseOut */
  /* handler:onMouseOver */
  onMouseOver(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == 1) {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('831fddd4bb7ced9296de1aa550213577'))['opacity'] = '0';
  } else {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('831fddd4bb7ced9296de1aa550213577'))['opacity'] = '1';
  }

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./pages/blankpage/components/dba21793ecfc81f1424a1ce6ad99a8b3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) < listLength) {
    ___arguments.context.pageData['counterSlide2'] = ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == listLength ? 1 : (getObjectProperty(___arguments.context.pageData, 'counterSlide2')) + 1);
  }
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ___arguments.context.pageData['currentSlide'] = (getObjectProperty(___arguments.context.pageData, 'counterSlide2'));

  },
  /* handler:onClick */
  /* handler:onMouseOut */
  onMouseOut(___arguments) {
    var listLength;


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('dba21793ecfc81f1424a1ce6ad99a8b3'))['opacity'] = '0';

  },
  /* handler:onMouseOut */
  /* handler:onMouseOver */
  onMouseOver(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == listLength) {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('dba21793ecfc81f1424a1ce6ad99a8b3'))['opacity'] = '0';
  } else {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('dba21793ecfc81f1424a1ce6ad99a8b3'))['opacity'] = '1';
  }

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./pages/blankpage/components/31c87ba294176e587b91b87c58e68a82/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/blankpage/components/16b9cb3fdc9bf1919465a18c77a6e467/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/blankpage/components/3607af0d8419be43455fc4ac16f9cc33/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var company;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  company = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'company'));
  if (company.length <= 100) {
    ___arguments.context.pageData['companyValue'] = company;
  } else if (company.length > 100) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['company'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['company'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'companyValue')), 0));
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/blankpage/components/30ea90dd05af67628fd821d5003c3d3d/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  onMounted(___arguments) {
      ___arguments.context.pageData['firstNameValue'] = '';
  ___arguments.context.pageData['lastNameValue'] = '';
  ___arguments.context.pageData['emailValue'] = '';
  ___arguments.context.pageData['phoneValue'] = '';
  ___arguments.context.pageData['companyValue'] = '';
  ___arguments.context.pageData['websiteValue'] = '';
  ___arguments.context.pageData['subjectValue'] = '';
  ___arguments.context.pageData['messageValue'] = '';

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/blankpage/components/ba557046fb5348f79a8a94c342388c04/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'MessageSent'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/blankpage/components/2d06b571aa5bcd13df8e8426dd1c5849/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2d06b571aa5bcd13df8e8426dd1c5849'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2d06b571aa5bcd13df8e8426dd1c5849'))['color'] = 'white';

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/blankpage/components/14e3a2a815e855eaa285739b916320ed/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  async ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('2d06b571aa5bcd13df8e8426dd1c5849'))['text'] = 'hello@hyperpixels.net';
  await (async function() {
  	var a = document.getElementsByClassName('emailuslink'); //or grab it by tagname etc
  	a[0].href = "mailto:hello@hyperpixels.net"
  })();

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  async ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('2d06b571aa5bcd13df8e8426dd1c5849'))['text'] = 'Email Us';
  await (async function() {
  	var a = document.getElementsByClassName('emailuslink'); //or grab it by tagname etc
  	a[0].href = "mailto:undefined"
  })();

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/dash/components/30ea90dd05af67628fd821d5003c3d3d/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  onMounted(___arguments) {
      ___arguments.context.pageData['firstNameValue'] = '';
  ___arguments.context.pageData['lastNameValue'] = '';
  ___arguments.context.pageData['emailValue'] = '';
  ___arguments.context.pageData['phoneValue'] = '';
  ___arguments.context.pageData['companyValue'] = '';
  ___arguments.context.pageData['websiteValue'] = '';
  ___arguments.context.pageData['subjectValue'] = '';
  ___arguments.context.pageData['messageValue'] = '';

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/dash/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var AvailableHoursAfterTickets, MaximumWeeklyHours, MaximumHoursPerMonth, Contract_Hours, Posted_Hours, companyRecord, result, user, temp, step3, step2, TotalAnnually, ContractHours, base, SecondList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function LoginOnPageRefresh() {
  Backendless.setCurrentUserToken(((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('HPUserCookie')));
  user = (await Backendless.UserService.getCurrentUser(false));
  ___arguments.context.appData['currentUser'] = user;
  ___arguments.context.appData['companyNamesLIST'] = (getObjectProperty(((await Backendless.Data.of('Users').loadRelations((getObjectProperty((getObjectProperty(___arguments.context.appData, 'currentUser')), 'objectId')), Backendless.LoadRelationsQueryBuilder.create().setRelationName('Linked_Companies').setPageSize(10).setOffset(0)))[0]), 'Company_Name'));
  ___arguments.context.appData['currentCompanyObjectId'] = (getObjectProperty(((await Backendless.Data.of('Users').loadRelations((getObjectProperty((getObjectProperty(___arguments.context.appData, 'currentUser')), 'objectId')), Backendless.LoadRelationsQueryBuilder.create().setRelationName('Linked_Companies').setPageSize(1).setOffset(0)))[0]), 'objectId'));
  if ((getObjectProperty((getObjectProperty(___arguments.context.appData, 'currentUser')), 'objectId')) == 'C1448329-3668-4275-8C4E-866D8C665C4F') {
    ___arguments.context.pageData['userIsAdmin'] = true;
  } else {
    ___arguments.context.pageData['userIsAdmin'] = false;
  }
}

/**
 * Describe this function...
 */
async function CalculateRemainingHours(companyRecord) {
  AvailableHoursAfterTickets = 0;
  temp = 0;
  temp = (Number((Number.parseFloat((getObjectProperty(((await Backendless.Data.of('Support_Tickets').find(Backendless.DataQueryBuilder.create().setWhereClause((['Linked_Company.objectId = \'',(getObjectProperty(___arguments.context.appData, 'currentCompanyObjectId')),'\' AND Ignore_Ticket_Hours = false'].join(''))).setProperties('SUM (Time_Spent) AS TotalTicketsTimeSpent').setPageSize(10)))[0]), 'TotalTicketsTimeSpent'))).toFixed(1))));
  AvailableHoursAfterTickets = Posted_Hours + temp;
  return AvailableHoursAfterTickets
}

/**
 * Describe this function...
 */
async function MaximumMonthlyHours(ContractHours, AvailableHoursAfterTickets) {
  TotalAnnually = ContractHours * 12;
  step2 = TotalAnnually + AvailableHoursAfterTickets;
  step3 = step2 / 12;
  return step3
}


  await LoginOnPageRefresh();
  if ((await Backendless.UserService.isValidLogin()) == true) {
    ___arguments.context.pageData['MonthlyContractHours'] = 0;
    ___arguments.context.pageData['MaximumWeeklyHours'] = 0;
    ___arguments.context.pageData['MaximumMonthlyHours'] = 0;
    await (async function() {
    	const el = document.querySelectorAll('.content_editable')
    	
    	for (let i = 0; i < el.length; i++) {
    	let theDataID = el[i].classList.item(0);
    	theDataID = theDataID.substring(5);
    	el[i].setAttribute('contenteditable', 'true')
    	// el[i].setAttribute('data-modified','true') // We're now only modifying this attribute when the user clicks on the element.
    	el[i].setAttribute('data-source',theDataID)
    	
    	}
    })();
    ___arguments.context.pageData['inEditMode'] = true;
    ___arguments.context.pageData['editingPage'] = true;
    ___arguments.context.pageData['TicketsPageOffset'] = 0;
    result = (await (async function(pageData) {
    	/*
    	var myFunction = (e)=> {
    	  console.log(e.currentTarget.getAttribute('data-source'));        
    	}
    	*/
    	
    	var myFunction2 = (e)=> {
    	  pageData.currentDataSource = e.currentTarget.getAttribute('data-source'); // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
    	  console.log("pageData.currentDataSource =: "+ pageData.currentDataSource);
    	  pageData.isOpenModal = true;
    	  return pageData  
    	};
    	
    	Array.from(document.getElementsByClassName('content_editable')).forEach((editable) => {
    	  // editable.addEventListener('click', myFunction);
    	  editable.addEventListener('dblclick', myFunction2);
    	  window.___MY_EVENT_LISTENER  = myFunction2;
    	});  
    	
    	// Previously, we had this code: 
    	/* 
    	Array.from(document.getElementsByClassName('content_editable')).forEach((editable) => {
    	  editable.addEventListener('click', (e)=>{
    	    // e.preventDefault();
    	    console.log(e.currentTarget.getAttribute('data-source'));        
    	    // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
    	    // e.target will trap the actual element that was clicked
    	  });
    	*/
    	
    	/* Function backup
    	editable.addEventListener('dblclick', (e)=>{
    	    // e.preventDefault();
    	    pageData.currentDataSource = e.currentTarget.getAttribute('data-source'); // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
    	    // console.log("A double-click has occurred on element with the ID of: "+ runner.currentDataSource);
    	    console.log("pageData.currentDataSource =: "+ pageData.currentDataSource);
    	    // e.target will trap the actual element that was double-clicked
    	    pageData.isOpenModal = true;
    	    return pageData
    	  });
    	*/
    })(___arguments.context.pageData));
    companyRecord = (await Backendless.Data.of('Total_Company_Hours_View').find(Backendless.DataQueryBuilder.create().setWhereClause((['Company_ObjectID = \'',(getObjectProperty(___arguments.context.appData, 'currentCompanyObjectId')),'\''].join(''))).setPageSize(1)))[0];
    Contract_Hours = (Number((getObjectProperty(companyRecord, 'Contract_Hours'))));
    Posted_Hours = (Number((getObjectProperty(companyRecord, 'Posted_Hours'))));
    AvailableHoursAfterTickets = (Number.parseFloat((getObjectProperty(((await Backendless.Data.of('Support_Tickets').find(Backendless.DataQueryBuilder.create().setWhereClause((['Linked_Company.objectId = \'',(getObjectProperty(___arguments.context.appData, 'currentCompanyObjectId')),'\' AND Ignore_Ticket_Hours = false'].join(''))).setProperties('SUM (Time_Spent) AS TotalTicketsTimeSpent').setPageSize(10)))[0]), 'TotalTicketsTimeSpent'))).toFixed(1));
    AvailableHoursAfterTickets = await CalculateRemainingHours(companyRecord);
    MaximumHoursPerMonth = await MaximumMonthlyHours(Contract_Hours, AvailableHoursAfterTickets - 0);
    MaximumWeeklyHours = (Number(MaximumHoursPerMonth)) / 2.875;
    (function (componentUid, dataModel) { ___arguments.context.setComponentDataStoreByUid(componentUid, dataModel) })('e8a685b9572768044a561f8d3532499b', ({ [`CompanyName`]: (getObjectProperty(___arguments.context.appData, 'companyNamesLIST')),[`AvailableHours`]: Posted_Hours,[`MonthlyContractHours`]: Contract_Hours,[`MaximumMonthlyHours`]: (Number.parseFloat(MaximumHoursPerMonth).toFixed(1)),[`MaximumWeeklyHours`]: (Number.parseFloat(MaximumWeeklyHours).toFixed(1)),[`MaximumDailyHours`]: (Number.parseFloat(((Number(MaximumWeeklyHours)) / 4)).toFixed(1)),[`AvailableHoursAfterTickets`]: (Number.parseFloat(AvailableHoursAfterTickets).toFixed(1)),[`StoredAvailableHoursAfterTickets`]: (Number.parseFloat(AvailableHoursAfterTickets).toFixed(1)) }));
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('b90af9e3067c25f7b9adb5714b69b61e', (JSON.parse((JSON.stringify((await Backendless.Data.of('Support_Tickets').find(Backendless.DataQueryBuilder.create().setSortBy(['Ticket_Date DESC']).setPageSize(25).setOffset((getObjectProperty(___arguments.context.pageData, 'TicketsPageOffset'))))))))));
    ___arguments.context.pageData['LoadMoreTicketsEnabled'] = true;
  } else {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
  }

  },
  /* handler:onEnter */
  /* handler:onScroll */
  ['onScroll'](___arguments) {
      if (___arguments.scrollTop) {
    ___arguments.context.pageData['scrollTop'] = true;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['box-shadow'] = '0px 2px 4px rgba(0, 0, 0, 0.1)';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['height'] = '60px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-top'] = '7px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-bottom'] = '7px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('59d442df335dc04e702817c7015fea4c'))['color'] = 'black';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '-5px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '45px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#777777';
  } else {
    console.log('We\'re at the "Scroll Top"');
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['display'] = 'flex';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('59d442df335dc04e702817c7015fea4c'))['color'] = 'white';
    ___arguments.context.pageData['scrollTop'] = false;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['box-shadow'] = 'none';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['height'] = '80px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['background-color'] = 'transparent';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-top'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '80px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#ffffff';
  }

  },
  /* handler:onScroll */
  /* content */
}))

define('./pages/dash/components/19f1da6844bcd24c52e983cfbdbe9022/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (true) {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) < 6) {
      ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c')['Ticket_Status'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) + 1);
    } else {
      ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c')['Ticket_Status'] = 1;
    }
  }

  },
  /* handler:onClick */
  /* handler:onDelete */
  ['onDelete'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c')['Ticket_Status'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'OnLoad_TicketStatus'));

  },
  /* handler:onDelete */
  /* handler:onFrontIconAssignment */
  ['onFrontIconAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 1) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#BC2C45';
    return 'fiber_new';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 2) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#D4860B';
    return 'bedtime';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 3) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#04749C';
    return 'flip_camera_ios';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 4) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#642C8D';
    return 'access_time';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 5) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#AAAA22';
    return 'person_pin';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 6) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#04842D';
    return 'check_box';
  }

  },
  /* handler:onFrontIconAssignment */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 1) {
    return 'New';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 2) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#D4860B';
    return 'Pending';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 3) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#04749C';
    return 'In Progress';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 4) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#642C8D';
    return 'Waiting On Support';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 5) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#AAAA22';
    return 'Waiting On Client';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 6) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#04842D';
    return 'Completed!';
  }

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/dash/components/85be065e23aa1f58df8a71bbb6dc7662/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c')['OnLoad_TicketStatus'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status'));

  },
  /* handler:onMounted */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    
  },
  /* handler:onStyleAssignment */
  /* handler:onBeforeMount */
  ['onBeforeMount'](___arguments) {
    
  },
  /* handler:onBeforeMount */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log('Clickety-click');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dash/components/c6a2930ccba2d71d496e8503f464b1d7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var decision, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  if ((getObjectProperty(___arguments.context.pageData, 'isTicketSelected')) == true) {
    console.log(await asyncListFilter(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('b90af9e3067c25f7b9adb5714b69b61e')), async (item) => {
       if ((getObjectProperty(item, 'CurrentlySelectedTicket')) == true) {
        delete item['CurrentlySelectedTicket'];
        decision = true;
      } else {
        decision = true;
      }


     return decision;
    }));
  } else {
  }
  ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c')['CurrentlySelectedTicket'] = true;
  ___arguments.context.pageData['OpenDrawer'] = true;
  ___arguments.context.pageData['isTicketSelected'] = true;
  ___arguments.context.pageData['CurrentlySelectedTicketObjectID'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'objectId'));
  ___arguments.context.pageData['PassedDataModel'] = (JSON.parse((JSON.stringify(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c')))));
  (function (componentUid, dataModel) { ___arguments.context.setComponentDataStoreByUid(componentUid, dataModel) })('416556824f34d6d9385836c756cc014c', (JSON.parse((JSON.stringify(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'))))));
  (function (component) { component.el.scrollIntoView() })((getObjectProperty(___arguments.context.pageData, (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'objectId')))));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dash/components/93cf5db23368ed8bffab3de1d62822b7/bundle.js', [], () => ({
  /* content */
  /* handler:onOpen */
  ['onOpen'](___arguments) {
    
  },
  /* handler:onOpen */
  /* handler:isOpenLogic */
  ['isOpenLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'OpenDrawer'))

  },
  /* handler:isOpenLogic */
  /* handler:onClose */
  async ['onClose'](___arguments) {
    var decision, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  if ((getObjectProperty(___arguments.context.pageData, 'isTicketSelected')) == true) {
    console.log((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('b90af9e3067c25f7b9adb5714b69b61e'));
    console.log(await asyncListFilter(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('b90af9e3067c25f7b9adb5714b69b61e')), async (item) => {
       if ((getObjectProperty(item, 'CurrentlySelectedTicket')) == true) {
        delete item['CurrentlySelectedTicket'];
        decision = true;
      } else {
        decision = true;
      }


     return decision;
    }));
  } else {
  }
  ___arguments.context.pageData['OpenDrawer'] = false;
  (getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))['CurrentlySelectedTicket'] = false;
  ___arguments.context.pageData['CurrentlySelectedTicketObjectID'] = '';

  },
  /* handler:onClose */
  /* content */
}))

define('./pages/dash/components/b67b940bd10c4493f3ef6fae2c284b88/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid, event) {  ___arguments.context.getComponentByUid(componentUid).close(event) })('93cf5db23368ed8bffab3de1d62822b7', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dash/components/69985b47bd6b45fa795a184becd896f4/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Name'))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/dash/components/8e55ba1c2e0693d19426a8db2e524eae/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Notes'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dash/components/515a6d6655e2913b394dcf2c6a941c7f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var result, currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (await Backendless.UserService.isValidLogin()) {
    if ((getObjectProperty((getObjectProperty(___arguments.context.appData, 'currentUser')), 'objectId')) == 'C1448329-3668-4275-8C4E-866D8C665C4F') {
      ___arguments.context.pageData['adminEditMode'] = true;
    } else {
      ___arguments.context.pageData['adminEditMode'] = false;
    }
  }
  if (currentUser == true) {
    console.log('We\'re the Admin!');
  }
  await (async function() {
  	const el = document.querySelectorAll('.content_editable')
  	
  	for (let i = 0; i < el.length; i++) {
  	let theDataID = el[i].classList.item(0);
  	theDataID = theDataID.substring(5);
  	el[i].setAttribute('contenteditable', 'true')
  	// el[i].setAttribute('data-modified','true') // We're now only modifying this attribute when the user clicks on the element.
  	el[i].setAttribute('data-source',theDataID)
  	
  	}
  })();
  ___arguments.context.pageData['inEditMode'] = true;
  ___arguments.context.pageData['editingPage'] = true;
  ___arguments.context.pageData['isOpenModal'] = false;
  result = (await (async function(pageData) {
  	/*
  	var myFunction = (e)=> {
  	  console.log(e.currentTarget.getAttribute('data-source'));        
  	}
  	*/
  	
  	var myFunction2 = (e)=> {
  	  pageData.currentDataSource = e.currentTarget.getAttribute('data-source'); // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
  	  console.log("pageData.currentDataSource =: "+ pageData.currentDataSource);
  	  pageData.isOpenModal = true;
  	  return pageData  
  	};
  	
  	Array.from(document.getElementsByClassName('content_editable')).forEach((editable) => {
  	  // editable.addEventListener('click', myFunction);
  	  editable.addEventListener('dblclick', myFunction2);
  	  window.___MY_EVENT_LISTENER  = myFunction2;
  	});  
  	
  	// Previously, we had this code: 
  	/* 
  	Array.from(document.getElementsByClassName('content_editable')).forEach((editable) => {
  	  editable.addEventListener('click', (e)=>{
  	    // e.preventDefault();
  	    console.log(e.currentTarget.getAttribute('data-source'));        
  	    // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
  	    // e.target will trap the actual element that was clicked
  	  });
  	*/
  	
  	/* Function backup
  	editable.addEventListener('dblclick', (e)=>{
  	    // e.preventDefault();
  	    pageData.currentDataSource = e.currentTarget.getAttribute('data-source'); // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
  	    // console.log("A double-click has occurred on element with the ID of: "+ runner.currentDataSource);
  	    console.log("pageData.currentDataSource =: "+ pageData.currentDataSource);
  	    // e.target will trap the actual element that was double-clicked
  	    pageData.isOpenModal = true;
  	    return pageData
  	  });
  	*/
  })(___arguments.context.pageData));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dash/components/e7d4ce9742a74274a8f758c81d36cedd/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Description'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dash/components/39359e517e8299f37c9ad6f3878cdd7c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) < 6) {
    (getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))['Ticket_Status'] = ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) + 1);
    console.log(String('The ticket has been incremented: ') + String(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')));
  } else {
    (getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))['Ticket_Status'] = 1;
    console.log(String('The ticket has been reset to 1: ') + String(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')));
  }

  },
  /* handler:onClick */
  /* handler:onDelete */
  ['onDelete'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))['Ticket_Status'] = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'OnLoad_TicketStatus'));

  },
  /* handler:onDelete */
  /* handler:onFrontIconAssignment */
  ['onFrontIconAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 1) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#BC2C45';
    return 'fiber_new';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 2) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#D4860B';
    return 'bedtime';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 3) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#04749C';
    return 'flip_camera_ios';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 4) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#642C8D';
    return 'access_time';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 5) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#AAAA22';
    return 'person_pin';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 6) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#04842D';
    return 'check_box';
  } else {
    console.log(String('The Ticket_Status of the PassedDataModel is: ') + String(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')));
  }
  console.log(String('The Ticket_Status of the PassedDataModel of pageData is: ') + String(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')));

  },
  /* handler:onFrontIconAssignment */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 1) {
    console.log(String('The label has been updated with: ') + String('New'));
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#BC2C45';
    return 'New';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 2) {
    console.log(String('The label has been updated with: ') + String('Pending'));
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#D4860B';
    return 'Pending';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 3) {
    console.log(String('The label has been updated with: ') + String('In Progress'));
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#04749C';
    return 'In Progress';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 4) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#642C8D';
    return 'Waiting On Support';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 5) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#AAAA22';
    return 'Waiting On Client';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 6) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#04842D';
    return 'Completed!';
  }
  console.log(String('The Ticket_Status of the PassedDataModel of pageData is: ') + String(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')));

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/dash/components/8516f829a89573aea1ecd9c694449d54/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))['OnLoad_TicketStatus'] = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status'));

  },
  /* handler:onMounted */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    
  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/dash/components/c3213a164feed6ef2b22e331e4a31b04/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dash/components/2c8a6f746f7c4274b274dc68d6cfe88c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'OnLoad_TicketStatus'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dash/components/f20f534105889b957ec106deb1557f36/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'CompanyLogoURL'))

  },
  /* handler:onSourceUrlAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dash/components/a0e8e78e083d16d87ca9d50b632d3b01/bundle.js', [], () => ({
  /* content */
  /* handler:onTooltipTextAssignment */
  ['onTooltipTextAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'CompanyName'))

  },
  /* handler:onTooltipTextAssignment */
  /* content */
}))

define('./pages/dash/components/2cc11f9da0d3cf1bc6eaf6c3d7cc6b1d/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  async ['onMouseOver'](___arguments) {
    var CombinedList, SecondList, FirstList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'LoadMoreTicketsEnabled')) == true) {
    console.log('Loading more tickets...');
    ___arguments.context.pageData['TicketsPageOffset'] = ((getObjectProperty(___arguments.context.pageData, 'TicketsPageOffset')) + 25);
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('2cc11f9da0d3cf1bc6eaf6c3d7cc6b1d'))['content'] = 'Please wait...';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2cc11f9da0d3cf1bc6eaf6c3d7cc6b1d'))['color'] = '#FFFFFF';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('ed7ad4ee925a6e43b31917edc1187b21'))['background-color'] = '#8D2435';
    FirstList = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('b90af9e3067c25f7b9adb5714b69b61e'));
    SecondList = (JSON.parse((JSON.stringify((await Backendless.Data.of('Support_Tickets').find(Backendless.DataQueryBuilder.create().setSortBy(['Ticket_Date DESC']).setPageSize(25).setOffset((getObjectProperty(___arguments.context.pageData, 'TicketsPageOffset')))))))));
    CombinedList = (FirstList.concat(SecondList));
    console.log(CombinedList);
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('b90af9e3067c25f7b9adb5714b69b61e', (JSON.parse((JSON.stringify(CombinedList)))));
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('ed7ad4ee925a6e43b31917edc1187b21'))['background-color'] = '#CCC6C9';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2cc11f9da0d3cf1bc6eaf6c3d7cc6b1d'))['color'] = '#000000';
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('2cc11f9da0d3cf1bc6eaf6c3d7cc6b1d'))['content'] = 'Load More Tickets';
  }

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./pages/dash/components/2287e385c14b9a056f5558f38ba29a1c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['Showing tickets: (',(getObjectProperty(___arguments.context.pageData, 'TicketsPageOffset')) + 1,' to ',(getObjectProperty(___arguments.context.pageData, 'TicketsPageOffset')) + 25,')'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dash/components/441491b9e1deb0c17132e707fcd80b87/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    
  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/dash/components/a2468eeb4bd7d673efa3ee5aa0559f7c/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.dataModel, 'CurrentlySelectedTicket')) == true) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('a2468eeb4bd7d673efa3ee5aa0559f7c'))['background-color'] = '#990939';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('a2468eeb4bd7d673efa3ee5aa0559f7c'))['background-color'] = '#465466';
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onBeforeMount */
  ['onBeforeMount'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData[(getObjectProperty(___arguments.context.dataModel, 'objectId'))] = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('a2468eeb4bd7d673efa3ee5aa0559f7c'));

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./pages/dash/components/7071be4a758ce1d204f23c034e03d6c9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var userIsAdmin;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'userIsAdmin')) == true) {
    console.log((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('e8a685b9572768044a561f8d3532499b'));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dash/components/275722ccc9e2fca59d2a63e2f361255e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('Created: ') + String(new Intl.DateTimeFormat(undefined, undefined).format((getObjectProperty(___arguments.context.getComponentDataStoreByUid('cfe4133897c28fce29fc7133eaf4d168'), 'created')))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dash/components/c68c7976ca6566e903887c550947d78e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(Object.keys((getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))));

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    if (getObjectProperty(___arguments.context.pageData, 'userIsAdmin')) {
      return true;
    } else {
      return false;
    }

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/dash/components/877e8a2d53dbf2e341ffc846a3294f7a/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))['Ticket_Date'] = ___arguments.value;
  console.log(String('The value has been updated to: ') + String(___arguments.value));

  },
  /* handler:onChange */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Date'))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/dash/components/44ac1ba49a4d63c3946a53c930cf4406/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(getObjectProperty(___arguments.context.pageData, 'PassedDataModel'));

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    if (getObjectProperty(___arguments.context.pageData, 'userIsAdmin')) {
      return true;
    } else {
      return false;
    }

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/dash/components/7fb9e9c4c3da88da59ba018ecb4f822c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.Data.of('Support_Tickets').save( ({ [`objectId`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'objectId')),[`Ticket_Name`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Name')),[`CompanyName`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'CompanyName')),[`Time_Spent`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Time_Spent')),[`Ticket_Status`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')),[`Linked_Invoice_Reference`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Linked_Invoice_Reference')),[`Notes`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Notes')),[`Ticket_Entered_Date`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Date')),[`Ticket_Completed_Date`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Completed_Date')),[`Ticket_Invoice_Amount`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Invoice_Amount')) }) );
  ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('7fb9e9c4c3da88da59ba018ecb4f822c'))['color'] = '#03B935';

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    
  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/dash/components/c12bf915588659d3218289b8fe330fad/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    if (getObjectProperty(___arguments.context.pageData, 'userIsAdmin')) {
      console.log(Object.keys(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('b90af9e3067c25f7b9adb5714b69b61e'))));
    } else {
    }

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'userIsAdmin'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/dash/components/abd3be542d37e75a89c54245b45d2609/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Time_Spent'))

  },
  /* handler:onValueAssignment */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'adminEditMode')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/dash/components/b3c4aecc5ab8d2eebde7ac0aadcd972c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var error;


  try {
    console.log(Object.keys(___arguments.context.pageData));

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'userIsAdmin'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/dash/components/4415b4e21c67b99451b70838d6c8aa34/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log(Object.keys(___arguments.context.getComponentDataStoreByUid('416556824f34d6d9385836c756cc014c')));

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    if (getObjectProperty(___arguments.context.pageData, 'userIsAdmin')) {
      return true;
    } else {
      return false;
    }

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/dash/components/7be513ee0185358560eee36b6ee5a3f3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log(___arguments.context.getComponentDataStoreByUid('416556824f34d6d9385836c756cc014c'));

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    if (getObjectProperty(___arguments.context.pageData, 'userIsAdmin')) {
      return true;
    } else {
      return false;
    }

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/dash/components/a95140a9aebb95d8e7feb779ee56d03c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('cfe4133897c28fce29fc7133eaf4d168'), 'Ticket_Date')))).toDateString())

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dash/components/ec82e71e2748fdc3725d123957ae9596/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var selectedTicketObjectId, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedTicketObjectId = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'objectId'));
  console.log(selectedTicketObjectId);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dash/components/1982dd0935fcbca79a28b2831716b944/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'userIsAdmin'))

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log(___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dash/components/e2ed299fdf778674ae3ff5c65b24d038/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'userIsAdmin'))

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    if (getObjectProperty(___arguments.context.pageData, 'userIsAdmin')) {
      console.log((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('b90af9e3067c25f7b9adb5714b69b61e'));
    } else {
    }

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dash/components/538e1fea549d85e8a01c6153c1264d42/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('e8a685b9572768044a561f8d3532499b')['AvailableHoursAfterTickets'] = ___arguments.value;
  ___arguments.context.getComponentDataStoreByUid('e8a685b9572768044a561f8d3532499b')['MaximumMonthlyHours'] = (Number.parseFloat(((((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e8a685b9572768044a561f8d3532499b'), 'MonthlyContractHours')) * 12 + (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e8a685b9572768044a561f8d3532499b'), 'AvailableHoursAfterTickets'))) - 0) / 12)).toFixed(1));
  ___arguments.context.getComponentDataStoreByUid('e8a685b9572768044a561f8d3532499b')['MaximumWeeklyHours'] = (Number.parseFloat(((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e8a685b9572768044a561f8d3532499b'), 'MaximumMonthlyHours')) / 2.875)).toFixed(1));
  ___arguments.context.getComponentDataStoreByUid('e8a685b9572768044a561f8d3532499b')['MaximumDailyHours'] = (Number.parseFloat(((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e8a685b9572768044a561f8d3532499b'), 'MaximumWeeklyHours')) / 4)).toFixed(1));

  },
  /* handler:onChange */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e8a685b9572768044a561f8d3532499b'), 'AvailableHoursAfterTickets'))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/dash/components/44fae8f783292a27a3c8ced9197ad74a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('e8a685b9572768044a561f8d3532499b')['AvailableHoursAfterTickets'] = (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e8a685b9572768044a561f8d3532499b'), 'StoredAvailableHoursAfterTickets'))));
  ___arguments.context.getComponentDataStoreByUid('e8a685b9572768044a561f8d3532499b')['MaximumMonthlyHours'] = (Number.parseFloat(((((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e8a685b9572768044a561f8d3532499b'), 'MonthlyContractHours')) * 12 + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e8a685b9572768044a561f8d3532499b'), 'StoredAvailableHoursAfterTickets'))))) - 0) / 12)).toFixed(1));
  ___arguments.context.getComponentDataStoreByUid('e8a685b9572768044a561f8d3532499b')['MaximumWeeklyHours'] = (Number.parseFloat(((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e8a685b9572768044a561f8d3532499b'), 'MaximumMonthlyHours')) / 2.875)).toFixed(1));
  ___arguments.context.getComponentDataStoreByUid('e8a685b9572768044a561f8d3532499b')['MaximumDailyHours'] = (Number.parseFloat(((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e8a685b9572768044a561f8d3532499b'), 'MaximumWeeklyHours')) / 4)).toFixed(1));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dash_6-20_AnimationWorking/components/30ea90dd05af67628fd821d5003c3d3d/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  onMounted(___arguments) {
      ___arguments.context.pageData['firstNameValue'] = '';
  ___arguments.context.pageData['lastNameValue'] = '';
  ___arguments.context.pageData['emailValue'] = '';
  ___arguments.context.pageData['phoneValue'] = '';
  ___arguments.context.pageData['companyValue'] = '';
  ___arguments.context.pageData['websiteValue'] = '';
  ___arguments.context.pageData['subjectValue'] = '';
  ___arguments.context.pageData['messageValue'] = '';

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/dash_6-20_AnimationWorking/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var Contract_Hours, Posted_Hours, result, user;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function LoginOnPageRefresh() {
  Backendless.setCurrentUserToken(((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('HPUserCookie')));
  user = (await Backendless.UserService.getCurrentUser(false));
  ___arguments.context.appData['currentUser'] = user;
  ___arguments.context.appData['companyNamesLIST'] = (getObjectProperty(((await Backendless.Data.of('Users').loadRelations((getObjectProperty((getObjectProperty(___arguments.context.appData, 'currentUser')), 'objectId')), Backendless.LoadRelationsQueryBuilder.create().setRelationName('Linked_Companies').setPageSize(10).setOffset(0)))[0]), 'Company_Name'));
  ___arguments.context.appData['currentCompanyObjectId'] = (getObjectProperty(((await Backendless.Data.of('Users').loadRelations((getObjectProperty((getObjectProperty(___arguments.context.appData, 'currentUser')), 'objectId')), Backendless.LoadRelationsQueryBuilder.create().setRelationName('Linked_Companies').setPageSize(1).setOffset(0)))[0]), 'objectId'));
}


  if (await Backendless.UserService.isValidLogin()) {
    await LoginOnPageRefresh();
    ___arguments.context.pageData['MonthlyContractHours'] = 0;
    ___arguments.context.pageData['MaximumWeeklyHours'] = 0;
    ___arguments.context.pageData['MaximumMonthlyHours'] = 0;
    await (async function() {
    	const el = document.querySelectorAll('.content_editable')
    	
    	for (let i = 0; i < el.length; i++) {
    	let theDataID = el[i].classList.item(0);
    	theDataID = theDataID.substring(5);
    	el[i].setAttribute('contenteditable', 'true')
    	// el[i].setAttribute('data-modified','true') // We're now only modifying this attribute when the user clicks on the element.
    	el[i].setAttribute('data-source',theDataID)
    	
    	}
    })();
    ___arguments.context.pageData['inEditMode'] = true;
    ___arguments.context.pageData['editingPage'] = true;
    result = (await (async function(pageData) {
    	/*
    	var myFunction = (e)=> {
    	  console.log(e.currentTarget.getAttribute('data-source'));        
    	}
    	*/
    	
    	var myFunction2 = (e)=> {
    	  pageData.currentDataSource = e.currentTarget.getAttribute('data-source'); // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
    	  console.log("pageData.currentDataSource =: "+ pageData.currentDataSource);
    	  pageData.isOpenModal = true;
    	  return pageData  
    	};
    	
    	Array.from(document.getElementsByClassName('content_editable')).forEach((editable) => {
    	  // editable.addEventListener('click', myFunction);
    	  editable.addEventListener('dblclick', myFunction2);
    	  window.___MY_EVENT_LISTENER  = myFunction2;
    	});  
    	
    	// Previously, we had this code: 
    	/* 
    	Array.from(document.getElementsByClassName('content_editable')).forEach((editable) => {
    	  editable.addEventListener('click', (e)=>{
    	    // e.preventDefault();
    	    console.log(e.currentTarget.getAttribute('data-source'));        
    	    // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
    	    // e.target will trap the actual element that was clicked
    	  });
    	*/
    	
    	/* Function backup
    	editable.addEventListener('dblclick', (e)=>{
    	    // e.preventDefault();
    	    pageData.currentDataSource = e.currentTarget.getAttribute('data-source'); // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
    	    // console.log("A double-click has occurred on element with the ID of: "+ runner.currentDataSource);
    	    console.log("pageData.currentDataSource =: "+ pageData.currentDataSource);
    	    // e.target will trap the actual element that was double-clicked
    	    pageData.isOpenModal = true;
    	    return pageData
    	  });
    	*/
    })(___arguments.context.pageData));
    Contract_Hours = (getObjectProperty(((await Backendless.Data.of('Total_Company_Hours_View').find(Backendless.DataQueryBuilder.create().setWhereClause((['Company_ObjectID = \'',(getObjectProperty(___arguments.context.appData, 'currentCompanyObjectId')),'\''].join(''))).setPageSize(10)))[0]), 'Contract_Hours'));
    Posted_Hours = (getObjectProperty(((await Backendless.Data.of('Total_Company_Hours_View').find(Backendless.DataQueryBuilder.create().setWhereClause((['Company_ObjectID = \'',(getObjectProperty(___arguments.context.appData, 'currentCompanyObjectId')),'\''].join(''))).setPageSize(10)))[0]), 'Posted_Hours'));
    (function (componentUid, dataModel) { ___arguments.context.setComponentDataStoreByUid(componentUid, dataModel) })('e8a685b9572768044a561f8d3532499b', ({ [`CompanyName`]: (getObjectProperty(___arguments.context.appData, 'companyNamesLIST')),[`AvailableHours`]: Posted_Hours,[`MonthlyContractHours`]: Contract_Hours,[`MaximumMonthlyHours`]: (Number(Contract_Hours)) * 2 <= Posted_Hours ? (Number(Contract_Hours)) * 2 : Posted_Hours,[`MaximumWeeklyHours`]: ((Number(Contract_Hours)) / 4) * 2,[`MaximumDailyHours`]: (Number.parseFloat(((Number(Contract_Hours)) / 5)).toFixed(1)) }));
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('b90af9e3067c25f7b9adb5714b69b61e', (await Backendless.Data.of('Support_Tickets').find(Backendless.DataQueryBuilder.create().setSortBy(['Ticket_Date DESC']).setPageSize(25))));
  } else {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
  }

  },
  /* handler:onEnter */
  /* handler:onScroll */
  ['onScroll'](___arguments) {
      if (___arguments.scrollTop) {
    ___arguments.context.pageData['scrollTop'] = true;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['box-shadow'] = '0px 2px 4px rgba(0, 0, 0, 0.1)';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['height'] = '60px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-top'] = '7px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-bottom'] = '7px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('59d442df335dc04e702817c7015fea4c'))['color'] = 'black';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '-5px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '45px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#777777';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('59d442df335dc04e702817c7015fea4c'))['color'] = 'white';
    ___arguments.context.pageData['scrollTop'] = false;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['box-shadow'] = 'none';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['height'] = '80px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['background-color'] = 'transparent';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-top'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '80px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#ffffff';
  }

  },
  /* handler:onScroll */
  /* content */
}))

define('./pages/dash_6-20_AnimationWorking/components/19f1da6844bcd24c52e983cfbdbe9022/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) < 6) {
    ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c')['Ticket_Status'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) + 1);
  } else {
    ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c')['Ticket_Status'] = 1;
  }

  },
  /* handler:onClick */
  /* handler:onDelete */
  ['onDelete'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c')['Ticket_Status'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'OnLoad_TicketStatus'));

  },
  /* handler:onDelete */
  /* handler:onFrontIconAssignment */
  ['onFrontIconAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 1) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#BC2C45';
    return 'fiber_new';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 2) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#D4860B';
    return 'bedtime';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 3) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#04749C';
    return 'flip_camera_ios';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 4) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#642C8D';
    return 'access_time';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 5) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#AAAA22';
    return 'person_pin';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 6) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#04842D';
    return 'check_box';
  }

  },
  /* handler:onFrontIconAssignment */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 1) {
    return 'New';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 2) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#D4860B';
    return 'Pending';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 3) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#04749C';
    return 'In Progress';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 4) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#642C8D';
    return 'Waiting On Support';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 5) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#AAAA22';
    return 'Waiting On Client';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 6) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#04842D';
    return 'Completed!';
  }

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/dash_6-20_AnimationWorking/components/85be065e23aa1f58df8a71bbb6dc7662/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c')['OnLoad_TicketStatus'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status'));

  },
  /* handler:onMounted */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'CurrentlySelectedTicket')) == true) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('85be065e23aa1f58df8a71bbb6dc7662'))['background-color'] = '#990939';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('85be065e23aa1f58df8a71bbb6dc7662'))['background-color'] = '#465466';
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/dash_6-20_AnimationWorking/components/c6a2930ccba2d71d496e8503f464b1d7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c')['CurrentlySelectedTicket'] = true;
  ___arguments.context.pageData['OpenDrawer'] = true;
  ___arguments.context.pageData['isTicketSelected'] = true;
  ___arguments.context.pageData['CurrentlySelectedTicketObjectID'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'objectId'));
  ___arguments.context.pageData['PassedDataModel'] = ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c');
  console.log(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dash_6-20_AnimationWorking/components/93cf5db23368ed8bffab3de1d62822b7/bundle.js', [], () => ({
  /* content */
  /* handler:onOpen */
  ['onOpen'](___arguments) {
      console.log('The drawer is open???');

  },
  /* handler:onOpen */
  /* handler:isOpenLogic */
  ['isOpenLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'OpenDrawer'))

  },
  /* handler:isOpenLogic */
  /* handler:onClose */
  ['onClose'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['OpenDrawer'] = false;
  (getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))['CurrentlySelectedTicket'] = false;
  ___arguments.context.pageData['CurrentlySelectedTicketObjectID'] = '';

  },
  /* handler:onClose */
  /* content */
}))

define('./pages/dash_6-20_AnimationWorking/components/b67b940bd10c4493f3ef6fae2c284b88/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['OpenDrawer'] = false;
  (getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))['CurrentlySelectedTicket'] = false;
  ___arguments.context.pageData['CurrentlySelectedTicketObjectID'] = '';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dash_6-20_AnimationWorking/components/69985b47bd6b45fa795a184becd896f4/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Name'))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/dash_6-20_AnimationWorking/components/8e55ba1c2e0693d19426a8db2e524eae/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Notes'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dash_6-20_AnimationWorking/components/515a6d6655e2913b394dcf2c6a941c7f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var result;


  await (async function() {
  	const el = document.querySelectorAll('.content_editable')
  	
  	for (let i = 0; i < el.length; i++) {
  	let theDataID = el[i].classList.item(0);
  	theDataID = theDataID.substring(5);
  	el[i].setAttribute('contenteditable', 'true')
  	// el[i].setAttribute('data-modified','true') // We're now only modifying this attribute when the user clicks on the element.
  	el[i].setAttribute('data-source',theDataID)
  	
  	}
  })();
  ___arguments.context.pageData['inEditMode'] = true;
  ___arguments.context.pageData['editingPage'] = true;
  ___arguments.context.pageData['isOpenModal'] = false;
  result = (await (async function(pageData) {
  	/*
  	var myFunction = (e)=> {
  	  console.log(e.currentTarget.getAttribute('data-source'));        
  	}
  	*/
  	
  	var myFunction2 = (e)=> {
  	  pageData.currentDataSource = e.currentTarget.getAttribute('data-source'); // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
  	  console.log("pageData.currentDataSource =: "+ pageData.currentDataSource);
  	  pageData.isOpenModal = true;
  	  return pageData  
  	};
  	
  	Array.from(document.getElementsByClassName('content_editable')).forEach((editable) => {
  	  // editable.addEventListener('click', myFunction);
  	  editable.addEventListener('dblclick', myFunction2);
  	  window.___MY_EVENT_LISTENER  = myFunction2;
  	});  
  	
  	// Previously, we had this code: 
  	/* 
  	Array.from(document.getElementsByClassName('content_editable')).forEach((editable) => {
  	  editable.addEventListener('click', (e)=>{
  	    // e.preventDefault();
  	    console.log(e.currentTarget.getAttribute('data-source'));        
  	    // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
  	    // e.target will trap the actual element that was clicked
  	  });
  	*/
  	
  	/* Function backup
  	editable.addEventListener('dblclick', (e)=>{
  	    // e.preventDefault();
  	    pageData.currentDataSource = e.currentTarget.getAttribute('data-source'); // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
  	    // console.log("A double-click has occurred on element with the ID of: "+ runner.currentDataSource);
  	    console.log("pageData.currentDataSource =: "+ pageData.currentDataSource);
  	    // e.target will trap the actual element that was double-clicked
  	    pageData.isOpenModal = true;
  	    return pageData
  	  });
  	*/
  })(___arguments.context.pageData));
  await (async function() {
  	await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('26ebd15ce2f2c1d6f9813d1a00a26054', true, true, '', 'You are now editing this webpage.', 'info');
  })();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dash_alternate/components/30ea90dd05af67628fd821d5003c3d3d/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  onMounted(___arguments) {
      ___arguments.context.pageData['firstNameValue'] = '';
  ___arguments.context.pageData['lastNameValue'] = '';
  ___arguments.context.pageData['emailValue'] = '';
  ___arguments.context.pageData['phoneValue'] = '';
  ___arguments.context.pageData['companyValue'] = '';
  ___arguments.context.pageData['websiteValue'] = '';
  ___arguments.context.pageData['subjectValue'] = '';
  ___arguments.context.pageData['messageValue'] = '';

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/dash_alternate/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var Contract_Hours, Posted_Hours, result, user;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function LoginOnPageRefresh() {
  Backendless.setCurrentUserToken(((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('HPUserCookie')));
  user = (await Backendless.UserService.getCurrentUser(false));
  ___arguments.context.appData['currentUser'] = user;
  ___arguments.context.appData['companyNamesLIST'] = (getObjectProperty(((await Backendless.Data.of('Users').loadRelations((getObjectProperty((getObjectProperty(___arguments.context.appData, 'currentUser')), 'objectId')), Backendless.LoadRelationsQueryBuilder.create().setRelationName('Linked_Companies').setPageSize(10).setOffset(0)))[0]), 'Company_Name'));
  ___arguments.context.appData['currentCompanyObjectId'] = (getObjectProperty(((await Backendless.Data.of('Users').loadRelations((getObjectProperty((getObjectProperty(___arguments.context.appData, 'currentUser')), 'objectId')), Backendless.LoadRelationsQueryBuilder.create().setRelationName('Linked_Companies').setPageSize(1).setOffset(0)))[0]), 'objectId'));
}


  if (await Backendless.UserService.isValidLogin()) {
    await LoginOnPageRefresh();
    ___arguments.context.pageData['MonthlyContractHours'] = 0;
    ___arguments.context.pageData['MaximumWeeklyHours'] = 0;
    ___arguments.context.pageData['MaximumMonthlyHours'] = 0;
    await (async function() {
    	const el = document.querySelectorAll('.content_editable')
    	
    	for (let i = 0; i < el.length; i++) {
    	let theDataID = el[i].classList.item(0);
    	theDataID = theDataID.substring(5);
    	el[i].setAttribute('contenteditable', 'true')
    	// el[i].setAttribute('data-modified','true') // We're now only modifying this attribute when the user clicks on the element.
    	el[i].setAttribute('data-source',theDataID)
    	
    	}
    })();
    ___arguments.context.pageData['inEditMode'] = true;
    ___arguments.context.pageData['editingPage'] = true;
    ___arguments.context.pageData['TicketsPageOffset'] = 0;
    result = (await (async function(pageData) {
    	/*
    	var myFunction = (e)=> {
    	  console.log(e.currentTarget.getAttribute('data-source'));        
    	}
    	*/
    	
    	var myFunction2 = (e)=> {
    	  pageData.currentDataSource = e.currentTarget.getAttribute('data-source'); // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
    	  console.log("pageData.currentDataSource =: "+ pageData.currentDataSource);
    	  pageData.isOpenModal = true;
    	  return pageData  
    	};
    	
    	Array.from(document.getElementsByClassName('content_editable')).forEach((editable) => {
    	  // editable.addEventListener('click', myFunction);
    	  editable.addEventListener('dblclick', myFunction2);
    	  window.___MY_EVENT_LISTENER  = myFunction2;
    	});  
    	
    	// Previously, we had this code: 
    	/* 
    	Array.from(document.getElementsByClassName('content_editable')).forEach((editable) => {
    	  editable.addEventListener('click', (e)=>{
    	    // e.preventDefault();
    	    console.log(e.currentTarget.getAttribute('data-source'));        
    	    // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
    	    // e.target will trap the actual element that was clicked
    	  });
    	*/
    	
    	/* Function backup
    	editable.addEventListener('dblclick', (e)=>{
    	    // e.preventDefault();
    	    pageData.currentDataSource = e.currentTarget.getAttribute('data-source'); // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
    	    // console.log("A double-click has occurred on element with the ID of: "+ runner.currentDataSource);
    	    console.log("pageData.currentDataSource =: "+ pageData.currentDataSource);
    	    // e.target will trap the actual element that was double-clicked
    	    pageData.isOpenModal = true;
    	    return pageData
    	  });
    	*/
    })(___arguments.context.pageData));
    Contract_Hours = (getObjectProperty(((await Backendless.Data.of('Total_Company_Hours_View').find(Backendless.DataQueryBuilder.create().setWhereClause((['Company_ObjectID = \'',(getObjectProperty(___arguments.context.appData, 'currentCompanyObjectId')),'\''].join(''))).setPageSize(10)))[0]), 'Contract_Hours'));
    Posted_Hours = (getObjectProperty(((await Backendless.Data.of('Total_Company_Hours_View').find(Backendless.DataQueryBuilder.create().setWhereClause((['Company_ObjectID = \'',(getObjectProperty(___arguments.context.appData, 'currentCompanyObjectId')),'\''].join(''))).setPageSize(10)))[0]), 'Posted_Hours'));
    (function (componentUid, dataModel) { ___arguments.context.setComponentDataStoreByUid(componentUid, dataModel) })('e8a685b9572768044a561f8d3532499b', ({ [`CompanyName`]: (getObjectProperty(___arguments.context.appData, 'companyNamesLIST')),[`AvailableHours`]: Posted_Hours,[`MonthlyContractHours`]: Contract_Hours,[`MaximumMonthlyHours`]: (Number(Contract_Hours)) * 2 <= Posted_Hours ? (Number(Contract_Hours)) * 2 : Posted_Hours,[`MaximumWeeklyHours`]: ((Number(Contract_Hours)) / 4) * 2,[`MaximumDailyHours`]: (Number.parseFloat(((Number(Contract_Hours)) / 5)).toFixed(1)) }));
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('b90af9e3067c25f7b9adb5714b69b61e', (await Backendless.Data.of('Support_Tickets').find(Backendless.DataQueryBuilder.create().setSortBy(['Ticket_Date DESC']).setPageSize(25))));
  } else {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
  }

  },
  /* handler:onEnter */
  /* handler:onScroll */
  ['onScroll'](___arguments) {
      if (___arguments.scrollTop) {
    ___arguments.context.pageData['scrollTop'] = true;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['box-shadow'] = '0px 2px 4px rgba(0, 0, 0, 0.1)';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['height'] = '60px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-top'] = '7px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-bottom'] = '7px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('59d442df335dc04e702817c7015fea4c'))['color'] = 'black';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '-5px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '45px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#777777';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('59d442df335dc04e702817c7015fea4c'))['color'] = 'white';
    ___arguments.context.pageData['scrollTop'] = false;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['box-shadow'] = 'none';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['height'] = '80px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['background-color'] = 'transparent';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-top'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '80px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#ffffff';
  }

  },
  /* handler:onScroll */
  /* content */
}))

define('./pages/dash_alternate/components/19f1da6844bcd24c52e983cfbdbe9022/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) < 6) {
    ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c')['Ticket_Status'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) + 1);
  } else {
    ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c')['Ticket_Status'] = 1;
  }

  },
  /* handler:onClick */
  /* handler:onDelete */
  ['onDelete'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c')['Ticket_Status'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'OnLoad_TicketStatus'));

  },
  /* handler:onDelete */
  /* handler:onFrontIconAssignment */
  ['onFrontIconAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 1) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#BC2C45';
    return 'fiber_new';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 2) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#D4860B';
    return 'bedtime';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 3) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#04749C';
    return 'flip_camera_ios';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 4) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#642C8D';
    return 'access_time';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 5) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#AAAA22';
    return 'person_pin';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 6) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#04842D';
    return 'check_box';
  }

  },
  /* handler:onFrontIconAssignment */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 1) {
    return 'New';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 2) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#D4860B';
    return 'Pending';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 3) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#04749C';
    return 'In Progress';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 4) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#642C8D';
    return 'Waiting On Support';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 5) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#AAAA22';
    return 'Waiting On Client';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 6) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#04842D';
    return 'Completed!';
  }

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/dash_alternate/components/85be065e23aa1f58df8a71bbb6dc7662/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c')['OnLoad_TicketStatus'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status'));

  },
  /* handler:onMounted */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'CurrentlySelectedTicket')) == true) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('85be065e23aa1f58df8a71bbb6dc7662'))['background-color'] = '#990939';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('85be065e23aa1f58df8a71bbb6dc7662'))['background-color'] = '#465466';
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/dash_alternate/components/c6a2930ccba2d71d496e8503f464b1d7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var decision, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  if ((getObjectProperty(___arguments.context.pageData, 'isTicketSelected')) == true) {
    console.log(await asyncListFilter(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('b90af9e3067c25f7b9adb5714b69b61e')), async (item) => {
       if ((getObjectProperty(item, 'CurrentlySelectedTicket')) == true) {
        delete item['CurrentlySelectedTicket'];
        decision = true;
      } else {
        decision = true;
      }


     return decision;
    }));
  } else {
  }
  ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c')['CurrentlySelectedTicket'] = true;
  ___arguments.context.pageData['OpenDrawer'] = true;
  ___arguments.context.pageData['isTicketSelected'] = true;
  ___arguments.context.pageData['CurrentlySelectedTicketObjectID'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'objectId'));
  ___arguments.context.pageData['PassedDataModel'] = ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dash_alternate/components/93cf5db23368ed8bffab3de1d62822b7/bundle.js', [], () => ({
  /* content */
  /* handler:onOpen */
  ['onOpen'](___arguments) {
      console.log('The drawer is open???');

  },
  /* handler:onOpen */
  /* handler:isOpenLogic */
  ['isOpenLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'OpenDrawer'))

  },
  /* handler:isOpenLogic */
  /* handler:onClose */
  async ['onClose'](___arguments) {
    var decision, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  if ((getObjectProperty(___arguments.context.pageData, 'isTicketSelected')) == true) {
    console.log((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('b90af9e3067c25f7b9adb5714b69b61e'));
    console.log(await asyncListFilter(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('b90af9e3067c25f7b9adb5714b69b61e')), async (item) => {
       if ((getObjectProperty(item, 'CurrentlySelectedTicket')) == true) {
        delete item['CurrentlySelectedTicket'];
        decision = true;
      } else {
        decision = true;
      }


     return decision;
    }));
  } else {
  }
  ___arguments.context.pageData['OpenDrawer'] = false;
  (getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))['CurrentlySelectedTicket'] = false;
  ___arguments.context.pageData['CurrentlySelectedTicketObjectID'] = '';

  },
  /* handler:onClose */
  /* content */
}))

define('./pages/dash_alternate/components/b67b940bd10c4493f3ef6fae2c284b88/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['OpenDrawer'] = false;
  (getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))['CurrentlySelectedTicket'] = false;
  ___arguments.context.pageData['CurrentlySelectedTicketObjectID'] = '';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dash_alternate/components/69985b47bd6b45fa795a184becd896f4/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Name'))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/dash_alternate/components/8e55ba1c2e0693d19426a8db2e524eae/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Notes'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dash_alternate/components/515a6d6655e2913b394dcf2c6a941c7f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var result;


  await (async function() {
  	const el = document.querySelectorAll('.content_editable')
  	
  	for (let i = 0; i < el.length; i++) {
  	let theDataID = el[i].classList.item(0);
  	theDataID = theDataID.substring(5);
  	el[i].setAttribute('contenteditable', 'true')
  	// el[i].setAttribute('data-modified','true') // We're now only modifying this attribute when the user clicks on the element.
  	el[i].setAttribute('data-source',theDataID)
  	
  	}
  })();
  ___arguments.context.pageData['inEditMode'] = true;
  ___arguments.context.pageData['editingPage'] = true;
  ___arguments.context.pageData['isOpenModal'] = false;
  result = (await (async function(pageData) {
  	/*
  	var myFunction = (e)=> {
  	  console.log(e.currentTarget.getAttribute('data-source'));        
  	}
  	*/
  	
  	var myFunction2 = (e)=> {
  	  pageData.currentDataSource = e.currentTarget.getAttribute('data-source'); // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
  	  console.log("pageData.currentDataSource =: "+ pageData.currentDataSource);
  	  pageData.isOpenModal = true;
  	  return pageData  
  	};
  	
  	Array.from(document.getElementsByClassName('content_editable')).forEach((editable) => {
  	  // editable.addEventListener('click', myFunction);
  	  editable.addEventListener('dblclick', myFunction2);
  	  window.___MY_EVENT_LISTENER  = myFunction2;
  	});  
  	
  	// Previously, we had this code: 
  	/* 
  	Array.from(document.getElementsByClassName('content_editable')).forEach((editable) => {
  	  editable.addEventListener('click', (e)=>{
  	    // e.preventDefault();
  	    console.log(e.currentTarget.getAttribute('data-source'));        
  	    // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
  	    // e.target will trap the actual element that was clicked
  	  });
  	*/
  	
  	/* Function backup
  	editable.addEventListener('dblclick', (e)=>{
  	    // e.preventDefault();
  	    pageData.currentDataSource = e.currentTarget.getAttribute('data-source'); // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
  	    // console.log("A double-click has occurred on element with the ID of: "+ runner.currentDataSource);
  	    console.log("pageData.currentDataSource =: "+ pageData.currentDataSource);
  	    // e.target will trap the actual element that was double-clicked
  	    pageData.isOpenModal = true;
  	    return pageData
  	  });
  	*/
  })(___arguments.context.pageData));
  await (async function() {
  	await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('26ebd15ce2f2c1d6f9813d1a00a26054', true, true, '', 'You are now editing this webpage.', 'info');
  })();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dash_alternate/components/e7d4ce9742a74274a8f758c81d36cedd/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Description'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dash_alternate/components/39359e517e8299f37c9ad6f3878cdd7c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) < 6) {
    (getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))['Ticket_Status'] = ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) + 1);
    console.log(String('The ticket has been incremented: ') + String(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')));
  } else {
    (getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))['Ticket_Status'] = 1;
    console.log(String('The ticket has been reset to 1: ') + String(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')));
  }

  },
  /* handler:onClick */
  /* handler:onDelete */
  ['onDelete'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))['Ticket_Status'] = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'OnLoad_TicketStatus'));

  },
  /* handler:onDelete */
  /* handler:onFrontIconAssignment */
  ['onFrontIconAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 1) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#BC2C45';
    return 'fiber_new';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 2) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#D4860B';
    return 'bedtime';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 3) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#04749C';
    return 'flip_camera_ios';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 4) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#642C8D';
    return 'access_time';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 5) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#AAAA22';
    return 'person_pin';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 6) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#04842D';
    return 'check_box';
  } else {
    console.log(String('The Ticket_Status of the PassedDataModel is: ') + String(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')));
  }
  console.log(String('The Ticket_Status of the PassedDataModel of pageData is: ') + String(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')));

  },
  /* handler:onFrontIconAssignment */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 1) {
    console.log(String('The label has been updated with: ') + String('New'));
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#BC2C45';
    return 'New';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 2) {
    console.log(String('The label has been updated with: ') + String('Pending'));
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#D4860B';
    return 'Pending';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 3) {
    console.log(String('The label has been updated with: ') + String('In Progress'));
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#04749C';
    return 'In Progress';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 4) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#642C8D';
    return 'Waiting On Support';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 5) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#AAAA22';
    return 'Waiting On Client';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 6) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#04842D';
    return 'Completed!';
  }
  console.log(String('The Ticket_Status of the PassedDataModel of pageData is: ') + String(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')));

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/dash_alternate/components/8516f829a89573aea1ecd9c694449d54/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))['OnLoad_TicketStatus'] = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status'));

  },
  /* handler:onMounted */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    
  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/dash_alternate/components/c3213a164feed6ef2b22e331e4a31b04/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dash_alternate/components/2c8a6f746f7c4274b274dc68d6cfe88c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'OnLoad_TicketStatus'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dash_alternate/components/f20f534105889b957ec106deb1557f36/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('cfe4133897c28fce29fc7133eaf4d168'), 'CompanyLogoURL'))

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/dash_alternate/components/a0e8e78e083d16d87ca9d50b632d3b01/bundle.js', [], () => ({
  /* content */
  /* handler:onTooltipTextAssignment */
  ['onTooltipTextAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('cfe4133897c28fce29fc7133eaf4d168'), 'CompanyName'))

  },
  /* handler:onTooltipTextAssignment */
  /* content */
}))

define('./pages/dash_alternate/components/2cc11f9da0d3cf1bc6eaf6c3d7cc6b1d/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  async ['onMouseOver'](___arguments) {
    var listToAdd;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  console.log('Loading more tickets...');
  console.log('Fetch fresh batch of tickets here...');
  ___arguments.context.pageData['TicketsPageOffset'] = ((getObjectProperty(___arguments.context.pageData, 'TicketsPageOffset')) + 25);
  ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('2cc11f9da0d3cf1bc6eaf6c3d7cc6b1d'))['content'] = 'Please wait...';
  ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2cc11f9da0d3cf1bc6eaf6c3d7cc6b1d'))['color'] = '#FFFFFF';
  ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('ed7ad4ee925a6e43b31917edc1187b21'))['background-color'] = '#8D2435';
  listToAdd = (await Backendless.Data.of('Support_Tickets').find(Backendless.DataQueryBuilder.create().setSortBy(['Ticket_Date DESC']).setPageSize(25).setOffset((getObjectProperty(___arguments.context.pageData, 'TicketsPageOffset')))));
  await new Promise(r => setTimeout(r, 250 || 0));
  console.log((addItemToList(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('b90af9e3067c25f7b9adb5714b69b61e')), listToAdd)));
  ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('ed7ad4ee925a6e43b31917edc1187b21'))['background-color'] = '#CCC6C9';
  ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2cc11f9da0d3cf1bc6eaf6c3d7cc6b1d'))['color'] = '#000000';
  ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('2cc11f9da0d3cf1bc6eaf6c3d7cc6b1d'))['content'] = 'Load More Tickets';

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./pages/dash_alternate/components/2287e385c14b9a056f5558f38ba29a1c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['Showing tickets: (',(getObjectProperty(___arguments.context.pageData, 'TicketsPageOffset')),' to ',(getObjectProperty(___arguments.context.pageData, 'TicketsPageOffset')) + 25,')'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dash_backup7-22SOD/components/30ea90dd05af67628fd821d5003c3d3d/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  onMounted(___arguments) {
      ___arguments.context.pageData['firstNameValue'] = '';
  ___arguments.context.pageData['lastNameValue'] = '';
  ___arguments.context.pageData['emailValue'] = '';
  ___arguments.context.pageData['phoneValue'] = '';
  ___arguments.context.pageData['companyValue'] = '';
  ___arguments.context.pageData['websiteValue'] = '';
  ___arguments.context.pageData['subjectValue'] = '';
  ___arguments.context.pageData['messageValue'] = '';

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/dash_backup7-22SOD/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var AvailableHoursAfterTickets, MaximumWeeklyHours, MaximumHoursPerMonth, Contract_Hours, Posted_Hours, companyRecord, result, user, temp, step3, step2, TotalAnnually, ContractHours, base;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function LoginOnPageRefresh() {
  Backendless.setCurrentUserToken(((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('HPUserCookie')));
  user = (await Backendless.UserService.getCurrentUser(false));
  ___arguments.context.appData['currentUser'] = user;
  ___arguments.context.appData['companyNamesLIST'] = (getObjectProperty(((await Backendless.Data.of('Users').loadRelations((getObjectProperty((getObjectProperty(___arguments.context.appData, 'currentUser')), 'objectId')), Backendless.LoadRelationsQueryBuilder.create().setRelationName('Linked_Companies').setPageSize(10).setOffset(0)))[0]), 'Company_Name'));
  ___arguments.context.appData['currentCompanyObjectId'] = (getObjectProperty(((await Backendless.Data.of('Users').loadRelations((getObjectProperty((getObjectProperty(___arguments.context.appData, 'currentUser')), 'objectId')), Backendless.LoadRelationsQueryBuilder.create().setRelationName('Linked_Companies').setPageSize(1).setOffset(0)))[0]), 'objectId'));
  if ((getObjectProperty((getObjectProperty(___arguments.context.appData, 'currentUser')), 'objectId')) == 'C1448329-3668-4275-8C4E-866D8C665C4F') {
    ___arguments.context.pageData['userIsAdmin'] = true;
  } else {
    ___arguments.context.pageData['userIsAdmin'] = false;
  }
}

/**
 * Describe this function...
 */
async function CalculateRemainingHours(companyRecord) {
  AvailableHoursAfterTickets = 0;
  temp = 0;
  temp = (Number((Number.parseFloat((getObjectProperty(((await Backendless.Data.of('Support_Tickets').find(Backendless.DataQueryBuilder.create().setWhereClause((['Linked_Company.objectId = \'',(getObjectProperty(___arguments.context.appData, 'currentCompanyObjectId')),'\' AND Ignore_Ticket_Hours = false'].join(''))).setProperties('SUM (Time_Spent) AS TotalTicketsTimeSpent').setPageSize(10)))[0]), 'TotalTicketsTimeSpent'))).toFixed(1))));
  AvailableHoursAfterTickets = Posted_Hours + temp;
  return AvailableHoursAfterTickets
}

/**
 * Describe this function...
 */
async function MaximumMonthlyHours(ContractHours, AvailableHoursAfterTickets) {
  TotalAnnually = ContractHours * 12;
  step2 = TotalAnnually + AvailableHoursAfterTickets;
  step3 = step2 / 12;
  return step3
}


  await LoginOnPageRefresh();
  if ((await Backendless.UserService.isValidLogin()) == true) {
    ___arguments.context.pageData['MonthlyContractHours'] = 0;
    ___arguments.context.pageData['MaximumWeeklyHours'] = 0;
    ___arguments.context.pageData['MaximumMonthlyHours'] = 0;
    await (async function() {
    	const el = document.querySelectorAll('.content_editable')
    	
    	for (let i = 0; i < el.length; i++) {
    	let theDataID = el[i].classList.item(0);
    	theDataID = theDataID.substring(5);
    	el[i].setAttribute('contenteditable', 'true')
    	// el[i].setAttribute('data-modified','true') // We're now only modifying this attribute when the user clicks on the element.
    	el[i].setAttribute('data-source',theDataID)
    	
    	}
    })();
    ___arguments.context.pageData['inEditMode'] = true;
    ___arguments.context.pageData['editingPage'] = true;
    ___arguments.context.pageData['TicketsPageOffset'] = 0;
    result = (await (async function(pageData) {
    	/*
    	var myFunction = (e)=> {
    	  console.log(e.currentTarget.getAttribute('data-source'));        
    	}
    	*/
    	
    	var myFunction2 = (e)=> {
    	  pageData.currentDataSource = e.currentTarget.getAttribute('data-source'); // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
    	  console.log("pageData.currentDataSource =: "+ pageData.currentDataSource);
    	  pageData.isOpenModal = true;
    	  return pageData  
    	};
    	
    	Array.from(document.getElementsByClassName('content_editable')).forEach((editable) => {
    	  // editable.addEventListener('click', myFunction);
    	  editable.addEventListener('dblclick', myFunction2);
    	  window.___MY_EVENT_LISTENER  = myFunction2;
    	});  
    	
    	// Previously, we had this code: 
    	/* 
    	Array.from(document.getElementsByClassName('content_editable')).forEach((editable) => {
    	  editable.addEventListener('click', (e)=>{
    	    // e.preventDefault();
    	    console.log(e.currentTarget.getAttribute('data-source'));        
    	    // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
    	    // e.target will trap the actual element that was clicked
    	  });
    	*/
    	
    	/* Function backup
    	editable.addEventListener('dblclick', (e)=>{
    	    // e.preventDefault();
    	    pageData.currentDataSource = e.currentTarget.getAttribute('data-source'); // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
    	    // console.log("A double-click has occurred on element with the ID of: "+ runner.currentDataSource);
    	    console.log("pageData.currentDataSource =: "+ pageData.currentDataSource);
    	    // e.target will trap the actual element that was double-clicked
    	    pageData.isOpenModal = true;
    	    return pageData
    	  });
    	*/
    })(___arguments.context.pageData));
    companyRecord = (await Backendless.Data.of('Total_Company_Hours_View').find(Backendless.DataQueryBuilder.create().setWhereClause((['Company_ObjectID = \'',(getObjectProperty(___arguments.context.appData, 'currentCompanyObjectId')),'\''].join(''))).setPageSize(1)))[0];
    Contract_Hours = (Number((getObjectProperty(companyRecord, 'Contract_Hours'))));
    Posted_Hours = (Number((getObjectProperty(companyRecord, 'Posted_Hours'))));
    AvailableHoursAfterTickets = (Number.parseFloat((getObjectProperty(((await Backendless.Data.of('Support_Tickets').find(Backendless.DataQueryBuilder.create().setWhereClause((['Linked_Company.objectId = \'',(getObjectProperty(___arguments.context.appData, 'currentCompanyObjectId')),'\' AND Ignore_Ticket_Hours = false'].join(''))).setProperties('SUM (Time_Spent) AS TotalTicketsTimeSpent').setPageSize(10)))[0]), 'TotalTicketsTimeSpent'))).toFixed(1));
    AvailableHoursAfterTickets = await CalculateRemainingHours(companyRecord);
    MaximumHoursPerMonth = await MaximumMonthlyHours(Contract_Hours, AvailableHoursAfterTickets - 0);
    MaximumWeeklyHours = (Number(MaximumHoursPerMonth)) / 2.875;
    (function (componentUid, dataModel) { ___arguments.context.setComponentDataStoreByUid(componentUid, dataModel) })('e8a685b9572768044a561f8d3532499b', ({ [`CompanyName`]: (getObjectProperty(___arguments.context.appData, 'companyNamesLIST')),[`AvailableHours`]: Posted_Hours,[`MonthlyContractHours`]: Contract_Hours,[`MaximumMonthlyHours`]: (Number.parseFloat(MaximumHoursPerMonth).toFixed(1)),[`MaximumWeeklyHours`]: (Number.parseFloat(MaximumWeeklyHours).toFixed(1)),[`MaximumDailyHours`]: (Number.parseFloat(((Number(MaximumWeeklyHours)) / 4)).toFixed(1)),[`AvailableHoursAfterTickets`]: (Number.parseFloat(AvailableHoursAfterTickets).toFixed(1)),[`StoredAvailableHoursAfterTickets`]: (Number.parseFloat(AvailableHoursAfterTickets).toFixed(1)) }));
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('b90af9e3067c25f7b9adb5714b69b61e', (await Backendless.Data.of('Support_Tickets').find(Backendless.DataQueryBuilder.create().setSortBy(['Ticket_Date DESC']).setPageSize(25))));
    ___arguments.context.pageData['LoadMoreTicketsEnabled'] = true;
  } else {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
  }

  },
  /* handler:onEnter */
  /* handler:onScroll */
  ['onScroll'](___arguments) {
      if (___arguments.scrollTop) {
    ___arguments.context.pageData['scrollTop'] = true;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['box-shadow'] = '0px 2px 4px rgba(0, 0, 0, 0.1)';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['height'] = '60px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-top'] = '7px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-bottom'] = '7px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('59d442df335dc04e702817c7015fea4c'))['color'] = 'black';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '-5px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '45px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#777777';
  } else {
    console.log('We\'re at the "Scroll Top"');
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['display'] = 'flex';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('59d442df335dc04e702817c7015fea4c'))['color'] = 'white';
    ___arguments.context.pageData['scrollTop'] = false;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['box-shadow'] = 'none';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['height'] = '80px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['background-color'] = 'transparent';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-top'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '80px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#ffffff';
  }

  },
  /* handler:onScroll */
  /* content */
}))

define('./pages/dash_backup7-22SOD/components/19f1da6844bcd24c52e983cfbdbe9022/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (true) {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) < 6) {
      ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c')['Ticket_Status'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) + 1);
    } else {
      ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c')['Ticket_Status'] = 1;
    }
  }

  },
  /* handler:onClick */
  /* handler:onDelete */
  ['onDelete'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c')['Ticket_Status'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'OnLoad_TicketStatus'));

  },
  /* handler:onDelete */
  /* handler:onFrontIconAssignment */
  ['onFrontIconAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 1) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#BC2C45';
    return 'fiber_new';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 2) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#D4860B';
    return 'bedtime';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 3) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#04749C';
    return 'flip_camera_ios';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 4) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#642C8D';
    return 'access_time';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 5) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#AAAA22';
    return 'person_pin';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 6) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#04842D';
    return 'check_box';
  }

  },
  /* handler:onFrontIconAssignment */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 1) {
    return 'New';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 2) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#D4860B';
    return 'Pending';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 3) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#04749C';
    return 'In Progress';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 4) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#642C8D';
    return 'Waiting On Support';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 5) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#AAAA22';
    return 'Waiting On Client';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 6) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#04842D';
    return 'Completed!';
  }

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/dash_backup7-22SOD/components/85be065e23aa1f58df8a71bbb6dc7662/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c')['OnLoad_TicketStatus'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status'));

  },
  /* handler:onMounted */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    
  },
  /* handler:onStyleAssignment */
  /* handler:onBeforeMount */
  ['onBeforeMount'](___arguments) {
    
  },
  /* handler:onBeforeMount */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log('Clickety-click');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dash_backup7-22SOD/components/c6a2930ccba2d71d496e8503f464b1d7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var decision, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  if ((getObjectProperty(___arguments.context.pageData, 'isTicketSelected')) == true) {
    console.log(await asyncListFilter(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('b90af9e3067c25f7b9adb5714b69b61e')), async (item) => {
       if ((getObjectProperty(item, 'CurrentlySelectedTicket')) == true) {
        delete item['CurrentlySelectedTicket'];
        decision = true;
      } else {
        decision = true;
      }


     return decision;
    }));
  } else {
  }
  ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c')['CurrentlySelectedTicket'] = true;
  ___arguments.context.pageData['OpenDrawer'] = true;
  ___arguments.context.pageData['isTicketSelected'] = true;
  ___arguments.context.pageData['CurrentlySelectedTicketObjectID'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'objectId'));
  ___arguments.context.pageData['PassedDataModel'] = ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c');
  (function (componentUid, dataModel) { ___arguments.context.setComponentDataStoreByUid(componentUid, dataModel) })('416556824f34d6d9385836c756cc014c', ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'));
  (function (component) { component.el.scrollIntoView() })((getObjectProperty(___arguments.context.pageData, (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'objectId')))));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dash_backup7-22SOD/components/93cf5db23368ed8bffab3de1d62822b7/bundle.js', [], () => ({
  /* content */
  /* handler:onOpen */
  ['onOpen'](___arguments) {
    
  },
  /* handler:onOpen */
  /* handler:isOpenLogic */
  ['isOpenLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'OpenDrawer'))

  },
  /* handler:isOpenLogic */
  /* handler:onClose */
  async ['onClose'](___arguments) {
    var decision, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  if ((getObjectProperty(___arguments.context.pageData, 'isTicketSelected')) == true) {
    console.log((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('b90af9e3067c25f7b9adb5714b69b61e'));
    console.log(await asyncListFilter(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('b90af9e3067c25f7b9adb5714b69b61e')), async (item) => {
       if ((getObjectProperty(item, 'CurrentlySelectedTicket')) == true) {
        delete item['CurrentlySelectedTicket'];
        decision = true;
      } else {
        decision = true;
      }


     return decision;
    }));
  } else {
  }
  ___arguments.context.pageData['OpenDrawer'] = false;
  (getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))['CurrentlySelectedTicket'] = false;
  ___arguments.context.pageData['CurrentlySelectedTicketObjectID'] = '';

  },
  /* handler:onClose */
  /* content */
}))

define('./pages/dash_backup7-22SOD/components/b67b940bd10c4493f3ef6fae2c284b88/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid, event) {  ___arguments.context.getComponentByUid(componentUid).close(event) })('93cf5db23368ed8bffab3de1d62822b7', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dash_backup7-22SOD/components/69985b47bd6b45fa795a184becd896f4/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Name'))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/dash_backup7-22SOD/components/8e55ba1c2e0693d19426a8db2e524eae/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Notes'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dash_backup7-22SOD/components/515a6d6655e2913b394dcf2c6a941c7f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var result, currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (await Backendless.UserService.isValidLogin()) {
    if ((getObjectProperty((getObjectProperty(___arguments.context.appData, 'currentUser')), 'objectId')) == 'C1448329-3668-4275-8C4E-866D8C665C4F') {
      ___arguments.context.pageData['adminEditMode'] = true;
    } else {
      ___arguments.context.pageData['adminEditMode'] = false;
    }
  }
  if (currentUser == true) {
    console.log('We\'re the Admin!');
  }
  await (async function() {
  	const el = document.querySelectorAll('.content_editable')
  	
  	for (let i = 0; i < el.length; i++) {
  	let theDataID = el[i].classList.item(0);
  	theDataID = theDataID.substring(5);
  	el[i].setAttribute('contenteditable', 'true')
  	// el[i].setAttribute('data-modified','true') // We're now only modifying this attribute when the user clicks on the element.
  	el[i].setAttribute('data-source',theDataID)
  	
  	}
  })();
  ___arguments.context.pageData['inEditMode'] = true;
  ___arguments.context.pageData['editingPage'] = true;
  ___arguments.context.pageData['isOpenModal'] = false;
  result = (await (async function(pageData) {
  	/*
  	var myFunction = (e)=> {
  	  console.log(e.currentTarget.getAttribute('data-source'));        
  	}
  	*/
  	
  	var myFunction2 = (e)=> {
  	  pageData.currentDataSource = e.currentTarget.getAttribute('data-source'); // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
  	  console.log("pageData.currentDataSource =: "+ pageData.currentDataSource);
  	  pageData.isOpenModal = true;
  	  return pageData  
  	};
  	
  	Array.from(document.getElementsByClassName('content_editable')).forEach((editable) => {
  	  // editable.addEventListener('click', myFunction);
  	  editable.addEventListener('dblclick', myFunction2);
  	  window.___MY_EVENT_LISTENER  = myFunction2;
  	});  
  	
  	// Previously, we had this code: 
  	/* 
  	Array.from(document.getElementsByClassName('content_editable')).forEach((editable) => {
  	  editable.addEventListener('click', (e)=>{
  	    // e.preventDefault();
  	    console.log(e.currentTarget.getAttribute('data-source'));        
  	    // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
  	    // e.target will trap the actual element that was clicked
  	  });
  	*/
  	
  	/* Function backup
  	editable.addEventListener('dblclick', (e)=>{
  	    // e.preventDefault();
  	    pageData.currentDataSource = e.currentTarget.getAttribute('data-source'); // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
  	    // console.log("A double-click has occurred on element with the ID of: "+ runner.currentDataSource);
  	    console.log("pageData.currentDataSource =: "+ pageData.currentDataSource);
  	    // e.target will trap the actual element that was double-clicked
  	    pageData.isOpenModal = true;
  	    return pageData
  	  });
  	*/
  })(___arguments.context.pageData));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dash_backup7-22SOD/components/e7d4ce9742a74274a8f758c81d36cedd/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Description'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dash_backup7-22SOD/components/39359e517e8299f37c9ad6f3878cdd7c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) < 6) {
    (getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))['Ticket_Status'] = ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) + 1);
    console.log(String('The ticket has been incremented: ') + String(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')));
  } else {
    (getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))['Ticket_Status'] = 1;
    console.log(String('The ticket has been reset to 1: ') + String(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')));
  }

  },
  /* handler:onClick */
  /* handler:onDelete */
  ['onDelete'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))['Ticket_Status'] = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'OnLoad_TicketStatus'));

  },
  /* handler:onDelete */
  /* handler:onFrontIconAssignment */
  ['onFrontIconAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 1) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#BC2C45';
    return 'fiber_new';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 2) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#D4860B';
    return 'bedtime';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 3) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#04749C';
    return 'flip_camera_ios';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 4) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#642C8D';
    return 'access_time';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 5) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#AAAA22';
    return 'person_pin';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 6) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#04842D';
    return 'check_box';
  } else {
    console.log(String('The Ticket_Status of the PassedDataModel is: ') + String(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')));
  }
  console.log(String('The Ticket_Status of the PassedDataModel of pageData is: ') + String(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')));

  },
  /* handler:onFrontIconAssignment */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 1) {
    console.log(String('The label has been updated with: ') + String('New'));
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#BC2C45';
    return 'New';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 2) {
    console.log(String('The label has been updated with: ') + String('Pending'));
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#D4860B';
    return 'Pending';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 3) {
    console.log(String('The label has been updated with: ') + String('In Progress'));
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#04749C';
    return 'In Progress';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 4) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#642C8D';
    return 'Waiting On Support';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 5) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#AAAA22';
    return 'Waiting On Client';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 6) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#04842D';
    return 'Completed!';
  }
  console.log(String('The Ticket_Status of the PassedDataModel of pageData is: ') + String(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')));

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/dash_backup7-22SOD/components/8516f829a89573aea1ecd9c694449d54/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))['OnLoad_TicketStatus'] = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status'));

  },
  /* handler:onMounted */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    
  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/dash_backup7-22SOD/components/c3213a164feed6ef2b22e331e4a31b04/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dash_backup7-22SOD/components/2c8a6f746f7c4274b274dc68d6cfe88c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'OnLoad_TicketStatus'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dash_backup7-22SOD/components/f20f534105889b957ec106deb1557f36/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'CompanyLogoURL'))

  },
  /* handler:onSourceUrlAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dash_backup7-22SOD/components/a0e8e78e083d16d87ca9d50b632d3b01/bundle.js', [], () => ({
  /* content */
  /* handler:onTooltipTextAssignment */
  ['onTooltipTextAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'CompanyName'))

  },
  /* handler:onTooltipTextAssignment */
  /* content */
}))

define('./pages/dash_backup7-22SOD/components/2cc11f9da0d3cf1bc6eaf6c3d7cc6b1d/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  async ['onMouseOver'](___arguments) {
    var CombinedList, SecondList, FirstList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'LoadMoreTicketsEnabled')) == true) {
    console.log('Loading more tickets...');
    ___arguments.context.pageData['TicketsPageOffset'] = ((getObjectProperty(___arguments.context.pageData, 'TicketsPageOffset')) + 25);
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('2cc11f9da0d3cf1bc6eaf6c3d7cc6b1d'))['content'] = 'Please wait...';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2cc11f9da0d3cf1bc6eaf6c3d7cc6b1d'))['color'] = '#FFFFFF';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('ed7ad4ee925a6e43b31917edc1187b21'))['background-color'] = '#8D2435';
    FirstList = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('b90af9e3067c25f7b9adb5714b69b61e'));
    SecondList = (JSON.parse((JSON.stringify((await Backendless.Data.of('Support_Tickets').find(Backendless.DataQueryBuilder.create().setSortBy(['Ticket_Date DESC']).setPageSize(25).setOffset((getObjectProperty(___arguments.context.pageData, 'TicketsPageOffset')))))))));
    CombinedList = (FirstList.concat(SecondList));
    console.log(CombinedList);
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('b90af9e3067c25f7b9adb5714b69b61e', (JSON.parse((JSON.stringify(CombinedList)))));
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('ed7ad4ee925a6e43b31917edc1187b21'))['background-color'] = '#CCC6C9';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2cc11f9da0d3cf1bc6eaf6c3d7cc6b1d'))['color'] = '#000000';
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('2cc11f9da0d3cf1bc6eaf6c3d7cc6b1d'))['content'] = 'Load More Tickets';
  }

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./pages/dash_backup7-22SOD/components/2287e385c14b9a056f5558f38ba29a1c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['Showing tickets: (',(getObjectProperty(___arguments.context.pageData, 'TicketsPageOffset')) + 1,' to ',(getObjectProperty(___arguments.context.pageData, 'TicketsPageOffset')) + 25,')'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dash_backup7-22SOD/components/441491b9e1deb0c17132e707fcd80b87/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    
  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/dash_backup7-22SOD/components/a2468eeb4bd7d673efa3ee5aa0559f7c/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.dataModel, 'CurrentlySelectedTicket')) == true) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('a2468eeb4bd7d673efa3ee5aa0559f7c'))['background-color'] = '#990939';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('a2468eeb4bd7d673efa3ee5aa0559f7c'))['background-color'] = '#465466';
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onBeforeMount */
  ['onBeforeMount'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData[(getObjectProperty(___arguments.context.dataModel, 'objectId'))] = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('a2468eeb4bd7d673efa3ee5aa0559f7c'));

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./pages/dash_backup7-22SOD/components/7071be4a758ce1d204f23c034e03d6c9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var userIsAdmin;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'userIsAdmin')) == true) {
    console.log((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('e8a685b9572768044a561f8d3532499b'));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dash_backup7-22SOD/components/275722ccc9e2fca59d2a63e2f361255e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('Created: ') + String(new Intl.DateTimeFormat(undefined, undefined).format((getObjectProperty(___arguments.context.getComponentDataStoreByUid('cfe4133897c28fce29fc7133eaf4d168'), 'created')))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dash_backup7-22SOD/components/c68c7976ca6566e903887c550947d78e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(Object.keys((getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))));

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    if (getObjectProperty(___arguments.context.pageData, 'userIsAdmin')) {
      return true;
    } else {
      return false;
    }

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/dash_backup7-22SOD/components/877e8a2d53dbf2e341ffc846a3294f7a/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))['Ticket_Date'] = ___arguments.value;
  console.log(String('The value has been updated to: ') + String(___arguments.value));

  },
  /* handler:onChange */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Date'))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/dash_backup7-22SOD/components/44ac1ba49a4d63c3946a53c930cf4406/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(getObjectProperty(___arguments.context.pageData, 'PassedDataModel'));

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    if (getObjectProperty(___arguments.context.pageData, 'userIsAdmin')) {
      return true;
    } else {
      return false;
    }

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/dash_backup7-22SOD/components/7fb9e9c4c3da88da59ba018ecb4f822c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.Data.of('Support_Tickets').save( ({ [`objectId`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'objectId')),[`Ticket_Name`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Name')),[`CompanyName`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'CompanyName')),[`Time_Spent`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Time_Spent')),[`Ticket_Status`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')),[`Linked_Invoice_Reference`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Linked_Invoice_Reference')),[`Notes`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Notes')),[`Ticket_Entered_Date`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Date')),[`Ticket_Completed_Date`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Completed_Date')),[`Ticket_Invoice_Amount`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Invoice_Amount')) }) );
  ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('7fb9e9c4c3da88da59ba018ecb4f822c'))['color'] = '#03B935';

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    
  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/dash_backup7-22SOD/components/c12bf915588659d3218289b8fe330fad/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    if (getObjectProperty(___arguments.context.pageData, 'userIsAdmin')) {
      console.log(Object.keys(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('b90af9e3067c25f7b9adb5714b69b61e'))));
    } else {
    }

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'userIsAdmin'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/dash_backup7-22SOD/components/abd3be542d37e75a89c54245b45d2609/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Time_Spent'))

  },
  /* handler:onValueAssignment */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'adminEditMode')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/dash_backup7-22SOD/components/b3c4aecc5ab8d2eebde7ac0aadcd972c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var error;


  try {
    console.log(Object.keys(___arguments.context.pageData));

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'userIsAdmin'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/dash_backup7-22SOD/components/4415b4e21c67b99451b70838d6c8aa34/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log(Object.keys(___arguments.context.getComponentDataStoreByUid('416556824f34d6d9385836c756cc014c')));

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    if (getObjectProperty(___arguments.context.pageData, 'userIsAdmin')) {
      return true;
    } else {
      return false;
    }

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/dash_backup7-22SOD/components/7be513ee0185358560eee36b6ee5a3f3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log(___arguments.context.getComponentDataStoreByUid('416556824f34d6d9385836c756cc014c'));

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    if (getObjectProperty(___arguments.context.pageData, 'userIsAdmin')) {
      return true;
    } else {
      return false;
    }

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/dash_backup7-22SOD/components/a95140a9aebb95d8e7feb779ee56d03c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('cfe4133897c28fce29fc7133eaf4d168'), 'Ticket_Date')))).toDateString())

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dash_backup7-22SOD/components/ec82e71e2748fdc3725d123957ae9596/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var item, list, selectedTicketObjectId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedTicketObjectId = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'objectId'));
  console.log(selectedTicketObjectId);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dash_backup7-22SOD/components/1982dd0935fcbca79a28b2831716b944/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'userIsAdmin'))

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log(___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dash_backup7-22SOD/components/e2ed299fdf778674ae3ff5c65b24d038/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'userIsAdmin'))

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    if (getObjectProperty(___arguments.context.pageData, 'userIsAdmin')) {
      console.log((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('b90af9e3067c25f7b9adb5714b69b61e'));
    } else {
    }

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dash_backup7-22SOD/components/538e1fea549d85e8a01c6153c1264d42/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('e8a685b9572768044a561f8d3532499b')['AvailableHoursAfterTickets'] = ___arguments.value;
  ___arguments.context.getComponentDataStoreByUid('e8a685b9572768044a561f8d3532499b')['MaximumMonthlyHours'] = (Number.parseFloat(((((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e8a685b9572768044a561f8d3532499b'), 'MonthlyContractHours')) * 12 + (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e8a685b9572768044a561f8d3532499b'), 'AvailableHoursAfterTickets'))) - 0) / 12)).toFixed(1));
  ___arguments.context.getComponentDataStoreByUid('e8a685b9572768044a561f8d3532499b')['MaximumWeeklyHours'] = (Number.parseFloat(((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e8a685b9572768044a561f8d3532499b'), 'MaximumMonthlyHours')) / 2.875)).toFixed(1));
  ___arguments.context.getComponentDataStoreByUid('e8a685b9572768044a561f8d3532499b')['MaximumDailyHours'] = (Number.parseFloat(((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e8a685b9572768044a561f8d3532499b'), 'MaximumWeeklyHours')) / 4)).toFixed(1));

  },
  /* handler:onChange */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e8a685b9572768044a561f8d3532499b'), 'AvailableHoursAfterTickets'))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/dash_backup7-22SOD/components/44fae8f783292a27a3c8ced9197ad74a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('e8a685b9572768044a561f8d3532499b')['AvailableHoursAfterTickets'] = (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e8a685b9572768044a561f8d3532499b'), 'StoredAvailableHoursAfterTickets'))));
  ___arguments.context.getComponentDataStoreByUid('e8a685b9572768044a561f8d3532499b')['MaximumMonthlyHours'] = (Number.parseFloat(((((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e8a685b9572768044a561f8d3532499b'), 'MonthlyContractHours')) * 12 + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e8a685b9572768044a561f8d3532499b'), 'StoredAvailableHoursAfterTickets'))))) - 0) / 12)).toFixed(1));
  ___arguments.context.getComponentDataStoreByUid('e8a685b9572768044a561f8d3532499b')['MaximumWeeklyHours'] = (Number.parseFloat(((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e8a685b9572768044a561f8d3532499b'), 'MaximumMonthlyHours')) / 2.875)).toFixed(1));
  ___arguments.context.getComponentDataStoreByUid('e8a685b9572768044a561f8d3532499b')['MaximumDailyHours'] = (Number.parseFloat(((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e8a685b9572768044a561f8d3532499b'), 'MaximumWeeklyHours')) / 4)).toFixed(1));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dash_backup_6-21_AnimationWorking/components/30ea90dd05af67628fd821d5003c3d3d/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  onMounted(___arguments) {
      ___arguments.context.pageData['firstNameValue'] = '';
  ___arguments.context.pageData['lastNameValue'] = '';
  ___arguments.context.pageData['emailValue'] = '';
  ___arguments.context.pageData['phoneValue'] = '';
  ___arguments.context.pageData['companyValue'] = '';
  ___arguments.context.pageData['websiteValue'] = '';
  ___arguments.context.pageData['subjectValue'] = '';
  ___arguments.context.pageData['messageValue'] = '';

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/dash_backup_6-21_AnimationWorking/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var Contract_Hours, Posted_Hours, result, user;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function LoginOnPageRefresh() {
  Backendless.setCurrentUserToken(((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('HPUserCookie')));
  user = (await Backendless.UserService.getCurrentUser(false));
  ___arguments.context.appData['currentUser'] = user;
  ___arguments.context.appData['companyNamesLIST'] = (getObjectProperty(((await Backendless.Data.of('Users').loadRelations((getObjectProperty((getObjectProperty(___arguments.context.appData, 'currentUser')), 'objectId')), Backendless.LoadRelationsQueryBuilder.create().setRelationName('Linked_Companies').setPageSize(10).setOffset(0)))[0]), 'Company_Name'));
  ___arguments.context.appData['currentCompanyObjectId'] = (getObjectProperty(((await Backendless.Data.of('Users').loadRelations((getObjectProperty((getObjectProperty(___arguments.context.appData, 'currentUser')), 'objectId')), Backendless.LoadRelationsQueryBuilder.create().setRelationName('Linked_Companies').setPageSize(1).setOffset(0)))[0]), 'objectId'));
}


  if (await Backendless.UserService.isValidLogin()) {
    await LoginOnPageRefresh();
    ___arguments.context.pageData['MonthlyContractHours'] = 0;
    ___arguments.context.pageData['MaximumWeeklyHours'] = 0;
    ___arguments.context.pageData['MaximumMonthlyHours'] = 0;
    await (async function() {
    	const el = document.querySelectorAll('.content_editable')
    	
    	for (let i = 0; i < el.length; i++) {
    	let theDataID = el[i].classList.item(0);
    	theDataID = theDataID.substring(5);
    	el[i].setAttribute('contenteditable', 'true')
    	// el[i].setAttribute('data-modified','true') // We're now only modifying this attribute when the user clicks on the element.
    	el[i].setAttribute('data-source',theDataID)
    	
    	}
    })();
    ___arguments.context.pageData['inEditMode'] = true;
    ___arguments.context.pageData['editingPage'] = true;
    result = (await (async function(pageData) {
    	/*
    	var myFunction = (e)=> {
    	  console.log(e.currentTarget.getAttribute('data-source'));        
    	}
    	*/
    	
    	var myFunction2 = (e)=> {
    	  pageData.currentDataSource = e.currentTarget.getAttribute('data-source'); // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
    	  console.log("pageData.currentDataSource =: "+ pageData.currentDataSource);
    	  pageData.isOpenModal = true;
    	  return pageData  
    	};
    	
    	Array.from(document.getElementsByClassName('content_editable')).forEach((editable) => {
    	  // editable.addEventListener('click', myFunction);
    	  editable.addEventListener('dblclick', myFunction2);
    	  window.___MY_EVENT_LISTENER  = myFunction2;
    	});  
    	
    	// Previously, we had this code: 
    	/* 
    	Array.from(document.getElementsByClassName('content_editable')).forEach((editable) => {
    	  editable.addEventListener('click', (e)=>{
    	    // e.preventDefault();
    	    console.log(e.currentTarget.getAttribute('data-source'));        
    	    // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
    	    // e.target will trap the actual element that was clicked
    	  });
    	*/
    	
    	/* Function backup
    	editable.addEventListener('dblclick', (e)=>{
    	    // e.preventDefault();
    	    pageData.currentDataSource = e.currentTarget.getAttribute('data-source'); // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
    	    // console.log("A double-click has occurred on element with the ID of: "+ runner.currentDataSource);
    	    console.log("pageData.currentDataSource =: "+ pageData.currentDataSource);
    	    // e.target will trap the actual element that was double-clicked
    	    pageData.isOpenModal = true;
    	    return pageData
    	  });
    	*/
    })(___arguments.context.pageData));
    Contract_Hours = (getObjectProperty(((await Backendless.Data.of('Total_Company_Hours_View').find(Backendless.DataQueryBuilder.create().setWhereClause((['Company_ObjectID = \'',(getObjectProperty(___arguments.context.appData, 'currentCompanyObjectId')),'\''].join(''))).setPageSize(10)))[0]), 'Contract_Hours'));
    Posted_Hours = (getObjectProperty(((await Backendless.Data.of('Total_Company_Hours_View').find(Backendless.DataQueryBuilder.create().setWhereClause((['Company_ObjectID = \'',(getObjectProperty(___arguments.context.appData, 'currentCompanyObjectId')),'\''].join(''))).setPageSize(10)))[0]), 'Posted_Hours'));
    (function (componentUid, dataModel) { ___arguments.context.setComponentDataStoreByUid(componentUid, dataModel) })('e8a685b9572768044a561f8d3532499b', ({ [`CompanyName`]: (getObjectProperty(___arguments.context.appData, 'companyNamesLIST')),[`AvailableHours`]: Posted_Hours,[`MonthlyContractHours`]: Contract_Hours,[`MaximumMonthlyHours`]: (Number(Contract_Hours)) * 2 <= Posted_Hours ? (Number(Contract_Hours)) * 2 : Posted_Hours,[`MaximumWeeklyHours`]: ((Number(Contract_Hours)) / 4) * 2,[`MaximumDailyHours`]: (Number.parseFloat(((Number(Contract_Hours)) / 5)).toFixed(1)) }));
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('b90af9e3067c25f7b9adb5714b69b61e', (await Backendless.Data.of('Support_Tickets').find(Backendless.DataQueryBuilder.create().setSortBy(['Ticket_Date DESC']).setPageSize(25))));
  } else {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
  }

  },
  /* handler:onEnter */
  /* handler:onScroll */
  ['onScroll'](___arguments) {
      if (___arguments.scrollTop) {
    ___arguments.context.pageData['scrollTop'] = true;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['box-shadow'] = '0px 2px 4px rgba(0, 0, 0, 0.1)';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['height'] = '60px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-top'] = '7px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-bottom'] = '7px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('59d442df335dc04e702817c7015fea4c'))['color'] = 'black';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '-5px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '45px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#777777';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('59d442df335dc04e702817c7015fea4c'))['color'] = 'white';
    ___arguments.context.pageData['scrollTop'] = false;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['box-shadow'] = 'none';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['height'] = '80px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['background-color'] = 'transparent';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-top'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '80px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#ffffff';
  }

  },
  /* handler:onScroll */
  /* content */
}))

define('./pages/dash_backup_6-21_AnimationWorking/components/19f1da6844bcd24c52e983cfbdbe9022/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) < 6) {
    ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c')['Ticket_Status'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) + 1);
  } else {
    ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c')['Ticket_Status'] = 1;
  }

  },
  /* handler:onClick */
  /* handler:onDelete */
  ['onDelete'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c')['Ticket_Status'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'OnLoad_TicketStatus'));

  },
  /* handler:onDelete */
  /* handler:onFrontIconAssignment */
  ['onFrontIconAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 1) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#BC2C45';
    return 'fiber_new';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 2) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#D4860B';
    return 'bedtime';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 3) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#04749C';
    return 'flip_camera_ios';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 4) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#642C8D';
    return 'access_time';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 5) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#AAAA22';
    return 'person_pin';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 6) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#04842D';
    return 'check_box';
  }

  },
  /* handler:onFrontIconAssignment */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 1) {
    return 'New';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 2) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#D4860B';
    return 'Pending';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 3) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#04749C';
    return 'In Progress';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 4) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#642C8D';
    return 'Waiting On Support';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 5) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#AAAA22';
    return 'Waiting On Client';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 6) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#04842D';
    return 'Completed!';
  }

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/dash_backup_6-21_AnimationWorking/components/85be065e23aa1f58df8a71bbb6dc7662/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c')['OnLoad_TicketStatus'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status'));

  },
  /* handler:onMounted */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'CurrentlySelectedTicket')) == true) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('85be065e23aa1f58df8a71bbb6dc7662'))['background-color'] = '#990939';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('85be065e23aa1f58df8a71bbb6dc7662'))['background-color'] = '#465466';
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/dash_backup_6-21_AnimationWorking/components/c6a2930ccba2d71d496e8503f464b1d7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var decision, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c')['CurrentlySelectedTicket'] = true;
  ___arguments.context.pageData['OpenDrawer'] = true;
  ___arguments.context.pageData['isTicketSelected'] = true;
  ___arguments.context.pageData['CurrentlySelectedTicketObjectID'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'objectId'));
  ___arguments.context.pageData['PassedDataModel'] = ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dash_backup_6-21_AnimationWorking/components/93cf5db23368ed8bffab3de1d62822b7/bundle.js', [], () => ({
  /* content */
  /* handler:onOpen */
  ['onOpen'](___arguments) {
      console.log('The drawer is open???');

  },
  /* handler:onOpen */
  /* handler:isOpenLogic */
  ['isOpenLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'OpenDrawer'))

  },
  /* handler:isOpenLogic */
  /* handler:onClose */
  async ['onClose'](___arguments) {
    var decision, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  if ((getObjectProperty(___arguments.context.pageData, 'isTicketSelected')) == true) {
    console.log((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('b90af9e3067c25f7b9adb5714b69b61e'));
    console.log(await asyncListFilter(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('b90af9e3067c25f7b9adb5714b69b61e')), async (item) => {
       if ((getObjectProperty(item, 'CurrentlySelectedTicket')) == true) {
        delete item['CurrentlySelectedTicket'];
        decision = true;
      } else {
        decision = true;
      }


     return decision;
    }));
  } else {
  }
  ___arguments.context.pageData['OpenDrawer'] = false;
  (getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))['CurrentlySelectedTicket'] = false;
  ___arguments.context.pageData['CurrentlySelectedTicketObjectID'] = '';

  },
  /* handler:onClose */
  /* content */
}))

define('./pages/dash_backup_6-21_AnimationWorking/components/b67b940bd10c4493f3ef6fae2c284b88/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['OpenDrawer'] = false;
  (getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))['CurrentlySelectedTicket'] = false;
  ___arguments.context.pageData['CurrentlySelectedTicketObjectID'] = '';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dash_backup_6-21_AnimationWorking/components/69985b47bd6b45fa795a184becd896f4/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Name'))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/dash_backup_6-21_AnimationWorking/components/8e55ba1c2e0693d19426a8db2e524eae/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Notes'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dash_backup_6-21_AnimationWorking/components/515a6d6655e2913b394dcf2c6a941c7f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var result;


  await (async function() {
  	const el = document.querySelectorAll('.content_editable')
  	
  	for (let i = 0; i < el.length; i++) {
  	let theDataID = el[i].classList.item(0);
  	theDataID = theDataID.substring(5);
  	el[i].setAttribute('contenteditable', 'true')
  	// el[i].setAttribute('data-modified','true') // We're now only modifying this attribute when the user clicks on the element.
  	el[i].setAttribute('data-source',theDataID)
  	
  	}
  })();
  ___arguments.context.pageData['inEditMode'] = true;
  ___arguments.context.pageData['editingPage'] = true;
  ___arguments.context.pageData['isOpenModal'] = false;
  result = (await (async function(pageData) {
  	/*
  	var myFunction = (e)=> {
  	  console.log(e.currentTarget.getAttribute('data-source'));        
  	}
  	*/
  	
  	var myFunction2 = (e)=> {
  	  pageData.currentDataSource = e.currentTarget.getAttribute('data-source'); // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
  	  console.log("pageData.currentDataSource =: "+ pageData.currentDataSource);
  	  pageData.isOpenModal = true;
  	  return pageData  
  	};
  	
  	Array.from(document.getElementsByClassName('content_editable')).forEach((editable) => {
  	  // editable.addEventListener('click', myFunction);
  	  editable.addEventListener('dblclick', myFunction2);
  	  window.___MY_EVENT_LISTENER  = myFunction2;
  	});  
  	
  	// Previously, we had this code: 
  	/* 
  	Array.from(document.getElementsByClassName('content_editable')).forEach((editable) => {
  	  editable.addEventListener('click', (e)=>{
  	    // e.preventDefault();
  	    console.log(e.currentTarget.getAttribute('data-source'));        
  	    // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
  	    // e.target will trap the actual element that was clicked
  	  });
  	*/
  	
  	/* Function backup
  	editable.addEventListener('dblclick', (e)=>{
  	    // e.preventDefault();
  	    pageData.currentDataSource = e.currentTarget.getAttribute('data-source'); // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
  	    // console.log("A double-click has occurred on element with the ID of: "+ runner.currentDataSource);
  	    console.log("pageData.currentDataSource =: "+ pageData.currentDataSource);
  	    // e.target will trap the actual element that was double-clicked
  	    pageData.isOpenModal = true;
  	    return pageData
  	  });
  	*/
  })(___arguments.context.pageData));
  await (async function() {
  	await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('26ebd15ce2f2c1d6f9813d1a00a26054', true, true, '', 'You are now editing this webpage.', 'info');
  })();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dash_backup_6-21_AnimationWorking/components/e7d4ce9742a74274a8f758c81d36cedd/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Description'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dash_backup_6-21_AnimationWorking/components/39359e517e8299f37c9ad6f3878cdd7c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) < 6) {
    (getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))['Ticket_Status'] = ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) + 1);
    console.log(String('The ticket has been incremented: ') + String(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')));
  } else {
    (getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))['Ticket_Status'] = 1;
    console.log(String('The ticket has been reset to 1: ') + String(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')));
  }

  },
  /* handler:onClick */
  /* handler:onDelete */
  ['onDelete'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))['Ticket_Status'] = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'OnLoad_TicketStatus'));

  },
  /* handler:onDelete */
  /* handler:onFrontIconAssignment */
  ['onFrontIconAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 1) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#BC2C45';
    return 'fiber_new';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 2) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#D4860B';
    return 'bedtime';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 3) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#04749C';
    return 'flip_camera_ios';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 4) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#642C8D';
    return 'access_time';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 5) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#AAAA22';
    return 'person_pin';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 6) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#04842D';
    return 'check_box';
  } else {
    console.log(String('The Ticket_Status of the PassedDataModel is: ') + String(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')));
  }
  console.log(String('The Ticket_Status of the PassedDataModel of pageData is: ') + String(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')));

  },
  /* handler:onFrontIconAssignment */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 1) {
    console.log(String('The label has been updated with: ') + String('New'));
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#BC2C45';
    return 'New';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 2) {
    console.log(String('The label has been updated with: ') + String('Pending'));
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#D4860B';
    return 'Pending';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 3) {
    console.log(String('The label has been updated with: ') + String('In Progress'));
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#04749C';
    return 'In Progress';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 4) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#642C8D';
    return 'Waiting On Support';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 5) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#AAAA22';
    return 'Waiting On Client';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 6) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#04842D';
    return 'Completed!';
  }
  console.log(String('The Ticket_Status of the PassedDataModel of pageData is: ') + String(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')));

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/dash_backup_6-21_AnimationWorking/components/8516f829a89573aea1ecd9c694449d54/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))['OnLoad_TicketStatus'] = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status'));

  },
  /* handler:onMounted */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    
  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/dash_backup_6-21_AnimationWorking/components/c3213a164feed6ef2b22e331e4a31b04/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dash_backup_6-21_AnimationWorking/components/2c8a6f746f7c4274b274dc68d6cfe88c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'OnLoad_TicketStatus'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dash_backup_7-1-SOD/components/30ea90dd05af67628fd821d5003c3d3d/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  onMounted(___arguments) {
      ___arguments.context.pageData['firstNameValue'] = '';
  ___arguments.context.pageData['lastNameValue'] = '';
  ___arguments.context.pageData['emailValue'] = '';
  ___arguments.context.pageData['phoneValue'] = '';
  ___arguments.context.pageData['companyValue'] = '';
  ___arguments.context.pageData['websiteValue'] = '';
  ___arguments.context.pageData['subjectValue'] = '';
  ___arguments.context.pageData['messageValue'] = '';

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/dash_backup_7-1-SOD/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var Contract_Hours, Posted_Hours, result, user;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function LoginOnPageRefresh() {
  Backendless.setCurrentUserToken(((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('HPUserCookie')));
  user = (await Backendless.UserService.getCurrentUser(false));
  ___arguments.context.appData['currentUser'] = user;
  ___arguments.context.appData['companyNamesLIST'] = (getObjectProperty(((await Backendless.Data.of('Users').loadRelations((getObjectProperty((getObjectProperty(___arguments.context.appData, 'currentUser')), 'objectId')), Backendless.LoadRelationsQueryBuilder.create().setRelationName('Linked_Companies').setPageSize(10).setOffset(0)))[0]), 'Company_Name'));
  ___arguments.context.appData['currentCompanyObjectId'] = (getObjectProperty(((await Backendless.Data.of('Users').loadRelations((getObjectProperty((getObjectProperty(___arguments.context.appData, 'currentUser')), 'objectId')), Backendless.LoadRelationsQueryBuilder.create().setRelationName('Linked_Companies').setPageSize(1).setOffset(0)))[0]), 'objectId'));
}


  await LoginOnPageRefresh();
  if ((await Backendless.UserService.isValidLogin()) == true) {
    ___arguments.context.pageData['MonthlyContractHours'] = 0;
    ___arguments.context.pageData['MaximumWeeklyHours'] = 0;
    ___arguments.context.pageData['MaximumMonthlyHours'] = 0;
    await (async function() {
    	const el = document.querySelectorAll('.content_editable')
    	
    	for (let i = 0; i < el.length; i++) {
    	let theDataID = el[i].classList.item(0);
    	theDataID = theDataID.substring(5);
    	el[i].setAttribute('contenteditable', 'true')
    	// el[i].setAttribute('data-modified','true') // We're now only modifying this attribute when the user clicks on the element.
    	el[i].setAttribute('data-source',theDataID)
    	
    	}
    })();
    ___arguments.context.pageData['inEditMode'] = true;
    ___arguments.context.pageData['editingPage'] = true;
    ___arguments.context.pageData['TicketsPageOffset'] = 0;
    result = (await (async function(pageData) {
    	/*
    	var myFunction = (e)=> {
    	  console.log(e.currentTarget.getAttribute('data-source'));        
    	}
    	*/
    	
    	var myFunction2 = (e)=> {
    	  pageData.currentDataSource = e.currentTarget.getAttribute('data-source'); // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
    	  console.log("pageData.currentDataSource =: "+ pageData.currentDataSource);
    	  pageData.isOpenModal = true;
    	  return pageData  
    	};
    	
    	Array.from(document.getElementsByClassName('content_editable')).forEach((editable) => {
    	  // editable.addEventListener('click', myFunction);
    	  editable.addEventListener('dblclick', myFunction2);
    	  window.___MY_EVENT_LISTENER  = myFunction2;
    	});  
    	
    	// Previously, we had this code: 
    	/* 
    	Array.from(document.getElementsByClassName('content_editable')).forEach((editable) => {
    	  editable.addEventListener('click', (e)=>{
    	    // e.preventDefault();
    	    console.log(e.currentTarget.getAttribute('data-source'));        
    	    // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
    	    // e.target will trap the actual element that was clicked
    	  });
    	*/
    	
    	/* Function backup
    	editable.addEventListener('dblclick', (e)=>{
    	    // e.preventDefault();
    	    pageData.currentDataSource = e.currentTarget.getAttribute('data-source'); // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
    	    // console.log("A double-click has occurred on element with the ID of: "+ runner.currentDataSource);
    	    console.log("pageData.currentDataSource =: "+ pageData.currentDataSource);
    	    // e.target will trap the actual element that was double-clicked
    	    pageData.isOpenModal = true;
    	    return pageData
    	  });
    	*/
    })(___arguments.context.pageData));
    Contract_Hours = (getObjectProperty(((await Backendless.Data.of('Total_Company_Hours_View').find(Backendless.DataQueryBuilder.create().setWhereClause((['Company_ObjectID = \'',(getObjectProperty(___arguments.context.appData, 'currentCompanyObjectId')),'\''].join(''))).setPageSize(10)))[0]), 'Contract_Hours'));
    Posted_Hours = (getObjectProperty(((await Backendless.Data.of('Total_Company_Hours_View').find(Backendless.DataQueryBuilder.create().setWhereClause((['Company_ObjectID = \'',(getObjectProperty(___arguments.context.appData, 'currentCompanyObjectId')),'\''].join(''))).setPageSize(10)))[0]), 'Posted_Hours'));
    (function (componentUid, dataModel) { ___arguments.context.setComponentDataStoreByUid(componentUid, dataModel) })('e8a685b9572768044a561f8d3532499b', ({ [`CompanyName`]: (getObjectProperty(___arguments.context.appData, 'companyNamesLIST')),[`AvailableHours`]: Posted_Hours,[`MonthlyContractHours`]: Contract_Hours,[`MaximumMonthlyHours`]: (Number(Contract_Hours)) * 2 <= Posted_Hours ? (Number(Contract_Hours)) * 2 : Posted_Hours,[`MaximumWeeklyHours`]: ((Number(Contract_Hours)) / 4) * 2,[`MaximumDailyHours`]: (Number.parseFloat(((Number(Contract_Hours)) / 5)).toFixed(1)) }));
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('b90af9e3067c25f7b9adb5714b69b61e', (await Backendless.Data.of('Support_Tickets').find(Backendless.DataQueryBuilder.create().setSortBy(['Ticket_Date DESC']).setPageSize(25))));
  } else {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
  }

  },
  /* handler:onEnter */
  /* handler:onScroll */
  ['onScroll'](___arguments) {
      if (___arguments.scrollTop) {
    ___arguments.context.pageData['scrollTop'] = true;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['box-shadow'] = '0px 2px 4px rgba(0, 0, 0, 0.1)';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['height'] = '60px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-top'] = '7px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-bottom'] = '7px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('59d442df335dc04e702817c7015fea4c'))['color'] = 'black';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '-5px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '45px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#777777';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('59d442df335dc04e702817c7015fea4c'))['color'] = 'white';
    ___arguments.context.pageData['scrollTop'] = false;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['box-shadow'] = 'none';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['height'] = '80px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['background-color'] = 'transparent';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-top'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '80px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#ffffff';
  }

  },
  /* handler:onScroll */
  /* content */
}))

define('./pages/dash_backup_7-1-SOD/components/19f1da6844bcd24c52e983cfbdbe9022/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) < 6) {
    ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c')['Ticket_Status'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) + 1);
  } else {
    ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c')['Ticket_Status'] = 1;
  }

  },
  /* handler:onClick */
  /* handler:onDelete */
  ['onDelete'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c')['Ticket_Status'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'OnLoad_TicketStatus'));

  },
  /* handler:onDelete */
  /* handler:onFrontIconAssignment */
  ['onFrontIconAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 1) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#BC2C45';
    return 'fiber_new';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 2) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#D4860B';
    return 'bedtime';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 3) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#04749C';
    return 'flip_camera_ios';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 4) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#642C8D';
    return 'access_time';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 5) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#AAAA22';
    return 'person_pin';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 6) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#04842D';
    return 'check_box';
  }

  },
  /* handler:onFrontIconAssignment */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 1) {
    return 'New';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 2) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#D4860B';
    return 'Pending';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 3) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#04749C';
    return 'In Progress';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 4) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#642C8D';
    return 'Waiting On Support';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 5) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#AAAA22';
    return 'Waiting On Client';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 6) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#04842D';
    return 'Completed!';
  }

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/dash_backup_7-1-SOD/components/85be065e23aa1f58df8a71bbb6dc7662/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c')['OnLoad_TicketStatus'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status'));

  },
  /* handler:onMounted */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    
  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/dash_backup_7-1-SOD/components/c6a2930ccba2d71d496e8503f464b1d7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var decision, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  if ((getObjectProperty(___arguments.context.pageData, 'isTicketSelected')) == true) {
    console.log(await asyncListFilter(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('b90af9e3067c25f7b9adb5714b69b61e')), async (item) => {
       if ((getObjectProperty(item, 'CurrentlySelectedTicket')) == true) {
        delete item['CurrentlySelectedTicket'];
        decision = true;
      } else {
        decision = true;
      }


     return decision;
    }));
  } else {
  }
  ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c')['CurrentlySelectedTicket'] = true;
  ___arguments.context.pageData['OpenDrawer'] = true;
  ___arguments.context.pageData['isTicketSelected'] = true;
  ___arguments.context.pageData['CurrentlySelectedTicketObjectID'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'objectId'));
  ___arguments.context.pageData['PassedDataModel'] = ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dash_backup_7-1-SOD/components/93cf5db23368ed8bffab3de1d62822b7/bundle.js', [], () => ({
  /* content */
  /* handler:onOpen */
  ['onOpen'](___arguments) {
    
  },
  /* handler:onOpen */
  /* handler:isOpenLogic */
  ['isOpenLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'OpenDrawer'))

  },
  /* handler:isOpenLogic */
  /* handler:onClose */
  async ['onClose'](___arguments) {
    var decision, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  if ((getObjectProperty(___arguments.context.pageData, 'isTicketSelected')) == true) {
    console.log((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('b90af9e3067c25f7b9adb5714b69b61e'));
    console.log(await asyncListFilter(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('b90af9e3067c25f7b9adb5714b69b61e')), async (item) => {
       if ((getObjectProperty(item, 'CurrentlySelectedTicket')) == true) {
        delete item['CurrentlySelectedTicket'];
        decision = true;
      } else {
        decision = true;
      }


     return decision;
    }));
  } else {
  }
  await ( async function (componentUid, event) {  ___arguments.context.getComponentByUid(componentUid).close(event) })('93cf5db23368ed8bffab3de1d62822b7', undefined);
  ___arguments.context.pageData['OpenDrawer'] = false;
  (getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))['CurrentlySelectedTicket'] = false;
  ___arguments.context.pageData['CurrentlySelectedTicketObjectID'] = '';

  },
  /* handler:onClose */
  /* content */
}))

define('./pages/dash_backup_7-1-SOD/components/b67b940bd10c4493f3ef6fae2c284b88/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['OpenDrawer'] = false;
  (getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))['CurrentlySelectedTicket'] = false;
  ___arguments.context.pageData['CurrentlySelectedTicketObjectID'] = '';
  await ( async function (componentUid, event) {  ___arguments.context.getComponentByUid(componentUid).close(event) })('93cf5db23368ed8bffab3de1d62822b7', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dash_backup_7-1-SOD/components/69985b47bd6b45fa795a184becd896f4/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Name'))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/dash_backup_7-1-SOD/components/8e55ba1c2e0693d19426a8db2e524eae/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Notes'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dash_backup_7-1-SOD/components/515a6d6655e2913b394dcf2c6a941c7f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var result;


  await (async function() {
  	const el = document.querySelectorAll('.content_editable')
  	
  	for (let i = 0; i < el.length; i++) {
  	let theDataID = el[i].classList.item(0);
  	theDataID = theDataID.substring(5);
  	el[i].setAttribute('contenteditable', 'true')
  	// el[i].setAttribute('data-modified','true') // We're now only modifying this attribute when the user clicks on the element.
  	el[i].setAttribute('data-source',theDataID)
  	
  	}
  })();
  ___arguments.context.pageData['inEditMode'] = true;
  ___arguments.context.pageData['editingPage'] = true;
  ___arguments.context.pageData['isOpenModal'] = false;
  result = (await (async function(pageData) {
  	/*
  	var myFunction = (e)=> {
  	  console.log(e.currentTarget.getAttribute('data-source'));        
  	}
  	*/
  	
  	var myFunction2 = (e)=> {
  	  pageData.currentDataSource = e.currentTarget.getAttribute('data-source'); // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
  	  console.log("pageData.currentDataSource =: "+ pageData.currentDataSource);
  	  pageData.isOpenModal = true;
  	  return pageData  
  	};
  	
  	Array.from(document.getElementsByClassName('content_editable')).forEach((editable) => {
  	  // editable.addEventListener('click', myFunction);
  	  editable.addEventListener('dblclick', myFunction2);
  	  window.___MY_EVENT_LISTENER  = myFunction2;
  	});  
  	
  	// Previously, we had this code: 
  	/* 
  	Array.from(document.getElementsByClassName('content_editable')).forEach((editable) => {
  	  editable.addEventListener('click', (e)=>{
  	    // e.preventDefault();
  	    console.log(e.currentTarget.getAttribute('data-source'));        
  	    // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
  	    // e.target will trap the actual element that was clicked
  	  });
  	*/
  	
  	/* Function backup
  	editable.addEventListener('dblclick', (e)=>{
  	    // e.preventDefault();
  	    pageData.currentDataSource = e.currentTarget.getAttribute('data-source'); // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
  	    // console.log("A double-click has occurred on element with the ID of: "+ runner.currentDataSource);
  	    console.log("pageData.currentDataSource =: "+ pageData.currentDataSource);
  	    // e.target will trap the actual element that was double-clicked
  	    pageData.isOpenModal = true;
  	    return pageData
  	  });
  	*/
  })(___arguments.context.pageData));
  await (async function() {
  	await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('26ebd15ce2f2c1d6f9813d1a00a26054', true, true, '', 'You are now editing this webpage.', 'info');
  })();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dash_backup_7-1-SOD/components/e7d4ce9742a74274a8f758c81d36cedd/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Description'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dash_backup_7-1-SOD/components/39359e517e8299f37c9ad6f3878cdd7c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) < 6) {
    (getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))['Ticket_Status'] = ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) + 1);
    console.log(String('The ticket has been incremented: ') + String(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')));
  } else {
    (getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))['Ticket_Status'] = 1;
    console.log(String('The ticket has been reset to 1: ') + String(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')));
  }

  },
  /* handler:onClick */
  /* handler:onDelete */
  ['onDelete'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))['Ticket_Status'] = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'OnLoad_TicketStatus'));

  },
  /* handler:onDelete */
  /* handler:onFrontIconAssignment */
  ['onFrontIconAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 1) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#BC2C45';
    return 'fiber_new';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 2) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#D4860B';
    return 'bedtime';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 3) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#04749C';
    return 'flip_camera_ios';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 4) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#642C8D';
    return 'access_time';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 5) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#AAAA22';
    return 'person_pin';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 6) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#04842D';
    return 'check_box';
  } else {
    console.log(String('The Ticket_Status of the PassedDataModel is: ') + String(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')));
  }
  console.log(String('The Ticket_Status of the PassedDataModel of pageData is: ') + String(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')));

  },
  /* handler:onFrontIconAssignment */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 1) {
    console.log(String('The label has been updated with: ') + String('New'));
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#BC2C45';
    return 'New';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 2) {
    console.log(String('The label has been updated with: ') + String('Pending'));
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#D4860B';
    return 'Pending';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 3) {
    console.log(String('The label has been updated with: ') + String('In Progress'));
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#04749C';
    return 'In Progress';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 4) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#642C8D';
    return 'Waiting On Support';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 5) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#AAAA22';
    return 'Waiting On Client';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 6) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#04842D';
    return 'Completed!';
  }
  console.log(String('The Ticket_Status of the PassedDataModel of pageData is: ') + String(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')));

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/dash_backup_7-1-SOD/components/8516f829a89573aea1ecd9c694449d54/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))['OnLoad_TicketStatus'] = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status'));

  },
  /* handler:onMounted */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    
  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/dash_backup_7-1-SOD/components/c3213a164feed6ef2b22e331e4a31b04/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dash_backup_7-1-SOD/components/2c8a6f746f7c4274b274dc68d6cfe88c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'OnLoad_TicketStatus'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dash_backup_7-1-SOD/components/f20f534105889b957ec106deb1557f36/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'CompanyLogoURL'))

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/dash_backup_7-1-SOD/components/a0e8e78e083d16d87ca9d50b632d3b01/bundle.js', [], () => ({
  /* content */
  /* handler:onTooltipTextAssignment */
  ['onTooltipTextAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('cfe4133897c28fce29fc7133eaf4d168'), 'CompanyName'))

  },
  /* handler:onTooltipTextAssignment */
  /* content */
}))

define('./pages/dash_backup_7-1-SOD/components/2cc11f9da0d3cf1bc6eaf6c3d7cc6b1d/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  async ['onMouseOver'](___arguments) {
    var CombinedList, SecondList, FirstList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log('Loading more tickets...');
  console.log('Fetch fresh batch of tickets here...');
  ___arguments.context.pageData['TicketsPageOffset'] = ((getObjectProperty(___arguments.context.pageData, 'TicketsPageOffset')) + 25);
  ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('2cc11f9da0d3cf1bc6eaf6c3d7cc6b1d'))['content'] = 'Please wait...';
  ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2cc11f9da0d3cf1bc6eaf6c3d7cc6b1d'))['color'] = '#FFFFFF';
  ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('ed7ad4ee925a6e43b31917edc1187b21'))['background-color'] = '#8D2435';
  FirstList = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('b90af9e3067c25f7b9adb5714b69b61e'));
  SecondList = (await Backendless.Data.of('Support_Tickets').find(Backendless.DataQueryBuilder.create().setSortBy(['Ticket_Date DESC']).setPageSize(25).setOffset((getObjectProperty(___arguments.context.pageData, 'TicketsPageOffset')))));
  CombinedList = (FirstList.concat(SecondList));
  console.log(CombinedList);
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('b90af9e3067c25f7b9adb5714b69b61e', (JSON.parse((JSON.stringify(CombinedList)))));
  ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('ed7ad4ee925a6e43b31917edc1187b21'))['background-color'] = '#CCC6C9';
  ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2cc11f9da0d3cf1bc6eaf6c3d7cc6b1d'))['color'] = '#000000';
  ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('2cc11f9da0d3cf1bc6eaf6c3d7cc6b1d'))['content'] = 'Load More Tickets';

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./pages/dash_backup_7-1-SOD/components/2287e385c14b9a056f5558f38ba29a1c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['Showing tickets: (',(getObjectProperty(___arguments.context.pageData, 'TicketsPageOffset')),' to ',(getObjectProperty(___arguments.context.pageData, 'TicketsPageOffset')) + 25,')'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dash_backup_7-1-SOD/components/441491b9e1deb0c17132e707fcd80b87/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    
  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/dash_backup_7-1-SOD/components/a2468eeb4bd7d673efa3ee5aa0559f7c/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.dataModel, 'CurrentlySelectedTicket')) == true) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('a2468eeb4bd7d673efa3ee5aa0559f7c'))['background-color'] = '#990939';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('a2468eeb4bd7d673efa3ee5aa0559f7c'))['background-color'] = '#465466';
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/dash_backup_7-8/components/30ea90dd05af67628fd821d5003c3d3d/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  onMounted(___arguments) {
      ___arguments.context.pageData['firstNameValue'] = '';
  ___arguments.context.pageData['lastNameValue'] = '';
  ___arguments.context.pageData['emailValue'] = '';
  ___arguments.context.pageData['phoneValue'] = '';
  ___arguments.context.pageData['companyValue'] = '';
  ___arguments.context.pageData['websiteValue'] = '';
  ___arguments.context.pageData['subjectValue'] = '';
  ___arguments.context.pageData['messageValue'] = '';

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/dash_backup_7-8/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var AvailableHoursAfterTickets, MaximumWeeklyHours, MaximumHoursPerMonth, Contract_Hours, Posted_Hours, companyRecord, result, user, temp, step3, step2, TotalAnnually, ContractHours, base;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function LoginOnPageRefresh() {
  Backendless.setCurrentUserToken(((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('HPUserCookie')));
  user = (await Backendless.UserService.getCurrentUser(false));
  ___arguments.context.appData['currentUser'] = user;
  ___arguments.context.appData['companyNamesLIST'] = (getObjectProperty(((await Backendless.Data.of('Users').loadRelations((getObjectProperty((getObjectProperty(___arguments.context.appData, 'currentUser')), 'objectId')), Backendless.LoadRelationsQueryBuilder.create().setRelationName('Linked_Companies').setPageSize(10).setOffset(0)))[0]), 'Company_Name'));
  ___arguments.context.appData['currentCompanyObjectId'] = (getObjectProperty(((await Backendless.Data.of('Users').loadRelations((getObjectProperty((getObjectProperty(___arguments.context.appData, 'currentUser')), 'objectId')), Backendless.LoadRelationsQueryBuilder.create().setRelationName('Linked_Companies').setPageSize(1).setOffset(0)))[0]), 'objectId'));
  if ((getObjectProperty((getObjectProperty(___arguments.context.appData, 'currentUser')), 'objectId')) == 'C1448329-3668-4275-8C4E-866D8C665C4F') {
    ___arguments.context.pageData['userIsAdmin'] = true;
  } else {
    ___arguments.context.pageData['userIsAdmin'] = false;
  }
}

/**
 * Describe this function...
 */
async function CalculateRemainingHours(companyRecord) {
  AvailableHoursAfterTickets = 0;
  temp = 0;
  temp = (Number((Number.parseFloat((getObjectProperty(((await Backendless.Data.of('Support_Tickets').find(Backendless.DataQueryBuilder.create().setWhereClause((['Linked_Company.objectId = \'',(getObjectProperty(___arguments.context.appData, 'currentCompanyObjectId')),'\' AND Ignore_Ticket_Hours = false'].join(''))).setProperties('SUM (Time_Spent) AS TotalTicketsTimeSpent').setPageSize(10)))[0]), 'TotalTicketsTimeSpent'))).toFixed(1))));
  AvailableHoursAfterTickets = Posted_Hours + temp;
  return AvailableHoursAfterTickets
}

/**
 * Describe this function...
 */
async function MaximumMonthlyHours(ContractHours, AvailableHoursAfterTickets) {
  TotalAnnually = ContractHours * 12;
  step2 = TotalAnnually + AvailableHoursAfterTickets;
  step3 = step2 / 12;
  return step3
}


  await LoginOnPageRefresh();
  if ((await Backendless.UserService.isValidLogin()) == true) {
    ___arguments.context.pageData['MonthlyContractHours'] = 0;
    ___arguments.context.pageData['MaximumWeeklyHours'] = 0;
    ___arguments.context.pageData['MaximumMonthlyHours'] = 0;
    await (async function() {
    	const el = document.querySelectorAll('.content_editable')
    	
    	for (let i = 0; i < el.length; i++) {
    	let theDataID = el[i].classList.item(0);
    	theDataID = theDataID.substring(5);
    	el[i].setAttribute('contenteditable', 'true')
    	// el[i].setAttribute('data-modified','true') // We're now only modifying this attribute when the user clicks on the element.
    	el[i].setAttribute('data-source',theDataID)
    	
    	}
    })();
    ___arguments.context.pageData['inEditMode'] = true;
    ___arguments.context.pageData['editingPage'] = true;
    ___arguments.context.pageData['TicketsPageOffset'] = 0;
    result = (await (async function(pageData) {
    	/*
    	var myFunction = (e)=> {
    	  console.log(e.currentTarget.getAttribute('data-source'));        
    	}
    	*/
    	
    	var myFunction2 = (e)=> {
    	  pageData.currentDataSource = e.currentTarget.getAttribute('data-source'); // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
    	  console.log("pageData.currentDataSource =: "+ pageData.currentDataSource);
    	  pageData.isOpenModal = true;
    	  return pageData  
    	};
    	
    	Array.from(document.getElementsByClassName('content_editable')).forEach((editable) => {
    	  // editable.addEventListener('click', myFunction);
    	  editable.addEventListener('dblclick', myFunction2);
    	  window.___MY_EVENT_LISTENER  = myFunction2;
    	});  
    	
    	// Previously, we had this code: 
    	/* 
    	Array.from(document.getElementsByClassName('content_editable')).forEach((editable) => {
    	  editable.addEventListener('click', (e)=>{
    	    // e.preventDefault();
    	    console.log(e.currentTarget.getAttribute('data-source'));        
    	    // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
    	    // e.target will trap the actual element that was clicked
    	  });
    	*/
    	
    	/* Function backup
    	editable.addEventListener('dblclick', (e)=>{
    	    // e.preventDefault();
    	    pageData.currentDataSource = e.currentTarget.getAttribute('data-source'); // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
    	    // console.log("A double-click has occurred on element with the ID of: "+ runner.currentDataSource);
    	    console.log("pageData.currentDataSource =: "+ pageData.currentDataSource);
    	    // e.target will trap the actual element that was double-clicked
    	    pageData.isOpenModal = true;
    	    return pageData
    	  });
    	*/
    })(___arguments.context.pageData));
    companyRecord = (await Backendless.Data.of('Total_Company_Hours_View').find(Backendless.DataQueryBuilder.create().setWhereClause((['Company_ObjectID = \'',(getObjectProperty(___arguments.context.appData, 'currentCompanyObjectId')),'\''].join(''))).setPageSize(10)))[0];
    Contract_Hours = (Number((getObjectProperty(companyRecord, 'Contract_Hours'))));
    Posted_Hours = (Number((getObjectProperty(companyRecord, 'Posted_Hours'))));
    AvailableHoursAfterTickets = (Number.parseFloat((getObjectProperty(((await Backendless.Data.of('Support_Tickets').find(Backendless.DataQueryBuilder.create().setWhereClause((['Linked_Company.objectId = \'',(getObjectProperty(___arguments.context.appData, 'currentCompanyObjectId')),'\' AND Ignore_Ticket_Hours = false'].join(''))).setProperties('SUM (Time_Spent) AS TotalTicketsTimeSpent').setPageSize(10)))[0]), 'TotalTicketsTimeSpent'))).toFixed(1));
    AvailableHoursAfterTickets = await CalculateRemainingHours(companyRecord);
    MaximumHoursPerMonth = await MaximumMonthlyHours(Contract_Hours, AvailableHoursAfterTickets - Contract_Hours);
    MaximumWeeklyHours = (Number(MaximumHoursPerMonth)) / 2.875;
    (function (componentUid, dataModel) { ___arguments.context.setComponentDataStoreByUid(componentUid, dataModel) })('e8a685b9572768044a561f8d3532499b', ({ [`CompanyName`]: (getObjectProperty(___arguments.context.appData, 'companyNamesLIST')),[`AvailableHours`]: Posted_Hours,[`MonthlyContractHours`]: Contract_Hours,[`MaximumMonthlyHours`]: (Number.parseFloat(MaximumHoursPerMonth).toFixed(1)),[`MaximumWeeklyHours`]: (Number.parseFloat(MaximumWeeklyHours).toFixed(1)),[`MaximumDailyHours`]: (Number.parseFloat(((Number(MaximumWeeklyHours)) / 4)).toFixed(1)),[`AvailableHoursAfterTickets`]: (Number.parseFloat(AvailableHoursAfterTickets).toFixed(1)) }));
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('b90af9e3067c25f7b9adb5714b69b61e', (await Backendless.Data.of('Support_Tickets').find(Backendless.DataQueryBuilder.create().setSortBy(['created DESC']).setPageSize(25))));
    ___arguments.context.pageData['LoadMoreTicketsEnabled'] = true;
  } else {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
  }

  },
  /* handler:onEnter */
  /* handler:onScroll */
  ['onScroll'](___arguments) {
      if (___arguments.scrollTop) {
    ___arguments.context.pageData['scrollTop'] = true;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['box-shadow'] = '0px 2px 4px rgba(0, 0, 0, 0.1)';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['height'] = '60px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-top'] = '7px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-bottom'] = '7px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('59d442df335dc04e702817c7015fea4c'))['color'] = 'black';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '-5px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '45px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#777777';
  } else {
    console.log('We\'re at the "Scroll Top"');
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['display'] = 'flex';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('59d442df335dc04e702817c7015fea4c'))['color'] = 'white';
    ___arguments.context.pageData['scrollTop'] = false;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['box-shadow'] = 'none';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['height'] = '80px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['background-color'] = 'transparent';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-top'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '80px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#ffffff';
  }

  },
  /* handler:onScroll */
  /* content */
}))

define('./pages/dash_backup_7-8/components/19f1da6844bcd24c52e983cfbdbe9022/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) < 6) {
    ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c')['Ticket_Status'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) + 1);
  } else {
    ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c')['Ticket_Status'] = 1;
  }

  },
  /* handler:onClick */
  /* handler:onDelete */
  ['onDelete'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c')['Ticket_Status'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'OnLoad_TicketStatus'));

  },
  /* handler:onDelete */
  /* handler:onFrontIconAssignment */
  ['onFrontIconAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 1) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#BC2C45';
    return 'fiber_new';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 2) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#D4860B';
    return 'bedtime';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 3) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#04749C';
    return 'flip_camera_ios';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 4) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#642C8D';
    return 'access_time';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 5) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#AAAA22';
    return 'person_pin';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 6) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#04842D';
    return 'check_box';
  }

  },
  /* handler:onFrontIconAssignment */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 1) {
    return 'New';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 2) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#D4860B';
    return 'Pending';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 3) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#04749C';
    return 'In Progress';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 4) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#642C8D';
    return 'Waiting On Support';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 5) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#AAAA22';
    return 'Waiting On Client';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 6) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#04842D';
    return 'Completed!';
  }

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/dash_backup_7-8/components/85be065e23aa1f58df8a71bbb6dc7662/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c')['OnLoad_TicketStatus'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status'));

  },
  /* handler:onMounted */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    
  },
  /* handler:onStyleAssignment */
  /* handler:onBeforeMount */
  ['onBeforeMount'](___arguments) {
    
  },
  /* handler:onBeforeMount */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log('Clickety-click');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dash_backup_7-8/components/c6a2930ccba2d71d496e8503f464b1d7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var decision, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  if ((getObjectProperty(___arguments.context.pageData, 'isTicketSelected')) == true) {
    console.log(await asyncListFilter(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('b90af9e3067c25f7b9adb5714b69b61e')), async (item) => {
       if ((getObjectProperty(item, 'CurrentlySelectedTicket')) == true) {
        delete item['CurrentlySelectedTicket'];
        decision = true;
      } else {
        decision = true;
      }


     return decision;
    }));
  } else {
  }
  ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c')['CurrentlySelectedTicket'] = true;
  ___arguments.context.pageData['OpenDrawer'] = true;
  ___arguments.context.pageData['isTicketSelected'] = true;
  ___arguments.context.pageData['CurrentlySelectedTicketObjectID'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'objectId'));
  ___arguments.context.pageData['PassedDataModel'] = ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c');
  (function (componentUid, dataModel) { ___arguments.context.setComponentDataStoreByUid(componentUid, dataModel) })('416556824f34d6d9385836c756cc014c', ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'));
  (function (component) { component.el.scrollIntoView() })((getObjectProperty(___arguments.context.pageData, (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'objectId')))));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dash_backup_7-8/components/93cf5db23368ed8bffab3de1d62822b7/bundle.js', [], () => ({
  /* content */
  /* handler:onOpen */
  ['onOpen'](___arguments) {
    
  },
  /* handler:onOpen */
  /* handler:isOpenLogic */
  ['isOpenLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'OpenDrawer'))

  },
  /* handler:isOpenLogic */
  /* handler:onClose */
  async ['onClose'](___arguments) {
    var decision, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  if ((getObjectProperty(___arguments.context.pageData, 'isTicketSelected')) == true) {
    console.log((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('b90af9e3067c25f7b9adb5714b69b61e'));
    console.log(await asyncListFilter(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('b90af9e3067c25f7b9adb5714b69b61e')), async (item) => {
       if ((getObjectProperty(item, 'CurrentlySelectedTicket')) == true) {
        delete item['CurrentlySelectedTicket'];
        decision = true;
      } else {
        decision = true;
      }


     return decision;
    }));
  } else {
  }
  await ( async function (componentUid, event) {  ___arguments.context.getComponentByUid(componentUid).close(event) })('93cf5db23368ed8bffab3de1d62822b7', undefined);
  ___arguments.context.pageData['OpenDrawer'] = false;
  (getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))['CurrentlySelectedTicket'] = false;
  ___arguments.context.pageData['CurrentlySelectedTicketObjectID'] = '';

  },
  /* handler:onClose */
  /* content */
}))

define('./pages/dash_backup_7-8/components/b67b940bd10c4493f3ef6fae2c284b88/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid, event) {  ___arguments.context.getComponentByUid(componentUid).close(event) })('93cf5db23368ed8bffab3de1d62822b7', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dash_backup_7-8/components/69985b47bd6b45fa795a184becd896f4/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Name'))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/dash_backup_7-8/components/8e55ba1c2e0693d19426a8db2e524eae/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Notes'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dash_backup_7-8/components/515a6d6655e2913b394dcf2c6a941c7f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var result, currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (await Backendless.UserService.isValidLogin()) {
    if ((getObjectProperty((getObjectProperty(___arguments.context.appData, 'currentUser')), 'objectId')) == 'C1448329-3668-4275-8C4E-866D8C665C4F') {
      ___arguments.context.pageData['adminEditMode'] = true;
    } else {
      ___arguments.context.pageData['adminEditMode'] = false;
    }
  }
  if (currentUser == true) {
    console.log('We\'re the Admin!');
  }
  await (async function() {
  	const el = document.querySelectorAll('.content_editable')
  	
  	for (let i = 0; i < el.length; i++) {
  	let theDataID = el[i].classList.item(0);
  	theDataID = theDataID.substring(5);
  	el[i].setAttribute('contenteditable', 'true')
  	// el[i].setAttribute('data-modified','true') // We're now only modifying this attribute when the user clicks on the element.
  	el[i].setAttribute('data-source',theDataID)
  	
  	}
  })();
  ___arguments.context.pageData['inEditMode'] = true;
  ___arguments.context.pageData['editingPage'] = true;
  ___arguments.context.pageData['isOpenModal'] = false;
  result = (await (async function(pageData) {
  	/*
  	var myFunction = (e)=> {
  	  console.log(e.currentTarget.getAttribute('data-source'));        
  	}
  	*/
  	
  	var myFunction2 = (e)=> {
  	  pageData.currentDataSource = e.currentTarget.getAttribute('data-source'); // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
  	  console.log("pageData.currentDataSource =: "+ pageData.currentDataSource);
  	  pageData.isOpenModal = true;
  	  return pageData  
  	};
  	
  	Array.from(document.getElementsByClassName('content_editable')).forEach((editable) => {
  	  // editable.addEventListener('click', myFunction);
  	  editable.addEventListener('dblclick', myFunction2);
  	  window.___MY_EVENT_LISTENER  = myFunction2;
  	});  
  	
  	// Previously, we had this code: 
  	/* 
  	Array.from(document.getElementsByClassName('content_editable')).forEach((editable) => {
  	  editable.addEventListener('click', (e)=>{
  	    // e.preventDefault();
  	    console.log(e.currentTarget.getAttribute('data-source'));        
  	    // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
  	    // e.target will trap the actual element that was clicked
  	  });
  	*/
  	
  	/* Function backup
  	editable.addEventListener('dblclick', (e)=>{
  	    // e.preventDefault();
  	    pageData.currentDataSource = e.currentTarget.getAttribute('data-source'); // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
  	    // console.log("A double-click has occurred on element with the ID of: "+ runner.currentDataSource);
  	    console.log("pageData.currentDataSource =: "+ pageData.currentDataSource);
  	    // e.target will trap the actual element that was double-clicked
  	    pageData.isOpenModal = true;
  	    return pageData
  	  });
  	*/
  })(___arguments.context.pageData));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dash_backup_7-8/components/e7d4ce9742a74274a8f758c81d36cedd/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Description'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dash_backup_7-8/components/39359e517e8299f37c9ad6f3878cdd7c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) < 6) {
    (getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))['Ticket_Status'] = ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) + 1);
    console.log(String('The ticket has been incremented: ') + String(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')));
  } else {
    (getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))['Ticket_Status'] = 1;
    console.log(String('The ticket has been reset to 1: ') + String(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')));
  }

  },
  /* handler:onClick */
  /* handler:onDelete */
  ['onDelete'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))['Ticket_Status'] = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'OnLoad_TicketStatus'));

  },
  /* handler:onDelete */
  /* handler:onFrontIconAssignment */
  ['onFrontIconAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 1) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#BC2C45';
    return 'fiber_new';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 2) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#D4860B';
    return 'bedtime';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 3) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#04749C';
    return 'flip_camera_ios';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 4) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#642C8D';
    return 'access_time';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 5) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#AAAA22';
    return 'person_pin';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 6) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#04842D';
    return 'check_box';
  } else {
    console.log(String('The Ticket_Status of the PassedDataModel is: ') + String(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')));
  }
  console.log(String('The Ticket_Status of the PassedDataModel of pageData is: ') + String(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')));

  },
  /* handler:onFrontIconAssignment */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 1) {
    console.log(String('The label has been updated with: ') + String('New'));
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#BC2C45';
    return 'New';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 2) {
    console.log(String('The label has been updated with: ') + String('Pending'));
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#D4860B';
    return 'Pending';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 3) {
    console.log(String('The label has been updated with: ') + String('In Progress'));
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#04749C';
    return 'In Progress';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 4) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#642C8D';
    return 'Waiting On Support';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 5) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#AAAA22';
    return 'Waiting On Client';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 6) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#04842D';
    return 'Completed!';
  }
  console.log(String('The Ticket_Status of the PassedDataModel of pageData is: ') + String(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')));

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/dash_backup_7-8/components/8516f829a89573aea1ecd9c694449d54/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))['OnLoad_TicketStatus'] = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status'));

  },
  /* handler:onMounted */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    
  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/dash_backup_7-8/components/c3213a164feed6ef2b22e331e4a31b04/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dash_backup_7-8/components/2c8a6f746f7c4274b274dc68d6cfe88c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'OnLoad_TicketStatus'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dash_backup_7-8/components/f20f534105889b957ec106deb1557f36/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'CompanyLogoURL'))

  },
  /* handler:onSourceUrlAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dash_backup_7-8/components/a0e8e78e083d16d87ca9d50b632d3b01/bundle.js', [], () => ({
  /* content */
  /* handler:onTooltipTextAssignment */
  ['onTooltipTextAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'CompanyName'))

  },
  /* handler:onTooltipTextAssignment */
  /* content */
}))

define('./pages/dash_backup_7-8/components/2cc11f9da0d3cf1bc6eaf6c3d7cc6b1d/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  async ['onMouseOver'](___arguments) {
    var CombinedList, SecondList, FirstList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'LoadMoreTicketsEnabled')) == true) {
    console.log('Loading more tickets...');
    ___arguments.context.pageData['TicketsPageOffset'] = ((getObjectProperty(___arguments.context.pageData, 'TicketsPageOffset')) + 25);
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('2cc11f9da0d3cf1bc6eaf6c3d7cc6b1d'))['content'] = 'Please wait...';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2cc11f9da0d3cf1bc6eaf6c3d7cc6b1d'))['color'] = '#FFFFFF';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('ed7ad4ee925a6e43b31917edc1187b21'))['background-color'] = '#8D2435';
    FirstList = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('b90af9e3067c25f7b9adb5714b69b61e'));
    SecondList = (await Backendless.Data.of('Support_Tickets').find(Backendless.DataQueryBuilder.create().setSortBy(['Ticket_Date DESC']).setPageSize(25).setOffset((getObjectProperty(___arguments.context.pageData, 'TicketsPageOffset')))));
    CombinedList = (FirstList.concat(SecondList));
    console.log(CombinedList);
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('b90af9e3067c25f7b9adb5714b69b61e', (JSON.parse((JSON.stringify(CombinedList)))));
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('ed7ad4ee925a6e43b31917edc1187b21'))['background-color'] = '#CCC6C9';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2cc11f9da0d3cf1bc6eaf6c3d7cc6b1d'))['color'] = '#000000';
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('2cc11f9da0d3cf1bc6eaf6c3d7cc6b1d'))['content'] = 'Load More Tickets';
  }

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./pages/dash_backup_7-8/components/2287e385c14b9a056f5558f38ba29a1c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['Showing tickets: (',(getObjectProperty(___arguments.context.pageData, 'TicketsPageOffset')),' to ',(getObjectProperty(___arguments.context.pageData, 'TicketsPageOffset')) + 25,')'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dash_backup_7-8/components/441491b9e1deb0c17132e707fcd80b87/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    
  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/dash_backup_7-8/components/a2468eeb4bd7d673efa3ee5aa0559f7c/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.dataModel, 'CurrentlySelectedTicket')) == true) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('a2468eeb4bd7d673efa3ee5aa0559f7c'))['background-color'] = '#990939';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('a2468eeb4bd7d673efa3ee5aa0559f7c'))['background-color'] = '#465466';
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onBeforeMount */
  ['onBeforeMount'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData[(getObjectProperty(___arguments.context.dataModel, 'objectId'))] = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('a2468eeb4bd7d673efa3ee5aa0559f7c'));

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./pages/dash_backup_7-8/components/7071be4a758ce1d204f23c034e03d6c9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var userIsAdmin;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dash_backup_7-8/components/275722ccc9e2fca59d2a63e2f361255e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('Created: ') + String(new Intl.DateTimeFormat(undefined, undefined).format((getObjectProperty(___arguments.context.getComponentDataStoreByUid('cfe4133897c28fce29fc7133eaf4d168'), 'created')))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dash_backup_7-8/components/c68c7976ca6566e903887c550947d78e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(Object.keys((getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))));

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    if (getObjectProperty(___arguments.context.pageData, 'userIsAdmin')) {
      return true;
    } else {
      return false;
    }

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/dash_backup_7-8/components/877e8a2d53dbf2e341ffc846a3294f7a/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))['Ticket_Date'] = ___arguments.value;
  console.log(String('The value has been updated to: ') + String(___arguments.value));

  },
  /* handler:onChange */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Date'))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/dash_backup_7-8/components/44ac1ba49a4d63c3946a53c930cf4406/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(getObjectProperty(___arguments.context.pageData, 'PassedDataModel'));

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    if (getObjectProperty(___arguments.context.pageData, 'userIsAdmin')) {
      return true;
    } else {
      return false;
    }

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/dash_backup_7-8/components/7fb9e9c4c3da88da59ba018ecb4f822c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.Data.of('Support_Tickets').save( ({ [`objectId`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'objectId')),[`Ticket_Name`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Name')),[`CompanyName`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'CompanyName')),[`Time_Spent`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Time_Spent')),[`Ticket_Status`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')),[`Linked_Invoice_Reference`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Linked_Invoice_Reference')),[`Notes`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Notes')),[`Ticket_Entered_Date`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Date')),[`Ticket_Completed_Date`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Completed_Date')),[`Ticket_Invoice_Amount`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Invoice_Amount')) }) );
  ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('7fb9e9c4c3da88da59ba018ecb4f822c'))['color'] = '#03B935';

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    
  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/dash_backup_7-8/components/c12bf915588659d3218289b8fe330fad/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    if (getObjectProperty(___arguments.context.pageData, 'userIsAdmin')) {
      console.log(___arguments.context.pageData);
    } else {
    }

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'userIsAdmin'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/dash_backup_7-8/components/abd3be542d37e75a89c54245b45d2609/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Time_Spent'))

  },
  /* handler:onValueAssignment */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'adminEditMode')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/dash_backup_7-8/components/b3c4aecc5ab8d2eebde7ac0aadcd972c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var error;


  try {
    console.log(Object.keys(___arguments.context.pageData));

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    if (getObjectProperty(___arguments.context.pageData, 'userIsAdmin')) {
      return true;
    } else {
      return false;
    }

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/dash_backup_7-8/components/4415b4e21c67b99451b70838d6c8aa34/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log(Object.keys(___arguments.context.getComponentDataStoreByUid('416556824f34d6d9385836c756cc014c')));

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    if (getObjectProperty(___arguments.context.pageData, 'userIsAdmin')) {
      return true;
    } else {
      return false;
    }

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/dash_backup_7-8/components/7be513ee0185358560eee36b6ee5a3f3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log(___arguments.context.getComponentDataStoreByUid('416556824f34d6d9385836c756cc014c'));

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    if (getObjectProperty(___arguments.context.pageData, 'userIsAdmin')) {
      return true;
    } else {
      return false;
    }

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/dash_backup_7-8/components/a95140a9aebb95d8e7feb779ee56d03c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('cfe4133897c28fce29fc7133eaf4d168'), 'Ticket_Date')))).toDateString())

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dash_backup_7-8/components/ec82e71e2748fdc3725d123957ae9596/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var item, list, selectedTicketObjectId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedTicketObjectId = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'objectId'));
  console.log(selectedTicketObjectId);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dash_backup_7-8/components/9c2aaa385096d4a60dff23c2127e14bd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log(___arguments.context.pageData);

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'userIsAdmin'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/dash_backup_7-8/components/cd7aee6d38d9eee550367a82db6adeb2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    if (getObjectProperty(___arguments.context.pageData, 'userIsAdmin')) {
      console.log((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('b90af9e3067c25f7b9adb5714b69b61e'));
    } else {
    }

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'userIsAdmin'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/dash_blank/components/30ea90dd05af67628fd821d5003c3d3d/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  onMounted(___arguments) {
      ___arguments.context.pageData['firstNameValue'] = '';
  ___arguments.context.pageData['lastNameValue'] = '';
  ___arguments.context.pageData['emailValue'] = '';
  ___arguments.context.pageData['phoneValue'] = '';
  ___arguments.context.pageData['companyValue'] = '';
  ___arguments.context.pageData['websiteValue'] = '';
  ___arguments.context.pageData['subjectValue'] = '';
  ___arguments.context.pageData['messageValue'] = '';

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/dash_blank/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
      if (await Backendless.UserService.isValidLogin()) {
  } else {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
  }
  ___arguments.context.pageData['scrollTop'] = true;

  },
  /* handler:onEnter */
  /* handler:onScroll */
  ['onScroll'](___arguments) {
      if (___arguments.scrollTop) {
    ___arguments.context.pageData['scrollTop'] = true;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['box-shadow'] = '0px 2px 4px rgba(0, 0, 0, 0.1)';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['height'] = '60px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-top'] = '7px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-bottom'] = '7px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('59d442df335dc04e702817c7015fea4c'))['color'] = 'black';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '-5px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '45px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#777777';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('59d442df335dc04e702817c7015fea4c'))['color'] = 'white';
    ___arguments.context.pageData['scrollTop'] = false;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['box-shadow'] = 'none';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['height'] = '80px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['background-color'] = 'transparent';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-top'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '80px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#ffffff';
  }

  },
  /* handler:onScroll */
  /* content */
}))

define('./pages/dash_sergey/components/30ea90dd05af67628fd821d5003c3d3d/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  onMounted(___arguments) {
      ___arguments.context.pageData['firstNameValue'] = '';
  ___arguments.context.pageData['lastNameValue'] = '';
  ___arguments.context.pageData['emailValue'] = '';
  ___arguments.context.pageData['phoneValue'] = '';
  ___arguments.context.pageData['companyValue'] = '';
  ___arguments.context.pageData['websiteValue'] = '';
  ___arguments.context.pageData['subjectValue'] = '';
  ___arguments.context.pageData['messageValue'] = '';

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/dash_sergey/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var Contract_Hours, Posted_Hours, result, user;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function LoginOnPageRefresh() {
  Backendless.setCurrentUserToken(((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('HPUserCookie')));
  user = (await Backendless.UserService.getCurrentUser(false));
  ___arguments.context.appData['currentUser'] = user;
  ___arguments.context.appData['companyNamesLIST'] = (getObjectProperty(((await Backendless.Data.of('Users').loadRelations((getObjectProperty((getObjectProperty(___arguments.context.appData, 'currentUser')), 'objectId')), Backendless.LoadRelationsQueryBuilder.create().setRelationName('Linked_Companies').setPageSize(10).setOffset(0)))[0]), 'Company_Name'));
  ___arguments.context.appData['currentCompanyObjectId'] = (getObjectProperty(((await Backendless.Data.of('Users').loadRelations((getObjectProperty((getObjectProperty(___arguments.context.appData, 'currentUser')), 'objectId')), Backendless.LoadRelationsQueryBuilder.create().setRelationName('Linked_Companies').setPageSize(1).setOffset(0)))[0]), 'objectId'));
}


  if (await Backendless.UserService.isValidLogin()) {
    await LoginOnPageRefresh();
    ___arguments.context.pageData['MonthlyContractHours'] = 0;
    ___arguments.context.pageData['MaximumWeeklyHours'] = 0;
    ___arguments.context.pageData['MaximumMonthlyHours'] = 0;
    await (async function() {
    	const el = document.querySelectorAll('.content_editable')
    	
    	for (let i = 0; i < el.length; i++) {
    	let theDataID = el[i].classList.item(0);
    	theDataID = theDataID.substring(5);
    	el[i].setAttribute('contenteditable', 'true')
    	// el[i].setAttribute('data-modified','true') // We're now only modifying this attribute when the user clicks on the element.
    	el[i].setAttribute('data-source',theDataID)
    	
    	}
    })();
    ___arguments.context.pageData['inEditMode'] = true;
    ___arguments.context.pageData['editingPage'] = true;
    ___arguments.context.pageData['TicketsPageOffset'] = 0;
    result = (await (async function(pageData) {
    	/*
    	var myFunction = (e)=> {
    	  console.log(e.currentTarget.getAttribute('data-source'));        
    	}
    	*/
    	
    	var myFunction2 = (e)=> {
    	  pageData.currentDataSource = e.currentTarget.getAttribute('data-source'); // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
    	  console.log("pageData.currentDataSource =: "+ pageData.currentDataSource);
    	  pageData.isOpenModal = true;
    	  return pageData  
    	};
    	
    	Array.from(document.getElementsByClassName('content_editable')).forEach((editable) => {
    	  // editable.addEventListener('click', myFunction);
    	  editable.addEventListener('dblclick', myFunction2);
    	  window.___MY_EVENT_LISTENER  = myFunction2;
    	});  
    	
    	// Previously, we had this code: 
    	/* 
    	Array.from(document.getElementsByClassName('content_editable')).forEach((editable) => {
    	  editable.addEventListener('click', (e)=>{
    	    // e.preventDefault();
    	    console.log(e.currentTarget.getAttribute('data-source'));        
    	    // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
    	    // e.target will trap the actual element that was clicked
    	  });
    	*/
    	
    	/* Function backup
    	editable.addEventListener('dblclick', (e)=>{
    	    // e.preventDefault();
    	    pageData.currentDataSource = e.currentTarget.getAttribute('data-source'); // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
    	    // console.log("A double-click has occurred on element with the ID of: "+ runner.currentDataSource);
    	    console.log("pageData.currentDataSource =: "+ pageData.currentDataSource);
    	    // e.target will trap the actual element that was double-clicked
    	    pageData.isOpenModal = true;
    	    return pageData
    	  });
    	*/
    })(___arguments.context.pageData));
    Contract_Hours = (getObjectProperty(((await Backendless.Data.of('Total_Company_Hours_View').find(Backendless.DataQueryBuilder.create().setWhereClause((['Company_ObjectID = \'',(getObjectProperty(___arguments.context.appData, 'currentCompanyObjectId')),'\''].join(''))).setPageSize(10)))[0]), 'Contract_Hours'));
    Posted_Hours = (getObjectProperty(((await Backendless.Data.of('Total_Company_Hours_View').find(Backendless.DataQueryBuilder.create().setWhereClause((['Company_ObjectID = \'',(getObjectProperty(___arguments.context.appData, 'currentCompanyObjectId')),'\''].join(''))).setPageSize(10)))[0]), 'Posted_Hours'));
    (function (componentUid, dataModel) { ___arguments.context.setComponentDataStoreByUid(componentUid, dataModel) })('e8a685b9572768044a561f8d3532499b', ({ [`CompanyName`]: (getObjectProperty(___arguments.context.appData, 'companyNamesLIST')),[`AvailableHours`]: Posted_Hours,[`MonthlyContractHours`]: Contract_Hours,[`MaximumMonthlyHours`]: (Number(Contract_Hours)) * 2 <= Posted_Hours ? (Number(Contract_Hours)) * 2 : Posted_Hours,[`MaximumWeeklyHours`]: ((Number(Contract_Hours)) / 4) * 2,[`MaximumDailyHours`]: (Number.parseFloat(((Number(Contract_Hours)) / 5)).toFixed(1)) }));
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('b90af9e3067c25f7b9adb5714b69b61e', (await Backendless.Data.of('Support_Tickets').find(Backendless.DataQueryBuilder.create().setSortBy(['Ticket_Date DESC']).setPageSize(25))));
  } else {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
  }

  },
  /* handler:onEnter */
  /* handler:onScroll */
  ['onScroll'](___arguments) {
      if (___arguments.scrollTop) {
    ___arguments.context.pageData['scrollTop'] = true;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['box-shadow'] = '0px 2px 4px rgba(0, 0, 0, 0.1)';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['height'] = '60px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-top'] = '7px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-bottom'] = '7px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('59d442df335dc04e702817c7015fea4c'))['color'] = 'black';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '-5px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '45px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#777777';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('59d442df335dc04e702817c7015fea4c'))['color'] = 'white';
    ___arguments.context.pageData['scrollTop'] = false;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['box-shadow'] = 'none';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['height'] = '80px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['background-color'] = 'transparent';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-top'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '80px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#ffffff';
  }

  },
  /* handler:onScroll */
  /* content */
}))

define('./pages/dash_sergey/components/19f1da6844bcd24c52e983cfbdbe9022/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status'));
  console.log(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'));
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) < 6) {
    ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c')['Ticket_Status'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) + 1);
  } else {
    ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c')['Ticket_Status'] = 1;
  }

  },
  /* handler:onClick */
  /* handler:onDelete */
  ['onDelete'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c')['Ticket_Status'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'OnLoad_TicketStatus'));

  },
  /* handler:onDelete */
  /* handler:onFrontIconAssignment */
  ['onFrontIconAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 1) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#BC2C45';
    return 'fiber_new';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 2) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#D4860B';
    return 'bedtime';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 3) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#04749C';
    return 'flip_camera_ios';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 4) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#642C8D';
    return 'access_time';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 5) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#AAAA22';
    return 'person_pin';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 6) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#04842D';
    return 'check_box';
  }

  },
  /* handler:onFrontIconAssignment */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    var chipLabel;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 1) {
    return 'New';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 2) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#D4860B';
    return 'Pending';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 3) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#04749C';
    return 'In Progress';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 4) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#642C8D';
    return 'Waiting On Support';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 5) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#AAAA22';
    return 'Waiting On Client';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status')) == 6) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('19f1da6844bcd24c52e983cfbdbe9022'))['background-color'] = '#04842D';
    return 'Completed!';
  }

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/dash_sergey/components/85be065e23aa1f58df8a71bbb6dc7662/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c')['OnLoad_TicketStatus'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'Ticket_Status'));

  },
  /* handler:onMounted */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'CurrentlySelectedTicket')) == true) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('85be065e23aa1f58df8a71bbb6dc7662'))['background-color'] = '#990939';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('85be065e23aa1f58df8a71bbb6dc7662'))['background-color'] = '#465466';
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/dash_sergey/components/c6a2930ccba2d71d496e8503f464b1d7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var decision, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  if ((getObjectProperty(___arguments.context.pageData, 'isTicketSelected')) == true) {
    console.log(await asyncListFilter(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('b90af9e3067c25f7b9adb5714b69b61e')), async (item) => {
       if ((getObjectProperty(item, 'CurrentlySelectedTicket')) == true) {
        delete item['CurrentlySelectedTicket'];
        decision = true;
      } else {
        decision = true;
      }


     return decision;
    }));
  } else {
  }
  ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c')['CurrentlySelectedTicket'] = true;
  ___arguments.context.pageData['OpenDrawer'] = true;
  ___arguments.context.pageData['isTicketSelected'] = true;
  ___arguments.context.pageData['CurrentlySelectedTicketObjectID'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'objectId'));
  ___arguments.context.pageData['PassedDataModel'] = ___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dash_sergey/components/93cf5db23368ed8bffab3de1d62822b7/bundle.js', [], () => ({
  /* content */
  /* handler:onOpen */
  ['onOpen'](___arguments) {
    
  },
  /* handler:onOpen */
  /* handler:isOpenLogic */
  ['isOpenLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'OpenDrawer'))

  },
  /* handler:isOpenLogic */
  /* handler:onClose */
  async ['onClose'](___arguments) {
    var decision, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  if ((getObjectProperty(___arguments.context.pageData, 'isTicketSelected')) == true) {
    console.log((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('b90af9e3067c25f7b9adb5714b69b61e'));
    console.log(await asyncListFilter(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('b90af9e3067c25f7b9adb5714b69b61e')), async (item) => {
       if ((getObjectProperty(item, 'CurrentlySelectedTicket')) == true) {
        delete item['CurrentlySelectedTicket'];
        decision = true;
      } else {
        decision = true;
      }


     return decision;
    }));
  } else {
  }
  await ( async function (componentUid, event) {  ___arguments.context.getComponentByUid(componentUid).close(event) })('93cf5db23368ed8bffab3de1d62822b7', undefined);
  ___arguments.context.pageData['OpenDrawer'] = false;
  (getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))['CurrentlySelectedTicket'] = false;
  ___arguments.context.pageData['CurrentlySelectedTicketObjectID'] = '';

  },
  /* handler:onClose */
  /* content */
}))

define('./pages/dash_sergey/components/b67b940bd10c4493f3ef6fae2c284b88/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['OpenDrawer'] = false;
  (getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))['CurrentlySelectedTicket'] = false;
  ___arguments.context.pageData['CurrentlySelectedTicketObjectID'] = '';
  await ( async function (componentUid, event) {  ___arguments.context.getComponentByUid(componentUid).close(event) })('93cf5db23368ed8bffab3de1d62822b7', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dash_sergey/components/69985b47bd6b45fa795a184becd896f4/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Name'))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/dash_sergey/components/8e55ba1c2e0693d19426a8db2e524eae/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Notes'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dash_sergey/components/515a6d6655e2913b394dcf2c6a941c7f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var result;


  await (async function() {
  	const el = document.querySelectorAll('.content_editable')
  	
  	for (let i = 0; i < el.length; i++) {
  	let theDataID = el[i].classList.item(0);
  	theDataID = theDataID.substring(5);
  	el[i].setAttribute('contenteditable', 'true')
  	// el[i].setAttribute('data-modified','true') // We're now only modifying this attribute when the user clicks on the element.
  	el[i].setAttribute('data-source',theDataID)
  	
  	}
  })();
  ___arguments.context.pageData['inEditMode'] = true;
  ___arguments.context.pageData['editingPage'] = true;
  ___arguments.context.pageData['isOpenModal'] = false;
  result = (await (async function(pageData) {
  	/*
  	var myFunction = (e)=> {
  	  console.log(e.currentTarget.getAttribute('data-source'));        
  	}
  	*/
  	
  	var myFunction2 = (e)=> {
  	  pageData.currentDataSource = e.currentTarget.getAttribute('data-source'); // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
  	  console.log("pageData.currentDataSource =: "+ pageData.currentDataSource);
  	  pageData.isOpenModal = true;
  	  return pageData  
  	};
  	
  	Array.from(document.getElementsByClassName('content_editable')).forEach((editable) => {
  	  // editable.addEventListener('click', myFunction);
  	  editable.addEventListener('dblclick', myFunction2);
  	  window.___MY_EVENT_LISTENER  = myFunction2;
  	});  
  	
  	// Previously, we had this code: 
  	/* 
  	Array.from(document.getElementsByClassName('content_editable')).forEach((editable) => {
  	  editable.addEventListener('click', (e)=>{
  	    // e.preventDefault();
  	    console.log(e.currentTarget.getAttribute('data-source'));        
  	    // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
  	    // e.target will trap the actual element that was clicked
  	  });
  	*/
  	
  	/* Function backup
  	editable.addEventListener('dblclick', (e)=>{
  	    // e.preventDefault();
  	    pageData.currentDataSource = e.currentTarget.getAttribute('data-source'); // Above code MUST be set to currentTarget to trap the element's event that the code is actually running from.
  	    // console.log("A double-click has occurred on element with the ID of: "+ runner.currentDataSource);
  	    console.log("pageData.currentDataSource =: "+ pageData.currentDataSource);
  	    // e.target will trap the actual element that was double-clicked
  	    pageData.isOpenModal = true;
  	    return pageData
  	  });
  	*/
  })(___arguments.context.pageData));
  await (async function() {
  	await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('26ebd15ce2f2c1d6f9813d1a00a26054', true, true, '', 'You are now editing this webpage.', 'info');
  })();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/dash_sergey/components/e7d4ce9742a74274a8f758c81d36cedd/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Description'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dash_sergey/components/39359e517e8299f37c9ad6f3878cdd7c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) < 6) {
    (getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))['Ticket_Status'] = ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) + 1);
    console.log(String('The ticket has been incremented: ') + String(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')));
  } else {
    (getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))['Ticket_Status'] = 1;
    console.log(String('The ticket has been reset to 1: ') + String(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')));
  }

  },
  /* handler:onClick */
  /* handler:onDelete */
  ['onDelete'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))['Ticket_Status'] = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'OnLoad_TicketStatus'));

  },
  /* handler:onDelete */
  /* handler:onFrontIconAssignment */
  ['onFrontIconAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 1) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#BC2C45';
    return 'fiber_new';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 2) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#D4860B';
    return 'bedtime';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 3) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#04749C';
    return 'flip_camera_ios';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 4) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#642C8D';
    return 'access_time';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 5) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#AAAA22';
    return 'person_pin';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 6) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#04842D';
    return 'check_box';
  } else {
    console.log(String('The Ticket_Status of the PassedDataModel is: ') + String(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')));
  }
  console.log(String('The Ticket_Status of the PassedDataModel of pageData is: ') + String(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')));

  },
  /* handler:onFrontIconAssignment */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 1) {
    console.log(String('The label has been updated with: ') + String('New'));
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#BC2C45';
    return 'New';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 2) {
    console.log(String('The label has been updated with: ') + String('Pending'));
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#D4860B';
    return 'Pending';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 3) {
    console.log(String('The label has been updated with: ') + String('In Progress'));
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#04749C';
    return 'In Progress';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 4) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#642C8D';
    return 'Waiting On Support';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 5) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#AAAA22';
    return 'Waiting On Client';
  } else if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')) == 6) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('39359e517e8299f37c9ad6f3878cdd7c'))['background-color'] = '#04842D';
    return 'Completed!';
  }
  console.log(String('The Ticket_Status of the PassedDataModel of pageData is: ') + String(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status')));

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/dash_sergey/components/8516f829a89573aea1ecd9c694449d54/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'PassedDataModel'))['OnLoad_TicketStatus'] = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status'));

  },
  /* handler:onMounted */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    
  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/dash_sergey/components/c3213a164feed6ef2b22e331e4a31b04/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'Ticket_Status'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dash_sergey/components/2c8a6f746f7c4274b274dc68d6cfe88c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'PassedDataModel')), 'OnLoad_TicketStatus'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dash_sergey/components/2cc11f9da0d3cf1bc6eaf6c3d7cc6b1d/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  async ['onMouseOver'](___arguments) {
    var CombinedList, SecondList, FirstList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log('Loading more tickets...');
  console.log('Fetch fresh batch of tickets here...');
  console.log((new Date()).valueOf());
  ___arguments.context.pageData['TicketsPageOffset'] = ((getObjectProperty(___arguments.context.pageData, 'TicketsPageOffset')) + 25);
  ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('2cc11f9da0d3cf1bc6eaf6c3d7cc6b1d'))['content'] = 'Please wait...';
  ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2cc11f9da0d3cf1bc6eaf6c3d7cc6b1d'))['color'] = '#FFFFFF';
  ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('ed7ad4ee925a6e43b31917edc1187b21'))['background-color'] = '#8D2435';
  FirstList = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('b90af9e3067c25f7b9adb5714b69b61e'));
  SecondList = (await Backendless.Data.of('Support_Tickets').find(Backendless.DataQueryBuilder.create().setSortBy(['Ticket_Date DESC']).setPageSize(25).setOffset((getObjectProperty(___arguments.context.pageData, 'TicketsPageOffset')))));
  console.log((new Date()).valueOf());
  CombinedList = (FirstList.concat(SecondList));
  console.log(CombinedList);
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('b90af9e3067c25f7b9adb5714b69b61e', (JSON.parse((JSON.stringify(CombinedList)))));
  ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('ed7ad4ee925a6e43b31917edc1187b21'))['background-color'] = '#CCC6C9';
  ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2cc11f9da0d3cf1bc6eaf6c3d7cc6b1d'))['color'] = '#000000';
  ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('2cc11f9da0d3cf1bc6eaf6c3d7cc6b1d'))['content'] = 'Load More Tickets';
  console.log((new Date()).valueOf());

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./pages/dash_sergey/components/2287e385c14b9a056f5558f38ba29a1c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['Showing tickets: (',(getObjectProperty(___arguments.context.pageData, 'TicketsPageOffset')),' to ',(getObjectProperty(___arguments.context.pageData, 'TicketsPageOffset')) + 25,')'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/dash_sergey/components/9dd8a193bcb91c52935d8e9d7408c94a/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('a2468eeb4bd7d673efa3ee5aa0559f7c'), 'CompanyLogoURL'))

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/home/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onResize */
  ['onResize'](___arguments) {
    var leftSlideBy, left, j, half, galleryList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (true) {
    if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 990) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 0;
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 'auto';
    }
    galleryList = (getObjectProperty(___arguments.context.appData, 'galleryList'));
    if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) >= 1200) {
      ___arguments.context.appData['leftSlideBy'] = 240;
    }
    if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 1199) {
      ___arguments.context.appData['leftSlideBy'] = 200;
    }
    if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 990) {
      ___arguments.context.appData['leftSlideBy'] = 260;
    }
    if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768) {
      ___arguments.context.appData['leftSlideBy'] = 370;
    }
    if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576) {
      ___arguments.context.appData['leftSlideBy'] = 250;
    }
    if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
      ___arguments.context.appData['leftSlideBy'] = 100;
    }
    leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
    half = Math.floor(galleryList.length / 2);
    ___arguments.context.pageData['leftStart'] = (-15 - half * leftSlideBy);
    if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 576) {
      ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 120);
    }
    if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 480) {
      ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 130);
    }
    if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
      ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 15);
    }
    left = (getObjectProperty(___arguments.context.pageData, 'leftStart'));
    var j_list = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('c376c79acf1e16de2463e38439c34656'));
    for (var j_index in j_list) {
      j = j_list[j_index];
      j['opacity'] = 1;
      j['left'] = left;
      if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
        j['active'] = true;
      } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
        j['active'] = true;
      } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
        j['active'] = true;
      } else if (left == 0 && leftSlideBy == 100) {
        j['active'] = true;
      } else {
        j['active'] = false;
      }
      left = (typeof left == 'number' ? left : 0) + leftSlideBy;
    }
  }

  },
  /* handler:onResize */
  /* handler:onScroll */
  async ['onScroll'](___arguments) {
    var a, item, team, gallery, home2, features, testimonials, contact, topOnViewport, header, elem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function topOnViewport2(elem, header) {
  topOnViewport = (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', elem)) - ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight'))) + (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) - ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', header)));
  return topOnViewport
}


  if (true) {
    contact = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0ee4f7a2f9f93096442f303878b88406')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
    team = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
    gallery = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
    testimonials = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
    features = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
    home2 = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
    if (___arguments.scrollTop - ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) < home2) {
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')), 'anchor'))) {
          item['active'] = true;
        } else {
          item['active'] = false;
        }
      ; return item;}))));
    }
    if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b'))))) < features && ___arguments.scrollTop > home2) {
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')), 'anchor'))) {
          item['active'] = true;
        } else {
          item['active'] = false;
        }
      ; return item;}))));
    }
    if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < testimonials && ___arguments.scrollTop > features + home2) {
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')), 'anchor'))) {
          item['active'] = true;
        } else {
          item['active'] = false;
        }
      ; return item;}))));
    }
    if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < gallery && ___arguments.scrollTop > testimonials + features + home2) {
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')), 'anchor'))) {
          item['active'] = true;
        } else {
          item['active'] = false;
        }
      ; return item;}))));
    }
    if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < team && ___arguments.scrollTop > testimonials + features + home2 + gallery) {
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')), 'anchor'))) {
          item['active'] = true;
        } else {
          item['active'] = false;
        }
      ; return item;}))));
    }
    if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < contact && ___arguments.scrollTop > testimonials + features + home2 + gallery + team) {
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0ee4f7a2f9f93096442f303878b88406')), 'anchor'))) {
          item['active'] = true;
        } else {
          item['active'] = false;
        }
      ; return item;}))));
    }
    if (___arguments.scrollTop > ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) / 3) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('cb279d48324be4a1644ace919b54f80c'))['opacity'] = 0.3;
    } else if (___arguments.scrollTop > ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) / 10) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('cb279d48324be4a1644ace919b54f80c'))['opacity'] = 0.5;
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('cb279d48324be4a1644ace919b54f80c'))['opacity'] = 1;
    }
    if (___arguments.scrollTop) {
      ___arguments.context.pageData['scrollTop'] = true;
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['box-shadow'] = '0px 2px 4px rgba(0, 0, 0, 0.1)';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['height'] = '60px';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['background-color'] = '#ffffff';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['color'] = '#777777';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-top'] = '7px';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-bottom'] = '7px';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '-5px';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '45px';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#777777';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#777777';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#777777';
    } else {
      ___arguments.context.pageData['scrollTop'] = false;
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['box-shadow'] = 'none';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['height'] = '80px';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['background-color'] = 'transparent';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['color'] = '#ffffff';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-top'] = '0px';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '0px';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '80px';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#ffffff';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#ffffff';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#ffffff';
    }
    if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 400) {
      a = 150;
    } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 500) {
      a = (typeof a == 'number' ? a : 0) + 200;
    } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 600) {
      a = (typeof a == 'number' ? a : 0) + 300;
    } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 700) {
      a = (typeof a == 'number' ? a : 0) + 400;
    } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 800) {
      a = (typeof a == 'number' ? a : 0) + 500;
    } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 900) {
      a = (typeof a == 'number' ? a : 0) + 600;
    } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 1050) {
      a = (typeof a == 'number' ? a : 0) + 700;
    }
    if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 990 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 1050) {
      if (___arguments.scrollTop >= ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) - a)) {
        ___arguments.context.appData['featuresInFocus'] = true;
      }
      if (___arguments.scrollTop >= ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) - a)) {
        ___arguments.context.appData['testimonialsInFocus'] = true;
      }
      if (___arguments.scrollTop >= ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')))) - a)) {
        ___arguments.context.appData['teamInFocus'] = true;
      }
    } else {
      ___arguments.context.appData['featuresInFocus'] = true;
      ___arguments.context.appData['testimonialsInFocus'] = true;
      ___arguments.context.appData['teamInFocus'] = true;
    }
  }

  },
  /* handler:onScroll */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var teamList, testimonialsList, appData, galleryList, featuresList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (true) {
    Backendless.setCurrentUserToken(((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('HPUserCookie')));
    if ((await Backendless.UserService.isValidLogin()) == true) {
      ___arguments.context.appData['currentUser'] = (await Backendless.UserService.getCurrentUser(false));
    }
    appData = (await (async function() {
    	const data = {
    	  "features": [
    	    {
    	      "id": "f1",
    	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-1.jpg",
    	      "title": "Amazing Experience",
    	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    	    },
    	    {
    	      "id": "f2",
    	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-2.jpg",
    	      "title": "24/7 Support",
    	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    	    },
    	    {
    	      "id": "f3",
    	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-3.jpg",
    	      "title": "Real-time",
    	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    	    },
    	    {
    	      "id": "f4",
    	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-4.jpg",
    	      "title": "Easy To Use",
    	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    	    },
    	    {
    	      "id": "f5",
    	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-5.jpg",
    	      "title": "Free Updates",
    	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    	    },
    	    {
    	      "id": "f6",
    	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-6.jpg",
    	      "title": "All Platforms",
    	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    	    }
    	  ],
    	  "gallery": [
    	        {
    	      "id": "g1",
    	      "image": "https://app.webmojo.io/api/files/web/Hyperpixels/images/ACCO Website.png"
    	    },
    	    {
    	      "id": "g2",
    	      "image": "https://app.webmojo.io/api/files/web/Hyperpixels/images/Clay County Seal - Final(Full-Transparent).png"
    	    },
    	    {
    	      "id": "g3",
    	      "image": "https://app.webmojo.io/api/files/web/Hyperpixels/images/Calvary Logo (Full).png"
    	    },
    	    {
    	      "id": "g4",
    	      "image": "https://app.webmojo.io/api/files/web/Hyperpixels/images/Aspen Hill Kennels - Final (Full).png"
    	    },
    	    {
    	      "id": "g5",
    	      "image": "https://app.webmojo.io/api/files/web/Hyperpixels/images/Act Leasing Services Logo (No Box - On White - FINAL).png"
    	    },
    	    {
    	      "id": "g6",
    	      "image": "https://app.webmojo.io/api/files/web/Hyperpixels/images/Titanium Fitness Logo 2 (Final).png"
    	    }
    	  ],
    	  "team": [
    	    {
    	      "id": "t1",
    	      "name": "Joan Doe",
    	      "career": "Founder - CEO",
    	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo1.jpg",
    	      "socialMedia": {
    	        "social": [
    	          {
    	            "url": "#",
    	            "name": "facebook"
    	          },
    	          {
    	            "url": "#",
    	            "name": "github"
    	          },
    	          {
    	            "url": "#",
    	            "name": "linkedin"
    	          },
    	          {
    	            "url": "",
    	            "name": "twitter"
    	          }
    	        ]
    	      }
    	    },
    	    {
    	      "id": "t2",
    	      "name": "John Doe",
    	      "career": "Co-founder",
    	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo2.jpg",
    	      "socialMedia": {
    	        "social": [
    	          {
    	            "url": "#",
    	            "name": "facebook"
    	          },
    	          {
    	            "url": "",
    	            "name": "github"
    	          },
    	          {
    	            "url": "",
    	            "name": "linkedin"
    	          },
    	          {
    	            "url": "#",
    	            "name": "twitter"
    	          }
    	        ]
    	      }
    	    },
    	    {
    	      "id": "t3",
    	      "name": "Joan Doe",
    	      "career": "Co-founder",
    	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo3.jpg",
    	      "socialMedia": {
    	        "social": [
    	          {
    	            "url": "#",
    	            "name": "facebook"
    	          },
    	          {
    	            "url": "#",
    	            "name": "github"
    	          },
    	          {
    	            "url": "#",
    	            "name": "linkedin"
    	          },
    	          {
    	            "url": "",
    	            "name": "twitter"
    	          }
    	        ]
    	      }
    	    },
    	    {
    	      "id": "t4",
    	      "name": "John Doe",
    	      "career": "Financial Manager",
    	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo4.jpg",
    	      "socialMedia": {
    	        "social": [
    	          {
    	            "url": "#",
    	            "name": "facebook"
    	          },
    	          {
    	            "url": "",
    	            "name": "github"
    	          },
    	          {
    	            "url": "#",
    	            "name": "linkedin"
    	          },
    	          {
    	            "url": "",
    	            "name": "twitter"
    	          }
    	        ]
    	      }
    	    }
    	  ],
    	  "testimonials": [
    	    {
    	      "id": "tm1",
    	      "author": "Danella Construction",
    	      "description": "Custom Software Design",
    	      "photo": "https://app.webmojo.io/api/files/web/Hyperpixels/images/Danella.png"
    	    },
    	    {
    	      "id": "tm2",
    	      "author": "Anderson Construction Company",
    	      "description": "Custom Software Design, Custom Website Design Services, Business Technical Support Services",
    	      "photo": "https://app.webmojo.io/api/files/web/Hyperpixels/images/ACCO Icon.png"
    	    },
    	    {
    	      "id": "tm3",
    	      "author": "U.S. Army Corps of Engineers -- used by some USACE contractors",
    	      "description": "Custom Software Design",
    	      "photo": "https://app.webmojo.io/api/files/web/Hyperpixels/images/United_States_Army_Corps_of_Engineers-Logo.png"
    	    },
    	    {
    	      "id": "tm4",
    	      "author": "ATN - Alabama Technology Network - Eufaula, AL",
    	      "description": "Custom Website Design Services, I.T. Consulting Services, Software Architect, Software Engineering for Windows and the Web, Custom Graphics Design",
    	      "photo": "https://app.webmojo.io/api/files/web/Hyperpixels/images/ATNLogo.jpg"
    	    },
    	    {
    	      "id": "tm5",
    	      "author": "Mann's Bait Company - Eufaula, AL",
    	      "description": "Custom Website Design, Custom Coding, Custom Graphics Design, Video and Audio Editing Services",
    	      "photo": "https://app.webmojo.io/api/files/web/Hyperpixels/images/MannsbaitLogo.webp"
    	    },
    	    {
    	      "id": "tm6",
    	      "author": "County of Clay County, GA",
    	      "description": "Business Technical Support Services, Custom Software Design, Custom Website Design, Custom Logo Design Services",
    	      "photo": "https://app.webmojo.io/api/files/web/Hyperpixels/images/ClayCountyLogo-Web-sm.png"
    	    },
    	    {
    	      "id": "tm7",
    	      "author": "Alabama Interforest (Panel, Inc.)",
    	      "description": "Custom Website Design Services",
    	      "photo": "https://app.webmojo.io/api/files/web/Hyperpixels/images/PanelProcessing.png"
    	    },
    	    {
    	      "id": "tm8",
    	      "author": "Bethel Baptist Church - Fort Gaines, GA",
    	      "description": "Custom Website Design Services, Custom Logo Design Services",
    	      "photo": "https://app.webmojo.io/api/files/web/Hyperpixels/images/Bethel Baptist Church - Final.png"
    	    },
    	    {
    	      "id": "tm9",
    	      "author": "Calvary Baptist Church - Eufaula, AL",
    	      "description": "Custom Website Design Services, Custom Logo Design Services",
    	      "photo": "https://app.webmojo.io/api/files/web/Hyperpixels/images/CalvaryLogo-Mini.png"
    	    }
    	  ]
    	}

    	return data
    })());
    galleryList = (getObjectProperty(appData, 'gallery'));
    ___arguments.context.appData['galleryList'] = galleryList;
    testimonialsList = (getObjectProperty(appData, 'testimonials'));
    ___arguments.context.pageData['MessageSent'] = false;
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('153bb1d13492b519dc0717b333c20947', testimonialsList);
  }

  },
  /* handler:onEnter */
  /* handler:onPageQuery */
  ['onPageQuery'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (true) {
    if ((getObjectProperty(___arguments.pageQuery, 'view')) == 'services') {
      (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')));
    }
    if ((getObjectProperty(___arguments.pageQuery, 'view')) == 'clients') {
      (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')));
    }
    if ((getObjectProperty(___arguments.pageQuery, 'view')) == 'portfolio') {
      (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')));
    }
    if ((getObjectProperty(___arguments.pageQuery, 'view')) == 'contact') {
      console.log('We just scrolled to the Contact section!');
      (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0ee4f7a2f9f93096442f303878b88406')));
    }
  }

  },
  /* handler:onPageQuery */
  /* content */
}))

define('./pages/home/components/153bb1d13492b519dc0717b333c20947/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
      return ((___arguments.context.appData['testimonialsInFocus']) ? ['testimonials__items-block', 'testimonials__items-block_in-focus'] : ['testimonials__items-block'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home/components/c376c79acf1e16de2463e38439c34656/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var item, leftSlideBy, left, newGalleryList, half, firstList, galleryList, lastList, idGalleryItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  await new Promise(r => setTimeout(r, 1000 || 0));
  galleryList = (getObjectProperty(___arguments.context.appData, 'galleryList'));
  idGalleryItem = 1;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c376c79acf1e16de2463e38439c34656', (await Promise.all(galleryList.map(async item => {  item['id'] = idGalleryItem;
    idGalleryItem = (typeof idGalleryItem == 'number' ? idGalleryItem : 0) + 1;
  ; return item;}))));
  ___arguments.context.appData['leftSlideBy'] = 240;
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 1199) {
    ___arguments.context.appData['leftSlideBy'] = 200;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 990) {
    ___arguments.context.appData['leftSlideBy'] = 260;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768) {
    ___arguments.context.appData['leftSlideBy'] = 370;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576) {
    ___arguments.context.appData['leftSlideBy'] = 250;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
    ___arguments.context.appData['leftSlideBy'] = 100;
  }
  half = Math.floor(galleryList.length / 2);
  lastList = galleryList.slice(((half + 1) - 1), galleryList.length);
  firstList = galleryList.slice(0, half);
  newGalleryList = (JSON.parse((JSON.stringify(((addItemToList(lastList, galleryList)).concat(firstList))))));
  leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
  ___arguments.context.pageData['leftStart'] = (-15 - half * leftSlideBy);
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 576) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 120);
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 480) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 130);
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 15);
  }
  left = (getObjectProperty(___arguments.context.pageData, 'leftStart'));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c376c79acf1e16de2463e38439c34656', (await Promise.all(newGalleryList.map(async item => {  item['opacity'] = 1;
    item['left'] = left;
    if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
      item['active'] = true;
    } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
      item['active'] = true;
    } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
      item['active'] = true;
    } else if (left == 0 && leftSlideBy == 100) {
      item['active'] = true;
    } else {
      item['active'] = false;
    }
    left = (typeof left == 'number' ? left : 0) + leftSlideBy;
  ; return item;}))));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/home/components/e23154e2b40dc5a8ac7f9a7e34ff980f/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 480) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e23154e2b40dc5a8ac7f9a7e34ff980f'))['left'] = (String(getObjectProperty(___arguments.context.dataModel, 'left')) + String('px'));
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e23154e2b40dc5a8ac7f9a7e34ff980f'))['left'] = (String(getObjectProperty(___arguments.context.dataModel, 'left')) + String('%'));
  }
  ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e23154e2b40dc5a8ac7f9a7e34ff980f'))['opacity'] = (getObjectProperty(___arguments.context.dataModel, 'opacity'));
  if (getObjectProperty(___arguments.context.dataModel, 'active')) {
    return ['g-slider__item', 'g-slider__item_center'];
  }

  return ['g-slider__item']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home/components/108f6ec347effdbce289a49d625d03af/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['isOpenModal'] = true;
  ___arguments.context.pageData['counterSlide2'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e23154e2b40dc5a8ac7f9a7e34ff980f'), 'id'));
  ___arguments.context.pageData['currentSlide'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e23154e2b40dc5a8ac7f9a7e34ff980f'), 'id'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/26a1309c25831a8af67d5fe98587c773/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    var leftSlideBy, minValue, card, left, j, maxValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  maxValue = 0;
  minValue = 0;
  leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
  if ((typeof (getObjectProperty(___arguments.context.pageData, 'stopClick')) === 'undefined') || ((new Date()).valueOf()) > (getObjectProperty(___arguments.context.pageData, 'stopClick')) + 300) {
    ___arguments.context.pageData['stopClick'] = ((new Date()).valueOf());
    var j_list = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('c376c79acf1e16de2463e38439c34656'));
    for (var j_index in j_list) {
      j = j_list[j_index];
      j['opacity'] = 1;
      j['left'] = ((getObjectProperty(j, 'left')) + leftSlideBy);
      left = (getObjectProperty(j, 'left'));
      if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
        j['active'] = true;
      } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
        j['active'] = true;
      } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
        j['active'] = true;
      } else if (left == 0 && leftSlideBy == 100) {
        j['active'] = true;
      } else {
        j['active'] = false;
      }
      if (maxValue < left) {
        maxValue = left;
        card = j;
      }
      if (minValue > left) {
        minValue = left;
      }
    }
    card['opacity'] = 0;
    card['left'] = (minValue - leftSlideBy);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/0ecc200a34ec908bf97bef91588de587/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var leftSlideBy, maxValue, card, left, j, minValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  maxValue = 0;
  minValue = 0;
  leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
  if ((typeof (getObjectProperty(___arguments.context.pageData, 'stopClick')) === 'undefined') || ((new Date()).valueOf()) > (getObjectProperty(___arguments.context.pageData, 'stopClick')) + 300) {
    ___arguments.context.pageData['stopClick'] = ((new Date()).valueOf());
    var j_list = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('c376c79acf1e16de2463e38439c34656'));
    for (var j_index in j_list) {
      j = j_list[j_index];
      j['left'] = ((getObjectProperty(j, 'left')) - leftSlideBy);
      left = (getObjectProperty(j, 'left'));
      j['opacity'] = 1;
      if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
        j['active'] = true;
      } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
        j['active'] = true;
      } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
        j['active'] = true;
      } else if (left == 0 && leftSlideBy == 100) {
        j['active'] = true;
      } else {
        j['active'] = false;
      }
      if (minValue > left) {
        minValue = left;
        card = j;
      }
      if (maxValue < left) {
        maxValue = left;
      }
    }
    card['opacity'] = 0;
    card['left'] = (maxValue + leftSlideBy);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/4bc8650348b45a9267097b43aff505c6/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('4bc8650348b45a9267097b43aff505c6', (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e5ac9c8ad984738efc349d07b4dc74b1'), 'socialMedia')), 'social')));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/home/components/1fdde5c76ea432e90eb67a18533b2dac/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes, nameSocial;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['team-card__link', 'link'];
  nameSocial = (getObjectProperty(___arguments.context.dataModel, 'name'));
  if ((getObjectProperty(___arguments.context.dataModel, 'url')) == '') {
    addItemToList(classes, 'team-card__link_hide');
  }
  if (nameSocial == 'facebook') {
    addItemToList(classes, 'fab fa-facebook-f');
  }
  if (nameSocial == 'github') {
    addItemToList(classes, 'fab fa-github');
  }
  if (nameSocial == 'linkedin') {
    addItemToList(classes, 'fab fa-linkedin-in');
  }
  if (nameSocial == 'twitter') {
    addItemToList(classes, 'fab fa-twitter');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home/components/b6f27fbafe317fe16572dfeaf1fb0698/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
      return ((___arguments.context.appData['teamInFocus']) ? ['team__cards-block', 'team__cards-block_in-focus'] : ['team__cards-block'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home/components/d2551aa8d85f0b8224661c4aaaa53f32/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var firstName, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  firstName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName'));
  regExp = (new RegExp('.{2,}', 'g'));
  if (firstName.length <= 50) {
    ___arguments.context.pageData['firstNameValue'] = firstName;
  } else if (firstName.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['firstName'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['firstName'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'firstNameValue')), 0));
  }
  if ((regExp.test(firstName)) == false && firstName != '' || firstName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length < 2 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = false;
  } else if ((regExp.test(firstName)) == false && firstName == '' || firstName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length == 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/home/components/3ddf6adfe3e580cc962a62234bf2e2d5/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-first-name'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home/components/f5bd0a2c6f0bde798ea8df133e9e7e3c/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var lastName, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  lastName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName'));
  regExp = (new RegExp('.{2,}', 'g'));
  if (lastName.length <= 50) {
    ___arguments.context.pageData['lastNameValue'] = lastName;
  } else if (lastName.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['lastName'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['lastName'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'lastNameValue')), 0));
  }
  if ((regExp.test(lastName)) == false && lastName != '' || lastName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length < 2 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = false;
  } else if ((regExp.test(lastName)) == false && lastName == '' || lastName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length == 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/home/components/557e31a13073b4848ca61837b9e70a38/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-last-name'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home/components/cdc2459c0f1ac842997b621a04744c0c/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var email, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  email = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'email'));
  regExp = (new RegExp('[a-zA-Z0-9.\\-_+]{1,}@[^\\W\\.][a-zA-Z0-9\\-]{0,}\\.[a-zA-Z]{1,}', 'g'));
  if (email.length <= 50) {
    ___arguments.context.pageData['emailValue'] = email;
  } else if (email.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['email'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['email'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'emailValue')), 0));
  }
  if ((regExp.test(email)) == false && email != '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = false;
  } else if ((regExp.test(email)) == false && email == '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/home/components/556317981b853c56d8e8e837e5b16c9e/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-email'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home/components/d64247a69fb82b1b932f6a1191167d68/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-phone'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home/components/1712009c62182acf71f1e4e777ac98dd/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var phone, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  phone = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone'));
  regExp = (new RegExp('^[0-9+\\(\\)\\-\\s]+$', 'g'));
  if (phone.length <= 50) {
    ___arguments.context.pageData['phoneValue'] = phone;
  } else if (phone.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['phone'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['phone'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'phoneValue')), 0));
  }
  if (phone.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone')).length < 5 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = true;
  } else if ((regExp.test(phone)) && phone.length >= 5) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = false;
  } else if (phone == '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = true;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/home/components/275519e4f1ff2a5c0d556bd7bfaaf6c5/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-website'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home/components/ddb987ac0b1a2e02086b043645e7decd/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var website, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  website = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website'));
  regExp = (new RegExp('[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)', 'gi'));
  if (website.length <= 249) {
    ___arguments.context.pageData['websiteValue'] = website;
  } else if (website.length > 249) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['website'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['website'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'websiteValue')), 0));
  }
  if ((regExp.test(website)) == false && website != '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = true;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = false;
  }
  if ((regExp.test((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website')))) == true && website.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website')).length) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/home/components/b17c55c60f4dcdf2ae8fc66b95b63932/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var subject, regExp, firstName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  subject = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject'));
  regExp = (new RegExp('.{4,}', 'g'));
  if (subject.length <= 300) {
    ___arguments.context.pageData['subjectValue'] = subject;
  } else if (subject.length > 300) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['subject'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['subject'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'subjectValue')), 0));
  }
  if ((regExp.test(subject)) == false && subject != '' || subject.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length < 4 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = false;
  } else if ((regExp.test(subject)) == false && subject == '' || subject.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length == 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/home/components/a38dcbb7299151a66062e5a8eaaf984c/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-subject'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home/components/101b130a0c5ff2588aa5637fc5fcdb27/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-message'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage']) {
    addItemToList(classes, 'c-form__label_alert');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home/components/6eb697a67eb600c29f860957a2ac3b3e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, message, subject, website, phone, email, lastName, firstName, checkStatusMessage, checkStatusSubject, checkStatusWebsite, checkStatusPhone, checkStatusEmail, checkStatusLastName, checkStatusFirstName, statusMessage, statusSubject, statusWebsite, statusPhone, statusEmail, statusLastName, statusFirstName, company, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function ensureArray(items) {
  return Array.isArray(items) ? items : [items]
}

/**
 * Describe this function...
 */
async function firstNameValidation() {
  regExp = (new RegExp('.{2,}', 'g'));
  if (!(regExp.test(firstName)) || firstName == '' || firstName == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusFirsName() {
  if (!(getObjectProperty(statusFirstName, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('d2551aa8d85f0b8224661c4aaaa53f32')));
    if (firstName == '' || firstName == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function lastNameValidation() {
  regExp = (new RegExp('.{2,}', 'g'));
  if (!(regExp.test(lastName)) || lastName == '' || lastName == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusLastName2() {
  if (!(getObjectProperty(statusLastName, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f5bd0a2c6f0bde798ea8df133e9e7e3c')));
    if (lastName == '' || lastName == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function emailValidation() {
  regExp = (new RegExp('[a-zA-Z0-9.\\-_+]{1,}@[^\\W\\.][a-zA-Z0-9\\-]{0,}\\.[a-zA-Z]{1,}', 'g'));
  if (!(regExp.test(email)) || email == '' || email == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function phoneValidation() {
  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'hintPhone')) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusEmail2() {
  if (!(getObjectProperty(statusEmail, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('cdc2459c0f1ac842997b621a04744c0c')));
    if (email == '' || email == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function websiteValidation() {
  regExp = (new RegExp('[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)', 'gi'));
  if (!(regExp.test(website)) && website != '' && website != null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function subjectValidation() {
  regExp = (new RegExp('.{4,}', 'g'));
  if (!(regExp.test(subject)) || subject == '' || subject == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusPhone2() {
  if (!(getObjectProperty(statusPhone, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('1712009c62182acf71f1e4e777ac98dd')));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = true;
    return false;
  }
  return true
}

/**
 * Describe this function...
 */
async function messageValidation() {
  if (message == '' || message == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusWebsite2() {
  if (!(getObjectProperty(statusWebsite, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('ddb987ac0b1a2e02086b043645e7decd')));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = true;
    return false;
  }
  return true
}

/**
 * Describe this function...
 */
async function checkStatusSubject2() {
  if (!(getObjectProperty(statusSubject, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b17c55c60f4dcdf2ae8fc66b95b63932')));
    if (subject == '' || subject == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function checkStatusMessage2() {
  if (!(getObjectProperty(statusMessage, 'status'))) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage'] = true;
    return false;
  }
  return true
}


  firstName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName'));
  lastName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName'));
  email = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'email'));
  phone = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone'));
  company = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'company'));
  website = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website'));
  subject = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject'));
  message = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'message'));
  statusFirstName = await firstNameValidation();
  statusLastName = await lastNameValidation();
  statusEmail = await emailValidation();
  statusPhone = await phoneValidation();
  statusWebsite = await websiteValidation();
  statusSubject = await subjectValidation();
  statusMessage = await messageValidation();
  checkStatusMessage = await checkStatusMessage2();
  checkStatusSubject = await checkStatusSubject2();
  checkStatusWebsite = await checkStatusWebsite2();
  checkStatusPhone = await checkStatusPhone2();
  checkStatusEmail = await checkStatusEmail2();
  checkStatusLastName = await checkStatusLastName2();
  checkStatusFirstName = await checkStatusFirsName();
  if (checkStatusFirstName != false && checkStatusLastName != false && checkStatusEmail != false && checkStatusPhone != false && checkStatusWebsite != false && checkStatusSubject != false && checkStatusMessage != false) {
    await Backendless.Messaging.sendEmail('New contact from the Hyperpixels Website', new Backendless.Bodyparts({ htmlmessage: ([['From: ',firstName,' ',lastName].join(''),'<br>Email: ',email,'<br> Phone: ',phone,'<br> Website: ',website,'<br> Subject: ',subject,'<br> Message: <br>',message].join('')) }), ensureArray('mike@hyperpixels.net'), undefined);
    ___arguments.context.pageData['MessageSent'] = true;
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b65aa795e4d6a994b3ddd6f68533c80d', false);
    try {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('df8b067a885dcda84a91376a3c4c5c7c', true);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7224592bf6676a81e607676ddbc597e1', false);

    } catch (error) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('df8b067a885dcda84a91376a3c4c5c7c', false);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7224592bf6676a81e607676ddbc597e1', true);
      console.log(error);

    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/58c7b83b0c67da4ff3bef93451af2162/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var message, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  message = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'message'));
  regExp = (new RegExp('.{1,}', 'g'));
  if (message.length <= 249) {
    ___arguments.context.pageData['messageValue'] = message;
  } else if (message.length > 249) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['message'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['message'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'messageValue')), 0));
  }
  if ((regExp.test(message)) == false && message == '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage'] = true;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/home/components/251dd247af0d2f6ebe6ccde3866c89b5/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isOpenModal')) {
    return ['modal', 'open'];
  }

  return ['modal']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home/components/70dd1dff3e215deab9dcc3e29a6566e6/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async onMounted(___arguments) {
    var counter, data, gallery, item, listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setIdItem(data) {
  counter = 1;
  return await Promise.all(data.map(async item => {  item['id'] = counter;
  counter = (typeof counter == 'number' ? counter : 0) + 1;
; return item;}))
}


  await new Promise(r => setTimeout(r, 1000 || 0));
  gallery = (getObjectProperty(___arguments.context.appData, 'galleryList'));
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', (await setIdItem(gallery)));
  listLength = gallery.length;
  ___arguments.context.pageData['totalSlide'] = listLength;
  ___arguments.context.pageData['currentSlide'] = 1;

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/home/components/bb37f7327e62a50eb06507a61d6d1fc6/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == (getObjectProperty(___arguments.context.dataModel, 'id'))) {
    return ['modal__slider-item', 'active'];
  }

  return ['modal__slider-item']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home/components/831fddd4bb7ced9296de1aa550213577/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) > 1) {
    ___arguments.context.pageData['counterSlide2'] = ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == 1 ? listLength : (getObjectProperty(___arguments.context.pageData, 'counterSlide2')) - 1);
  }
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ___arguments.context.pageData['currentSlide'] = (getObjectProperty(___arguments.context.pageData, 'counterSlide2'));

  },
  /* handler:onClick */
  /* handler:onMouseOut */
  onMouseOut(___arguments) {
    var listLength;


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('831fddd4bb7ced9296de1aa550213577'))['opacity'] = '0';

  },
  /* handler:onMouseOut */
  /* handler:onMouseOver */
  onMouseOver(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == 1) {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('831fddd4bb7ced9296de1aa550213577'))['opacity'] = '0';
  } else {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('831fddd4bb7ced9296de1aa550213577'))['opacity'] = '1';
  }

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./pages/home/components/dba21793ecfc81f1424a1ce6ad99a8b3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) < listLength) {
    ___arguments.context.pageData['counterSlide2'] = ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == listLength ? 1 : (getObjectProperty(___arguments.context.pageData, 'counterSlide2')) + 1);
  }
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ___arguments.context.pageData['currentSlide'] = (getObjectProperty(___arguments.context.pageData, 'counterSlide2'));

  },
  /* handler:onClick */
  /* handler:onMouseOut */
  onMouseOut(___arguments) {
    var listLength;


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('dba21793ecfc81f1424a1ce6ad99a8b3'))['opacity'] = '0';

  },
  /* handler:onMouseOut */
  /* handler:onMouseOver */
  onMouseOver(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == listLength) {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('dba21793ecfc81f1424a1ce6ad99a8b3'))['opacity'] = '0';
  } else {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('dba21793ecfc81f1424a1ce6ad99a8b3'))['opacity'] = '1';
  }

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./pages/home/components/31c87ba294176e587b91b87c58e68a82/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/16b9cb3fdc9bf1919465a18c77a6e467/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/3607af0d8419be43455fc4ac16f9cc33/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var company;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  company = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'company'));
  if (company.length <= 100) {
    ___arguments.context.pageData['companyValue'] = company;
  } else if (company.length > 100) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['company'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['company'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'companyValue')), 0));
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/home/components/30ea90dd05af67628fd821d5003c3d3d/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  onMounted(___arguments) {
      ___arguments.context.pageData['firstNameValue'] = '';
  ___arguments.context.pageData['lastNameValue'] = '';
  ___arguments.context.pageData['emailValue'] = '';
  ___arguments.context.pageData['phoneValue'] = '';
  ___arguments.context.pageData['companyValue'] = '';
  ___arguments.context.pageData['websiteValue'] = '';
  ___arguments.context.pageData['subjectValue'] = '';
  ___arguments.context.pageData['messageValue'] = '';

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/home/components/ba557046fb5348f79a8a94c342388c04/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'MessageSent'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/home/components/2d06b571aa5bcd13df8e8426dd1c5849/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2d06b571aa5bcd13df8e8426dd1c5849'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2d06b571aa5bcd13df8e8426dd1c5849'))['color'] = 'white';

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/home/components/14e3a2a815e855eaa285739b916320ed/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  async ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('2d06b571aa5bcd13df8e8426dd1c5849'))['text'] = 'hello@hyperpixels.net';
  await (async function() {
  	var a = document.getElementsByClassName('emailuslink'); //or grab it by tagname etc
  	a[0].href = "mailto:hello@hyperpixels.net"
  })();

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  async ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('2d06b571aa5bcd13df8e8426dd1c5849'))['text'] = 'Email Us';
  await (async function() {
  	var a = document.getElementsByClassName('emailuslink'); //or grab it by tagname etc
  	a[0].href = "mailto:undefined"
  })();

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/home-backup-051624/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onResize */
  ['onResize'](___arguments) {
    var leftSlideBy, left, j, half, galleryList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 990) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 0;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 'auto';
  }
  galleryList = (getObjectProperty(___arguments.context.appData, 'galleryList'));
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) >= 1200) {
    ___arguments.context.appData['leftSlideBy'] = 240;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 1199) {
    ___arguments.context.appData['leftSlideBy'] = 200;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 990) {
    ___arguments.context.appData['leftSlideBy'] = 260;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768) {
    ___arguments.context.appData['leftSlideBy'] = 370;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576) {
    ___arguments.context.appData['leftSlideBy'] = 250;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
    ___arguments.context.appData['leftSlideBy'] = 100;
  }
  leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
  half = Math.floor(galleryList.length / 2);
  ___arguments.context.pageData['leftStart'] = (-15 - half * leftSlideBy);
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 576) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 120);
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 480) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 130);
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 15);
  }
  left = (getObjectProperty(___arguments.context.pageData, 'leftStart'));
  var j_list = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('c376c79acf1e16de2463e38439c34656'));
  for (var j_index in j_list) {
    j = j_list[j_index];
    j['opacity'] = 1;
    j['left'] = left;
    if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
      j['active'] = true;
    } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
      j['active'] = true;
    } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
      j['active'] = true;
    } else if (left == 0 && leftSlideBy == 100) {
      j['active'] = true;
    } else {
      j['active'] = false;
    }
    left = (typeof left == 'number' ? left : 0) + leftSlideBy;
  }

  },
  /* handler:onResize */
  /* handler:onScroll */
  async ['onScroll'](___arguments) {
    var a, item, team, gallery, home2, features, testimonials, contact, topOnViewport, header, elem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function topOnViewport2(elem, header) {
  topOnViewport = (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', elem)) - ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight'))) + (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) - ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', header)));
  return topOnViewport
}


  contact = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0ee4f7a2f9f93096442f303878b88406')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  team = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  gallery = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  testimonials = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  features = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  home2 = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  if (___arguments.scrollTop - ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) < home2) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b'))))) < features && ___arguments.scrollTop > home2) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < testimonials && ___arguments.scrollTop > features + home2) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < gallery && ___arguments.scrollTop > testimonials + features + home2) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < team && ___arguments.scrollTop > testimonials + features + home2 + gallery) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < contact && ___arguments.scrollTop > testimonials + features + home2 + gallery + team) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0ee4f7a2f9f93096442f303878b88406')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop > ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) / 3) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('cb279d48324be4a1644ace919b54f80c'))['opacity'] = 0.3;
  } else if (___arguments.scrollTop > ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) / 10) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('cb279d48324be4a1644ace919b54f80c'))['opacity'] = 0.5;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('cb279d48324be4a1644ace919b54f80c'))['opacity'] = 1;
  }
  if (___arguments.scrollTop) {
    ___arguments.context.pageData['scrollTop'] = true;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['box-shadow'] = '0px 2px 4px rgba(0, 0, 0, 0.1)';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['height'] = '60px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-top'] = '7px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-bottom'] = '7px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '-5px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '45px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#777777';
  } else {
    ___arguments.context.pageData['scrollTop'] = false;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['box-shadow'] = 'none';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['height'] = '80px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['background-color'] = 'transparent';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-top'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '80px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#ffffff';
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 400) {
    a = 150;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 500) {
    a = (typeof a == 'number' ? a : 0) + 200;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 600) {
    a = (typeof a == 'number' ? a : 0) + 300;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 700) {
    a = (typeof a == 'number' ? a : 0) + 400;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 800) {
    a = (typeof a == 'number' ? a : 0) + 500;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 900) {
    a = (typeof a == 'number' ? a : 0) + 600;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 1050) {
    a = (typeof a == 'number' ? a : 0) + 700;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 990 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 1050) {
    if (___arguments.scrollTop >= ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) - a)) {
      ___arguments.context.appData['featuresInFocus'] = true;
    }
    if (___arguments.scrollTop >= ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) - a)) {
      ___arguments.context.appData['testimonialsInFocus'] = true;
    }
    if (___arguments.scrollTop >= ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')))) - a)) {
      ___arguments.context.appData['teamInFocus'] = true;
    }
  } else {
    ___arguments.context.appData['featuresInFocus'] = true;
    ___arguments.context.appData['testimonialsInFocus'] = true;
    ___arguments.context.appData['teamInFocus'] = true;
  }

  },
  /* handler:onScroll */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var teamList, testimonialsList, appData, galleryList, featuresList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await (async function() {
  	  kofiWidgetOverlay.draw('hyperpixels', {
  	    'type': 'floating-chat',
  	    'floating-chat.donateButton.text': 'Donate',
  	    'floating-chat.donateButton.background-color': '#000000',
  	    'floating-chat.donateButton.text-color': '#fff'
  	  });
  })();
  appData = (await (async function() {
  	const data = {
  	  "features": [
  	    {
  	      "id": "f1",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-1.jpg",
  	      "title": "Amazing Experience",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f2",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-2.jpg",
  	      "title": "24/7 Support",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f3",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-3.jpg",
  	      "title": "Real-time",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f4",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-4.jpg",
  	      "title": "Easy To Use",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f5",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-5.jpg",
  	      "title": "Free Updates",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f6",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-6.jpg",
  	      "title": "All Platforms",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    }
  	  ],
  	  "gallery": [
  	    {
  	      "id": "g1",
  	      "image": "https://app.webmojo.io/api/files/web/Hyperpixels/images/Clay County Seal - Final(Full-Transparent).png"
  	    },
  	    {
  	      "id": "g2",
  	      "image": "https://app.webmojo.io/api/files/web/Hyperpixels/images/Calvary Logo (Full).png"
  	    },
  	    {
  	      "id": "g3",
  	      "image": "https://app.webmojo.io/api/files/web/Hyperpixels/images/Aspen Hill Kennels - Final (Full).png"
  	    },
  	    {
  	      "id": "g4",
  	      "image": "https://app.webmojo.io/api/files/web/Hyperpixels/images/Act Leasing Services Logo (No Box - On White - FINAL).png"
  	    },
  	    {
  	      "id": "g5",
  	      "image": "https://app.webmojo.io/api/files/web/Hyperpixels/images/Titanium Fitness Logo 2 (Final).png"
  	    },
  	    {
  	      "id": "g6",
  	      "image": "https://app.webmojo.io/api/files/web/Hyperpixels/images/ACCO Website.png"
  	    }
  	  ],
  	  "team": [
  	    {
  	      "id": "t1",
  	      "name": "Joan Doe",
  	      "career": "Founder - CEO",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo1.jpg",
  	      "socialMedia": {
  	        "social": [
  	          {
  	            "url": "#",
  	            "name": "facebook"
  	          },
  	          {
  	            "url": "#",
  	            "name": "github"
  	          },
  	          {
  	            "url": "#",
  	            "name": "linkedin"
  	          },
  	          {
  	            "url": "",
  	            "name": "twitter"
  	          }
  	        ]
  	      }
  	    },
  	    {
  	      "id": "t2",
  	      "name": "John Doe",
  	      "career": "Co-founder",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo2.jpg",
  	      "socialMedia": {
  	        "social": [
  	          {
  	            "url": "#",
  	            "name": "facebook"
  	          },
  	          {
  	            "url": "",
  	            "name": "github"
  	          },
  	          {
  	            "url": "",
  	            "name": "linkedin"
  	          },
  	          {
  	            "url": "#",
  	            "name": "twitter"
  	          }
  	        ]
  	      }
  	    },
  	    {
  	      "id": "t3",
  	      "name": "Joan Doe",
  	      "career": "Co-founder",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo3.jpg",
  	      "socialMedia": {
  	        "social": [
  	          {
  	            "url": "#",
  	            "name": "facebook"
  	          },
  	          {
  	            "url": "#",
  	            "name": "github"
  	          },
  	          {
  	            "url": "#",
  	            "name": "linkedin"
  	          },
  	          {
  	            "url": "",
  	            "name": "twitter"
  	          }
  	        ]
  	      }
  	    },
  	    {
  	      "id": "t4",
  	      "name": "John Doe",
  	      "career": "Financial Manager",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo4.jpg",
  	      "socialMedia": {
  	        "social": [
  	          {
  	            "url": "#",
  	            "name": "facebook"
  	          },
  	          {
  	            "url": "",
  	            "name": "github"
  	          },
  	          {
  	            "url": "#",
  	            "name": "linkedin"
  	          },
  	          {
  	            "url": "",
  	            "name": "twitter"
  	          }
  	        ]
  	      }
  	    }
  	  ],
  	  "testimonials": [
  	    {
  	      "id": "tm1",
  	      "author": "Jane Doe",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/testimonials/auth1.jpg"
  	    },
  	    {
  	      "id": "tm2",
  	      "author": "John Doe",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/testimonials/auth2.jpg"
  	    },
  	    {
  	      "id": "tm3",
  	      "author": "Jane Doe",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/testimonials/auth3.jpg"
  	    },
  	    {
  	      "id": "tm4",
  	      "author": "John Doe",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/testimonials/item4.jpg"
  	    }
  	  ]
  	}

  	return data
  })());
  galleryList = (getObjectProperty(appData, 'gallery'));
  ___arguments.context.appData['galleryList'] = galleryList;
  ___arguments.context.pageData['MessageSent'] = false;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('153bb1d13492b519dc0717b333c20947', testimonialsList);

  },
  /* handler:onEnter */
  /* handler:onPageQuery */
  ['onPageQuery'](___arguments) {
    var list;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.pageQuery, 'view')) == 'services') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')));
  }
  if ((getObjectProperty(___arguments.pageQuery, 'view')) == 'portfolio') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')));
  }
  if ((getObjectProperty(___arguments.pageQuery, 'view')) == 'contact') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0ee4f7a2f9f93096442f303878b88406')));
  }

  },
  /* handler:onPageQuery */
  /* content */
}))

define('./pages/home-backup-051624/components/153bb1d13492b519dc0717b333c20947/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
      return ((___arguments.context.appData['testimonialsInFocus']) ? ['testimonials__items-block', 'testimonials__items-block_in-focus'] : ['testimonials__items-block'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home-backup-051624/components/c376c79acf1e16de2463e38439c34656/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var item, leftSlideBy, left, newGalleryList, half, firstList, galleryList, lastList, idGalleryItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  await new Promise(r => setTimeout(r, 1000 || 0));
  galleryList = (getObjectProperty(___arguments.context.appData, 'galleryList'));
  idGalleryItem = 1;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c376c79acf1e16de2463e38439c34656', (await Promise.all(galleryList.map(async item => {  item['id'] = idGalleryItem;
    idGalleryItem = (typeof idGalleryItem == 'number' ? idGalleryItem : 0) + 1;
  ; return item;}))));
  ___arguments.context.appData['leftSlideBy'] = 240;
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 1199) {
    ___arguments.context.appData['leftSlideBy'] = 200;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 990) {
    ___arguments.context.appData['leftSlideBy'] = 260;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768) {
    ___arguments.context.appData['leftSlideBy'] = 370;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576) {
    ___arguments.context.appData['leftSlideBy'] = 250;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
    ___arguments.context.appData['leftSlideBy'] = 100;
  }
  half = Math.floor(galleryList.length / 2);
  lastList = galleryList.slice(((half + 1) - 1), galleryList.length);
  firstList = galleryList.slice(0, half);
  newGalleryList = (JSON.parse((JSON.stringify(((addItemToList(lastList, galleryList)).concat(firstList))))));
  leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
  ___arguments.context.pageData['leftStart'] = (-15 - half * leftSlideBy);
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 576) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 120);
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 480) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 130);
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 15);
  }
  left = (getObjectProperty(___arguments.context.pageData, 'leftStart'));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c376c79acf1e16de2463e38439c34656', (await Promise.all(newGalleryList.map(async item => {  item['opacity'] = 1;
    item['left'] = left;
    if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
      item['active'] = true;
    } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
      item['active'] = true;
    } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
      item['active'] = true;
    } else if (left == 0 && leftSlideBy == 100) {
      item['active'] = true;
    } else {
      item['active'] = false;
    }
    left = (typeof left == 'number' ? left : 0) + leftSlideBy;
  ; return item;}))));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/home-backup-051624/components/e23154e2b40dc5a8ac7f9a7e34ff980f/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 480) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e23154e2b40dc5a8ac7f9a7e34ff980f'))['left'] = (String(getObjectProperty(___arguments.context.dataModel, 'left')) + String('px'));
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e23154e2b40dc5a8ac7f9a7e34ff980f'))['left'] = (String(getObjectProperty(___arguments.context.dataModel, 'left')) + String('%'));
  }
  ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e23154e2b40dc5a8ac7f9a7e34ff980f'))['opacity'] = (getObjectProperty(___arguments.context.dataModel, 'opacity'));
  if (getObjectProperty(___arguments.context.dataModel, 'active')) {
    return ['g-slider__item', 'g-slider__item_center'];
  }

  return ['g-slider__item']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home-backup-051624/components/108f6ec347effdbce289a49d625d03af/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['isOpenModal'] = true;
  ___arguments.context.pageData['counterSlide2'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e23154e2b40dc5a8ac7f9a7e34ff980f'), 'id'));
  ___arguments.context.pageData['currentSlide'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e23154e2b40dc5a8ac7f9a7e34ff980f'), 'id'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home-backup-051624/components/26a1309c25831a8af67d5fe98587c773/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    var leftSlideBy, minValue, card, left, j, maxValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  maxValue = 0;
  minValue = 0;
  leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
  if ((typeof (getObjectProperty(___arguments.context.pageData, 'stopClick')) === 'undefined') || ((new Date()).valueOf()) > (getObjectProperty(___arguments.context.pageData, 'stopClick')) + 300) {
    ___arguments.context.pageData['stopClick'] = ((new Date()).valueOf());
    var j_list = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('c376c79acf1e16de2463e38439c34656'));
    for (var j_index in j_list) {
      j = j_list[j_index];
      j['opacity'] = 1;
      j['left'] = ((getObjectProperty(j, 'left')) + leftSlideBy);
      left = (getObjectProperty(j, 'left'));
      if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
        j['active'] = true;
      } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
        j['active'] = true;
      } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
        j['active'] = true;
      } else if (left == 0 && leftSlideBy == 100) {
        j['active'] = true;
      } else {
        j['active'] = false;
      }
      if (maxValue < left) {
        maxValue = left;
        card = j;
      }
      if (minValue > left) {
        minValue = left;
      }
    }
    card['opacity'] = 0;
    card['left'] = (minValue - leftSlideBy);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home-backup-051624/components/0ecc200a34ec908bf97bef91588de587/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var leftSlideBy, maxValue, card, left, j, minValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  maxValue = 0;
  minValue = 0;
  leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
  if ((typeof (getObjectProperty(___arguments.context.pageData, 'stopClick')) === 'undefined') || ((new Date()).valueOf()) > (getObjectProperty(___arguments.context.pageData, 'stopClick')) + 300) {
    ___arguments.context.pageData['stopClick'] = ((new Date()).valueOf());
    var j_list = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('c376c79acf1e16de2463e38439c34656'));
    for (var j_index in j_list) {
      j = j_list[j_index];
      j['left'] = ((getObjectProperty(j, 'left')) - leftSlideBy);
      left = (getObjectProperty(j, 'left'));
      j['opacity'] = 1;
      if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
        j['active'] = true;
      } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
        j['active'] = true;
      } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
        j['active'] = true;
      } else if (left == 0 && leftSlideBy == 100) {
        j['active'] = true;
      } else {
        j['active'] = false;
      }
      if (minValue > left) {
        minValue = left;
        card = j;
      }
      if (maxValue < left) {
        maxValue = left;
      }
    }
    card['opacity'] = 0;
    card['left'] = (maxValue + leftSlideBy);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home-backup-051624/components/4bc8650348b45a9267097b43aff505c6/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('4bc8650348b45a9267097b43aff505c6', (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e5ac9c8ad984738efc349d07b4dc74b1'), 'socialMedia')), 'social')));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/home-backup-051624/components/1fdde5c76ea432e90eb67a18533b2dac/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes, nameSocial;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['team-card__link', 'link'];
  nameSocial = (getObjectProperty(___arguments.context.dataModel, 'name'));
  if ((getObjectProperty(___arguments.context.dataModel, 'url')) == '') {
    addItemToList(classes, 'team-card__link_hide');
  }
  if (nameSocial == 'facebook') {
    addItemToList(classes, 'fab fa-facebook-f');
  }
  if (nameSocial == 'github') {
    addItemToList(classes, 'fab fa-github');
  }
  if (nameSocial == 'linkedin') {
    addItemToList(classes, 'fab fa-linkedin-in');
  }
  if (nameSocial == 'twitter') {
    addItemToList(classes, 'fab fa-twitter');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home-backup-051624/components/b6f27fbafe317fe16572dfeaf1fb0698/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
      return ((___arguments.context.appData['teamInFocus']) ? ['team__cards-block', 'team__cards-block_in-focus'] : ['team__cards-block'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home-backup-051624/components/d2551aa8d85f0b8224661c4aaaa53f32/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var firstName, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  firstName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName'));
  regExp = (new RegExp('.{2,}', 'g'));
  if (firstName.length <= 50) {
    ___arguments.context.pageData['firstNameValue'] = firstName;
  } else if (firstName.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['firstName'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['firstName'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'firstNameValue')), 0));
  }
  if ((regExp.test(firstName)) == false && firstName != '' || firstName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length < 2 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = false;
  } else if ((regExp.test(firstName)) == false && firstName == '' || firstName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length == 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/home-backup-051624/components/3ddf6adfe3e580cc962a62234bf2e2d5/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-first-name'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home-backup-051624/components/f5bd0a2c6f0bde798ea8df133e9e7e3c/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var lastName, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  lastName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName'));
  regExp = (new RegExp('.{2,}', 'g'));
  if (lastName.length <= 50) {
    ___arguments.context.pageData['lastNameValue'] = lastName;
  } else if (lastName.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['lastName'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['lastName'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'lastNameValue')), 0));
  }
  if ((regExp.test(lastName)) == false && lastName != '' || lastName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length < 2 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = false;
  } else if ((regExp.test(lastName)) == false && lastName == '' || lastName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length == 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/home-backup-051624/components/557e31a13073b4848ca61837b9e70a38/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-last-name'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home-backup-051624/components/cdc2459c0f1ac842997b621a04744c0c/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var email, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  email = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'email'));
  regExp = (new RegExp('[a-zA-Z0-9.\\-_+]{1,}@[^\\W\\.][a-zA-Z0-9\\-]{0,}\\.[a-zA-Z]{1,}', 'g'));
  if (email.length <= 50) {
    ___arguments.context.pageData['emailValue'] = email;
  } else if (email.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['email'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['email'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'emailValue')), 0));
  }
  if ((regExp.test(email)) == false && email != '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = false;
  } else if ((regExp.test(email)) == false && email == '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/home-backup-051624/components/556317981b853c56d8e8e837e5b16c9e/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-email'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home-backup-051624/components/d64247a69fb82b1b932f6a1191167d68/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-phone'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home-backup-051624/components/1712009c62182acf71f1e4e777ac98dd/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var phone, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  phone = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone'));
  regExp = (new RegExp('^[0-9+\\(\\)\\-\\s]+$', 'g'));
  if (phone.length <= 50) {
    ___arguments.context.pageData['phoneValue'] = phone;
  } else if (phone.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['phone'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['phone'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'phoneValue')), 0));
  }
  if (phone.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone')).length < 5 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = true;
  } else if ((regExp.test(phone)) && phone.length >= 5) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = false;
  } else if (phone == '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = true;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/home-backup-051624/components/275519e4f1ff2a5c0d556bd7bfaaf6c5/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-website'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home-backup-051624/components/ddb987ac0b1a2e02086b043645e7decd/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var website, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  website = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website'));
  regExp = (new RegExp('[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)', 'gi'));
  if (website.length <= 249) {
    ___arguments.context.pageData['websiteValue'] = website;
  } else if (website.length > 249) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['website'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['website'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'websiteValue')), 0));
  }
  if ((regExp.test(website)) == false && website != '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = true;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = false;
  }
  if ((regExp.test((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website')))) == true && website.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website')).length) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/home-backup-051624/components/b17c55c60f4dcdf2ae8fc66b95b63932/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var subject, regExp, firstName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  subject = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject'));
  regExp = (new RegExp('.{4,}', 'g'));
  if (subject.length <= 300) {
    ___arguments.context.pageData['subjectValue'] = subject;
  } else if (subject.length > 300) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['subject'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['subject'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'subjectValue')), 0));
  }
  if ((regExp.test(subject)) == false && subject != '' || subject.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length < 4 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = false;
  } else if ((regExp.test(subject)) == false && subject == '' || subject.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length == 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/home-backup-051624/components/a38dcbb7299151a66062e5a8eaaf984c/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-subject'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home-backup-051624/components/101b130a0c5ff2588aa5637fc5fcdb27/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-message'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage']) {
    addItemToList(classes, 'c-form__label_alert');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home-backup-051624/components/6eb697a67eb600c29f860957a2ac3b3e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, message, subject, website, phone, email, lastName, firstName, checkStatusMessage, checkStatusSubject, checkStatusWebsite, checkStatusPhone, checkStatusEmail, checkStatusLastName, checkStatusFirstName, statusMessage, statusSubject, statusWebsite, statusPhone, statusEmail, statusLastName, statusFirstName, company, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function ensureArray(items) {
  return Array.isArray(items) ? items : [items]
}

/**
 * Describe this function...
 */
async function firstNameValidation() {
  regExp = (new RegExp('.{2,}', 'g'));
  if (!(regExp.test(firstName)) || firstName == '' || firstName == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusFirsName() {
  if (!(getObjectProperty(statusFirstName, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('d2551aa8d85f0b8224661c4aaaa53f32')));
    if (firstName == '' || firstName == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function lastNameValidation() {
  regExp = (new RegExp('.{2,}', 'g'));
  if (!(regExp.test(lastName)) || lastName == '' || lastName == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusLastName2() {
  if (!(getObjectProperty(statusLastName, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f5bd0a2c6f0bde798ea8df133e9e7e3c')));
    if (lastName == '' || lastName == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function emailValidation() {
  regExp = (new RegExp('[a-zA-Z0-9.\\-_+]{1,}@[^\\W\\.][a-zA-Z0-9\\-]{0,}\\.[a-zA-Z]{1,}', 'g'));
  if (!(regExp.test(email)) || email == '' || email == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function phoneValidation() {
  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'hintPhone')) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusEmail2() {
  if (!(getObjectProperty(statusEmail, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('cdc2459c0f1ac842997b621a04744c0c')));
    if (email == '' || email == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function websiteValidation() {
  regExp = (new RegExp('[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)', 'gi'));
  if (!(regExp.test(website)) && website != '' && website != null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function subjectValidation() {
  regExp = (new RegExp('.{4,}', 'g'));
  if (!(regExp.test(subject)) || subject == '' || subject == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusPhone2() {
  if (!(getObjectProperty(statusPhone, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('1712009c62182acf71f1e4e777ac98dd')));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = true;
    return false;
  }
  return true
}

/**
 * Describe this function...
 */
async function messageValidation() {
  if (message == '' || message == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusWebsite2() {
  if (!(getObjectProperty(statusWebsite, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('ddb987ac0b1a2e02086b043645e7decd')));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = true;
    return false;
  }
  return true
}

/**
 * Describe this function...
 */
async function checkStatusSubject2() {
  if (!(getObjectProperty(statusSubject, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b17c55c60f4dcdf2ae8fc66b95b63932')));
    if (subject == '' || subject == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function checkStatusMessage2() {
  if (!(getObjectProperty(statusMessage, 'status'))) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage'] = true;
    return false;
  }
  return true
}


  firstName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName'));
  lastName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName'));
  email = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'email'));
  phone = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone'));
  company = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'company'));
  website = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website'));
  subject = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject'));
  message = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'message'));
  statusFirstName = await firstNameValidation();
  statusLastName = await lastNameValidation();
  statusEmail = await emailValidation();
  statusPhone = await phoneValidation();
  statusWebsite = await websiteValidation();
  statusSubject = await subjectValidation();
  statusMessage = await messageValidation();
  checkStatusMessage = await checkStatusMessage2();
  checkStatusSubject = await checkStatusSubject2();
  checkStatusWebsite = await checkStatusWebsite2();
  checkStatusPhone = await checkStatusPhone2();
  checkStatusEmail = await checkStatusEmail2();
  checkStatusLastName = await checkStatusLastName2();
  checkStatusFirstName = await checkStatusFirsName();
  if (checkStatusFirstName != false && checkStatusLastName != false && checkStatusEmail != false && checkStatusPhone != false && checkStatusWebsite != false && checkStatusSubject != false && checkStatusMessage != false) {
    await Backendless.Messaging.sendEmail('New contact from the Hyperpixels Website', new Backendless.Bodyparts({ htmlmessage: ([['From: ',firstName,' ',lastName].join(''),'<br>Email: ',email,'<br> Phone: ',phone,'<br> Website: ',website,'<br> Subject: ',subject,'<br> Message: <br>',message].join('')) }), ensureArray('mike@hyperpixels.net'), undefined);
    ___arguments.context.pageData['MessageSent'] = true;
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b65aa795e4d6a994b3ddd6f68533c80d', false);
    try {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('df8b067a885dcda84a91376a3c4c5c7c', true);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7224592bf6676a81e607676ddbc597e1', false);

    } catch (error) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('df8b067a885dcda84a91376a3c4c5c7c', false);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7224592bf6676a81e607676ddbc597e1', true);
      console.log(error);

    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home-backup-051624/components/58c7b83b0c67da4ff3bef93451af2162/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var message, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  message = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'message'));
  regExp = (new RegExp('.{1,}', 'g'));
  if (message.length <= 249) {
    ___arguments.context.pageData['messageValue'] = message;
  } else if (message.length > 249) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['message'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['message'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'messageValue')), 0));
  }
  if ((regExp.test(message)) == false && message == '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage'] = true;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/home-backup-051624/components/251dd247af0d2f6ebe6ccde3866c89b5/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isOpenModal')) {
    return ['modal', 'open'];
  }

  return ['modal']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home-backup-051624/components/70dd1dff3e215deab9dcc3e29a6566e6/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async onMounted(___arguments) {
    var counter, data, gallery, item, listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setIdItem(data) {
  counter = 1;
  return await Promise.all(data.map(async item => {  item['id'] = counter;
  counter = (typeof counter == 'number' ? counter : 0) + 1;
; return item;}))
}


  await new Promise(r => setTimeout(r, 1000 || 0));
  gallery = (getObjectProperty(___arguments.context.appData, 'galleryList'));
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', (await setIdItem(gallery)));
  listLength = gallery.length;
  ___arguments.context.pageData['totalSlide'] = listLength;
  ___arguments.context.pageData['currentSlide'] = 1;

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/home-backup-051624/components/bb37f7327e62a50eb06507a61d6d1fc6/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == (getObjectProperty(___arguments.context.dataModel, 'id'))) {
    return ['modal__slider-item', 'active'];
  }

  return ['modal__slider-item']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home-backup-051624/components/831fddd4bb7ced9296de1aa550213577/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) > 1) {
    ___arguments.context.pageData['counterSlide2'] = ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == 1 ? listLength : (getObjectProperty(___arguments.context.pageData, 'counterSlide2')) - 1);
  }
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ___arguments.context.pageData['currentSlide'] = (getObjectProperty(___arguments.context.pageData, 'counterSlide2'));

  },
  /* handler:onClick */
  /* handler:onMouseOut */
  onMouseOut(___arguments) {
    var listLength;


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('831fddd4bb7ced9296de1aa550213577'))['opacity'] = '0';

  },
  /* handler:onMouseOut */
  /* handler:onMouseOver */
  onMouseOver(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == 1) {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('831fddd4bb7ced9296de1aa550213577'))['opacity'] = '0';
  } else {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('831fddd4bb7ced9296de1aa550213577'))['opacity'] = '1';
  }

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./pages/home-backup-051624/components/dba21793ecfc81f1424a1ce6ad99a8b3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) < listLength) {
    ___arguments.context.pageData['counterSlide2'] = ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == listLength ? 1 : (getObjectProperty(___arguments.context.pageData, 'counterSlide2')) + 1);
  }
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ___arguments.context.pageData['currentSlide'] = (getObjectProperty(___arguments.context.pageData, 'counterSlide2'));

  },
  /* handler:onClick */
  /* handler:onMouseOut */
  onMouseOut(___arguments) {
    var listLength;


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('dba21793ecfc81f1424a1ce6ad99a8b3'))['opacity'] = '0';

  },
  /* handler:onMouseOut */
  /* handler:onMouseOver */
  onMouseOver(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == listLength) {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('dba21793ecfc81f1424a1ce6ad99a8b3'))['opacity'] = '0';
  } else {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('dba21793ecfc81f1424a1ce6ad99a8b3'))['opacity'] = '1';
  }

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./pages/home-backup-051624/components/31c87ba294176e587b91b87c58e68a82/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home-backup-051624/components/16b9cb3fdc9bf1919465a18c77a6e467/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home-backup-051624/components/3607af0d8419be43455fc4ac16f9cc33/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var company;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  company = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'company'));
  if (company.length <= 100) {
    ___arguments.context.pageData['companyValue'] = company;
  } else if (company.length > 100) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['company'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['company'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'companyValue')), 0));
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/home-backup-051624/components/30ea90dd05af67628fd821d5003c3d3d/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  onMounted(___arguments) {
      ___arguments.context.pageData['firstNameValue'] = '';
  ___arguments.context.pageData['lastNameValue'] = '';
  ___arguments.context.pageData['emailValue'] = '';
  ___arguments.context.pageData['phoneValue'] = '';
  ___arguments.context.pageData['companyValue'] = '';
  ___arguments.context.pageData['websiteValue'] = '';
  ___arguments.context.pageData['subjectValue'] = '';
  ___arguments.context.pageData['messageValue'] = '';

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/home-backup-051624/components/ba557046fb5348f79a8a94c342388c04/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'MessageSent'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/home-backup-051624/components/2d06b571aa5bcd13df8e8426dd1c5849/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2d06b571aa5bcd13df8e8426dd1c5849'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2d06b571aa5bcd13df8e8426dd1c5849'))['color'] = 'white';

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/home-backup-051624/components/14e3a2a815e855eaa285739b916320ed/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  async ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('2d06b571aa5bcd13df8e8426dd1c5849'))['text'] = 'hello@hyperpixels.net';
  await (async function() {
  	var a = document.getElementsByClassName('emailuslink'); //or grab it by tagname etc
  	a[0].href = "mailto:hello@hyperpixels.net"
  })();

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  async ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('2d06b571aa5bcd13df8e8426dd1c5849'))['text'] = 'Email Us';
  await (async function() {
  	var a = document.getElementsByClassName('emailuslink'); //or grab it by tagname etc
  	a[0].href = "mailto:undefined"
  })();

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/home-problems/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onResize */
  ['onResize'](___arguments) {
    var leftSlideBy, left, j, half, galleryList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 990) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 0;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 'auto';
  }
  galleryList = (getObjectProperty(___arguments.context.appData, 'galleryList'));
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) >= 1200) {
    ___arguments.context.appData['leftSlideBy'] = 240;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 1199) {
    ___arguments.context.appData['leftSlideBy'] = 200;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 990) {
    ___arguments.context.appData['leftSlideBy'] = 260;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768) {
    ___arguments.context.appData['leftSlideBy'] = 370;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576) {
    ___arguments.context.appData['leftSlideBy'] = 250;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
    ___arguments.context.appData['leftSlideBy'] = 100;
  }
  leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
  half = Math.floor(galleryList.length / 2);
  ___arguments.context.pageData['leftStart'] = (-15 - half * leftSlideBy);
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 576) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 120);
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 480) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 130);
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 15);
  }
  left = (getObjectProperty(___arguments.context.pageData, 'leftStart'));
  var j_list = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('c376c79acf1e16de2463e38439c34656'));
  for (var j_index in j_list) {
    j = j_list[j_index];
    j['opacity'] = 1;
    j['left'] = left;
    if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
      j['active'] = true;
    } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
      j['active'] = true;
    } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
      j['active'] = true;
    } else if (left == 0 && leftSlideBy == 100) {
      j['active'] = true;
    } else {
      j['active'] = false;
    }
    left = (typeof left == 'number' ? left : 0) + leftSlideBy;
  }

  },
  /* handler:onResize */
  /* handler:onScroll */
  async ['onScroll'](___arguments) {
    var a, item, team, gallery, home2, features, testimonials, contact, topOnViewport, header, elem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function topOnViewport2(elem, header) {
  topOnViewport = (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', elem)) - ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight'))) + (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) - ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', header)));
  return topOnViewport
}


  contact = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0ee4f7a2f9f93096442f303878b88406')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  gallery = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  features = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  home2 = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  if (___arguments.scrollTop - ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) < home2) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b'))))) < features && ___arguments.scrollTop > home2) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < testimonials && ___arguments.scrollTop > features + home2) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < gallery && ___arguments.scrollTop > testimonials + features + home2) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < team && ___arguments.scrollTop > testimonials + features + home2 + gallery) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < contact && ___arguments.scrollTop > testimonials + features + home2 + gallery + team) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0ee4f7a2f9f93096442f303878b88406')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop > ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) / 3) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('cb279d48324be4a1644ace919b54f80c'))['opacity'] = 0.3;
  } else if (___arguments.scrollTop > ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) / 10) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('cb279d48324be4a1644ace919b54f80c'))['opacity'] = 0.5;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('cb279d48324be4a1644ace919b54f80c'))['opacity'] = 1;
  }
  if (___arguments.scrollTop) {
    ___arguments.context.pageData['scrollTop'] = true;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['box-shadow'] = '0px 2px 4px rgba(0, 0, 0, 0.1)';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['height'] = '60px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-top'] = '7px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-bottom'] = '7px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '-5px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '45px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#777777';
  } else {
    ___arguments.context.pageData['scrollTop'] = false;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['box-shadow'] = 'none';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['height'] = '80px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['background-color'] = 'transparent';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-top'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '80px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#ffffff';
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 400) {
    a = 150;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 500) {
    a = (typeof a == 'number' ? a : 0) + 200;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 600) {
    a = (typeof a == 'number' ? a : 0) + 300;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 700) {
    a = (typeof a == 'number' ? a : 0) + 400;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 800) {
    a = (typeof a == 'number' ? a : 0) + 500;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 900) {
    a = (typeof a == 'number' ? a : 0) + 600;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 1050) {
    a = (typeof a == 'number' ? a : 0) + 700;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 990 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 1050) {
    if (___arguments.scrollTop >= ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) - a)) {
      ___arguments.context.appData['featuresInFocus'] = true;
    }
    if (___arguments.scrollTop >= ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) - a)) {
      ___arguments.context.appData['testimonialsInFocus'] = true;
    }
    if (___arguments.scrollTop >= ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')))) - a)) {
      ___arguments.context.appData['teamInFocus'] = true;
    }
  } else {
    ___arguments.context.appData['featuresInFocus'] = true;
    ___arguments.context.appData['testimonialsInFocus'] = true;
    ___arguments.context.appData['teamInFocus'] = true;
  }

  },
  /* handler:onScroll */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var teamList, testimonialsList, appData, galleryList, featuresList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  appData = (await (async function() {
  	const data = {
  	  "features": [
  	    {
  	      "id": "f1",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-1.jpg",
  	      "title": "Amazing Experience",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f2",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-2.jpg",
  	      "title": "24/7 Support",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f3",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-3.jpg",
  	      "title": "Real-time",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f4",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-4.jpg",
  	      "title": "Easy To Use",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f5",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-5.jpg",
  	      "title": "Free Updates",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f6",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-6.jpg",
  	      "title": "All Platforms",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    }
  	  ],
  	  "gallery": [
  	    {
  	      "id": "g1",
  	      "image": "https://app.webmojo.io/api/files/web/Hyperpixels/images/Clay County Seal - Final(Full-Transparent).png"
  	    },
  	    {
  	      "id": "g2",
  	      "image": "https://app.webmojo.io/api/files/web/Hyperpixels/images/Calvary Logo (Full).png"
  	    },
  	    {
  	      "id": "g3",
  	      "image": "https://app.webmojo.io/api/files/web/Hyperpixels/images/Aspen Hill Kennels - Final (Full).png"
  	    },
  	    {
  	      "id": "g4",
  	      "image": "https://app.webmojo.io/api/files/web/Hyperpixels/images/Act Leasing Services Logo (No Box - On White - FINAL).png"
  	    },
  	    {
  	      "id": "g5",
  	      "image": "https://app.webmojo.io/api/files/web/Hyperpixels/images/Titanium Fitness Logo 2 (Final).png"
  	    },
  	    {
  	      "id": "g6",
  	      "image": "https://app.webmojo.io/api/files/web/Hyperpixels/images/ACCO Website.png"
  	    }
  	  ],
  	  "team": [
  	    {
  	      "id": "t1",
  	      "name": "Joan Doe",
  	      "career": "Founder - CEO",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo1.jpg",
  	      "socialMedia": {
  	        "social": [
  	          {
  	            "url": "#",
  	            "name": "facebook"
  	          },
  	          {
  	            "url": "#",
  	            "name": "github"
  	          },
  	          {
  	            "url": "#",
  	            "name": "linkedin"
  	          },
  	          {
  	            "url": "",
  	            "name": "twitter"
  	          }
  	        ]
  	      }
  	    },
  	    {
  	      "id": "t2",
  	      "name": "John Doe",
  	      "career": "Co-founder",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo2.jpg",
  	      "socialMedia": {
  	        "social": [
  	          {
  	            "url": "#",
  	            "name": "facebook"
  	          },
  	          {
  	            "url": "",
  	            "name": "github"
  	          },
  	          {
  	            "url": "",
  	            "name": "linkedin"
  	          },
  	          {
  	            "url": "#",
  	            "name": "twitter"
  	          }
  	        ]
  	      }
  	    },
  	    {
  	      "id": "t3",
  	      "name": "Joan Doe",
  	      "career": "Co-founder",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo3.jpg",
  	      "socialMedia": {
  	        "social": [
  	          {
  	            "url": "#",
  	            "name": "facebook"
  	          },
  	          {
  	            "url": "#",
  	            "name": "github"
  	          },
  	          {
  	            "url": "#",
  	            "name": "linkedin"
  	          },
  	          {
  	            "url": "",
  	            "name": "twitter"
  	          }
  	        ]
  	      }
  	    },
  	    {
  	      "id": "t4",
  	      "name": "John Doe",
  	      "career": "Financial Manager",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo4.jpg",
  	      "socialMedia": {
  	        "social": [
  	          {
  	            "url": "#",
  	            "name": "facebook"
  	          },
  	          {
  	            "url": "",
  	            "name": "github"
  	          },
  	          {
  	            "url": "#",
  	            "name": "linkedin"
  	          },
  	          {
  	            "url": "",
  	            "name": "twitter"
  	          }
  	        ]
  	      }
  	    }
  	  ],
  	  "testimonials": [
  	    {
  	      "id": "tm1",
  	      "author": "Jane Doe",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/testimonials/auth1.jpg"
  	    },
  	    {
  	      "id": "tm2",
  	      "author": "John Doe",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/testimonials/auth2.jpg"
  	    },
  	    {
  	      "id": "tm3",
  	      "author": "Jane Doe",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/testimonials/auth3.jpg"
  	    },
  	    {
  	      "id": "tm4",
  	      "author": "John Doe",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/testimonials/item4.jpg"
  	    }
  	  ]
  	}

  	return data
  })());
  galleryList = (getObjectProperty(appData, 'gallery'));
  ___arguments.context.appData['galleryList'] = galleryList;
  ___arguments.context.pageData['MessageSent'] = false;

  },
  /* handler:onEnter */
  /* handler:onPageQuery */
  ['onPageQuery'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.pageQuery, 'view')) == 'services') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')));
  }
  if ((getObjectProperty(___arguments.pageQuery, 'view')) == 'portfolio') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')));
  }
  if ((getObjectProperty(___arguments.pageQuery, 'view')) == 'contact') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0ee4f7a2f9f93096442f303878b88406')));
  }

  },
  /* handler:onPageQuery */
  /* content */
}))

define('./pages/home-problems/components/153bb1d13492b519dc0717b333c20947/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
      return ((___arguments.context.appData['testimonialsInFocus']) ? ['testimonials__items-block', 'testimonials__items-block_in-focus'] : ['testimonials__items-block'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home-problems/components/c376c79acf1e16de2463e38439c34656/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var item, leftSlideBy, left, newGalleryList, half, firstList, galleryList, lastList, idGalleryItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  await new Promise(r => setTimeout(r, 1000 || 0));
  galleryList = (getObjectProperty(___arguments.context.appData, 'galleryList'));
  idGalleryItem = 1;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c376c79acf1e16de2463e38439c34656', (await Promise.all(galleryList.map(async item => {  item['id'] = idGalleryItem;
    idGalleryItem = (typeof idGalleryItem == 'number' ? idGalleryItem : 0) + 1;
  ; return item;}))));
  ___arguments.context.appData['leftSlideBy'] = 240;
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 1199) {
    ___arguments.context.appData['leftSlideBy'] = 200;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 990) {
    ___arguments.context.appData['leftSlideBy'] = 260;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768) {
    ___arguments.context.appData['leftSlideBy'] = 370;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576) {
    ___arguments.context.appData['leftSlideBy'] = 250;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
    ___arguments.context.appData['leftSlideBy'] = 100;
  }
  half = Math.floor(galleryList.length / 2);
  lastList = galleryList.slice(((half + 1) - 1), galleryList.length);
  firstList = galleryList.slice(0, half);
  newGalleryList = (JSON.parse((JSON.stringify(((addItemToList(lastList, galleryList)).concat(firstList))))));
  leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
  ___arguments.context.pageData['leftStart'] = (-15 - half * leftSlideBy);
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 576) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 120);
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 480) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 130);
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 15);
  }
  left = (getObjectProperty(___arguments.context.pageData, 'leftStart'));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c376c79acf1e16de2463e38439c34656', (await Promise.all(newGalleryList.map(async item => {  item['opacity'] = 1;
    item['left'] = left;
    if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
      item['active'] = true;
    } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
      item['active'] = true;
    } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
      item['active'] = true;
    } else if (left == 0 && leftSlideBy == 100) {
      item['active'] = true;
    } else {
      item['active'] = false;
    }
    left = (typeof left == 'number' ? left : 0) + leftSlideBy;
  ; return item;}))));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/home-problems/components/e23154e2b40dc5a8ac7f9a7e34ff980f/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 480) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e23154e2b40dc5a8ac7f9a7e34ff980f'))['left'] = (String(getObjectProperty(___arguments.context.dataModel, 'left')) + String('px'));
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e23154e2b40dc5a8ac7f9a7e34ff980f'))['left'] = (String(getObjectProperty(___arguments.context.dataModel, 'left')) + String('%'));
  }
  ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e23154e2b40dc5a8ac7f9a7e34ff980f'))['opacity'] = (getObjectProperty(___arguments.context.dataModel, 'opacity'));
  if (getObjectProperty(___arguments.context.dataModel, 'active')) {
    return ['g-slider__item', 'g-slider__item_center'];
  }

  return ['g-slider__item']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home-problems/components/108f6ec347effdbce289a49d625d03af/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['isOpenModal'] = true;
  ___arguments.context.pageData['counterSlide2'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e23154e2b40dc5a8ac7f9a7e34ff980f'), 'id'));
  ___arguments.context.pageData['currentSlide'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e23154e2b40dc5a8ac7f9a7e34ff980f'), 'id'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home-problems/components/26a1309c25831a8af67d5fe98587c773/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    var leftSlideBy, minValue, card, left, j, maxValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  maxValue = 0;
  minValue = 0;
  leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
  if ((typeof (getObjectProperty(___arguments.context.pageData, 'stopClick')) === 'undefined') || ((new Date()).valueOf()) > (getObjectProperty(___arguments.context.pageData, 'stopClick')) + 300) {
    ___arguments.context.pageData['stopClick'] = ((new Date()).valueOf());
    var j_list = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('c376c79acf1e16de2463e38439c34656'));
    for (var j_index in j_list) {
      j = j_list[j_index];
      j['opacity'] = 1;
      j['left'] = ((getObjectProperty(j, 'left')) + leftSlideBy);
      left = (getObjectProperty(j, 'left'));
      if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
        j['active'] = true;
      } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
        j['active'] = true;
      } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
        j['active'] = true;
      } else if (left == 0 && leftSlideBy == 100) {
        j['active'] = true;
      } else {
        j['active'] = false;
      }
      if (maxValue < left) {
        maxValue = left;
        card = j;
      }
      if (minValue > left) {
        minValue = left;
      }
    }
    card['opacity'] = 0;
    card['left'] = (minValue - leftSlideBy);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home-problems/components/0ecc200a34ec908bf97bef91588de587/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var leftSlideBy, maxValue, card, left, j, minValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  maxValue = 0;
  minValue = 0;
  leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
  if ((typeof (getObjectProperty(___arguments.context.pageData, 'stopClick')) === 'undefined') || ((new Date()).valueOf()) > (getObjectProperty(___arguments.context.pageData, 'stopClick')) + 300) {
    ___arguments.context.pageData['stopClick'] = ((new Date()).valueOf());
    var j_list = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('c376c79acf1e16de2463e38439c34656'));
    for (var j_index in j_list) {
      j = j_list[j_index];
      j['left'] = ((getObjectProperty(j, 'left')) - leftSlideBy);
      left = (getObjectProperty(j, 'left'));
      j['opacity'] = 1;
      if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
        j['active'] = true;
      } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
        j['active'] = true;
      } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
        j['active'] = true;
      } else if (left == 0 && leftSlideBy == 100) {
        j['active'] = true;
      } else {
        j['active'] = false;
      }
      if (minValue > left) {
        minValue = left;
        card = j;
      }
      if (maxValue < left) {
        maxValue = left;
      }
    }
    card['opacity'] = 0;
    card['left'] = (maxValue + leftSlideBy);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home-problems/components/4bc8650348b45a9267097b43aff505c6/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('4bc8650348b45a9267097b43aff505c6', (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e5ac9c8ad984738efc349d07b4dc74b1'), 'socialMedia')), 'social')));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/home-problems/components/1fdde5c76ea432e90eb67a18533b2dac/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes, nameSocial;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['team-card__link', 'link'];
  nameSocial = (getObjectProperty(___arguments.context.dataModel, 'name'));
  if ((getObjectProperty(___arguments.context.dataModel, 'url')) == '') {
    addItemToList(classes, 'team-card__link_hide');
  }
  if (nameSocial == 'facebook') {
    addItemToList(classes, 'fab fa-facebook-f');
  }
  if (nameSocial == 'github') {
    addItemToList(classes, 'fab fa-github');
  }
  if (nameSocial == 'linkedin') {
    addItemToList(classes, 'fab fa-linkedin-in');
  }
  if (nameSocial == 'twitter') {
    addItemToList(classes, 'fab fa-twitter');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home-problems/components/b6f27fbafe317fe16572dfeaf1fb0698/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
      return ((___arguments.context.appData['teamInFocus']) ? ['team__cards-block', 'team__cards-block_in-focus'] : ['team__cards-block'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home-problems/components/d2551aa8d85f0b8224661c4aaaa53f32/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var firstName, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  firstName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName'));
  regExp = (new RegExp('.{2,}', 'g'));
  if (firstName.length <= 50) {
    ___arguments.context.pageData['firstNameValue'] = firstName;
  } else if (firstName.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['firstName'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['firstName'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'firstNameValue')), 0));
  }
  if ((regExp.test(firstName)) == false && firstName != '' || firstName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length < 2 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = false;
  } else if ((regExp.test(firstName)) == false && firstName == '' || firstName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length == 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/home-problems/components/3ddf6adfe3e580cc962a62234bf2e2d5/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-first-name'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home-problems/components/f5bd0a2c6f0bde798ea8df133e9e7e3c/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var lastName, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  lastName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName'));
  regExp = (new RegExp('.{2,}', 'g'));
  if (lastName.length <= 50) {
    ___arguments.context.pageData['lastNameValue'] = lastName;
  } else if (lastName.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['lastName'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['lastName'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'lastNameValue')), 0));
  }
  if ((regExp.test(lastName)) == false && lastName != '' || lastName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length < 2 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = false;
  } else if ((regExp.test(lastName)) == false && lastName == '' || lastName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length == 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/home-problems/components/557e31a13073b4848ca61837b9e70a38/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-last-name'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home-problems/components/cdc2459c0f1ac842997b621a04744c0c/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var email, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  email = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'email'));
  regExp = (new RegExp('[a-zA-Z0-9.\\-_+]{1,}@[^\\W\\.][a-zA-Z0-9\\-]{0,}\\.[a-zA-Z]{1,}', 'g'));
  if (email.length <= 50) {
    ___arguments.context.pageData['emailValue'] = email;
  } else if (email.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['email'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['email'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'emailValue')), 0));
  }
  if ((regExp.test(email)) == false && email != '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = false;
  } else if ((regExp.test(email)) == false && email == '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/home-problems/components/556317981b853c56d8e8e837e5b16c9e/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-email'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home-problems/components/d64247a69fb82b1b932f6a1191167d68/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-phone'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home-problems/components/1712009c62182acf71f1e4e777ac98dd/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var phone, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  phone = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone'));
  regExp = (new RegExp('^[0-9+\\(\\)\\-\\s]+$', 'g'));
  if (phone.length <= 50) {
    ___arguments.context.pageData['phoneValue'] = phone;
  } else if (phone.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['phone'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['phone'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'phoneValue')), 0));
  }
  if (phone.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone')).length < 5 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = true;
  } else if ((regExp.test(phone)) && phone.length >= 5) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = false;
  } else if (phone == '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = true;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/home-problems/components/275519e4f1ff2a5c0d556bd7bfaaf6c5/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-website'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home-problems/components/ddb987ac0b1a2e02086b043645e7decd/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var website, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  website = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website'));
  regExp = (new RegExp('[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)', 'gi'));
  if (website.length <= 249) {
    ___arguments.context.pageData['websiteValue'] = website;
  } else if (website.length > 249) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['website'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['website'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'websiteValue')), 0));
  }
  if ((regExp.test(website)) == false && website != '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = true;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = false;
  }
  if ((regExp.test((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website')))) == true && website.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website')).length) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/home-problems/components/b17c55c60f4dcdf2ae8fc66b95b63932/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var subject, regExp, firstName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  subject = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject'));
  regExp = (new RegExp('.{4,}', 'g'));
  if (subject.length <= 300) {
    ___arguments.context.pageData['subjectValue'] = subject;
  } else if (subject.length > 300) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['subject'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['subject'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'subjectValue')), 0));
  }
  if ((regExp.test(subject)) == false && subject != '' || subject.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length < 4 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = false;
  } else if ((regExp.test(subject)) == false && subject == '' || subject.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length == 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/home-problems/components/a38dcbb7299151a66062e5a8eaaf984c/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-subject'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home-problems/components/101b130a0c5ff2588aa5637fc5fcdb27/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-message'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage']) {
    addItemToList(classes, 'c-form__label_alert');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home-problems/components/6eb697a67eb600c29f860957a2ac3b3e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, message, subject, website, phone, email, lastName, firstName, checkStatusMessage, checkStatusSubject, checkStatusWebsite, checkStatusPhone, checkStatusEmail, checkStatusLastName, checkStatusFirstName, statusMessage, statusSubject, statusWebsite, statusPhone, statusEmail, statusLastName, statusFirstName, company, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function ensureArray(items) {
  return Array.isArray(items) ? items : [items]
}

/**
 * Describe this function...
 */
async function firstNameValidation() {
  regExp = (new RegExp('.{2,}', 'g'));
  if (!(regExp.test(firstName)) || firstName == '' || firstName == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusFirsName() {
  if (!(getObjectProperty(statusFirstName, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('d2551aa8d85f0b8224661c4aaaa53f32')));
    if (firstName == '' || firstName == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function lastNameValidation() {
  regExp = (new RegExp('.{2,}', 'g'));
  if (!(regExp.test(lastName)) || lastName == '' || lastName == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusLastName2() {
  if (!(getObjectProperty(statusLastName, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f5bd0a2c6f0bde798ea8df133e9e7e3c')));
    if (lastName == '' || lastName == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function emailValidation() {
  regExp = (new RegExp('[a-zA-Z0-9.\\-_+]{1,}@[^\\W\\.][a-zA-Z0-9\\-]{0,}\\.[a-zA-Z]{1,}', 'g'));
  if (!(regExp.test(email)) || email == '' || email == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function phoneValidation() {
  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'hintPhone')) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusEmail2() {
  if (!(getObjectProperty(statusEmail, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('cdc2459c0f1ac842997b621a04744c0c')));
    if (email == '' || email == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function websiteValidation() {
  regExp = (new RegExp('[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)', 'gi'));
  if (!(regExp.test(website)) && website != '' && website != null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function subjectValidation() {
  regExp = (new RegExp('.{4,}', 'g'));
  if (!(regExp.test(subject)) || subject == '' || subject == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusPhone2() {
  if (!(getObjectProperty(statusPhone, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('1712009c62182acf71f1e4e777ac98dd')));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = true;
    return false;
  }
  return true
}

/**
 * Describe this function...
 */
async function messageValidation() {
  if (message == '' || message == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusWebsite2() {
  if (!(getObjectProperty(statusWebsite, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('ddb987ac0b1a2e02086b043645e7decd')));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = true;
    return false;
  }
  return true
}

/**
 * Describe this function...
 */
async function checkStatusSubject2() {
  if (!(getObjectProperty(statusSubject, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b17c55c60f4dcdf2ae8fc66b95b63932')));
    if (subject == '' || subject == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function checkStatusMessage2() {
  if (!(getObjectProperty(statusMessage, 'status'))) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage'] = true;
    return false;
  }
  return true
}


  firstName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName'));
  lastName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName'));
  email = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'email'));
  phone = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone'));
  company = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'company'));
  website = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website'));
  subject = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject'));
  message = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'message'));
  statusFirstName = await firstNameValidation();
  statusLastName = await lastNameValidation();
  statusEmail = await emailValidation();
  statusPhone = await phoneValidation();
  statusWebsite = await websiteValidation();
  statusSubject = await subjectValidation();
  statusMessage = await messageValidation();
  checkStatusMessage = await checkStatusMessage2();
  checkStatusSubject = await checkStatusSubject2();
  checkStatusWebsite = await checkStatusWebsite2();
  checkStatusPhone = await checkStatusPhone2();
  checkStatusEmail = await checkStatusEmail2();
  checkStatusLastName = await checkStatusLastName2();
  checkStatusFirstName = await checkStatusFirsName();
  if (checkStatusFirstName != false && checkStatusLastName != false && checkStatusEmail != false && checkStatusPhone != false && checkStatusWebsite != false && checkStatusSubject != false && checkStatusMessage != false) {
    await Backendless.Messaging.sendEmail('New contact from the Hyperpixels Website', new Backendless.Bodyparts({ htmlmessage: ([['From: ',firstName,' ',lastName].join(''),'<br>Email: ',email,'<br> Phone: ',phone,'<br> Website: ',website,'<br> Subject: ',subject,'<br> Message: <br>',message].join('')) }), ensureArray('mike@hyperpixels.net'), undefined);
    ___arguments.context.pageData['MessageSent'] = true;
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b65aa795e4d6a994b3ddd6f68533c80d', false);
    try {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('df8b067a885dcda84a91376a3c4c5c7c', true);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7224592bf6676a81e607676ddbc597e1', false);

    } catch (error) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('df8b067a885dcda84a91376a3c4c5c7c', false);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7224592bf6676a81e607676ddbc597e1', true);
      console.log(error);

    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home-problems/components/58c7b83b0c67da4ff3bef93451af2162/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var message, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  message = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'message'));
  regExp = (new RegExp('.{1,}', 'g'));
  if (message.length <= 249) {
    ___arguments.context.pageData['messageValue'] = message;
  } else if (message.length > 249) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['message'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['message'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'messageValue')), 0));
  }
  if ((regExp.test(message)) == false && message == '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage'] = true;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/home-problems/components/251dd247af0d2f6ebe6ccde3866c89b5/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isOpenModal')) {
    return ['modal', 'open'];
  }

  return ['modal']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home-problems/components/70dd1dff3e215deab9dcc3e29a6566e6/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async onMounted(___arguments) {
    var counter, data, gallery, item, listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setIdItem(data) {
  counter = 1;
  return await Promise.all(data.map(async item => {  item['id'] = counter;
  counter = (typeof counter == 'number' ? counter : 0) + 1;
; return item;}))
}


  await new Promise(r => setTimeout(r, 1000 || 0));
  gallery = (getObjectProperty(___arguments.context.appData, 'galleryList'));
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', (await setIdItem(gallery)));
  listLength = gallery.length;
  ___arguments.context.pageData['totalSlide'] = listLength;
  ___arguments.context.pageData['currentSlide'] = 1;

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/home-problems/components/bb37f7327e62a50eb06507a61d6d1fc6/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == (getObjectProperty(___arguments.context.dataModel, 'id'))) {
    return ['modal__slider-item', 'active'];
  }

  return ['modal__slider-item']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home-problems/components/831fddd4bb7ced9296de1aa550213577/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) > 1) {
    ___arguments.context.pageData['counterSlide2'] = ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == 1 ? listLength : (getObjectProperty(___arguments.context.pageData, 'counterSlide2')) - 1);
  }
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ___arguments.context.pageData['currentSlide'] = (getObjectProperty(___arguments.context.pageData, 'counterSlide2'));

  },
  /* handler:onClick */
  /* handler:onMouseOut */
  onMouseOut(___arguments) {
    var listLength;


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('831fddd4bb7ced9296de1aa550213577'))['opacity'] = '0';

  },
  /* handler:onMouseOut */
  /* handler:onMouseOver */
  onMouseOver(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == 1) {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('831fddd4bb7ced9296de1aa550213577'))['opacity'] = '0';
  } else {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('831fddd4bb7ced9296de1aa550213577'))['opacity'] = '1';
  }

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./pages/home-problems/components/dba21793ecfc81f1424a1ce6ad99a8b3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) < listLength) {
    ___arguments.context.pageData['counterSlide2'] = ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == listLength ? 1 : (getObjectProperty(___arguments.context.pageData, 'counterSlide2')) + 1);
  }
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ___arguments.context.pageData['currentSlide'] = (getObjectProperty(___arguments.context.pageData, 'counterSlide2'));

  },
  /* handler:onClick */
  /* handler:onMouseOut */
  onMouseOut(___arguments) {
    var listLength;


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('dba21793ecfc81f1424a1ce6ad99a8b3'))['opacity'] = '0';

  },
  /* handler:onMouseOut */
  /* handler:onMouseOver */
  onMouseOver(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == listLength) {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('dba21793ecfc81f1424a1ce6ad99a8b3'))['opacity'] = '0';
  } else {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('dba21793ecfc81f1424a1ce6ad99a8b3'))['opacity'] = '1';
  }

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./pages/home-problems/components/31c87ba294176e587b91b87c58e68a82/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home-problems/components/16b9cb3fdc9bf1919465a18c77a6e467/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home-problems/components/3607af0d8419be43455fc4ac16f9cc33/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var company;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  company = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'company'));
  if (company.length <= 100) {
    ___arguments.context.pageData['companyValue'] = company;
  } else if (company.length > 100) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['company'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['company'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'companyValue')), 0));
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/home-problems/components/30ea90dd05af67628fd821d5003c3d3d/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  onMounted(___arguments) {
      ___arguments.context.pageData['firstNameValue'] = '';
  ___arguments.context.pageData['lastNameValue'] = '';
  ___arguments.context.pageData['emailValue'] = '';
  ___arguments.context.pageData['phoneValue'] = '';
  ___arguments.context.pageData['companyValue'] = '';
  ___arguments.context.pageData['websiteValue'] = '';
  ___arguments.context.pageData['subjectValue'] = '';
  ___arguments.context.pageData['messageValue'] = '';

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/home-problems/components/ba557046fb5348f79a8a94c342388c04/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'MessageSent'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/home-problems/components/2d06b571aa5bcd13df8e8426dd1c5849/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2d06b571aa5bcd13df8e8426dd1c5849'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2d06b571aa5bcd13df8e8426dd1c5849'))['color'] = 'white';

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/home-problems/components/14e3a2a815e855eaa285739b916320ed/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  async ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('2d06b571aa5bcd13df8e8426dd1c5849'))['text'] = 'hello@hyperpixels.net';
  await (async function() {
  	var a = document.getElementsByClassName('emailuslink'); //or grab it by tagname etc
  	a[0].href = "mailto:hello@hyperpixels.net"
  })();

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  async ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('2d06b571aa5bcd13df8e8426dd1c5849'))['text'] = 'Email Us';
  await (async function() {
  	var a = document.getElementsByClassName('emailuslink'); //or grab it by tagname etc
  	a[0].href = "mailto:undefined"
  })();

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/home-tabs/components/d1d9de3c08188cf9ff4108b1614be69a/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var AppURL, AppBaseURL;


  AppURL = (await (async function() {
  	return window.location.href
  })());
  AppBaseURL = AppURL.slice(0, 22);
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', [({ [`textLink`]: 'Home',[`urlLink`]: '#home',[`active`]: true }), ({ [`textLink`]: 'Services',[`urlLink`]: '#services',[`active`]: false }), ({ [`textLink`]: 'Portfolio',[`urlLink`]: '#portfolio',[`active`]: false }), ({ [`textLink`]: 'Project Estimator',[`urlLink`]: String('?page=webestimator'),[`active`]: false }), ({ [`textLink`]: 'Contact',[`urlLink`]: '#contact',[`active`]: false }), ({ [`textLink`]: 'Login',[`urlLink`]: '#login',[`active`]: false })]);

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/home-tabs/components/4494147e47463208b1e17aafe36cda16/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var textLink, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  textLink = (getObjectProperty(___arguments.context.dataModel, 'textLink'));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')) == textLink) {
      item['active'] = true;
    } else {
      item['active'] = false;
    }
  ; return item;}))));
  if (textLink == 'Home') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')));
  } else if (textLink == 'Services') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')));
  } else if (textLink == 'TestimonialsX') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')));
  } else if (textLink == 'Portfolio') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')));
  } else if (textLink == 'Team') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')));
  } else if (textLink == 'Contact') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0ee4f7a2f9f93096442f303878b88406')));
  } else if (textLink == 'Project Estimator') {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('projectestimator', undefined);
  } else if (textLink == 'Login') {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 990) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 0;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.dataModel, 'active')) {
    if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) == true) {
      return ['link', 'nav__link', 'nav__link_active', null, 'boldmenuitem'];
    } else if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) != true) {
      return ['link', 'nav__link', 'nav__link_active', 'whiteshadowtext', null, 'boldmenuitem'];
    }
  } else if ((getObjectProperty(___arguments.context.dataModel, 'active')) != true) {
    if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) == true) {
      return ['link', 'nav__link', null, null];
    } else if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) != true) {
      return ['link', 'nav__link', 'shadowtext', null];
    }
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home-tabs/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onResize */
  ['onResize'](___arguments) {
    var leftSlideBy, left, j, half, galleryList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 990) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 0;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 'auto';
  }
  galleryList = (getObjectProperty(___arguments.context.appData, 'galleryList'));
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) >= 1200) {
    ___arguments.context.appData['leftSlideBy'] = 240;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 1199) {
    ___arguments.context.appData['leftSlideBy'] = 200;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 990) {
    ___arguments.context.appData['leftSlideBy'] = 260;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768) {
    ___arguments.context.appData['leftSlideBy'] = 370;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576) {
    ___arguments.context.appData['leftSlideBy'] = 250;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
    ___arguments.context.appData['leftSlideBy'] = 100;
  }
  leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
  half = Math.floor(galleryList.length / 2);
  ___arguments.context.pageData['leftStart'] = (-15 - half * leftSlideBy);
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 576) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 120);
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 480) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 130);
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 15);
  }
  left = (getObjectProperty(___arguments.context.pageData, 'leftStart'));
  var j_list = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('c376c79acf1e16de2463e38439c34656'));
  for (var j_index in j_list) {
    j = j_list[j_index];
    j['opacity'] = 1;
    j['left'] = left;
    if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
      j['active'] = true;
    } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
      j['active'] = true;
    } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
      j['active'] = true;
    } else if (left == 0 && leftSlideBy == 100) {
      j['active'] = true;
    } else {
      j['active'] = false;
    }
    left = (typeof left == 'number' ? left : 0) + leftSlideBy;
  }

  },
  /* handler:onResize */
  /* handler:onScroll */
  async ['onScroll'](___arguments) {
    var a, item, team, gallery, home2, features, testimonials, contact, topOnViewport, header, elem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function topOnViewport2(elem, header) {
  topOnViewport = (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', elem)) - ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight'))) + (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) - ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', header)));
  return topOnViewport
}


  contact = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0ee4f7a2f9f93096442f303878b88406')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  team = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  gallery = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  testimonials = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  features = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  home2 = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  if (___arguments.scrollTop - ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) < home2) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b'))))) < features && ___arguments.scrollTop > home2) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < testimonials && ___arguments.scrollTop > features + home2) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < gallery && ___arguments.scrollTop > testimonials + features + home2) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < team && ___arguments.scrollTop > testimonials + features + home2 + gallery) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < contact && ___arguments.scrollTop > testimonials + features + home2 + gallery + team) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0ee4f7a2f9f93096442f303878b88406')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop > ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) / 3) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('f5f6c87f71d4ee4f6b9b334b92f88f31'))['opacity'] = 0.3;
  } else if (___arguments.scrollTop > ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) / 10) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('f5f6c87f71d4ee4f6b9b334b92f88f31'))['opacity'] = 0.5;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('f5f6c87f71d4ee4f6b9b334b92f88f31'))['opacity'] = 1;
  }
  if (___arguments.scrollTop) {
    ___arguments.context.pageData['scrollTop'] = true;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['box-shadow'] = '0px 2px 4px rgba(0, 0, 0, 0.1)';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['height'] = '60px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-top'] = '7px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '-5px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '45px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#777777';
  } else {
    ___arguments.context.pageData['scrollTop'] = false;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['box-shadow'] = 'none';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['height'] = '80px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['background-color'] = 'transparent';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-top'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '80px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#ffffff';
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 400) {
    a = 150;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 500) {
    a = (typeof a == 'number' ? a : 0) + 200;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 600) {
    a = (typeof a == 'number' ? a : 0) + 300;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 700) {
    a = (typeof a == 'number' ? a : 0) + 400;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 800) {
    a = (typeof a == 'number' ? a : 0) + 500;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 900) {
    a = (typeof a == 'number' ? a : 0) + 600;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 1050) {
    a = (typeof a == 'number' ? a : 0) + 700;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 990 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 1050) {
    if (___arguments.scrollTop >= ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) - a)) {
      ___arguments.context.appData['featuresInFocus'] = true;
    }
    if (___arguments.scrollTop >= ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) - a)) {
      ___arguments.context.appData['testimonialsInFocus'] = true;
    }
    if (___arguments.scrollTop >= ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')))) - a)) {
      ___arguments.context.appData['teamInFocus'] = true;
    }
  } else {
    ___arguments.context.appData['featuresInFocus'] = true;
    ___arguments.context.appData['testimonialsInFocus'] = true;
    ___arguments.context.appData['teamInFocus'] = true;
  }

  },
  /* handler:onScroll */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var teamList, testimonialsList, appData, galleryList, featuresList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await (async function() {
  	  kofiWidgetOverlay.draw('hyperpixels', {
  	    'type': 'floating-chat',
  	    'floating-chat.donateButton.text': 'Donate',
  	    'floating-chat.donateButton.background-color': '#000000',
  	    'floating-chat.donateButton.text-color': '#fff'
  	  });
  })();
  appData = (await (async function() {
  	const data = {
  	  "features": [
  	    {
  	      "id": "f1",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-1.jpg",
  	      "title": "Amazing Experience",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f2",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-2.jpg",
  	      "title": "24/7 Support",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f3",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-3.jpg",
  	      "title": "Real-time",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f4",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-4.jpg",
  	      "title": "Easy To Use",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f5",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-5.jpg",
  	      "title": "Free Updates",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f6",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-6.jpg",
  	      "title": "All Platforms",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    }
  	  ],
  	  "gallery": [
  	    {
  	      "id": "g1",
  	      "image": "https://app.webmojo.io/api/files/web/Hyperpixels/images/Clay County Seal - Final(Full-Transparent).png"
  	    },
  	    {
  	      "id": "g2",
  	      "image": "https://app.webmojo.io/api/files/web/Hyperpixels/images/Calvary Logo (Full).png"
  	    },
  	    {
  	      "id": "g3",
  	      "image": "https://app.webmojo.io/api/files/web/Hyperpixels/images/Aspen Hill Kennels - Final (Full).png"
  	    },
  	    {
  	      "id": "g4",
  	      "image": "https://app.webmojo.io/api/files/web/Hyperpixels/images/Act Leasing Services Logo (No Box - On White - FINAL).png"
  	    },
  	    {
  	      "id": "g5",
  	      "image": "https://app.webmojo.io/api/files/web/Hyperpixels/images/Titanium Fitness Logo 2 (Final).png"
  	    },
  	    {
  	      "id": "g6",
  	      "image": "https://app.webmojo.io/api/files/web/Hyperpixels/images/ACCO Website.png"
  	    }
  	  ],
  	  "team": [
  	    {
  	      "id": "t1",
  	      "name": "Joan Doe",
  	      "career": "Founder - CEO",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo1.jpg",
  	      "socialMedia": {
  	        "social": [
  	          {
  	            "url": "#",
  	            "name": "facebook"
  	          },
  	          {
  	            "url": "#",
  	            "name": "github"
  	          },
  	          {
  	            "url": "#",
  	            "name": "linkedin"
  	          },
  	          {
  	            "url": "",
  	            "name": "twitter"
  	          }
  	        ]
  	      }
  	    },
  	    {
  	      "id": "t2",
  	      "name": "John Doe",
  	      "career": "Co-founder",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo2.jpg",
  	      "socialMedia": {
  	        "social": [
  	          {
  	            "url": "#",
  	            "name": "facebook"
  	          },
  	          {
  	            "url": "",
  	            "name": "github"
  	          },
  	          {
  	            "url": "",
  	            "name": "linkedin"
  	          },
  	          {
  	            "url": "#",
  	            "name": "twitter"
  	          }
  	        ]
  	      }
  	    },
  	    {
  	      "id": "t3",
  	      "name": "Joan Doe",
  	      "career": "Co-founder",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo3.jpg",
  	      "socialMedia": {
  	        "social": [
  	          {
  	            "url": "#",
  	            "name": "facebook"
  	          },
  	          {
  	            "url": "#",
  	            "name": "github"
  	          },
  	          {
  	            "url": "#",
  	            "name": "linkedin"
  	          },
  	          {
  	            "url": "",
  	            "name": "twitter"
  	          }
  	        ]
  	      }
  	    },
  	    {
  	      "id": "t4",
  	      "name": "John Doe",
  	      "career": "Financial Manager",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo4.jpg",
  	      "socialMedia": {
  	        "social": [
  	          {
  	            "url": "#",
  	            "name": "facebook"
  	          },
  	          {
  	            "url": "",
  	            "name": "github"
  	          },
  	          {
  	            "url": "#",
  	            "name": "linkedin"
  	          },
  	          {
  	            "url": "",
  	            "name": "twitter"
  	          }
  	        ]
  	      }
  	    }
  	  ],
  	  "testimonials": [
  	    {
  	      "id": "tm1",
  	      "author": "Jane Doe",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/testimonials/auth1.jpg"
  	    },
  	    {
  	      "id": "tm2",
  	      "author": "John Doe",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/testimonials/auth2.jpg"
  	    },
  	    {
  	      "id": "tm3",
  	      "author": "Jane Doe",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/testimonials/auth3.jpg"
  	    },
  	    {
  	      "id": "tm4",
  	      "author": "John Doe",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/testimonials/item4.jpg"
  	    }
  	  ]
  	}

  	return data
  })());
  galleryList = (getObjectProperty(appData, 'gallery'));
  ___arguments.context.appData['galleryList'] = galleryList;
  ___arguments.context.pageData['MessageSent'] = false;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('153bb1d13492b519dc0717b333c20947', testimonialsList);

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/home-tabs/components/fefec6b6560f11b1a4c62ed6ad39d36c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52')), 'height')) == 349) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 0;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 349;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home-tabs/components/153bb1d13492b519dc0717b333c20947/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
      return ((___arguments.context.appData['testimonialsInFocus']) ? ['testimonials__items-block', 'testimonials__items-block_in-focus'] : ['testimonials__items-block'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home-tabs/components/c376c79acf1e16de2463e38439c34656/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var item, leftSlideBy, left, newGalleryList, half, firstList, galleryList, lastList, idGalleryItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  await new Promise(r => setTimeout(r, 1000 || 0));
  galleryList = (getObjectProperty(___arguments.context.appData, 'galleryList'));
  idGalleryItem = 1;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c376c79acf1e16de2463e38439c34656', (await Promise.all(galleryList.map(async item => {  item['id'] = idGalleryItem;
    idGalleryItem = (typeof idGalleryItem == 'number' ? idGalleryItem : 0) + 1;
  ; return item;}))));
  ___arguments.context.appData['leftSlideBy'] = 240;
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 1199) {
    ___arguments.context.appData['leftSlideBy'] = 200;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 990) {
    ___arguments.context.appData['leftSlideBy'] = 260;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768) {
    ___arguments.context.appData['leftSlideBy'] = 370;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576) {
    ___arguments.context.appData['leftSlideBy'] = 250;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
    ___arguments.context.appData['leftSlideBy'] = 100;
  }
  half = Math.floor(galleryList.length / 2);
  lastList = galleryList.slice(((half + 1) - 1), galleryList.length);
  firstList = galleryList.slice(0, half);
  newGalleryList = (JSON.parse((JSON.stringify(((addItemToList(lastList, galleryList)).concat(firstList))))));
  leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
  ___arguments.context.pageData['leftStart'] = (-15 - half * leftSlideBy);
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 576) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 120);
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 480) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 130);
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 15);
  }
  left = (getObjectProperty(___arguments.context.pageData, 'leftStart'));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c376c79acf1e16de2463e38439c34656', (await Promise.all(newGalleryList.map(async item => {  item['opacity'] = 1;
    item['left'] = left;
    if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
      item['active'] = true;
    } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
      item['active'] = true;
    } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
      item['active'] = true;
    } else if (left == 0 && leftSlideBy == 100) {
      item['active'] = true;
    } else {
      item['active'] = false;
    }
    left = (typeof left == 'number' ? left : 0) + leftSlideBy;
  ; return item;}))));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/home-tabs/components/e23154e2b40dc5a8ac7f9a7e34ff980f/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 480) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e23154e2b40dc5a8ac7f9a7e34ff980f'))['left'] = (String(getObjectProperty(___arguments.context.dataModel, 'left')) + String('px'));
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e23154e2b40dc5a8ac7f9a7e34ff980f'))['left'] = (String(getObjectProperty(___arguments.context.dataModel, 'left')) + String('%'));
  }
  ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e23154e2b40dc5a8ac7f9a7e34ff980f'))['opacity'] = (getObjectProperty(___arguments.context.dataModel, 'opacity'));
  if (getObjectProperty(___arguments.context.dataModel, 'active')) {
    return ['g-slider__item', 'g-slider__item_center'];
  }

  return ['g-slider__item']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home-tabs/components/108f6ec347effdbce289a49d625d03af/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['isOpenModal'] = true;
  ___arguments.context.pageData['counterSlide2'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e23154e2b40dc5a8ac7f9a7e34ff980f'), 'id'));
  ___arguments.context.pageData['currentSlide'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e23154e2b40dc5a8ac7f9a7e34ff980f'), 'id'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home-tabs/components/26a1309c25831a8af67d5fe98587c773/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    var leftSlideBy, minValue, card, left, j, maxValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  maxValue = 0;
  minValue = 0;
  leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
  if ((typeof (getObjectProperty(___arguments.context.pageData, 'stopClick')) === 'undefined') || ((new Date()).valueOf()) > (getObjectProperty(___arguments.context.pageData, 'stopClick')) + 300) {
    ___arguments.context.pageData['stopClick'] = ((new Date()).valueOf());
    var j_list = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('c376c79acf1e16de2463e38439c34656'));
    for (var j_index in j_list) {
      j = j_list[j_index];
      j['opacity'] = 1;
      j['left'] = ((getObjectProperty(j, 'left')) + leftSlideBy);
      left = (getObjectProperty(j, 'left'));
      if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
        j['active'] = true;
      } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
        j['active'] = true;
      } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
        j['active'] = true;
      } else if (left == 0 && leftSlideBy == 100) {
        j['active'] = true;
      } else {
        j['active'] = false;
      }
      if (maxValue < left) {
        maxValue = left;
        card = j;
      }
      if (minValue > left) {
        minValue = left;
      }
    }
    card['opacity'] = 0;
    card['left'] = (minValue - leftSlideBy);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home-tabs/components/0ecc200a34ec908bf97bef91588de587/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var leftSlideBy, maxValue, card, left, j, minValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  maxValue = 0;
  minValue = 0;
  leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
  if ((typeof (getObjectProperty(___arguments.context.pageData, 'stopClick')) === 'undefined') || ((new Date()).valueOf()) > (getObjectProperty(___arguments.context.pageData, 'stopClick')) + 300) {
    ___arguments.context.pageData['stopClick'] = ((new Date()).valueOf());
    var j_list = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('c376c79acf1e16de2463e38439c34656'));
    for (var j_index in j_list) {
      j = j_list[j_index];
      j['left'] = ((getObjectProperty(j, 'left')) - leftSlideBy);
      left = (getObjectProperty(j, 'left'));
      j['opacity'] = 1;
      if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
        j['active'] = true;
      } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
        j['active'] = true;
      } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
        j['active'] = true;
      } else if (left == 0 && leftSlideBy == 100) {
        j['active'] = true;
      } else {
        j['active'] = false;
      }
      if (minValue > left) {
        minValue = left;
        card = j;
      }
      if (maxValue < left) {
        maxValue = left;
      }
    }
    card['opacity'] = 0;
    card['left'] = (maxValue + leftSlideBy);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home-tabs/components/4bc8650348b45a9267097b43aff505c6/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('4bc8650348b45a9267097b43aff505c6', (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e5ac9c8ad984738efc349d07b4dc74b1'), 'socialMedia')), 'social')));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/home-tabs/components/1fdde5c76ea432e90eb67a18533b2dac/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes, nameSocial;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['team-card__link', 'link'];
  nameSocial = (getObjectProperty(___arguments.context.dataModel, 'name'));
  if ((getObjectProperty(___arguments.context.dataModel, 'url')) == '') {
    addItemToList(classes, 'team-card__link_hide');
  }
  if (nameSocial == 'facebook') {
    addItemToList(classes, 'fab fa-facebook-f');
  }
  if (nameSocial == 'github') {
    addItemToList(classes, 'fab fa-github');
  }
  if (nameSocial == 'linkedin') {
    addItemToList(classes, 'fab fa-linkedin-in');
  }
  if (nameSocial == 'twitter') {
    addItemToList(classes, 'fab fa-twitter');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home-tabs/components/b6f27fbafe317fe16572dfeaf1fb0698/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
      return ((___arguments.context.appData['teamInFocus']) ? ['team__cards-block', 'team__cards-block_in-focus'] : ['team__cards-block'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home-tabs/components/d2551aa8d85f0b8224661c4aaaa53f32/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var firstName, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  firstName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName'));
  regExp = (new RegExp('.{2,}', 'g'));
  if (firstName.length <= 50) {
    ___arguments.context.pageData['firstNameValue'] = firstName;
  } else if (firstName.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['firstName'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['firstName'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'firstNameValue')), 0));
  }
  if ((regExp.test(firstName)) == false && firstName != '' || firstName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length < 2 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = false;
  } else if ((regExp.test(firstName)) == false && firstName == '' || firstName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length == 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/home-tabs/components/3ddf6adfe3e580cc962a62234bf2e2d5/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-first-name'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home-tabs/components/f5bd0a2c6f0bde798ea8df133e9e7e3c/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var lastName, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  lastName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName'));
  regExp = (new RegExp('.{2,}', 'g'));
  if (lastName.length <= 50) {
    ___arguments.context.pageData['lastNameValue'] = lastName;
  } else if (lastName.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['lastName'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['lastName'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'lastNameValue')), 0));
  }
  if ((regExp.test(lastName)) == false && lastName != '' || lastName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length < 2 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = false;
  } else if ((regExp.test(lastName)) == false && lastName == '' || lastName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length == 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/home-tabs/components/557e31a13073b4848ca61837b9e70a38/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-last-name'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home-tabs/components/cdc2459c0f1ac842997b621a04744c0c/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var email, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  email = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'email'));
  regExp = (new RegExp('[a-zA-Z0-9.\\-_+]{1,}@[^\\W\\.][a-zA-Z0-9\\-]{0,}\\.[a-zA-Z]{1,}', 'g'));
  if (email.length <= 50) {
    ___arguments.context.pageData['emailValue'] = email;
  } else if (email.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['email'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['email'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'emailValue')), 0));
  }
  if ((regExp.test(email)) == false && email != '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = false;
  } else if ((regExp.test(email)) == false && email == '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/home-tabs/components/556317981b853c56d8e8e837e5b16c9e/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-email'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home-tabs/components/d64247a69fb82b1b932f6a1191167d68/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-phone'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home-tabs/components/1712009c62182acf71f1e4e777ac98dd/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var phone, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  phone = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone'));
  regExp = (new RegExp('^[0-9+\\(\\)\\-\\s]+$', 'g'));
  if (phone.length <= 50) {
    ___arguments.context.pageData['phoneValue'] = phone;
  } else if (phone.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['phone'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['phone'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'phoneValue')), 0));
  }
  if (phone.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone')).length < 5 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = true;
  } else if ((regExp.test(phone)) && phone.length >= 5) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = false;
  } else if (phone == '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = true;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/home-tabs/components/275519e4f1ff2a5c0d556bd7bfaaf6c5/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-website'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home-tabs/components/ddb987ac0b1a2e02086b043645e7decd/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var website, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  website = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website'));
  regExp = (new RegExp('[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)', 'gi'));
  if (website.length <= 249) {
    ___arguments.context.pageData['websiteValue'] = website;
  } else if (website.length > 249) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['website'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['website'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'websiteValue')), 0));
  }
  if ((regExp.test(website)) == false && website != '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = true;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = false;
  }
  if ((regExp.test((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website')))) == true && website.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website')).length) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/home-tabs/components/b17c55c60f4dcdf2ae8fc66b95b63932/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var subject, regExp, firstName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  subject = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject'));
  regExp = (new RegExp('.{4,}', 'g'));
  if (subject.length <= 300) {
    ___arguments.context.pageData['subjectValue'] = subject;
  } else if (subject.length > 300) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['subject'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['subject'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'subjectValue')), 0));
  }
  if ((regExp.test(subject)) == false && subject != '' || subject.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length < 4 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = false;
  } else if ((regExp.test(subject)) == false && subject == '' || subject.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length == 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/home-tabs/components/a38dcbb7299151a66062e5a8eaaf984c/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-subject'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home-tabs/components/101b130a0c5ff2588aa5637fc5fcdb27/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-message'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage']) {
    addItemToList(classes, 'c-form__label_alert');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home-tabs/components/6eb697a67eb600c29f860957a2ac3b3e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, message, subject, website, phone, email, lastName, firstName, checkStatusMessage, checkStatusSubject, checkStatusWebsite, checkStatusPhone, checkStatusEmail, checkStatusLastName, checkStatusFirstName, statusMessage, statusSubject, statusWebsite, statusPhone, statusEmail, statusLastName, statusFirstName, company, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function ensureArray(items) {
  return Array.isArray(items) ? items : [items]
}

/**
 * Describe this function...
 */
async function firstNameValidation() {
  regExp = (new RegExp('.{2,}', 'g'));
  if (!(regExp.test(firstName)) || firstName == '' || firstName == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusFirsName() {
  if (!(getObjectProperty(statusFirstName, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('d2551aa8d85f0b8224661c4aaaa53f32')));
    if (firstName == '' || firstName == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function lastNameValidation() {
  regExp = (new RegExp('.{2,}', 'g'));
  if (!(regExp.test(lastName)) || lastName == '' || lastName == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusLastName2() {
  if (!(getObjectProperty(statusLastName, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f5bd0a2c6f0bde798ea8df133e9e7e3c')));
    if (lastName == '' || lastName == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function emailValidation() {
  regExp = (new RegExp('[a-zA-Z0-9.\\-_+]{1,}@[^\\W\\.][a-zA-Z0-9\\-]{0,}\\.[a-zA-Z]{1,}', 'g'));
  if (!(regExp.test(email)) || email == '' || email == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function phoneValidation() {
  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'hintPhone')) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusEmail2() {
  if (!(getObjectProperty(statusEmail, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('cdc2459c0f1ac842997b621a04744c0c')));
    if (email == '' || email == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function websiteValidation() {
  regExp = (new RegExp('[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)', 'gi'));
  if (!(regExp.test(website)) && website != '' && website != null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function subjectValidation() {
  regExp = (new RegExp('.{4,}', 'g'));
  if (!(regExp.test(subject)) || subject == '' || subject == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusPhone2() {
  if (!(getObjectProperty(statusPhone, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('1712009c62182acf71f1e4e777ac98dd')));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = true;
    return false;
  }
  return true
}

/**
 * Describe this function...
 */
async function messageValidation() {
  if (message == '' || message == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusWebsite2() {
  if (!(getObjectProperty(statusWebsite, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('ddb987ac0b1a2e02086b043645e7decd')));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = true;
    return false;
  }
  return true
}

/**
 * Describe this function...
 */
async function checkStatusSubject2() {
  if (!(getObjectProperty(statusSubject, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b17c55c60f4dcdf2ae8fc66b95b63932')));
    if (subject == '' || subject == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function checkStatusMessage2() {
  if (!(getObjectProperty(statusMessage, 'status'))) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage'] = true;
    return false;
  }
  return true
}


  firstName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName'));
  lastName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName'));
  email = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'email'));
  phone = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone'));
  company = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'company'));
  website = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website'));
  subject = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject'));
  message = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'message'));
  statusFirstName = await firstNameValidation();
  statusLastName = await lastNameValidation();
  statusEmail = await emailValidation();
  statusPhone = await phoneValidation();
  statusWebsite = await websiteValidation();
  statusSubject = await subjectValidation();
  statusMessage = await messageValidation();
  checkStatusMessage = await checkStatusMessage2();
  checkStatusSubject = await checkStatusSubject2();
  checkStatusWebsite = await checkStatusWebsite2();
  checkStatusPhone = await checkStatusPhone2();
  checkStatusEmail = await checkStatusEmail2();
  checkStatusLastName = await checkStatusLastName2();
  checkStatusFirstName = await checkStatusFirsName();
  if (checkStatusFirstName != false && checkStatusLastName != false && checkStatusEmail != false && checkStatusPhone != false && checkStatusWebsite != false && checkStatusSubject != false && checkStatusMessage != false) {
    await Backendless.Messaging.sendEmail('New contact from the Hyperpixels Website', new Backendless.Bodyparts({ htmlmessage: ([['From: ',firstName,' ',lastName].join(''),'<br>Email: ',email,'<br> Phone: ',phone,'<br> Website: ',website,'<br> Subject: ',subject,'<br> Message: <br>',message].join('')) }), ensureArray('mike@hyperpixels.net'), undefined);
    ___arguments.context.pageData['MessageSent'] = true;
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b65aa795e4d6a994b3ddd6f68533c80d', false);
    try {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('df8b067a885dcda84a91376a3c4c5c7c', true);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7224592bf6676a81e607676ddbc597e1', false);

    } catch (error) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('df8b067a885dcda84a91376a3c4c5c7c', false);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7224592bf6676a81e607676ddbc597e1', true);
      console.log(error);

    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home-tabs/components/58c7b83b0c67da4ff3bef93451af2162/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var message, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  message = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'message'));
  regExp = (new RegExp('.{1,}', 'g'));
  if (message.length <= 249) {
    ___arguments.context.pageData['messageValue'] = message;
  } else if (message.length > 249) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['message'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['message'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'messageValue')), 0));
  }
  if ((regExp.test(message)) == false && message == '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage'] = true;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/home-tabs/components/dc8e2162b0174608379cfba7549be8db/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home-tabs/components/251dd247af0d2f6ebe6ccde3866c89b5/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isOpenModal')) {
    return ['modal', 'open'];
  }

  return ['modal']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home-tabs/components/70dd1dff3e215deab9dcc3e29a6566e6/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async onMounted(___arguments) {
    var counter, data, gallery, item, listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setIdItem(data) {
  counter = 1;
  return await Promise.all(data.map(async item => {  item['id'] = counter;
  counter = (typeof counter == 'number' ? counter : 0) + 1;
; return item;}))
}


  await new Promise(r => setTimeout(r, 1000 || 0));
  gallery = (getObjectProperty(___arguments.context.appData, 'galleryList'));
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', (await setIdItem(gallery)));
  listLength = gallery.length;
  ___arguments.context.pageData['totalSlide'] = listLength;
  ___arguments.context.pageData['currentSlide'] = 1;

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/home-tabs/components/bb37f7327e62a50eb06507a61d6d1fc6/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == (getObjectProperty(___arguments.context.dataModel, 'id'))) {
    return ['modal__slider-item', 'active'];
  }

  return ['modal__slider-item']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home-tabs/components/831fddd4bb7ced9296de1aa550213577/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) > 1) {
    ___arguments.context.pageData['counterSlide2'] = ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == 1 ? listLength : (getObjectProperty(___arguments.context.pageData, 'counterSlide2')) - 1);
  }
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ___arguments.context.pageData['currentSlide'] = (getObjectProperty(___arguments.context.pageData, 'counterSlide2'));

  },
  /* handler:onClick */
  /* handler:onMouseOut */
  onMouseOut(___arguments) {
    var listLength;


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('831fddd4bb7ced9296de1aa550213577'))['opacity'] = '0';

  },
  /* handler:onMouseOut */
  /* handler:onMouseOver */
  onMouseOver(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == 1) {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('831fddd4bb7ced9296de1aa550213577'))['opacity'] = '0';
  } else {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('831fddd4bb7ced9296de1aa550213577'))['opacity'] = '1';
  }

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./pages/home-tabs/components/dba21793ecfc81f1424a1ce6ad99a8b3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) < listLength) {
    ___arguments.context.pageData['counterSlide2'] = ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == listLength ? 1 : (getObjectProperty(___arguments.context.pageData, 'counterSlide2')) + 1);
  }
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ___arguments.context.pageData['currentSlide'] = (getObjectProperty(___arguments.context.pageData, 'counterSlide2'));

  },
  /* handler:onClick */
  /* handler:onMouseOut */
  onMouseOut(___arguments) {
    var listLength;


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('dba21793ecfc81f1424a1ce6ad99a8b3'))['opacity'] = '0';

  },
  /* handler:onMouseOut */
  /* handler:onMouseOver */
  onMouseOver(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == listLength) {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('dba21793ecfc81f1424a1ce6ad99a8b3'))['opacity'] = '0';
  } else {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('dba21793ecfc81f1424a1ce6ad99a8b3'))['opacity'] = '1';
  }

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./pages/home-tabs/components/31c87ba294176e587b91b87c58e68a82/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home-tabs/components/16b9cb3fdc9bf1919465a18c77a6e467/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home-tabs/components/3607af0d8419be43455fc4ac16f9cc33/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var company;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  company = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'company'));
  if (company.length <= 100) {
    ___arguments.context.pageData['companyValue'] = company;
  } else if (company.length > 100) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['company'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['company'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'companyValue')), 0));
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/home-tabs/components/30ea90dd05af67628fd821d5003c3d3d/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  onMounted(___arguments) {
      ___arguments.context.pageData['firstNameValue'] = '';
  ___arguments.context.pageData['lastNameValue'] = '';
  ___arguments.context.pageData['emailValue'] = '';
  ___arguments.context.pageData['phoneValue'] = '';
  ___arguments.context.pageData['companyValue'] = '';
  ___arguments.context.pageData['websiteValue'] = '';
  ___arguments.context.pageData['subjectValue'] = '';
  ___arguments.context.pageData['messageValue'] = '';

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/home-tabs/components/ba557046fb5348f79a8a94c342388c04/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'MessageSent'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/home-tabs/components/2d06b571aa5bcd13df8e8426dd1c5849/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2d06b571aa5bcd13df8e8426dd1c5849'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2d06b571aa5bcd13df8e8426dd1c5849'))['color'] = 'white';

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/home-tabs/components/14e3a2a815e855eaa285739b916320ed/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  async ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('2d06b571aa5bcd13df8e8426dd1c5849'))['text'] = 'hello@hyperpixels.net';
  await (async function() {
  	var a = document.getElementsByClassName('emailuslink'); //or grab it by tagname etc
  	a[0].href = "mailto:hello@hyperpixels.net"
  })();

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  async ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('2d06b571aa5bcd13df8e8426dd1c5849'))['text'] = 'Email Us';
  await (async function() {
  	var a = document.getElementsByClassName('emailuslink'); //or grab it by tagname etc
  	a[0].href = "mailto:undefined"
  })();

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/home-tabs/components/fbec563ee0737561ca883dbb0bb2554d/bundle.js', [], () => ({
  /* content */
  /* handler:tabsLogic */
  ['tabsLogic'](___arguments) {
      return [({ [`id`]: 'tab1',[`label`]: 'Website Design' }), ({ [`id`]: 'tab2',[`label`]: 'Full Stack Development' }), ({ [`id`]: 'tab3',[`label`]: 'Full Stack Development' })]

  },
  /* handler:tabsLogic */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentTab')) == 'tab1') {
    return ['tab', 'current'];
  }

  return ['tab']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/home-tabs/components/9534f7583b941f558643482d817d9d9e/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
      return ((await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getCurrentTabId() })('fbec563ee0737561ca883dbb0bb2554d')) == 'tab1')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/home-tabs/components/654fa0c4c6c43c4bbccd37c6ce53f578/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
      return ((await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getCurrentTabId() })('fbec563ee0737561ca883dbb0bb2554d')) == 'tab1')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/home-tabs/components/db2eded9d93a8dda16ba75bcc553a55a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
      return ((await ( async function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).getCurrentTabId() })('fbec563ee0737561ca883dbb0bb2554d')) == 'tab3')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/homebackup/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onResize */
  ['onResize'](___arguments) {
    var leftSlideBy, left, j, half, galleryList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 990) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 0;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 'auto';
  }
  galleryList = (getObjectProperty(___arguments.context.appData, 'galleryList'));
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) >= 1200) {
    ___arguments.context.appData['leftSlideBy'] = 240;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 1199) {
    ___arguments.context.appData['leftSlideBy'] = 200;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 990) {
    ___arguments.context.appData['leftSlideBy'] = 260;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768) {
    ___arguments.context.appData['leftSlideBy'] = 370;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576) {
    ___arguments.context.appData['leftSlideBy'] = 250;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
    ___arguments.context.appData['leftSlideBy'] = 100;
  }
  leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
  half = Math.floor(galleryList.length / 2);
  ___arguments.context.pageData['leftStart'] = (-15 - half * leftSlideBy);
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 576) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 120);
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 480) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 130);
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 15);
  }
  left = (getObjectProperty(___arguments.context.pageData, 'leftStart'));
  var j_list = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('c376c79acf1e16de2463e38439c34656'));
  for (var j_index in j_list) {
    j = j_list[j_index];
    j['opacity'] = 1;
    j['left'] = left;
    if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
      j['active'] = true;
    } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
      j['active'] = true;
    } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
      j['active'] = true;
    } else if (left == 0 && leftSlideBy == 100) {
      j['active'] = true;
    } else {
      j['active'] = false;
    }
    left = (typeof left == 'number' ? left : 0) + leftSlideBy;
  }

  },
  /* handler:onResize */
  /* handler:onScroll */
  async ['onScroll'](___arguments) {
    var a, item, team, gallery, home2, features, testimonials, contact, topOnViewport, header, elem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function topOnViewport2(elem, header) {
  topOnViewport = (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', elem)) - ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight'))) + (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) - ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', header)));
  return topOnViewport
}


  contact = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0ee4f7a2f9f93096442f303878b88406')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  team = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  gallery = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  testimonials = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  features = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  home2 = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  if (___arguments.scrollTop - ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) < home2) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b'))))) < features && ___arguments.scrollTop > home2) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < testimonials && ___arguments.scrollTop > features + home2) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < gallery && ___arguments.scrollTop > testimonials + features + home2) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < team && ___arguments.scrollTop > testimonials + features + home2 + gallery) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < contact && ___arguments.scrollTop > testimonials + features + home2 + gallery + team) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0ee4f7a2f9f93096442f303878b88406')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop > ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) / 3) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('f5f6c87f71d4ee4f6b9b334b92f88f31'))['opacity'] = 0.3;
  } else if (___arguments.scrollTop > ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) / 10) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('f5f6c87f71d4ee4f6b9b334b92f88f31'))['opacity'] = 0.5;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('f5f6c87f71d4ee4f6b9b334b92f88f31'))['opacity'] = 1;
  }
  if (___arguments.scrollTop) {
    ___arguments.context.pageData['scrollTop'] = true;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['box-shadow'] = '0px 2px 4px rgba(0, 0, 0, 0.1)';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['height'] = '60px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-top'] = '7px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '-5px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '45px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#777777';
  } else {
    ___arguments.context.pageData['scrollTop'] = false;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['box-shadow'] = 'none';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['height'] = '80px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['background-color'] = 'transparent';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-top'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '80px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#ffffff';
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 400) {
    a = 150;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 500) {
    a = (typeof a == 'number' ? a : 0) + 200;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 600) {
    a = (typeof a == 'number' ? a : 0) + 300;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 700) {
    a = (typeof a == 'number' ? a : 0) + 400;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 800) {
    a = (typeof a == 'number' ? a : 0) + 500;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 900) {
    a = (typeof a == 'number' ? a : 0) + 600;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 1050) {
    a = (typeof a == 'number' ? a : 0) + 700;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 990 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 1050) {
    if (___arguments.scrollTop >= ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) - a)) {
      ___arguments.context.appData['featuresInFocus'] = true;
    }
    if (___arguments.scrollTop >= ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) - a)) {
      ___arguments.context.appData['testimonialsInFocus'] = true;
    }
    if (___arguments.scrollTop >= ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')))) - a)) {
      ___arguments.context.appData['teamInFocus'] = true;
    }
  } else {
    ___arguments.context.appData['featuresInFocus'] = true;
    ___arguments.context.appData['testimonialsInFocus'] = true;
    ___arguments.context.appData['teamInFocus'] = true;
  }

  },
  /* handler:onScroll */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var teamList, testimonialsList, featuresList, appData, galleryList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  appData = (await (async function() {
  	const data = {
  	  "features": [
  	    {
  	      "id": "f1",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-1.jpg",
  	      "title": "Amazing Experience",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f2",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-2.jpg",
  	      "title": "24/7 Support",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f3",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-3.jpg",
  	      "title": "Real-time",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f4",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-4.jpg",
  	      "title": "Easy To Use",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f5",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-5.jpg",
  	      "title": "Free Updates",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f6",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-6.jpg",
  	      "title": "All Platforms",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    }
  	  ],
  	  "gallery": [
  	    {
  	      "id": "g1",
  	      "image": "https://backendless.com/images/Fresh/dbImg/gallery/item1.jpg"
  	    },
  	    {
  	      "id": "g2",
  	      "image": "https://backendless.com/images/Fresh/dbImg/gallery/item2.jpg"
  	    },
  	    {
  	      "id": "g3",
  	      "image": "https://backendless.com/images/Fresh/dbImg/gallery/item3.jpg"
  	    },
  	    {
  	      "id": "g4",
  	      "image": "https://backendless.com/images/Fresh/dbImg/gallery/item4.jpg"
  	    },
  	    {
  	      "id": "g5",
  	      "image": "https://backendless.com/images/Fresh/dbImg/gallery/item5.jpg"
  	    },
  	    {
  	      "id": "g6",
  	      "image": "https://backendless.com/images/Fresh/dbImg/gallery/item6.jpg"
  	    }
  	  ],
  	  "team": [
  	    {
  	      "id": "t1",
  	      "name": "Joan Doe",
  	      "career": "Founder - CEO",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo1.jpg",
  	      "socialMedia": {
  	        "social": [
  	          {
  	            "url": "#",
  	            "name": "facebook"
  	          },
  	          {
  	            "url": "#",
  	            "name": "github"
  	          },
  	          {
  	            "url": "#",
  	            "name": "linkedin"
  	          },
  	          {
  	            "url": "",
  	            "name": "twitter"
  	          }
  	        ]
  	      }
  	    },
  	    {
  	      "id": "t2",
  	      "name": "John Doe",
  	      "career": "Co-founder",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo2.jpg",
  	      "socialMedia": {
  	        "social": [
  	          {
  	            "url": "#",
  	            "name": "facebook"
  	          },
  	          {
  	            "url": "",
  	            "name": "github"
  	          },
  	          {
  	            "url": "",
  	            "name": "linkedin"
  	          },
  	          {
  	            "url": "#",
  	            "name": "twitter"
  	          }
  	        ]
  	      }
  	    },
  	    {
  	      "id": "t3",
  	      "name": "Joan Doe",
  	      "career": "Co-founder",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo3.jpg",
  	      "socialMedia": {
  	        "social": [
  	          {
  	            "url": "#",
  	            "name": "facebook"
  	          },
  	          {
  	            "url": "#",
  	            "name": "github"
  	          },
  	          {
  	            "url": "#",
  	            "name": "linkedin"
  	          },
  	          {
  	            "url": "",
  	            "name": "twitter"
  	          }
  	        ]
  	      }
  	    },
  	    {
  	      "id": "t4",
  	      "name": "John Doe",
  	      "career": "Financial Manager",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo4.jpg",
  	      "socialMedia": {
  	        "social": [
  	          {
  	            "url": "#",
  	            "name": "facebook"
  	          },
  	          {
  	            "url": "",
  	            "name": "github"
  	          },
  	          {
  	            "url": "#",
  	            "name": "linkedin"
  	          },
  	          {
  	            "url": "",
  	            "name": "twitter"
  	          }
  	        ]
  	      }
  	    }
  	  ],
  	  "testimonials": [
  	    {
  	      "id": "tm1",
  	      "author": "Jane Doe",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/testimonials/auth1.jpg"
  	    },
  	    {
  	      "id": "tm2",
  	      "author": "John Doe",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/testimonials/auth2.jpg"
  	    },
  	    {
  	      "id": "tm3",
  	      "author": "Jane Doe",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/testimonials/auth3.jpg"
  	    },
  	    {
  	      "id": "tm4",
  	      "author": "John Doe",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/testimonials/item4.jpg"
  	    }
  	  ]
  	}

  	return data
  })());
  featuresList = (getObjectProperty(appData, 'features'));
  galleryList = (getObjectProperty(appData, 'gallery'));
  ___arguments.context.appData['galleryList'] = galleryList;
  teamList = (getObjectProperty(appData, 'team'));
  testimonialsList = (getObjectProperty(appData, 'testimonials'));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('31b2f36e83dd37c11b5e73f81d1bfac9', featuresList);
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('153bb1d13492b519dc0717b333c20947', testimonialsList);
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('b6f27fbafe317fe16572dfeaf1fb0698', teamList);

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/homebackup/components/31b2f36e83dd37c11b5e73f81d1bfac9/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
      return ((___arguments.context.appData['featuresInFocus']) ? ['features__items-block', 'features__items-block_in-focus'] : ['features__items-block'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/homebackup/components/153bb1d13492b519dc0717b333c20947/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
      return ((___arguments.context.appData['testimonialsInFocus']) ? ['testimonials__items-block', 'testimonials__items-block_in-focus'] : ['testimonials__items-block'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/homebackup/components/c376c79acf1e16de2463e38439c34656/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var item, leftSlideBy, left, newGalleryList, half, firstList, galleryList, lastList, idGalleryItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  await new Promise(r => setTimeout(r, 1000 || 0));
  galleryList = (getObjectProperty(___arguments.context.appData, 'galleryList'));
  idGalleryItem = 1;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c376c79acf1e16de2463e38439c34656', (await Promise.all(galleryList.map(async item => {  item['id'] = idGalleryItem;
    idGalleryItem = (typeof idGalleryItem == 'number' ? idGalleryItem : 0) + 1;
  ; return item;}))));
  ___arguments.context.appData['leftSlideBy'] = 240;
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 1199) {
    ___arguments.context.appData['leftSlideBy'] = 200;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 990) {
    ___arguments.context.appData['leftSlideBy'] = 260;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768) {
    ___arguments.context.appData['leftSlideBy'] = 370;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576) {
    ___arguments.context.appData['leftSlideBy'] = 250;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
    ___arguments.context.appData['leftSlideBy'] = 100;
  }
  half = Math.floor(galleryList.length / 2);
  lastList = galleryList.slice(((half + 1) - 1), galleryList.length);
  firstList = galleryList.slice(0, half);
  newGalleryList = (JSON.parse((JSON.stringify(((addItemToList(lastList, galleryList)).concat(firstList))))));
  leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
  ___arguments.context.pageData['leftStart'] = (-15 - half * leftSlideBy);
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 576) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 120);
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 480) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 130);
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 15);
  }
  left = (getObjectProperty(___arguments.context.pageData, 'leftStart'));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c376c79acf1e16de2463e38439c34656', (await Promise.all(newGalleryList.map(async item => {  item['opacity'] = 1;
    item['left'] = left;
    if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
      item['active'] = true;
    } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
      item['active'] = true;
    } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
      item['active'] = true;
    } else if (left == 0 && leftSlideBy == 100) {
      item['active'] = true;
    } else {
      item['active'] = false;
    }
    left = (typeof left == 'number' ? left : 0) + leftSlideBy;
  ; return item;}))));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/homebackup/components/e23154e2b40dc5a8ac7f9a7e34ff980f/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  async onClassListAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 480) {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('e23154e2b40dc5a8ac7f9a7e34ff980f'))['left'] = (String(getObjectProperty(___arguments.context.dataModel, 'left')) + String('px'));
  } else {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('e23154e2b40dc5a8ac7f9a7e34ff980f'))['left'] = (String(getObjectProperty(___arguments.context.dataModel, 'left')) + String('%'));
  }
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('e23154e2b40dc5a8ac7f9a7e34ff980f'))['opacity'] = (getObjectProperty(___arguments.context.dataModel, 'opacity'));
  if (getObjectProperty(___arguments.context.dataModel, 'active')) {
    return ['g-slider__item', 'g-slider__item_center'];
  }

  return ['g-slider__item']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/homebackup/components/108f6ec347effdbce289a49d625d03af/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['isOpenModal'] = true;
  ___arguments.context.pageData['counterSlide2'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e23154e2b40dc5a8ac7f9a7e34ff980f'), 'id'));
  ___arguments.context.pageData['currentSlide'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e23154e2b40dc5a8ac7f9a7e34ff980f'), 'id'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/homebackup/components/26a1309c25831a8af67d5fe98587c773/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    var leftSlideBy, minValue, card, left, j, maxValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  maxValue = 0;
  minValue = 0;
  leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
  if ((typeof (getObjectProperty(___arguments.context.pageData, 'stopClick')) === 'undefined') || ((new Date()).valueOf()) > (getObjectProperty(___arguments.context.pageData, 'stopClick')) + 300) {
    ___arguments.context.pageData['stopClick'] = ((new Date()).valueOf());
    var j_list = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('c376c79acf1e16de2463e38439c34656'));
    for (var j_index in j_list) {
      j = j_list[j_index];
      j['opacity'] = 1;
      j['left'] = ((getObjectProperty(j, 'left')) + leftSlideBy);
      left = (getObjectProperty(j, 'left'));
      if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
        j['active'] = true;
      } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
        j['active'] = true;
      } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
        j['active'] = true;
      } else if (left == 0 && leftSlideBy == 100) {
        j['active'] = true;
      } else {
        j['active'] = false;
      }
      if (maxValue < left) {
        maxValue = left;
        card = j;
      }
      if (minValue > left) {
        minValue = left;
      }
    }
    card['opacity'] = 0;
    card['left'] = (minValue - leftSlideBy);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/homebackup/components/0ecc200a34ec908bf97bef91588de587/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var leftSlideBy, maxValue, card, left, j, minValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  maxValue = 0;
  minValue = 0;
  leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
  if ((typeof (getObjectProperty(___arguments.context.pageData, 'stopClick')) === 'undefined') || ((new Date()).valueOf()) > (getObjectProperty(___arguments.context.pageData, 'stopClick')) + 300) {
    ___arguments.context.pageData['stopClick'] = ((new Date()).valueOf());
    var j_list = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('c376c79acf1e16de2463e38439c34656'));
    for (var j_index in j_list) {
      j = j_list[j_index];
      j['left'] = ((getObjectProperty(j, 'left')) - leftSlideBy);
      left = (getObjectProperty(j, 'left'));
      j['opacity'] = 1;
      if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
        j['active'] = true;
      } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
        j['active'] = true;
      } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
        j['active'] = true;
      } else if (left == 0 && leftSlideBy == 100) {
        j['active'] = true;
      } else {
        j['active'] = false;
      }
      if (minValue > left) {
        minValue = left;
        card = j;
      }
      if (maxValue < left) {
        maxValue = left;
      }
    }
    card['opacity'] = 0;
    card['left'] = (maxValue + leftSlideBy);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/homebackup/components/4bc8650348b45a9267097b43aff505c6/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('4bc8650348b45a9267097b43aff505c6', (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e5ac9c8ad984738efc349d07b4dc74b1'), 'socialMedia')), 'social')));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/homebackup/components/1fdde5c76ea432e90eb67a18533b2dac/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes, nameSocial;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['team-card__link', 'link'];
  nameSocial = (getObjectProperty(___arguments.context.dataModel, 'name'));
  if ((getObjectProperty(___arguments.context.dataModel, 'url')) == '') {
    addItemToList(classes, 'team-card__link_hide');
  }
  if (nameSocial == 'facebook') {
    addItemToList(classes, 'fab fa-facebook-f');
  }
  if (nameSocial == 'github') {
    addItemToList(classes, 'fab fa-github');
  }
  if (nameSocial == 'linkedin') {
    addItemToList(classes, 'fab fa-linkedin-in');
  }
  if (nameSocial == 'twitter') {
    addItemToList(classes, 'fab fa-twitter');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/homebackup/components/b6f27fbafe317fe16572dfeaf1fb0698/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
      return ((___arguments.context.appData['teamInFocus']) ? ['team__cards-block', 'team__cards-block_in-focus'] : ['team__cards-block'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/homebackup/components/d2551aa8d85f0b8224661c4aaaa53f32/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var firstName, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  firstName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName'));
  regExp = (new RegExp('.{2,}', 'g'));
  if (firstName.length <= 50) {
    ___arguments.context.pageData['firstNameValue'] = firstName;
  } else if (firstName.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['firstName'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['firstName'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'firstNameValue')), 0));
  }
  if ((regExp.test(firstName)) == false && firstName != '' || firstName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length < 2 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = false;
  } else if ((regExp.test(firstName)) == false && firstName == '' || firstName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length == 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/homebackup/components/3ddf6adfe3e580cc962a62234bf2e2d5/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-first-name'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/homebackup/components/f5bd0a2c6f0bde798ea8df133e9e7e3c/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var lastName, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  lastName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName'));
  regExp = (new RegExp('.{2,}', 'g'));
  if (lastName.length <= 50) {
    ___arguments.context.pageData['lastNameValue'] = lastName;
  } else if (lastName.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['lastName'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['lastName'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'lastNameValue')), 0));
  }
  if ((regExp.test(lastName)) == false && lastName != '' || lastName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length < 2 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = false;
  } else if ((regExp.test(lastName)) == false && lastName == '' || lastName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length == 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/homebackup/components/557e31a13073b4848ca61837b9e70a38/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-last-name'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/homebackup/components/cdc2459c0f1ac842997b621a04744c0c/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var email, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  email = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'email'));
  regExp = (new RegExp('[a-zA-Z0-9.\\-_+]{1,}@[^\\W\\.][a-zA-Z0-9\\-]{0,}\\.[a-zA-Z]{1,}', 'g'));
  if (email.length <= 50) {
    ___arguments.context.pageData['emailValue'] = email;
  } else if (email.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['email'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['email'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'emailValue')), 0));
  }
  if ((regExp.test(email)) == false && email != '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = false;
  } else if ((regExp.test(email)) == false && email == '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/homebackup/components/556317981b853c56d8e8e837e5b16c9e/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-email'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/homebackup/components/d64247a69fb82b1b932f6a1191167d68/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-phone'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/homebackup/components/1712009c62182acf71f1e4e777ac98dd/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var phone, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  phone = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone'));
  regExp = (new RegExp('^[0-9+\\(\\)\\-\\s]+$', 'g'));
  if (phone.length <= 50) {
    ___arguments.context.pageData['phoneValue'] = phone;
  } else if (phone.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['phone'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['phone'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'phoneValue')), 0));
  }
  if (phone.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone')).length < 5 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = true;
  } else if ((regExp.test(phone)) && phone.length >= 5) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = false;
  } else if (phone == '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = true;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/homebackup/components/275519e4f1ff2a5c0d556bd7bfaaf6c5/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-website'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/homebackup/components/ddb987ac0b1a2e02086b043645e7decd/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var website, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  website = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website'));
  regExp = (new RegExp('[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)', 'gi'));
  if (website.length <= 249) {
    ___arguments.context.pageData['websiteValue'] = website;
  } else if (website.length > 249) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['website'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['website'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'websiteValue')), 0));
  }
  if ((regExp.test(website)) == false && website != '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = true;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = false;
  }
  if ((regExp.test((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website')))) == true && website.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website')).length) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/homebackup/components/b17c55c60f4dcdf2ae8fc66b95b63932/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var subject, regExp, firstName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  subject = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject'));
  regExp = (new RegExp('.{4,}', 'g'));
  if (subject.length <= 300) {
    ___arguments.context.pageData['subjectValue'] = subject;
  } else if (subject.length > 300) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['subject'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['subject'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'subjectValue')), 0));
  }
  if ((regExp.test(subject)) == false && subject != '' || subject.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length < 4 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = false;
  } else if ((regExp.test(subject)) == false && subject == '' || subject.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length == 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/homebackup/components/a38dcbb7299151a66062e5a8eaaf984c/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-subject'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/homebackup/components/101b130a0c5ff2588aa5637fc5fcdb27/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-message'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage']) {
    addItemToList(classes, 'c-form__label_alert');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/homebackup/components/6eb697a67eb600c29f860957a2ac3b3e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var error, message, subject, website, phone, email, lastName, firstName, checkStatusMessage, checkStatusSubject, checkStatusWebsite, checkStatusPhone, checkStatusEmail, checkStatusLastName, checkStatusFirstName, statusMessage, statusSubject, statusWebsite, statusPhone, statusEmail, statusLastName, statusFirstName, company, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function firstNameValidation() {
  regExp = (new RegExp('.{2,}', 'g'));
  if (!(regExp.test(firstName)) || firstName == '' || firstName == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusFirsName() {
  if (!(getObjectProperty(statusFirstName, 'status'))) {
    await ( async function (component) { component.focus() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('d2551aa8d85f0b8224661c4aaaa53f32')));
    if (firstName == '' || firstName == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function lastNameValidation() {
  regExp = (new RegExp('.{2,}', 'g'));
  if (!(regExp.test(lastName)) || lastName == '' || lastName == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusLastName2() {
  if (!(getObjectProperty(statusLastName, 'status'))) {
    await ( async function (component) { component.focus() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('f5bd0a2c6f0bde798ea8df133e9e7e3c')));
    if (lastName == '' || lastName == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function emailValidation() {
  regExp = (new RegExp('[a-zA-Z0-9.\\-_+]{1,}@[^\\W\\.][a-zA-Z0-9\\-]{0,}\\.[a-zA-Z]{1,}', 'g'));
  if (!(regExp.test(email)) || email == '' || email == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function phoneValidation() {
  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'hintPhone')) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusEmail2() {
  if (!(getObjectProperty(statusEmail, 'status'))) {
    await ( async function (component) { component.focus() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('cdc2459c0f1ac842997b621a04744c0c')));
    if (email == '' || email == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function websiteValidation() {
  regExp = (new RegExp('[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)', 'gi'));
  if (!(regExp.test(website)) && website != '' && website != null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function subjectValidation() {
  regExp = (new RegExp('.{4,}', 'g'));
  if (!(regExp.test(subject)) || subject == '' || subject == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusPhone2() {
  if (!(getObjectProperty(statusPhone, 'status'))) {
    await ( async function (component) { component.focus() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('1712009c62182acf71f1e4e777ac98dd')));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = true;
    return false;
  }
  return true
}

/**
 * Describe this function...
 */
async function messageValidation() {
  if (message == '' || message == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusWebsite2() {
  if (!(getObjectProperty(statusWebsite, 'status'))) {
    await ( async function (component) { component.focus() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('ddb987ac0b1a2e02086b043645e7decd')));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = true;
    return false;
  }
  return true
}

/**
 * Describe this function...
 */
async function checkStatusSubject2() {
  if (!(getObjectProperty(statusSubject, 'status'))) {
    await ( async function (component) { component.focus() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('b17c55c60f4dcdf2ae8fc66b95b63932')));
    if (subject == '' || subject == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function checkStatusMessage2() {
  if (!(getObjectProperty(statusMessage, 'status'))) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage'] = true;
    return false;
  }
  return true
}


  firstName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName'));
  lastName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName'));
  email = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'email'));
  phone = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone'));
  company = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'company'));
  website = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website'));
  subject = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject'));
  message = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'message'));
  statusFirstName = await firstNameValidation();
  statusLastName = await lastNameValidation();
  statusEmail = await emailValidation();
  statusPhone = await phoneValidation();
  statusWebsite = await websiteValidation();
  statusSubject = await subjectValidation();
  statusMessage = await messageValidation();
  checkStatusMessage = await checkStatusMessage2();
  checkStatusSubject = await checkStatusSubject2();
  checkStatusWebsite = await checkStatusWebsite2();
  checkStatusPhone = await checkStatusPhone2();
  checkStatusEmail = await checkStatusEmail2();
  checkStatusLastName = await checkStatusLastName2();
  checkStatusFirstName = await checkStatusFirsName();
  if (checkStatusFirstName != false && checkStatusLastName != false && checkStatusEmail != false && checkStatusPhone != false && checkStatusWebsite != false && checkStatusSubject != false && checkStatusMessage != false) {
    await Backendless.Data.of('Subscriber').save( ({ 'firstName': firstName,'lastName': lastName,'email': email,'phone': phone,'website': website,'subject': subject,'message': message }) );
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b65aa795e4d6a994b3ddd6f68533c80d', false);
    try {
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('df8b067a885dcda84a91376a3c4c5c7c', true);
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7224592bf6676a81e607676ddbc597e1', false);

    } catch (error) {
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('df8b067a885dcda84a91376a3c4c5c7c', false);
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7224592bf6676a81e607676ddbc597e1', true);
      console.log(error);

    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/homebackup/components/58c7b83b0c67da4ff3bef93451af2162/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var message, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  message = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'message'));
  regExp = (new RegExp('.{1,}', 'g'));
  if (message.length <= 249) {
    ___arguments.context.pageData['messageValue'] = message;
  } else if (message.length > 249) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['message'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['message'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'messageValue')), 0));
  }
  if ((regExp.test(message)) == false && message == '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage'] = true;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/homebackup/components/251dd247af0d2f6ebe6ccde3866c89b5/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isOpenModal')) {
    return ['modal', 'open'];
  }

  return ['modal']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/homebackup/components/70dd1dff3e215deab9dcc3e29a6566e6/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async onMounted(___arguments) {
    var counter, data, gallery, item, listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setIdItem(data) {
  counter = 1;
  return await Promise.all(data.map(async item => {  item['id'] = counter;
  counter = (typeof counter == 'number' ? counter : 0) + 1;
; return item;}))
}


  await new Promise(r => setTimeout(r, 1000 || 0));
  gallery = (getObjectProperty(___arguments.context.appData, 'galleryList'));
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', (await setIdItem(gallery)));
  listLength = gallery.length;
  ___arguments.context.pageData['totalSlide'] = listLength;
  ___arguments.context.pageData['currentSlide'] = 1;

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/homebackup/components/bb37f7327e62a50eb06507a61d6d1fc6/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == (getObjectProperty(___arguments.context.dataModel, 'id'))) {
    return ['modal__slider-item', 'active'];
  }

  return ['modal__slider-item']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/homebackup/components/831fddd4bb7ced9296de1aa550213577/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) > 1) {
    ___arguments.context.pageData['counterSlide2'] = ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == 1 ? listLength : (getObjectProperty(___arguments.context.pageData, 'counterSlide2')) - 1);
  }
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ___arguments.context.pageData['currentSlide'] = (getObjectProperty(___arguments.context.pageData, 'counterSlide2'));

  },
  /* handler:onClick */
  /* handler:onMouseOut */
  onMouseOut(___arguments) {
    var listLength;


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('831fddd4bb7ced9296de1aa550213577'))['opacity'] = '0';

  },
  /* handler:onMouseOut */
  /* handler:onMouseOver */
  onMouseOver(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == 1) {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('831fddd4bb7ced9296de1aa550213577'))['opacity'] = '0';
  } else {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('831fddd4bb7ced9296de1aa550213577'))['opacity'] = '1';
  }

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./pages/homebackup/components/dba21793ecfc81f1424a1ce6ad99a8b3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) < listLength) {
    ___arguments.context.pageData['counterSlide2'] = ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == listLength ? 1 : (getObjectProperty(___arguments.context.pageData, 'counterSlide2')) + 1);
  }
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ___arguments.context.pageData['currentSlide'] = (getObjectProperty(___arguments.context.pageData, 'counterSlide2'));

  },
  /* handler:onClick */
  /* handler:onMouseOut */
  onMouseOut(___arguments) {
    var listLength;


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('dba21793ecfc81f1424a1ce6ad99a8b3'))['opacity'] = '0';

  },
  /* handler:onMouseOut */
  /* handler:onMouseOver */
  onMouseOver(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == listLength) {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('dba21793ecfc81f1424a1ce6ad99a8b3'))['opacity'] = '0';
  } else {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('dba21793ecfc81f1424a1ce6ad99a8b3'))['opacity'] = '1';
  }

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./pages/homebackup/components/31c87ba294176e587b91b87c58e68a82/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/homebackup/components/16b9cb3fdc9bf1919465a18c77a6e467/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/homebackup/components/3607af0d8419be43455fc4ac16f9cc33/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var company;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  company = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'company'));
  if (company.length <= 100) {
    ___arguments.context.pageData['companyValue'] = company;
  } else if (company.length > 100) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['company'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['company'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'companyValue')), 0));
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/homebackup/components/30ea90dd05af67628fd821d5003c3d3d/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  onMounted(___arguments) {
      ___arguments.context.pageData['firstNameValue'] = '';
  ___arguments.context.pageData['lastNameValue'] = '';
  ___arguments.context.pageData['emailValue'] = '';
  ___arguments.context.pageData['phoneValue'] = '';
  ___arguments.context.pageData['companyValue'] = '';
  ___arguments.context.pageData['websiteValue'] = '';
  ___arguments.context.pageData['subjectValue'] = '';
  ___arguments.context.pageData['messageValue'] = '';

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/login/components/85dae34486144e2efa2d934bfdddb172/bundle.js', [], () => ({
  /* content */

  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error, stayLoggedIn, password, username, user, logging, buttonLogin, x;

/**
 * Describe this function...
 */
async function login() {
  try {
    user = (await Backendless.UserService.login( username, password, stayLoggedIn  ));

  } catch (error) {
    await setError(error);

  }
}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function updateButtonLogin(logging) {
  buttonLogin = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'));
  buttonLogin['label'] = (logging ? 'Please wait...' : 'Login');
  buttonLogin['disabled'] = logging;
}

/**
 * Describe this function...
 */
async function setError(error) {
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('85dae34486144e2efa2d934bfdddb172'))['error'] = error;
}


  await setError(null);
  username = (getObjectProperty(___arguments.context.dataModel, 'username'));
  password = (getObjectProperty(___arguments.context.dataModel, 'password'));
  stayLoggedIn = true;
  if (username && password) {
    await updateButtonLogin(true);
    try {
      await login();

    } catch (error) {
      if ((getObjectProperty(error, 'code')) == 3033) {
        await login();
      } else {
        await setError(error);
      }

    } finally {
      await updateButtonLogin(false);

    }
  } else {
    await setError((new Error('Username and Password must be filled')));
  }
  if (user) {
    ___arguments.context.appData['currentUser'] = user;
    ___arguments.context.appData['companyNamesLIST'] = (getObjectProperty(((await Backendless.Data.of('Users').loadRelations((getObjectProperty((getObjectProperty(___arguments.context.appData, 'currentUser')), 'objectId')), Backendless.LoadRelationsQueryBuilder.create().setRelationName('Linked_Companies').setPageSize(10).setOffset(0)))[0]), 'Company_Name'));
    ___arguments.context.appData['currentCompanyObjectId'] = (getObjectProperty(((await Backendless.Data.of('Users').loadRelations((getObjectProperty((getObjectProperty(___arguments.context.appData, 'currentUser')), 'objectId')), Backendless.LoadRelationsQueryBuilder.create().setRelationName('Linked_Companies').setPageSize(1).setOffset(0)))[0]), 'objectId'));
    localStorage.setItem('HPUserCookie', JSON.stringify((getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'user-token'))));
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dash', undefined);
  }

  },
  /* handler:onSubmit *//* content */
}));

define('./pages/login/components/cee453cca5ca7d980fc63171f0674fed/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await (async function() {
  	history.back();
  })();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/login/components/5ce71b866583a2560651e76b0ea62d12/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('newaccount', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/moredeets/components/d1d9de3c08188cf9ff4108b1614be69a/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var AppURL, AppBaseURL;


  AppURL = (await (async function() {
  	return window.location.href
  })());
  AppBaseURL = AppURL.slice(0, 22);
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', [({ 'textLink': 'Home','urlLink': '#home','active': true }), ({ 'textLink': 'Contact','urlLink': '#contact','active': false })]);

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/moredeets/components/4494147e47463208b1e17aafe36cda16/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var textLink, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  textLink = (getObjectProperty(___arguments.context.dataModel, 'textLink'));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')) == textLink) {
      item['active'] = true;
    } else {
      item['active'] = false;
    }
  ; return item;}))));
  if (textLink == 'Home') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')));
  } else if (textLink == 'Contact') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0ee4f7a2f9f93096442f303878b88406')));
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 990) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 0;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.dataModel, 'active')) {
    if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) == true) {
      return ['link', 'nav__link', 'nav__link_active', null, 'boldmenuitem'];
    } else if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) != true) {
      return ['link', 'nav__link', 'nav__link_active', 'whiteshadowtext', null, 'boldmenuitem'];
    }
  } else if ((getObjectProperty(___arguments.context.dataModel, 'active')) != true) {
    if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) == true) {
      return ['link', 'nav__link', null, null];
    } else if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) != true) {
      return ['link', 'nav__link', 'shadowtext', null];
    }
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/moredeets/components/fefec6b6560f11b1a4c62ed6ad39d36c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52')), 'height')) == 349) {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 0;
  } else {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 349;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/moredeets/components/dc8e2162b0174608379cfba7549be8db/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/moredeets/components/30ea90dd05af67628fd821d5003c3d3d/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  onMounted(___arguments) {
      ___arguments.context.pageData['firstNameValue'] = '';
  ___arguments.context.pageData['lastNameValue'] = '';
  ___arguments.context.pageData['emailValue'] = '';
  ___arguments.context.pageData['phoneValue'] = '';
  ___arguments.context.pageData['companyValue'] = '';
  ___arguments.context.pageData['websiteValue'] = '';
  ___arguments.context.pageData['subjectValue'] = '';
  ___arguments.context.pageData['messageValue'] = '';

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/moredeets/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onScroll */
  async ['onScroll'](___arguments) {
    var a, item, team, gallery, home2, features, testimonials, contact, topOnViewport, header, elem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function topOnViewport2(elem, header) {
  topOnViewport = (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', elem)) - ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight'))) + (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) - ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', header)));
  return topOnViewport
}


  contact = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0ee4f7a2f9f93096442f303878b88406')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  team = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  gallery = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  testimonials = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  features = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  home2 = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  if (___arguments.scrollTop - ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) < home2) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b'))))) < features && ___arguments.scrollTop > home2) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < testimonials && ___arguments.scrollTop > features + home2) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < gallery && ___arguments.scrollTop > testimonials + features + home2) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < team && ___arguments.scrollTop > testimonials + features + home2 + gallery) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < contact && ___arguments.scrollTop > testimonials + features + home2 + gallery + team) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0ee4f7a2f9f93096442f303878b88406')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop > ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) / 3) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('f5f6c87f71d4ee4f6b9b334b92f88f31'))['opacity'] = 0.3;
  } else if (___arguments.scrollTop > ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) / 10) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('f5f6c87f71d4ee4f6b9b334b92f88f31'))['opacity'] = 0.5;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('f5f6c87f71d4ee4f6b9b334b92f88f31'))['opacity'] = 1;
  }
  if (___arguments.scrollTop) {
    ___arguments.context.pageData['scrollTop'] = true;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['box-shadow'] = '0px 2px 4px rgba(0, 0, 0, 0.1)';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['height'] = '60px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-top'] = '7px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '-5px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '45px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#777777';
  } else {
    ___arguments.context.pageData['scrollTop'] = false;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['box-shadow'] = 'none';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['height'] = '80px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['background-color'] = 'transparent';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-top'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '80px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#ffffff';
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 400) {
    a = 150;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 500) {
    a = (typeof a == 'number' ? a : 0) + 200;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 600) {
    a = (typeof a == 'number' ? a : 0) + 300;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 700) {
    a = (typeof a == 'number' ? a : 0) + 400;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 800) {
    a = (typeof a == 'number' ? a : 0) + 500;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 900) {
    a = (typeof a == 'number' ? a : 0) + 600;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 1050) {
    a = (typeof a == 'number' ? a : 0) + 700;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 990 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 1050) {
    if (___arguments.scrollTop >= ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) - a)) {
      ___arguments.context.appData['featuresInFocus'] = true;
    }
    if (___arguments.scrollTop >= ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) - a)) {
      ___arguments.context.appData['testimonialsInFocus'] = true;
    }
    if (___arguments.scrollTop >= ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')))) - a)) {
      ___arguments.context.appData['teamInFocus'] = true;
    }
  } else {
    ___arguments.context.appData['featuresInFocus'] = true;
    ___arguments.context.appData['testimonialsInFocus'] = true;
    ___arguments.context.appData['teamInFocus'] = true;
  }

  },
  /* handler:onScroll */
  /* handler:onEnter *//* handler:onEnter */
  /* handler:onResize *//* handler:onResize */
  /* content */
}))

define('./pages/newaccount/components/cee453cca5ca7d980fc63171f0674fed/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await (async function() {
  	history.back();
  })();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/newaccount/components/5ce71b866583a2560651e76b0ea62d12/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/newaccount/components/db5546014b7940ad8771a98d85f83036/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, password, username, newUser, stayLoggedIn, logging, buttonLogin;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function updateButtonCreate(logging) {
  buttonLogin = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'));
  buttonLogin['label'] = (logging ? 'Please wait...' : 'Login');
  buttonLogin['disabled'] = logging;
}

/**
 * Describe this function...
 */
async function setError(error) {
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('cfb9adb26e7ccf1f942c0c492bdb4a98'))['error'] = error;
}


  username = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('cfb9adb26e7ccf1f942c0c492bdb4a98'), 'InputField1'));
  password = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('cfb9adb26e7ccf1f942c0c492bdb4a98'), 'InputField2'));
  stayLoggedIn = true;
  if (username && password) {
    try {
      newUser = (await Backendless.UserService.register( new Backendless.User({ 'email': username,'password': password }) ));

    } catch (error) {
      console.log(error);
      console.log(getObjectProperty(error, 'code'));
      if ((getObjectProperty(error, 'code')) == 3033) {
        await setError((new Error('This user already exists!  Redirecting to login screen...')));
        await new Promise(r => setTimeout(r, 2500 || 0));
        (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
      } else {
        await setError((new Error('User created successfully!')));
      }

    } finally {
      await setError((new Error('User created successfully! Redirecting in: 3.')));
      await new Promise(r => setTimeout(r, 1000 || 0));
      await setError((new Error('User created successfully! Redirecting in: 2..')));
      await new Promise(r => setTimeout(r, 1000 || 0));
      await setError((new Error('User created successfully! Redirecting in: 1...')));
      await new Promise(r => setTimeout(r, 1000 || 0));
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);

    }
  } else {
    await setError((new Error('Username and Password must be filled')));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/newaccount/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
      await (async function() {
  	var myInput = document.getElementById('myInputHolder1').getElementsByTagName('input')[0];
  	myInput.setAttribute( "aria-autocomplete", "none");
  	var myInput2 = document.getElementById('myInputHolder2').getElementsByTagName('input')[0];
  	myInput2.setAttribute( "aria-autocomplete", "none");
  	
  	    let tagArr = document.getElementsByTagName("input");
  	      for (let i = 0; i < tagArr.length; i++) {
  	        tagArr[i].autocomplete = 'off';
  	      }
  })();

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/originalhomepage/components/d1d9de3c08188cf9ff4108b1614be69a/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  onMounted(___arguments) {
      ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', [({ 'textLink': 'Home','urlLink': '#home','active': true }), ({ 'textLink': 'Features','urlLink': '#features','active': false }), ({ 'textLink': 'Testimonials','urlLink': '#testimonials','active': false }), ({ 'textLink': 'Gallery','urlLink': '#gallery','active': false }), ({ 'textLink': 'Team','urlLink': '#team','active': false }), ({ 'textLink': 'Contact','urlLink': '#contact','active': false })]);

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/originalhomepage/components/4494147e47463208b1e17aafe36cda16/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var textLink, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  textLink = (getObjectProperty(___arguments.context.dataModel, 'textLink'));
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')) == textLink) {
      item['active'] = true;
    } else {
      item['active'] = false;
    }
  ; return item;}))));
  if (textLink == 'Home') {
    await ( async function (component) { component.el.scrollIntoView() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')));
  } else if (textLink == 'Features') {
    await ( async function (component) { component.el.scrollIntoView() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')));
  } else if (textLink == 'Testimonials') {
    await ( async function (component) { component.el.scrollIntoView() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')));
  } else if (textLink == 'Gallery') {
    await ( async function (component) { component.el.scrollIntoView() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')));
  } else if (textLink == 'Team') {
    await ( async function (component) { component.el.scrollIntoView() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')));
  } else if (textLink == 'Contact') {
    await ( async function (component) { component.el.scrollIntoView() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('0ee4f7a2f9f93096442f303878b88406')));
  }
  if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 990) {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 0;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.dataModel, 'active')) {
    return ['link', 'nav__link', 'nav__link_active'];
  }

  return ['link', 'nav__link']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/originalhomepage/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onResize */
  ['onResize'](___arguments) {
    var leftSlideBy, left, j, half, galleryList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 990) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 0;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 'auto';
  }
  galleryList = (getObjectProperty(___arguments.context.appData, 'galleryList'));
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) >= 1200) {
    ___arguments.context.appData['leftSlideBy'] = 240;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 1199) {
    ___arguments.context.appData['leftSlideBy'] = 200;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 990) {
    ___arguments.context.appData['leftSlideBy'] = 260;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768) {
    ___arguments.context.appData['leftSlideBy'] = 370;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576) {
    ___arguments.context.appData['leftSlideBy'] = 250;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
    ___arguments.context.appData['leftSlideBy'] = 100;
  }
  leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
  half = Math.floor(galleryList.length / 2);
  ___arguments.context.pageData['leftStart'] = (-15 - half * leftSlideBy);
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 576) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 120);
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 480) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 130);
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 15);
  }
  left = (getObjectProperty(___arguments.context.pageData, 'leftStart'));
  var j_list = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('c376c79acf1e16de2463e38439c34656'));
  for (var j_index in j_list) {
    j = j_list[j_index];
    j['opacity'] = 1;
    j['left'] = left;
    if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
      j['active'] = true;
    } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
      j['active'] = true;
    } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
      j['active'] = true;
    } else if (left == 0 && leftSlideBy == 100) {
      j['active'] = true;
    } else {
      j['active'] = false;
    }
    left = (typeof left == 'number' ? left : 0) + leftSlideBy;
  }

  },
  /* handler:onResize */
  /* handler:onScroll */
  async onScroll(___arguments) {
    var a, item, team, gallery, home2, features, testimonials, contact, topOnViewport, header, elem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function topOnViewport2(elem, header) {
  topOnViewport = ((await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', elem)) - (await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight'))) + ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) - (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', header)));
  return topOnViewport
}


  contact = await topOnViewport2(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('0ee4f7a2f9f93096442f303878b88406')), ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  team = await topOnViewport2(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')), ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  gallery = await topOnViewport2(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')), ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  testimonials = await topOnViewport2(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')), ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  features = await topOnViewport2(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')), ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  home2 = await topOnViewport2(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')), ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  if (___arguments.scrollTop - (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) < home2) {
    ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - ((await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b'))))) < features && ___arguments.scrollTop > home2) {
    ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - ((await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < testimonials && ___arguments.scrollTop > features + home2) {
    ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - ((await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < gallery && ___arguments.scrollTop > testimonials + features + home2) {
    ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - ((await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')))) + (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < team && ___arguments.scrollTop > testimonials + features + home2 + gallery) {
    ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - ((await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')))) + (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')))) + (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < contact && ___arguments.scrollTop > testimonials + features + home2 + gallery + team) {
    ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('0ee4f7a2f9f93096442f303878b88406')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop > (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) / 3) {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('f5f6c87f71d4ee4f6b9b334b92f88f31'))['opacity'] = 0.3;
  } else if (___arguments.scrollTop > (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) / 10) {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('f5f6c87f71d4ee4f6b9b334b92f88f31'))['opacity'] = 0.5;
  } else {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('f5f6c87f71d4ee4f6b9b334b92f88f31'))['opacity'] = 1;
  }
  if (___arguments.scrollTop) {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['box-shadow'] = '0px 2px 4px rgba(0, 0, 0, 0.1)';
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['height'] = '60px';
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['background-color'] = '#ffffff';
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['color'] = '#777777';
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '60px';
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#777777';
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#777777';
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#777777';
  } else {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['box-shadow'] = 'none';
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['height'] = '80px';
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['background-color'] = 'transparent';
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['color'] = '#ffffff';
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '80px';
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#ffffff';
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#ffffff';
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#ffffff';
  }
  if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 400) {
    a = 150;
  } else if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 500) {
    a = (typeof a == 'number' ? a : 0) + 200;
  } else if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 600) {
    a = (typeof a == 'number' ? a : 0) + 300;
  } else if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 700) {
    a = (typeof a == 'number' ? a : 0) + 400;
  } else if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 800) {
    a = (typeof a == 'number' ? a : 0) + 500;
  } else if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 900) {
    a = (typeof a == 'number' ? a : 0) + 600;
  } else if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 1050) {
    a = (typeof a == 'number' ? a : 0) + 700;
  }
  if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 990 && (await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 1050) {
    if (___arguments.scrollTop >= (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + ((await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) - a)) {
      ___arguments.context.appData['featuresInFocus'] = true;
    }
    if (___arguments.scrollTop >= (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) - a)) {
      ___arguments.context.appData['testimonialsInFocus'] = true;
    }
    if (___arguments.scrollTop >= (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + ((await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')))) - a)) {
      ___arguments.context.appData['teamInFocus'] = true;
    }
  } else {
    ___arguments.context.appData['featuresInFocus'] = true;
    ___arguments.context.appData['testimonialsInFocus'] = true;
    ___arguments.context.appData['teamInFocus'] = true;
  }

  },
  /* handler:onScroll */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var teamList, testimonialsList, featuresList, appData, galleryList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['isOpenModal'] = false;
  appData = (await (async function() {
  	const data = {
  	  "features": [
  	    {
  	      "id": "f1",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-1.jpg",
  	      "title": "Amazing Experience",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f2",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-2.jpg",
  	      "title": "24/7 Support",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f3",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-3.jpg",
  	      "title": "Real-time",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f4",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-4.jpg",
  	      "title": "Easy To Use",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f5",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-5.jpg",
  	      "title": "Free Updates",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f6",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-6.jpg",
  	      "title": "All Platforms",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    }
  	  ],
  	  "gallery": [
  	    {
  	      "id": "g1",
  	      "image": "https://backendless.com/images/Fresh/dbImg/gallery/item1.jpg"
  	    },
  	    {
  	      "id": "g2",
  	      "image": "https://backendless.com/images/Fresh/dbImg/gallery/item2.jpg"
  	    },
  	    {
  	      "id": "g3",
  	      "image": "https://backendless.com/images/Fresh/dbImg/gallery/item3.jpg"
  	    },
  	    {
  	      "id": "g4",
  	      "image": "https://backendless.com/images/Fresh/dbImg/gallery/item4.jpg"
  	    },
  	    {
  	      "id": "g5",
  	      "image": "https://backendless.com/images/Fresh/dbImg/gallery/item5.jpg"
  	    },
  	    {
  	      "id": "g6",
  	      "image": "https://backendless.com/images/Fresh/dbImg/gallery/item6.jpg"
  	    }
  	  ],
  	  "team": [
  	    {
  	      "id": "t1",
  	      "name": "Joan Doe",
  	      "career": "Founder - CEO",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo1.jpg",
  	      "socialMedia": {
  	        "social": [
  	          {
  	            "url": "#",
  	            "name": "facebook"
  	          },
  	          {
  	            "url": "#",
  	            "name": "github"
  	          },
  	          {
  	            "url": "#",
  	            "name": "linkedin"
  	          },
  	          {
  	            "url": "",
  	            "name": "twitter"
  	          }
  	        ]
  	      }
  	    },
  	    {
  	      "id": "t2",
  	      "name": "John Doe",
  	      "career": "Co-founder",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo2.jpg",
  	      "socialMedia": {
  	        "social": [
  	          {
  	            "url": "#",
  	            "name": "facebook"
  	          },
  	          {
  	            "url": "",
  	            "name": "github"
  	          },
  	          {
  	            "url": "",
  	            "name": "linkedin"
  	          },
  	          {
  	            "url": "#",
  	            "name": "twitter"
  	          }
  	        ]
  	      }
  	    },
  	    {
  	      "id": "t3",
  	      "name": "Joan Doe",
  	      "career": "Co-founder",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo3.jpg",
  	      "socialMedia": {
  	        "social": [
  	          {
  	            "url": "#",
  	            "name": "facebook"
  	          },
  	          {
  	            "url": "#",
  	            "name": "github"
  	          },
  	          {
  	            "url": "#",
  	            "name": "linkedin"
  	          },
  	          {
  	            "url": "",
  	            "name": "twitter"
  	          }
  	        ]
  	      }
  	    },
  	    {
  	      "id": "t4",
  	      "name": "John Doe",
  	      "career": "Financial Manager",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo4.jpg",
  	      "socialMedia": {
  	        "social": [
  	          {
  	            "url": "#",
  	            "name": "facebook"
  	          },
  	          {
  	            "url": "",
  	            "name": "github"
  	          },
  	          {
  	            "url": "#",
  	            "name": "linkedin"
  	          },
  	          {
  	            "url": "",
  	            "name": "twitter"
  	          }
  	        ]
  	      }
  	    }
  	  ],
  	  "testimonials": [
  	    {
  	      "id": "tm1",
  	      "author": "Jane Doe",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/testimonials/auth1.jpg"
  	    },
  	    {
  	      "id": "tm2",
  	      "author": "John Doe",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/testimonials/auth2.jpg"
  	    },
  	    {
  	      "id": "tm3",
  	      "author": "Jane Doe",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/testimonials/auth3.jpg"
  	    },
  	    {
  	      "id": "tm4",
  	      "author": "John Doe",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/testimonials/item4.jpg"
  	    }
  	  ]
  	}

  	return data
  })());
  featuresList = (getObjectProperty(appData, 'features'));
  galleryList = (getObjectProperty(appData, 'gallery'));
  ___arguments.context.appData['galleryList'] = galleryList;
  teamList = (getObjectProperty(appData, 'team'));
  testimonialsList = (getObjectProperty(appData, 'testimonials'));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('31b2f36e83dd37c11b5e73f81d1bfac9', featuresList);
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('153bb1d13492b519dc0717b333c20947', testimonialsList);
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('b6f27fbafe317fe16572dfeaf1fb0698', teamList);

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/originalhomepage/components/fefec6b6560f11b1a4c62ed6ad39d36c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52')), 'height')) == 349) {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 0;
  } else {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 349;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/originalhomepage/components/31b2f36e83dd37c11b5e73f81d1bfac9/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
      return ((___arguments.context.appData['featuresInFocus']) ? ['features__items-block', 'features__items-block_in-focus'] : ['features__items-block'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/originalhomepage/components/153bb1d13492b519dc0717b333c20947/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
      return ((___arguments.context.appData['testimonialsInFocus']) ? ['testimonials__items-block', 'testimonials__items-block_in-focus'] : ['testimonials__items-block'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/originalhomepage/components/c376c79acf1e16de2463e38439c34656/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async onMounted(___arguments) {
    var item, leftSlideBy, left, newGalleryList, half, firstList, galleryList, lastList, idGalleryItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  await new Promise(r => setTimeout(r, 1000 || 0));
  galleryList = (getObjectProperty(___arguments.context.appData, 'galleryList'));
  idGalleryItem = 1;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c376c79acf1e16de2463e38439c34656', (await Promise.all(galleryList.map(async item => {  item['id'] = idGalleryItem;
    idGalleryItem = (typeof idGalleryItem == 'number' ? idGalleryItem : 0) + 1;
  ; return item;}))));
  ___arguments.context.appData['leftSlideBy'] = 240;
  if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 1199) {
    ___arguments.context.appData['leftSlideBy'] = 200;
  }
  if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 990) {
    ___arguments.context.appData['leftSlideBy'] = 260;
  }
  if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768) {
    ___arguments.context.appData['leftSlideBy'] = 370;
  }
  if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576) {
    ___arguments.context.appData['leftSlideBy'] = 250;
  }
  if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
    ___arguments.context.appData['leftSlideBy'] = 100;
  }
  half = Math.floor(galleryList.length / 2);
  lastList = galleryList.slice(((half + 1) - 1), galleryList.length);
  firstList = galleryList.slice(0, half);
  newGalleryList = (JSON.parse((JSON.stringify(((addItemToList(lastList, galleryList)).concat(firstList))))));
  leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
  ___arguments.context.pageData['leftStart'] = (-15 - half * leftSlideBy);
  if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768 && (await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 576) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 120);
  }
  if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576 && (await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 480) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 130);
  }
  if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 15);
  }
  left = (getObjectProperty(___arguments.context.pageData, 'leftStart'));
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c376c79acf1e16de2463e38439c34656', (await Promise.all(newGalleryList.map(async item => {  item['opacity'] = 1;
    item['left'] = left;
    if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
      item['active'] = true;
    } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
      item['active'] = true;
    } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
      item['active'] = true;
    } else if (left == 0 && leftSlideBy == 100) {
      item['active'] = true;
    } else {
      item['active'] = false;
    }
    left = (typeof left == 'number' ? left : 0) + leftSlideBy;
  ; return item;}))));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/originalhomepage/components/e23154e2b40dc5a8ac7f9a7e34ff980f/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  async onClassListAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 480) {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('e23154e2b40dc5a8ac7f9a7e34ff980f'))['left'] = (String(getObjectProperty(___arguments.context.dataModel, 'left')) + String('px'));
  } else {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('e23154e2b40dc5a8ac7f9a7e34ff980f'))['left'] = (String(getObjectProperty(___arguments.context.dataModel, 'left')) + String('%'));
  }
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('e23154e2b40dc5a8ac7f9a7e34ff980f'))['opacity'] = (getObjectProperty(___arguments.context.dataModel, 'opacity'));
  if (getObjectProperty(___arguments.context.dataModel, 'active')) {
    return ['g-slider__item', 'g-slider__item_center'];
  }

  return ['g-slider__item']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/originalhomepage/components/108f6ec347effdbce289a49d625d03af/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['isOpenModal'] = true;
  ___arguments.context.pageData['counterSlide2'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e23154e2b40dc5a8ac7f9a7e34ff980f'), 'id'));
  ___arguments.context.pageData['currentSlide'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e23154e2b40dc5a8ac7f9a7e34ff980f'), 'id'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/originalhomepage/components/26a1309c25831a8af67d5fe98587c773/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    var leftSlideBy, minValue, card, left, j, maxValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  maxValue = 0;
  minValue = 0;
  leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
  if ((typeof (getObjectProperty(___arguments.context.pageData, 'stopClick')) === 'undefined') || ((new Date()).valueOf()) > (getObjectProperty(___arguments.context.pageData, 'stopClick')) + 300) {
    ___arguments.context.pageData['stopClick'] = ((new Date()).valueOf());
    var j_list = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('c376c79acf1e16de2463e38439c34656'));
    for (var j_index in j_list) {
      j = j_list[j_index];
      j['opacity'] = 1;
      j['left'] = ((getObjectProperty(j, 'left')) + leftSlideBy);
      left = (getObjectProperty(j, 'left'));
      if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
        j['active'] = true;
      } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
        j['active'] = true;
      } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
        j['active'] = true;
      } else if (left == 0 && leftSlideBy == 100) {
        j['active'] = true;
      } else {
        j['active'] = false;
      }
      if (maxValue < left) {
        maxValue = left;
        card = j;
      }
      if (minValue > left) {
        minValue = left;
      }
    }
    card['opacity'] = 0;
    card['left'] = (minValue - leftSlideBy);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/originalhomepage/components/0ecc200a34ec908bf97bef91588de587/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    var leftSlideBy, maxValue, card, left, j, minValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  maxValue = 0;
  minValue = 0;
  leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
  if ((typeof (getObjectProperty(___arguments.context.pageData, 'stopClick')) === 'undefined') || ((new Date()).valueOf()) > (getObjectProperty(___arguments.context.pageData, 'stopClick')) + 300) {
    ___arguments.context.pageData['stopClick'] = ((new Date()).valueOf());
    var j_list = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('c376c79acf1e16de2463e38439c34656'));
    for (var j_index in j_list) {
      j = j_list[j_index];
      j['left'] = ((getObjectProperty(j, 'left')) - leftSlideBy);
      left = (getObjectProperty(j, 'left'));
      j['opacity'] = 1;
      if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
        j['active'] = true;
      } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
        j['active'] = true;
      } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
        j['active'] = true;
      } else if (left == 0 && leftSlideBy == 100) {
        j['active'] = true;
      } else {
        j['active'] = false;
      }
      if (minValue > left) {
        minValue = left;
        card = j;
      }
      if (maxValue < left) {
        maxValue = left;
      }
    }
    card['opacity'] = 0;
    card['left'] = (maxValue + leftSlideBy);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/originalhomepage/components/4bc8650348b45a9267097b43aff505c6/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  onMounted(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('4bc8650348b45a9267097b43aff505c6', (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e5ac9c8ad984738efc349d07b4dc74b1'), 'socialMedia')), 'social')));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/originalhomepage/components/1fdde5c76ea432e90eb67a18533b2dac/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes, nameSocial;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['team-card__link', 'link'];
  nameSocial = (getObjectProperty(___arguments.context.dataModel, 'name'));
  if ((getObjectProperty(___arguments.context.dataModel, 'url')) == '') {
    addItemToList(classes, 'team-card__link_hide');
  }
  if (nameSocial == 'facebook') {
    addItemToList(classes, 'fab fa-facebook-f');
  }
  if (nameSocial == 'github') {
    addItemToList(classes, 'fab fa-github');
  }
  if (nameSocial == 'linkedin') {
    addItemToList(classes, 'fab fa-linkedin-in');
  }
  if (nameSocial == 'twitter') {
    addItemToList(classes, 'fab fa-twitter');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/originalhomepage/components/b6f27fbafe317fe16572dfeaf1fb0698/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
      return ((___arguments.context.appData['teamInFocus']) ? ['team__cards-block', 'team__cards-block_in-focus'] : ['team__cards-block'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/originalhomepage/components/d2551aa8d85f0b8224661c4aaaa53f32/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var firstName, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  firstName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName'));
  regExp = (new RegExp('.{2,}', 'g'));
  if (firstName.length <= 50) {
    ___arguments.context.pageData['firstNameValue'] = firstName;
  } else if (firstName.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['firstName'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['firstName'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'firstNameValue')), 0));
  }
  if ((regExp.test(firstName)) == false && firstName != '' || firstName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length < 2 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = false;
  } else if ((regExp.test(firstName)) == false && firstName == '' || firstName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length == 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/originalhomepage/components/3ddf6adfe3e580cc962a62234bf2e2d5/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-first-name'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/originalhomepage/components/f5bd0a2c6f0bde798ea8df133e9e7e3c/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var lastName, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  lastName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName'));
  regExp = (new RegExp('.{2,}', 'g'));
  if (lastName.length <= 50) {
    ___arguments.context.pageData['lastNameValue'] = lastName;
  } else if (lastName.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['lastName'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['lastName'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'lastNameValue')), 0));
  }
  if ((regExp.test(lastName)) == false && lastName != '' || lastName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length < 2 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = false;
  } else if ((regExp.test(lastName)) == false && lastName == '' || lastName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length == 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/originalhomepage/components/557e31a13073b4848ca61837b9e70a38/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-last-name'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/originalhomepage/components/cdc2459c0f1ac842997b621a04744c0c/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var email, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  email = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'email'));
  regExp = (new RegExp('[a-zA-Z0-9.\\-_+]{1,}@[^\\W\\.][a-zA-Z0-9\\-]{0,}\\.[a-zA-Z]{1,}', 'g'));
  if (email.length <= 50) {
    ___arguments.context.pageData['emailValue'] = email;
  } else if (email.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['email'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['email'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'emailValue')), 0));
  }
  if ((regExp.test(email)) == false && email != '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = false;
  } else if ((regExp.test(email)) == false && email == '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/originalhomepage/components/556317981b853c56d8e8e837e5b16c9e/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-email'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/originalhomepage/components/d64247a69fb82b1b932f6a1191167d68/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-phone'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/originalhomepage/components/1712009c62182acf71f1e4e777ac98dd/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var phone, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  phone = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone'));
  regExp = (new RegExp('^[0-9+\\(\\)\\-\\s]+$', 'g'));
  if (phone.length <= 50) {
    ___arguments.context.pageData['phoneValue'] = phone;
  } else if (phone.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['phone'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['phone'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'phoneValue')), 0));
  }
  if (phone.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone')).length < 5 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = true;
  } else if ((regExp.test(phone)) && phone.length >= 5) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = false;
  } else if (phone == '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = true;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/originalhomepage/components/275519e4f1ff2a5c0d556bd7bfaaf6c5/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-website'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/originalhomepage/components/ddb987ac0b1a2e02086b043645e7decd/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var website, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  website = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website'));
  regExp = (new RegExp('[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)', 'gi'));
  if (website.length <= 249) {
    ___arguments.context.pageData['websiteValue'] = website;
  } else if (website.length > 249) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['website'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['website'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'websiteValue')), 0));
  }
  if ((regExp.test(website)) == false && website != '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = true;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = false;
  }
  if ((regExp.test((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website')))) == true && website.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website')).length) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/originalhomepage/components/b17c55c60f4dcdf2ae8fc66b95b63932/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var subject, regExp, firstName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  subject = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject'));
  regExp = (new RegExp('.{4,}', 'g'));
  if (subject.length <= 300) {
    ___arguments.context.pageData['subjectValue'] = subject;
  } else if (subject.length > 300) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['subject'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['subject'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'subjectValue')), 0));
  }
  if ((regExp.test(subject)) == false && subject != '' || subject.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length < 4 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = false;
  } else if ((regExp.test(subject)) == false && subject == '' || subject.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length == 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/originalhomepage/components/a38dcbb7299151a66062e5a8eaaf984c/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-subject'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/originalhomepage/components/101b130a0c5ff2588aa5637fc5fcdb27/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-message'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage']) {
    addItemToList(classes, 'c-form__label_alert');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/originalhomepage/components/6eb697a67eb600c29f860957a2ac3b3e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var error, message, subject, website, phone, email, lastName, firstName, checkStatusMessage, checkStatusSubject, checkStatusWebsite, checkStatusPhone, checkStatusEmail, checkStatusLastName, checkStatusFirstName, statusMessage, statusSubject, statusWebsite, statusPhone, statusEmail, statusLastName, statusFirstName, company, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function firstNameValidation() {
  regExp = (new RegExp('.{2,}', 'g'));
  if (!(regExp.test(firstName)) || firstName == '' || firstName == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusFirsName() {
  if (!(getObjectProperty(statusFirstName, 'status'))) {
    await ( async function (component) { component.focus() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('d2551aa8d85f0b8224661c4aaaa53f32')));
    if (firstName == '' || firstName == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function lastNameValidation() {
  regExp = (new RegExp('.{2,}', 'g'));
  if (!(regExp.test(lastName)) || lastName == '' || lastName == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusLastName2() {
  if (!(getObjectProperty(statusLastName, 'status'))) {
    await ( async function (component) { component.focus() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('f5bd0a2c6f0bde798ea8df133e9e7e3c')));
    if (lastName == '' || lastName == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function emailValidation() {
  regExp = (new RegExp('[a-zA-Z0-9.\\-_+]{1,}@[^\\W\\.][a-zA-Z0-9\\-]{0,}\\.[a-zA-Z]{1,}', 'g'));
  if (!(regExp.test(email)) || email == '' || email == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function phoneValidation() {
  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'hintPhone')) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusEmail2() {
  if (!(getObjectProperty(statusEmail, 'status'))) {
    await ( async function (component) { component.focus() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('cdc2459c0f1ac842997b621a04744c0c')));
    if (email == '' || email == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function websiteValidation() {
  regExp = (new RegExp('[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)', 'gi'));
  if (!(regExp.test(website)) && website != '' && website != null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function subjectValidation() {
  regExp = (new RegExp('.{4,}', 'g'));
  if (!(regExp.test(subject)) || subject == '' || subject == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusPhone2() {
  if (!(getObjectProperty(statusPhone, 'status'))) {
    await ( async function (component) { component.focus() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('1712009c62182acf71f1e4e777ac98dd')));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = true;
    return false;
  }
  return true
}

/**
 * Describe this function...
 */
async function messageValidation() {
  if (message == '' || message == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusWebsite2() {
  if (!(getObjectProperty(statusWebsite, 'status'))) {
    await ( async function (component) { component.focus() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('ddb987ac0b1a2e02086b043645e7decd')));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = true;
    return false;
  }
  return true
}

/**
 * Describe this function...
 */
async function checkStatusSubject2() {
  if (!(getObjectProperty(statusSubject, 'status'))) {
    await ( async function (component) { component.focus() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('b17c55c60f4dcdf2ae8fc66b95b63932')));
    if (subject == '' || subject == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function checkStatusMessage2() {
  if (!(getObjectProperty(statusMessage, 'status'))) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage'] = true;
    return false;
  }
  return true
}


  firstName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName'));
  lastName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName'));
  email = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'email'));
  phone = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone'));
  company = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'company'));
  website = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website'));
  subject = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject'));
  message = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'message'));
  statusFirstName = await firstNameValidation();
  statusLastName = await lastNameValidation();
  statusEmail = await emailValidation();
  statusPhone = await phoneValidation();
  statusWebsite = await websiteValidation();
  statusSubject = await subjectValidation();
  statusMessage = await messageValidation();
  checkStatusMessage = await checkStatusMessage2();
  checkStatusSubject = await checkStatusSubject2();
  checkStatusWebsite = await checkStatusWebsite2();
  checkStatusPhone = await checkStatusPhone2();
  checkStatusEmail = await checkStatusEmail2();
  checkStatusLastName = await checkStatusLastName2();
  checkStatusFirstName = await checkStatusFirsName();
  if (checkStatusFirstName != false && checkStatusLastName != false && checkStatusEmail != false && checkStatusPhone != false && checkStatusWebsite != false && checkStatusSubject != false && checkStatusMessage != false) {
    await Backendless.Data.of('Subscriber').save( ({ 'firstName': firstName,'lastName': lastName,'email': email,'phone': phone,'website': website,'subject': subject,'message': message }) );
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b65aa795e4d6a994b3ddd6f68533c80d', false);
    try {
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('df8b067a885dcda84a91376a3c4c5c7c', true);
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7224592bf6676a81e607676ddbc597e1', false);

    } catch (error) {
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('df8b067a885dcda84a91376a3c4c5c7c', false);
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7224592bf6676a81e607676ddbc597e1', true);
      console.log(error);

    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/originalhomepage/components/58c7b83b0c67da4ff3bef93451af2162/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var message, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  message = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'message'));
  regExp = (new RegExp('.{1,}', 'g'));
  if (message.length <= 249) {
    ___arguments.context.pageData['messageValue'] = message;
  } else if (message.length > 249) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['message'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['message'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'messageValue')), 0));
  }
  if ((regExp.test(message)) == false && message == '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage'] = true;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/originalhomepage/components/dc8e2162b0174608379cfba7549be8db/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      await ( async function (component) { component.el.scrollIntoView() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/originalhomepage/components/251dd247af0d2f6ebe6ccde3866c89b5/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isOpenModal')) {
    return ['modal', 'open'];
  }

  return ['modal']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/originalhomepage/components/70dd1dff3e215deab9dcc3e29a6566e6/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async onMounted(___arguments) {
    var counter, data, gallery, item, listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setIdItem(data) {
  counter = 1;
  return await Promise.all(data.map(async item => {  item['id'] = counter;
  counter = (typeof counter == 'number' ? counter : 0) + 1;
; return item;}))
}


  await new Promise(r => setTimeout(r, 1000 || 0));
  gallery = (getObjectProperty(___arguments.context.appData, 'galleryList'));
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', (await setIdItem(gallery)));
  listLength = gallery.length;
  ___arguments.context.pageData['totalSlide'] = listLength;
  ___arguments.context.pageData['currentSlide'] = 1;

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/originalhomepage/components/bb37f7327e62a50eb06507a61d6d1fc6/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == (getObjectProperty(___arguments.context.dataModel, 'id'))) {
    return ['modal__slider-item', 'active'];
  }

  return ['modal__slider-item']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/originalhomepage/components/831fddd4bb7ced9296de1aa550213577/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) > 1) {
    ___arguments.context.pageData['counterSlide2'] = ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == 1 ? listLength : (getObjectProperty(___arguments.context.pageData, 'counterSlide2')) - 1);
  }
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ___arguments.context.pageData['currentSlide'] = (getObjectProperty(___arguments.context.pageData, 'counterSlide2'));

  },
  /* handler:onClick */
  /* handler:onMouseOut */
  onMouseOut(___arguments) {
    var listLength;


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('831fddd4bb7ced9296de1aa550213577'))['opacity'] = '0';

  },
  /* handler:onMouseOut */
  /* handler:onMouseOver */
  onMouseOver(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == 1) {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('831fddd4bb7ced9296de1aa550213577'))['opacity'] = '0';
  } else {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('831fddd4bb7ced9296de1aa550213577'))['opacity'] = '1';
  }

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./pages/originalhomepage/components/dba21793ecfc81f1424a1ce6ad99a8b3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) < listLength) {
    ___arguments.context.pageData['counterSlide2'] = ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == listLength ? 1 : (getObjectProperty(___arguments.context.pageData, 'counterSlide2')) + 1);
  }
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ___arguments.context.pageData['currentSlide'] = (getObjectProperty(___arguments.context.pageData, 'counterSlide2'));

  },
  /* handler:onClick */
  /* handler:onMouseOut */
  onMouseOut(___arguments) {
    var listLength;


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('dba21793ecfc81f1424a1ce6ad99a8b3'))['opacity'] = '0';

  },
  /* handler:onMouseOut */
  /* handler:onMouseOver */
  onMouseOver(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == listLength) {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('dba21793ecfc81f1424a1ce6ad99a8b3'))['opacity'] = '0';
  } else {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('dba21793ecfc81f1424a1ce6ad99a8b3'))['opacity'] = '1';
  }

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./pages/originalhomepage/components/31c87ba294176e587b91b87c58e68a82/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/originalhomepage/components/16b9cb3fdc9bf1919465a18c77a6e467/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/originalhomepage/components/3607af0d8419be43455fc4ac16f9cc33/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var company;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  company = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'company'));
  if (company.length <= 100) {
    ___arguments.context.pageData['companyValue'] = company;
  } else if (company.length > 100) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['company'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['company'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'companyValue')), 0));
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/originalhomepage/components/30ea90dd05af67628fd821d5003c3d3d/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  onMounted(___arguments) {
      ___arguments.context.pageData['firstNameValue'] = '';
  ___arguments.context.pageData['lastNameValue'] = '';
  ___arguments.context.pageData['emailValue'] = '';
  ___arguments.context.pageData['phoneValue'] = '';
  ___arguments.context.pageData['companyValue'] = '';
  ___arguments.context.pageData['websiteValue'] = '';
  ___arguments.context.pageData['subjectValue'] = '';
  ___arguments.context.pageData['messageValue'] = '';

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/projectestimator/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  ['onBeforeEnter'](___arguments) {
    
  },
  /* handler:onBeforeEnter */
  /* handler:onEnter */
  ['onEnter'](___arguments) {
    var templist, tempContent, i;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  ___arguments.context.pageData['EmailAddressEnabled'] = true;
  ___arguments.context.pageData['WebpagesArray'] = [];
  ___arguments.context.pageData['HoursSpent'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('e75e2cffafaa06fe83d2037d87289c69'))['HoursSpent'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b'))['DefaultWebpages'] = 10;
  templist = [];
  for (i = 1; i <= 25; i++) {
    if (i <= 10) {
      tempContent = ({ [`pagename`]: String('Webpage ') + String(i),[`difficulty`]: 5,[`visible`]: true,[`pagetotal`]: 625,[`calctotal`]: 625 });
    } else {
      tempContent = ({ [`pagename`]: String('Webpage ') + String(i),[`difficulty`]: 5,[`visible`]: false,[`pagetotal`]: 0,[`calctotal`]: 625 });
    }
    addItemToList(templist, tempContent);
  }
  ___arguments.context.pageData['WebpagesArray'] = templist;
  ___arguments.context.pageData['disableSaveButton'] = true;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['BLOG_Amount'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Memberships_Amount'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['ECommerce_BaseAmount'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['ECommerce_ItemsTotal'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['LiveChat_Amount'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Forms_Amount'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Galleries_Amount'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Reservations_Amount'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Search_Amount'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['SocialMedia_Amount'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Analytics_Amount'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Products'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['ECommerce_ItemsTotal'] = 0;

  },
  /* handler:onEnter */
  /* handler:onScroll */
  ['onScroll'](___arguments) {
    var a, item, team, gallery, home2, features, testimonials, contact;


  if (___arguments.scrollTop) {
    ___arguments.context.pageData['scrollTop'] = true;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('70db3058bb94033b242afebff2b3a306'))['box-shadow'] = '0px 2px 4px rgba(0, 0, 0, 0.1)';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('70db3058bb94033b242afebff2b3a306'))['height'] = '60px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('70db3058bb94033b242afebff2b3a306'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('70db3058bb94033b242afebff2b3a306'))['color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('70db3058bb94033b242afebff2b3a306'))['padding-top'] = '7px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('70db3058bb94033b242afebff2b3a306'))['padding-bottom'] = '7px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '-5px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '45px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#777777';
  } else {
    ___arguments.context.pageData['scrollTop'] = false;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('70db3058bb94033b242afebff2b3a306'))['box-shadow'] = 'none';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('70db3058bb94033b242afebff2b3a306'))['height'] = '80px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('70db3058bb94033b242afebff2b3a306'))['background-color'] = 'transparent';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('70db3058bb94033b242afebff2b3a306'))['color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('70db3058bb94033b242afebff2b3a306'))['padding-top'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '80px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#ffffff';
  }

  },
  /* handler:onScroll */
  /* content */
}))

define('./pages/projectestimator/components/a76515983417d25f08444d69ea3d9a98/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    var list;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/projectestimator/components/e00d715ae43c8e6d51148ba3cb91c607/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/projectestimator/components/66b6ed079a3dea2425de28b612934885/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var templist, i, tempContent;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  for (i = 1; i <= 25; i++) {
    ((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)])['difficulty'] = (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('244dd3741aed2948c1b54ea6d3e485e7')), 'value'));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/projectestimator/components/1861fcb150236764dfb74c4243bcd2ac/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.dataModel, 'visible'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/projectestimator/components/87ee172bc9f37594a21e2d26a6fff7e2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var templist, tempContent, i;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(getObjectProperty(___arguments.context.pageData, 'WebpagesArray'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/projectestimator/components/66e7b39e17522c23905a8fd3c5466c02/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 1) {
    return 'Simple, static (no database) webpage with minimal graphics';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 2) {
    return 'Simple, static (no database) webpage with additional graphics';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 3) {
    return 'Simple static website design with additional graphics and SMS and/or Email notifications';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 4) {
    return 'Simple static website design with additional graphics and SMS and/or Email notifications and API access';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 5) {
    return 'Basic dynamic website design with additional graphics, SMS and/or Email notifications and API access with custom coding and/or database access';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 6) {
    return 'Basic dynamic website design with additional graphics, SMS and/or Email notifications and API access with custom coding and/or database access with Paypal or Stripe integration for accepting payments';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 7) {
    return 'Dynamic website design with additional graphics, SMS and/or Email notifications and API access with custom coding and/or database access with Paypal or Stripe integration for accepting payments and shopping cart integration';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 8) {
    return 'Dynamic website design with additional graphics, SMS and/or Email notifications and API access with custom coding and/or database access with Paypal or Stripe integration for accepting payments and shopping cart integration and multiple website versions (Desktop and Mobile)';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 9) {
    return 'Dynamic website design with additional graphics, SMS and/or Email notifications and API access with custom coding and/or database access with Paypal or Stripe integration for accepting payments and shopping cart integration and multiple website versions (Desktop and Mobile) WITH many complex integrations';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/projectestimator/components/244dd3741aed2948c1b54ea6d3e485e7/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['DefaultComplexity'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/projectestimator/components/f5c9922553f3a7aaf5ef5dfc48680bc9/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var i;

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function stopSetTimeout(timerId) {  const timers = getGlobalEntitiesMap('setTimeouts'); if (timerId && timers[timerId]) {    clearTimeout(timers[timerId]);    delete timers[timerId]; }}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function runSetTimeout(timerId, callback, delay) {  const timers = getGlobalEntitiesMap('setTimeouts'); const timer = setTimeout(callback, delay); if (timerId) {  stopSetTimeout(timerId);  timers[timerId] = timer }}


  ___arguments.context.pageData['DefaultWebpages'] = ___arguments.value;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b'))['DefaultWebpages'] = ___arguments.value;
  stopSetTimeout('lazyload');

  ;(function() {
    const callback = async () => {
        for (i = 1; i <= 25; i++) {
      if (i <= ___arguments.value) {
        ((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)])['visible'] = true;
      } else {
        ((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)])['visible'] = false;
      }
    }

    };

    const timerId = 'lazyload';
    const timerDelay = 1000;

    runSetTimeout(timerId, callback, timerDelay)
  })()

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/projectestimator/components/4ef79c77e86eaa368d834011e8cda171/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'ECommerce'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/projectestimator/components/de713ef471a9fec70157b93403f27b04/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['BLOG_Amount'] = 500;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['BLOG_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/projectestimator/components/29c48cc7a15973fc3109bfcf59ec13e2/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var temp, tempWithCurrency;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  tempWithCurrency = String('$') + String((Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'BLOG_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Memberships_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'ECommerce_BaseAmount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'ECommerce_ItemsTotal')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'LiveChat_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Forms_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Galleries_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Reservations_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'SocialMedia_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Search_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Analytics_Amount')))) + 0);
  temp = String((Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'BLOG_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Memberships_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'ECommerce_BaseAmount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'ECommerce_ItemsTotal')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'LiveChat_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Forms_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Galleries_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Reservations_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'SocialMedia_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Search_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Analytics_Amount')))) + 0);
  ___arguments.context.pageData['AddOnsCosts'] = temp;

  return tempWithCurrency

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/projectestimator/components/5f83f0e8576882f9c9aaa54469aeb052/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Memberships_Amount'] = 2250;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Memberships_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/projectestimator/components/58b8f4e9547bfa614e9217690fbab0b2/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['ECommerce_BaseAmount'] = 750;
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['ECommerce_ItemsTotal'] = ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Products')) * 3);
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['ECommerce_BaseAmount'] = 0;
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['ECommerce_ItemsTotal'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/projectestimator/components/f2b99e3a43a60d1f9ff7b5c2736720fe/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Products')) > 2500) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['ECommerce_ItemsTotal'] = (Math.ceil((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Products')) * 0.45 + 2250));
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['ECommerce_ItemsTotal'] = ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Products')) * 1.3);
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/projectestimator/components/d45daa93141a6e8e729590a2d24c5751/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['LiveChat_Amount'] = 500;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['LiveChat_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/projectestimator/components/d497f8d1e18f4973ae0ae619bd1d491e/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['SocialMedia_Amount'] = 750;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['SocialMedia_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/projectestimator/components/8d1600ecc2fddc59f2b7184ec6085993/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Forms_Amount'] = 750;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Forms_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/projectestimator/components/95070f3135f6408b3f22eb2c7d8f391f/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Galleries_Amount'] = 750;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Galleries_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/projectestimator/components/71b49e876d4e88760c3e61d98f1cf277/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Reservations_Amount'] = 750;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Reservations_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/projectestimator/components/09d66d9e954a65b9a4eef836531d4bba/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Search_Amount'] = 1000;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Search_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/projectestimator/components/4772888552d3bc287ef141851e8dbc2c/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Analytics_Amount'] = 500;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Analytics_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/projectestimator/components/d4e71b41b165d6722e88cf32cfda783a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var result;


  result = (await BackendlessUI.Functions.Custom['fn_74ce0ae175a6ebbf849380f73bae038c'](___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/projectestimator/components/74fc2cfb9f17f2b95eda4e76e85505e4/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) > 1) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/projectestimator/components/f0da70595adfb9966431bf426d91b3bb/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) > 3) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/projectestimator/components/e9287055e3ae50d94757d0725eb8a4ca/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) > 4) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/projectestimator/components/52716bb289c1dbe9ceb14121ca3b975a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) > 2) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/projectestimator/components/2b458397d6fe02d10a5c067e80d708d5/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) > 6) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/projectestimator/components/9fb260b6ce540ecb4df4ad7d08594b81/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) > 5) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/projectestimator/components/e529a6a16bfe2b77355b7137c0cfe1fe/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) > 7) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/projectestimator/components/e462cd359c3da084f00a14fbaec3ee07/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) > 8) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/projectestimator/components/a16836d6fd9825e2bfd5f5310ec3adc7/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac')['pagetotal'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) * 125);

  return (String('$') + String((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) * 125))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/projectestimator/components/fe5e824d2e85eed60054c17d9afe83ac/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var amountToIncrement, runningtotal;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  amountToIncrement = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) * 125;
  ___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac')['calctotal'] = amountToIncrement;

  return amountToIncrement

  },
  /* handler:onContentAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/projectestimator/components/c56e5f36b9891b340aefee62b778d4a6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var runningTotal, i;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(___arguments.context.getComponentDataStoreByUid('a5dd83a5a156273cb16ff67ee3eb96a7'));
  runningTotal = 0;
  console.log(getObjectProperty(___arguments.context.pageData, 'WebpagesArray'));
  for (i = 1; i <= 25; i++) {
    if ((getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)]), 'visible')) == true) {
      runningTotal = runningTotal + (Number((getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)]), 'pagetotal'))));
    }
  }
  console.log(runningTotal);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/projectestimator/components/8fe7c65c3cbd3c9f64c6a89dc5e2cded/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var runningTotal, i;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  runningTotal = 0;
  for (i = 1; i <= 25; i++) {
    if ((getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)]), 'visible')) == true) {
      runningTotal = runningTotal + (Number((getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)]), 'pagetotal'))));
    }
  }

  return runningTotal

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/projectestimator/components/a189e0bea60d20aafe954ed96dd2aefd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b'));
  console.log(___arguments.context.getComponentDataStoreByUid('48b946fad1bd282d1d972a021434a96b'));
  console.log(___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/projectestimator/components/47d3aabe6ac54e4f08768607192adcd6/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b')), 'ProjectName')) == '' || (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b')), 'ProjectName')) == null) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/projectestimator/components/6dc6677acde33cf33fc997e8c30926d2/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('a07ca54c6b28cc871a7bf7d3fbe1687f')), 'value')) == '' || (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('a07ca54c6b28cc871a7bf7d3fbe1687f')), 'value')) == null) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/projectestimator/components/794280351187cf017ac347edd373ac39/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b')), 'DefaultWebpages'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/projectestimator/components/08d5a4a4fb56e438a18fe9098cae5d0b/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var runningTotal, i;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  runningTotal = 0;
  for (i = 1; i <= 25; i++) {
    if ((getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)]), 'visible')) == true) {
      runningTotal = runningTotal + (Number((getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)]), 'pagetotal'))));
    }
  }
  ___arguments.context.pageData['DevelopmentCosts'] = runningTotal;

  return (String('$') + String(runningTotal))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/projectestimator/components/231c50e4532a25916ec2b51900b36dc8/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('$') + String(getObjectProperty(___arguments.context.pageData, 'AddOnsCosts')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/projectestimator/components/62715681cd529f7f7ab9ea847f96537f/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('e75e2cffafaa06fe83d2037d87289c69')['WeeksTotal'] = ((Number((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('62715681cd529f7f7ab9ea847f96537f')), 'content')))) / 5000);

  return ((Number((getObjectProperty(___arguments.context.pageData, 'AddOnsCosts')))) + (Number((getObjectProperty(___arguments.context.pageData, 'DevelopmentCosts')))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/projectestimator/components/706918f750bfc22e2152ed4a577f284e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var checkedelementscount, i, j, lastInList, list, templist, text;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  templist = 'Blog|Memberships/Profiles|E-Commerce|Live Chat|Social Media Integration|Forms/Leads Generation|Image/Video Gallery|Event Scheduling/Reservations|Online Search|Analytics/Tracking'.split('|');
  checkedelementscount = 10;
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Blog')) != true) {
    templist = (removeItemInList(templist, 'Blog', ''));
    checkedelementscount = checkedelementscount - 1;
  }
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Memberships')) != true) {
    templist = (removeItemInList(templist, 'Memberships/Profiles', ''));
    checkedelementscount = checkedelementscount - 1;
  }
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'ECommerce')) != true) {
    templist = (removeItemInList(templist, 'E-Commerce', ''));
    checkedelementscount = checkedelementscount - 1;
  }
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'LiveChat')) != true) {
    templist = (removeItemInList(templist, 'Live Chat', ''));
    checkedelementscount = checkedelementscount - 1;
  }
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'SocialMedia')) != true) {
    templist = (removeItemInList(templist, 'Social Media Integration', ''));
    checkedelementscount = checkedelementscount - 1;
  }
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Forms')) != true) {
    templist = (removeItemInList(templist, 'Forms/Leads Generation', ''));
    checkedelementscount = checkedelementscount - 1;
  }
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Gallery')) != true) {
    templist = (removeItemInList(templist, 'Image/Video Gallery', ''));
    checkedelementscount = checkedelementscount - 1;
  }
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Reservations')) != true) {
    templist = (removeItemInList(templist, 'Event Scheduling/Reservations', ''));
    checkedelementscount = checkedelementscount - 1;
  }
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Search')) != true) {
    templist = (removeItemInList(templist, 'Online Search', ''));
    checkedelementscount = checkedelementscount - 1;
  }
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Analytics')) != true) {
    templist = (removeItemInList(templist, 'Analytics/Tracking', ''));
    checkedelementscount = checkedelementscount - 1;
  }
  console.log(templist.join(', '));

  return (templist.join(', '))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/projectestimator/components/6ce50b5e85030ec73d1785f19dce3ce8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('71fa494e0583e28f9d66d2cb18170f33'))['content'] = (getObjectProperty((await Backendless.Data.of('HP_ProjectQuotes').save( ({ [`objectId`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('48b946fad1bd282d1d972a021434a96b'), 'objectId')),[`ProjectName`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('48b946fad1bd282d1d972a021434a96b'), 'ProjectName')),[`EmailAddress`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('48b946fad1bd282d1d972a021434a96b'), 'EmailAddress')),[`MainData`]: ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b')),[`WebpagesData`]: (getObjectProperty(___arguments.context.pageData, 'WebpagesArray')),[`AddOnsData`]: ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')),[`HoursSpent`]: (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('e75e2cffafaa06fe83d2037d87289c69')), 'HoursSpent')),[`PercentageRemaining`]: (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('e75e2cffafaa06fe83d2037d87289c69')), 'PercentageRemaining')),[`PercentageCompleted`]: (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('e75e2cffafaa06fe83d2037d87289c69')), 'PercentageCompleted')),[`isProject`]: (getObjectProperty(___arguments.context.pageData, 'isProject')) }) )), 'objectId'));
  (function (componentUid, options) { ___arguments.context.getComponentByUid(componentUid).options = options })('f909ce44844fedbdb69d065a5f298471', (await Backendless.Data.of('HP_ProjectQuotes').find(Backendless.DataQueryBuilder.create().setWhereClause((['EmailAddress = \'',(getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('a07ca54c6b28cc871a7bf7d3fbe1687f')), 'value')),'\' AND isTechnicalSupport = false'].join(''))).setPageSize(25))));

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'disableSaveButton'))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/projectestimator/components/a07ca54c6b28cc871a7bf7d3fbe1687f/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      (function (componentUid, options) { ___arguments.context.getComponentByUid(componentUid).options = options })('f909ce44844fedbdb69d065a5f298471', (await Backendless.Data.of('HP_ProjectQuotes').find(Backendless.DataQueryBuilder.create().setWhereClause((['EmailAddress = \'',___arguments.value,'\' AND isTechnicalSupport = false'].join(''))).setPageSize(25))));
  ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('4f4d3c38f3544e8fb8f5b0cc1629cf5a'))['content'] = ___arguments.value;
  ___arguments.context.pageData['DeleteBTNEnabled'] = false;
  if (___arguments.value == null || ___arguments.value == '') {
    ___arguments.context.pageData['disableSaveButton'] = true;
  } else {
    ___arguments.context.pageData['disableSaveButton'] = false;
  }

  },
  /* handler:onChange */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'EmailAddressEnabled')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/projectestimator/components/f909ce44844fedbdb69d065a5f298471/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var temp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  temp = (await Backendless.Data.of('HP_ProjectQuotes').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId = \'',___arguments.value,'\''].join(''))).setPageSize(1)))[0];
  ___arguments.context.pageData['EmailAddressEnabled'] = false;
  (function (componentUid, dataModel) { ___arguments.context.setComponentDataStoreByUid(componentUid, dataModel) })('48b946fad1bd282d1d972a021434a96b', (getObjectProperty(temp, 'MainData')));
  (function (componentUid, dataModel) { ___arguments.context.setComponentDataStoreByUid(componentUid, dataModel) })('a5dd83a5a156273cb16ff67ee3eb96a7', (getObjectProperty(temp, 'WebpagesData')));
  (function (componentUid, dataModel) { ___arguments.context.setComponentDataStoreByUid(componentUid, dataModel) })('6ae9afcb4ee4df5cb997b74b63689957', (getObjectProperty(temp, 'AddOnsData')));
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b'))['DefaultWebpages'] = (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f5c9922553f3a7aaf5ef5dfc48680bc9')), 'value'));
  ___arguments.context.pageData['DefaultWebpages'] = (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f5c9922553f3a7aaf5ef5dfc48680bc9')), 'value'));
  ___arguments.context.pageData['WebpagesArray'] = (getObjectProperty(temp, 'WebpagesData'));
  ___arguments.context.pageData['objectId'] = (getObjectProperty(temp, 'objectId'));
  ___arguments.context.pageData['isProject'] = (getObjectProperty(temp, 'isProject'));
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b'))['objectId'] = (getObjectProperty(temp, 'objectId'));
  ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('671e6a711ee19fdc53ece75e802f34f5'))['content'] = (getObjectProperty(temp, 'ProjectName'));
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('e75e2cffafaa06fe83d2037d87289c69'))['HoursSpent'] = (getObjectProperty(temp, 'HoursSpent'));
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('e75e2cffafaa06fe83d2037d87289c69'))['PercentageRemaining'] = (getObjectProperty(temp, 'PercentageRemaining'));
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('e75e2cffafaa06fe83d2037d87289c69'))['PercentageCompleted'] = (getObjectProperty(temp, 'PercentageCompleted'));
  if ((await Backendless.Data.of('HP_ProjectQuotes').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((['objectId = \'',___arguments.value,'\''].join(''))))) > 0) {
    ___arguments.context.pageData['DeleteBTNEnabled'] = true;
  } else {
    ___arguments.context.pageData['DeleteBTNEnabled'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/projectestimator/components/28cb5c8809e34e2e4b0a8d5a28e4d2f6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['objectId'] = '';
  ___arguments.context.getComponentDataStoreByUid('48b946fad1bd282d1d972a021434a96b')['objectId'] = null;
  ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('71fa494e0583e28f9d66d2cb18170f33'))['content'] = (getObjectProperty((await Backendless.Data.of('HP_ProjectQuotes').save( ({ [`objectId`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('48b946fad1bd282d1d972a021434a96b'), 'objectId')),[`ProjectName`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('48b946fad1bd282d1d972a021434a96b'), 'ProjectName')),[`EmailAddress`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('48b946fad1bd282d1d972a021434a96b'), 'EmailAddress')),[`MainData`]: ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b')),[`WebpagesData`]: (getObjectProperty(___arguments.context.pageData, 'WebpagesArray')),[`AddOnsData`]: ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')) }) )), 'objectId'));
  (function (componentUid, options) { ___arguments.context.getComponentByUid(componentUid).options = options })('f909ce44844fedbdb69d065a5f298471', (await Backendless.Data.of('HP_ProjectQuotes').find(Backendless.DataQueryBuilder.create().setWhereClause((['EmailAddress = \'',(getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('a07ca54c6b28cc871a7bf7d3fbe1687f')), 'value')),'\''].join(''))).setPageSize(25))));

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'disableSaveButton'))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/projectestimator/components/8c0f937a136917bc4ec4888ebb1ff658/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      if (((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('f909ce44844fedbdb69d065a5f298471')) != null) {
    await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('5204cf72e348720e06c78053428adfcb');
  } else {
  }

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'DeleteBTNEnabled')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/projectestimator/components/5204cf72e348720e06c78053428adfcb/bundle.js', [], () => ({
  /* content */
  /* handler:onClose */
  async ['onClose'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('5204cf72e348720e06c78053428adfcb');

  },
  /* handler:onClose */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.Data.of('HP_ProjectQuotes').remove( (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f909ce44844fedbdb69d065a5f298471')), 'value')) );
  (function (componentUid, options) { ___arguments.context.getComponentByUid(componentUid).options = options })('f909ce44844fedbdb69d065a5f298471', (await Backendless.Data.of('HP_ProjectQuotes').find(Backendless.DataQueryBuilder.create().setWhereClause((['EmailAddress = \'',(getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('a07ca54c6b28cc871a7bf7d3fbe1687f')), 'value')),'\' AND isTechnicalSupport = false'].join(''))).setPageSize(25))));
  ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('4f4d3c38f3544e8fb8f5b0cc1629cf5a'))['content'] = (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('a07ca54c6b28cc871a7bf7d3fbe1687f')), 'value'));
  ___arguments.context.pageData['DeleteBTNEnabled'] = false;
  ___arguments.context.pageData['DeleteBTNEnabled'] = false;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b'))['objectId'] = null;
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('5204cf72e348720e06c78053428adfcb');

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/projectestimator/components/d8db1272c40d27990eaf439e5a0a0fde/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await (async function() {
  	location.reload();
  })();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/projectestimator/components/07e72da914ac136c8ec298074afaa0ec/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('technicalsupportestimator', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/projectestimator/components/ca91ad7b4201046c6488ec3a78a37fd3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['EmailAddressEnabled'] = true;

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId')) == 'C1448329-3668-4275-8C4E-866D8C665C4F') {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/projectestimator/components/ee27e781d6ab1e12b431645218451712/bundle.js', [], () => ({
  /* content */
  /* handler:onTargetClick */
  async ['onTargetClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId')) == 'C1448329-3668-4275-8C4E-866D8C665C4F') {
    await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('ee27e781d6ab1e12b431645218451712', (!___arguments['isOpen']));
  }

  },
  /* handler:onTargetClick */
  /* content */
}))

define('./pages/projectestimator/components/ade32a9a8b1cb86a042d7ad24ad49a7f/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var PercentageCompleted, ProjectTotalHours, ApproxHoursRemaining, TotalPagesTotalValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function PercentageCompleted2() {
  TotalPagesTotalValue = (Number((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('62715681cd529f7f7ab9ea847f96537f')), 'content'))));
  ProjectTotalHours = TotalPagesTotalValue / 100;
  ApproxHoursRemaining = ProjectTotalHours - (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e75e2cffafaa06fe83d2037d87289c69'), 'HoursSpent'))));
  PercentageCompleted = (Number.parseFloat(((100 / ProjectTotalHours) * 100)).toFixed(1));
  return PercentageCompleted
}


  ___arguments.context.getComponentDataStoreByUid('e75e2cffafaa06fe83d2037d87289c69')['HoursSpent'] = (Number(___arguments.value));

  },
  /* handler:onChange */
  /* handler:onKeyDown */
  async ['onKeyDown'](___arguments) {
    var PercentageCompleted, ProjectTotalHours, TotalHoursSpent, TotalPagesTotalValue, temp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function PercentageCompleted2() {
  TotalPagesTotalValue = (Number((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('62715681cd529f7f7ab9ea847f96537f')), 'content'))));
  ProjectTotalHours = TotalPagesTotalValue / 100;
  TotalHoursSpent = (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e75e2cffafaa06fe83d2037d87289c69'), 'HoursSpent'))));
  PercentageCompleted = (Number.parseFloat(((TotalHoursSpent / ProjectTotalHours) * 100)).toFixed(1));
  return PercentageCompleted
}


  if (___arguments.keyCode == 13) {
    temp = await PercentageCompleted2();
    ___arguments.context.getComponentDataStoreByUid('e75e2cffafaa06fe83d2037d87289c69')['PercentageCompleted'] = (Number.parseFloat(temp).toFixed(0));
    ___arguments.context.getComponentDataStoreByUid('e75e2cffafaa06fe83d2037d87289c69')['PercentageRemaining'] = (Number.parseFloat((100 - temp)).toFixed(0));
    await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('ee27e781d6ab1e12b431645218451712', false);
  }

  },
  /* handler:onKeyDown */
  /* handler:onBlur */
  async ['onBlur'](___arguments) {
      await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('ee27e781d6ab1e12b431645218451712', false);

  },
  /* handler:onBlur */
  /* content */
}))

define('./pages/projectestimator/components/6ab9e4db7118fe3d00386be8f7bcdf1d/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e75e2cffafaa06fe83d2037d87289c69'), 'HoursSpent'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/projectestimator/components/a3991201f38769e6e8682dc6f42b0ddc/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  async ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId')) == 'C1448329-3668-4275-8C4E-866D8C665C4F') {
    return false;
  } else {
    return true;
  }

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/projectestimator/components/9862f60e4cfa8908aba4dddd853fc2a8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'URL')), true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/projectestimator/components/3967c99152c77cc5c7a164c71a043b92/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId')) == 'C1448329-3668-4275-8C4E-866D8C665C4F') {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  ['onBeforeEnter'](___arguments) {
    
  },
  /* handler:onBeforeEnter */
  /* handler:onEnter */
  ['onEnter'](___arguments) {
    var templist, tempContent, i;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  ___arguments.context.pageData['EmailAddressEnabled'] = true;
  ___arguments.context.pageData['WebpagesArray'] = [];
  ___arguments.context.pageData['HoursSpent'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('e75e2cffafaa06fe83d2037d87289c69'))['HoursSpent'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b'))['DefaultWebpages'] = 10;
  templist = [];
  for (i = 1; i <= 25; i++) {
    if (i <= 10) {
      tempContent = ({ [`pagename`]: String('Webpage ') + String(i),[`difficulty`]: 5,[`visible`]: true,[`pagetotal`]: 625,[`calctotal`]: 625 });
    } else {
      tempContent = ({ [`pagename`]: String('Webpage ') + String(i),[`difficulty`]: 5,[`visible`]: false,[`pagetotal`]: 0,[`calctotal`]: 625 });
    }
    addItemToList(templist, tempContent);
  }
  ___arguments.context.pageData['WebpagesArray'] = templist;
  ___arguments.context.pageData['disableSaveButton'] = true;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['BLOG_Amount'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Memberships_Amount'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['ECommerce_BaseAmount'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['ECommerce_ItemsTotal'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['LiveChat_Amount'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Forms_Amount'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Galleries_Amount'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Reservations_Amount'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Search_Amount'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['SocialMedia_Amount'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Analytics_Amount'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Products'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['ECommerce_ItemsTotal'] = 0;

  },
  /* handler:onEnter */
  /* handler:onScroll */
  ['onScroll'](___arguments) {
    var a, item, team, gallery, home2, features, testimonials, contact;


  if (___arguments.scrollTop) {
    ___arguments.context.pageData['scrollTop'] = true;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('70db3058bb94033b242afebff2b3a306'))['box-shadow'] = '0px 2px 4px rgba(0, 0, 0, 0.1)';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('70db3058bb94033b242afebff2b3a306'))['height'] = '60px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('70db3058bb94033b242afebff2b3a306'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('70db3058bb94033b242afebff2b3a306'))['color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('70db3058bb94033b242afebff2b3a306'))['padding-top'] = '7px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('70db3058bb94033b242afebff2b3a306'))['padding-bottom'] = '7px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '-5px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '45px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#777777';
  } else {
    ___arguments.context.pageData['scrollTop'] = false;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('70db3058bb94033b242afebff2b3a306'))['box-shadow'] = 'none';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('70db3058bb94033b242afebff2b3a306'))['height'] = '80px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('70db3058bb94033b242afebff2b3a306'))['background-color'] = 'transparent';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('70db3058bb94033b242afebff2b3a306'))['color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('70db3058bb94033b242afebff2b3a306'))['padding-top'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '80px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#ffffff';
  }

  },
  /* handler:onScroll */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/a76515983417d25f08444d69ea3d9a98/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    var list;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/e00d715ae43c8e6d51148ba3cb91c607/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/66b6ed079a3dea2425de28b612934885/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var templist, i, tempContent;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  for (i = 1; i <= 25; i++) {
    ((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)])['difficulty'] = (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('244dd3741aed2948c1b54ea6d3e485e7')), 'value'));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/1861fcb150236764dfb74c4243bcd2ac/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.dataModel, 'visible'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/87ee172bc9f37594a21e2d26a6fff7e2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var templist, tempContent, i;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(getObjectProperty(___arguments.context.pageData, 'WebpagesArray'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/66e7b39e17522c23905a8fd3c5466c02/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 1) {
    return 'Simple, static (no database) webpage with minimal graphics';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 2) {
    return 'Simple, static (no database) webpage with additional graphics';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 3) {
    return 'Simple static website design with additional graphics and SMS and/or Email notifications';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 4) {
    return 'Simple static website design with additional graphics and SMS and/or Email notifications and API access';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 5) {
    return 'Basic dynamic website design with additional graphics, SMS and/or Email notifications and API access with custom coding and/or database access';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 6) {
    return 'Basic dynamic website design with additional graphics, SMS and/or Email notifications and API access with custom coding and/or database access with Paypal or Stripe integration for accepting payments';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 7) {
    return 'Dynamic website design with additional graphics, SMS and/or Email notifications and API access with custom coding and/or database access with Paypal or Stripe integration for accepting payments and shopping cart integration';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 8) {
    return 'Dynamic website design with additional graphics, SMS and/or Email notifications and API access with custom coding and/or database access with Paypal or Stripe integration for accepting payments and shopping cart integration and multiple website versions (Desktop and Mobile)';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 9) {
    return 'Dynamic website design with additional graphics, SMS and/or Email notifications and API access with custom coding and/or database access with Paypal or Stripe integration for accepting payments and shopping cart integration and multiple website versions (Desktop and Mobile) WITH many complex integrations';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/244dd3741aed2948c1b54ea6d3e485e7/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['DefaultComplexity'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/f5c9922553f3a7aaf5ef5dfc48680bc9/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var i;

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function stopSetTimeout(timerId) {  const timers = getGlobalEntitiesMap('setTimeouts'); if (timerId && timers[timerId]) {    clearTimeout(timers[timerId]);    delete timers[timerId]; }}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function runSetTimeout(timerId, callback, delay) {  const timers = getGlobalEntitiesMap('setTimeouts'); const timer = setTimeout(callback, delay); if (timerId) {  stopSetTimeout(timerId);  timers[timerId] = timer }}


  ___arguments.context.pageData['DefaultWebpages'] = ___arguments.value;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b'))['DefaultWebpages'] = ___arguments.value;
  stopSetTimeout('lazyload');

  ;(function() {
    const callback = async () => {
        for (i = 1; i <= 25; i++) {
      if (i <= ___arguments.value) {
        ((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)])['visible'] = true;
      } else {
        ((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)])['visible'] = false;
      }
    }

    };

    const timerId = 'lazyload';
    const timerDelay = 1000;

    runSetTimeout(timerId, callback, timerDelay)
  })()

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/4ef79c77e86eaa368d834011e8cda171/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'ECommerce'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/de713ef471a9fec70157b93403f27b04/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['BLOG_Amount'] = 500;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['BLOG_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/29c48cc7a15973fc3109bfcf59ec13e2/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var temp, tempWithCurrency;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  tempWithCurrency = String('$') + String((Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'BLOG_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Memberships_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'ECommerce_BaseAmount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'ECommerce_ItemsTotal')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'LiveChat_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Forms_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Galleries_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Reservations_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'SocialMedia_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Search_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Analytics_Amount')))) + 0);
  temp = String((Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'BLOG_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Memberships_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'ECommerce_BaseAmount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'ECommerce_ItemsTotal')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'LiveChat_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Forms_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Galleries_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Reservations_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'SocialMedia_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Search_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Analytics_Amount')))) + 0);
  ___arguments.context.pageData['AddOnsCosts'] = temp;

  return tempWithCurrency

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/5f83f0e8576882f9c9aaa54469aeb052/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Memberships_Amount'] = 2250;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Memberships_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/58b8f4e9547bfa614e9217690fbab0b2/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['ECommerce_BaseAmount'] = 750;
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['ECommerce_ItemsTotal'] = ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Products')) * 3);
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['ECommerce_BaseAmount'] = 0;
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['ECommerce_ItemsTotal'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/f2b99e3a43a60d1f9ff7b5c2736720fe/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Products')) > 2500) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['ECommerce_ItemsTotal'] = (Math.ceil((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Products')) * 0.45 + 2250));
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['ECommerce_ItemsTotal'] = ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Products')) * 1.3);
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/d45daa93141a6e8e729590a2d24c5751/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['LiveChat_Amount'] = 500;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['LiveChat_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/d497f8d1e18f4973ae0ae619bd1d491e/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['SocialMedia_Amount'] = 750;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['SocialMedia_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/8d1600ecc2fddc59f2b7184ec6085993/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Forms_Amount'] = 750;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Forms_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/95070f3135f6408b3f22eb2c7d8f391f/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Galleries_Amount'] = 750;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Galleries_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/71b49e876d4e88760c3e61d98f1cf277/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Reservations_Amount'] = 750;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Reservations_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/09d66d9e954a65b9a4eef836531d4bba/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Search_Amount'] = 1000;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Search_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/4772888552d3bc287ef141851e8dbc2c/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Analytics_Amount'] = 500;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Analytics_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/d4e71b41b165d6722e88cf32cfda783a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var result;


  result = (await BackendlessUI.Functions.Custom['fn_74ce0ae175a6ebbf849380f73bae038c'](___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/74fc2cfb9f17f2b95eda4e76e85505e4/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) > 1) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/f0da70595adfb9966431bf426d91b3bb/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) > 3) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/e9287055e3ae50d94757d0725eb8a4ca/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) > 4) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/52716bb289c1dbe9ceb14121ca3b975a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) > 2) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/2b458397d6fe02d10a5c067e80d708d5/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) > 6) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/9fb260b6ce540ecb4df4ad7d08594b81/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) > 5) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/e529a6a16bfe2b77355b7137c0cfe1fe/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) > 7) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/e462cd359c3da084f00a14fbaec3ee07/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) > 8) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/a16836d6fd9825e2bfd5f5310ec3adc7/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac')['pagetotal'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) * 125);

  return (String('$') + String((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) * 125))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/fe5e824d2e85eed60054c17d9afe83ac/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var amountToIncrement, runningtotal;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  amountToIncrement = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) * 125;
  ___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac')['calctotal'] = amountToIncrement;

  return amountToIncrement

  },
  /* handler:onContentAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/c56e5f36b9891b340aefee62b778d4a6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var runningTotal, i;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(___arguments.context.getComponentDataStoreByUid('a5dd83a5a156273cb16ff67ee3eb96a7'));
  runningTotal = 0;
  console.log(getObjectProperty(___arguments.context.pageData, 'WebpagesArray'));
  for (i = 1; i <= 25; i++) {
    if ((getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)]), 'visible')) == true) {
      runningTotal = runningTotal + (Number((getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)]), 'pagetotal'))));
    }
  }
  console.log(runningTotal);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/8fe7c65c3cbd3c9f64c6a89dc5e2cded/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var runningTotal, i;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  runningTotal = 0;
  for (i = 1; i <= 25; i++) {
    if ((getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)]), 'visible')) == true) {
      runningTotal = runningTotal + (Number((getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)]), 'pagetotal'))));
    }
  }

  return runningTotal

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/a189e0bea60d20aafe954ed96dd2aefd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b'));
  console.log(___arguments.context.getComponentDataStoreByUid('48b946fad1bd282d1d972a021434a96b'));
  console.log(___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/47d3aabe6ac54e4f08768607192adcd6/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b')), 'ProjectName')) == '' || (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b')), 'ProjectName')) == null) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/6dc6677acde33cf33fc997e8c30926d2/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('a07ca54c6b28cc871a7bf7d3fbe1687f')), 'value')) == '' || (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('a07ca54c6b28cc871a7bf7d3fbe1687f')), 'value')) == null) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/794280351187cf017ac347edd373ac39/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b')), 'DefaultWebpages'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/08d5a4a4fb56e438a18fe9098cae5d0b/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var runningTotal, i;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  runningTotal = 0;
  for (i = 1; i <= 25; i++) {
    if ((getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)]), 'visible')) == true) {
      runningTotal = runningTotal + (Number((getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)]), 'pagetotal'))));
    }
  }
  ___arguments.context.pageData['DevelopmentCosts'] = runningTotal;

  return (String('$') + String(runningTotal))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/231c50e4532a25916ec2b51900b36dc8/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('$') + String(getObjectProperty(___arguments.context.pageData, 'AddOnsCosts')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/62715681cd529f7f7ab9ea847f96537f/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('e75e2cffafaa06fe83d2037d87289c69')['WeeksTotal'] = ((Number((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('62715681cd529f7f7ab9ea847f96537f')), 'content')))) / 5000);

  return ((Number((getObjectProperty(___arguments.context.pageData, 'AddOnsCosts')))) + (Number((getObjectProperty(___arguments.context.pageData, 'DevelopmentCosts')))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/706918f750bfc22e2152ed4a577f284e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var checkedelementscount, i, j, lastInList, list, templist, text;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  templist = 'Blog|Memberships/Profiles|E-Commerce|Live Chat|Social Media Integration|Forms/Leads Generation|Image/Video Gallery|Event Scheduling/Reservations|Online Search|Analytics/Tracking'.split('|');
  checkedelementscount = 10;
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Blog')) != true) {
    templist = (removeItemInList(templist, 'Blog', ''));
    checkedelementscount = checkedelementscount - 1;
  }
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Memberships')) != true) {
    templist = (removeItemInList(templist, 'Memberships/Profiles', ''));
    checkedelementscount = checkedelementscount - 1;
  }
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'ECommerce')) != true) {
    templist = (removeItemInList(templist, 'E-Commerce', ''));
    checkedelementscount = checkedelementscount - 1;
  }
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'LiveChat')) != true) {
    templist = (removeItemInList(templist, 'Live Chat', ''));
    checkedelementscount = checkedelementscount - 1;
  }
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'SocialMedia')) != true) {
    templist = (removeItemInList(templist, 'Social Media Integration', ''));
    checkedelementscount = checkedelementscount - 1;
  }
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Forms')) != true) {
    templist = (removeItemInList(templist, 'Forms/Leads Generation', ''));
    checkedelementscount = checkedelementscount - 1;
  }
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Gallery')) != true) {
    templist = (removeItemInList(templist, 'Image/Video Gallery', ''));
    checkedelementscount = checkedelementscount - 1;
  }
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Reservations')) != true) {
    templist = (removeItemInList(templist, 'Event Scheduling/Reservations', ''));
    checkedelementscount = checkedelementscount - 1;
  }
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Search')) != true) {
    templist = (removeItemInList(templist, 'Online Search', ''));
    checkedelementscount = checkedelementscount - 1;
  }
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Analytics')) != true) {
    templist = (removeItemInList(templist, 'Analytics/Tracking', ''));
    checkedelementscount = checkedelementscount - 1;
  }
  console.log(templist.join(', '));

  return (templist.join(', '))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/6ce50b5e85030ec73d1785f19dce3ce8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('71fa494e0583e28f9d66d2cb18170f33'))['content'] = (getObjectProperty((await Backendless.Data.of('HP_ProjectQuotes').save( ({ [`objectId`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('48b946fad1bd282d1d972a021434a96b'), 'objectId')),[`ProjectName`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('48b946fad1bd282d1d972a021434a96b'), 'ProjectName')),[`EmailAddress`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('48b946fad1bd282d1d972a021434a96b'), 'EmailAddress')),[`MainData`]: ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b')),[`WebpagesData`]: (getObjectProperty(___arguments.context.pageData, 'WebpagesArray')),[`AddOnsData`]: ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')),[`HoursSpent`]: (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('e75e2cffafaa06fe83d2037d87289c69')), 'HoursSpent')),[`PercentageRemaining`]: (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('e75e2cffafaa06fe83d2037d87289c69')), 'PercentageRemaining')),[`PercentageCompleted`]: (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('e75e2cffafaa06fe83d2037d87289c69')), 'PercentageCompleted')),[`isProject`]: (getObjectProperty(___arguments.context.pageData, 'isProject')) }) )), 'objectId'));
  (function (componentUid, options) { ___arguments.context.getComponentByUid(componentUid).options = options })('f909ce44844fedbdb69d065a5f298471', (await Backendless.Data.of('HP_ProjectQuotes').find(Backendless.DataQueryBuilder.create().setWhereClause((['EmailAddress = \'',(getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('a07ca54c6b28cc871a7bf7d3fbe1687f')), 'value')),'\' AND isTechnicalSupport = false'].join(''))).setPageSize(25))));

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'disableSaveButton'))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/a07ca54c6b28cc871a7bf7d3fbe1687f/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      (function (componentUid, options) { ___arguments.context.getComponentByUid(componentUid).options = options })('f909ce44844fedbdb69d065a5f298471', (await Backendless.Data.of('HP_ProjectQuotes').find(Backendless.DataQueryBuilder.create().setWhereClause((['EmailAddress = \'',___arguments.value,'\' AND isTechnicalSupport = false'].join(''))).setPageSize(25))));
  ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('4f4d3c38f3544e8fb8f5b0cc1629cf5a'))['content'] = ___arguments.value;
  ___arguments.context.pageData['DeleteBTNEnabled'] = false;
  ___arguments.context.pageData['DeleteBTNEnabled'] = false;
  if (___arguments.value == null || ___arguments.value == '') {
    ___arguments.context.pageData['disableSaveButton'] = true;
  } else {
    ___arguments.context.pageData['disableSaveButton'] = false;
  }

  },
  /* handler:onChange */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'EmailAddressEnabled')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/f909ce44844fedbdb69d065a5f298471/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var temp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  temp = (await Backendless.Data.of('HP_ProjectQuotes').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId = \'',___arguments.value,'\''].join(''))).setPageSize(1)))[0];
  ___arguments.context.pageData['EmailAddressEnabled'] = false;
  (function (componentUid, dataModel) { ___arguments.context.setComponentDataStoreByUid(componentUid, dataModel) })('48b946fad1bd282d1d972a021434a96b', (getObjectProperty(temp, 'MainData')));
  (function (componentUid, dataModel) { ___arguments.context.setComponentDataStoreByUid(componentUid, dataModel) })('a5dd83a5a156273cb16ff67ee3eb96a7', (getObjectProperty(temp, 'WebpagesData')));
  (function (componentUid, dataModel) { ___arguments.context.setComponentDataStoreByUid(componentUid, dataModel) })('6ae9afcb4ee4df5cb997b74b63689957', (getObjectProperty(temp, 'AddOnsData')));
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b'))['DefaultWebpages'] = (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f5c9922553f3a7aaf5ef5dfc48680bc9')), 'value'));
  ___arguments.context.pageData['DefaultWebpages'] = (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f5c9922553f3a7aaf5ef5dfc48680bc9')), 'value'));
  ___arguments.context.pageData['WebpagesArray'] = (getObjectProperty(temp, 'WebpagesData'));
  ___arguments.context.pageData['objectId'] = (getObjectProperty(temp, 'objectId'));
  ___arguments.context.pageData['isProject'] = (getObjectProperty(temp, 'isProject'));
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b'))['objectId'] = (getObjectProperty(temp, 'objectId'));
  ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('671e6a711ee19fdc53ece75e802f34f5'))['content'] = (getObjectProperty(temp, 'ProjectName'));
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('e75e2cffafaa06fe83d2037d87289c69'))['HoursSpent'] = (getObjectProperty(temp, 'HoursSpent'));
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('e75e2cffafaa06fe83d2037d87289c69'))['PercentageRemaining'] = (getObjectProperty(temp, 'PercentageRemaining'));
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('e75e2cffafaa06fe83d2037d87289c69'))['PercentageCompleted'] = (getObjectProperty(temp, 'PercentageCompleted'));
  if ((await Backendless.Data.of('HP_ProjectQuotes').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((['objectId = \'',___arguments.value,'\''].join(''))))) > 0) {
    ___arguments.context.pageData['DeleteBTNEnabled'] = true;
  } else {
    ___arguments.context.pageData['DeleteBTNEnabled'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/28cb5c8809e34e2e4b0a8d5a28e4d2f6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['objectId'] = '';
  ___arguments.context.getComponentDataStoreByUid('48b946fad1bd282d1d972a021434a96b')['objectId'] = null;
  ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('71fa494e0583e28f9d66d2cb18170f33'))['content'] = (getObjectProperty((await Backendless.Data.of('HP_ProjectQuotes').save( ({ [`objectId`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('48b946fad1bd282d1d972a021434a96b'), 'objectId')),[`ProjectName`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('48b946fad1bd282d1d972a021434a96b'), 'ProjectName')),[`EmailAddress`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('48b946fad1bd282d1d972a021434a96b'), 'EmailAddress')),[`MainData`]: ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b')),[`WebpagesData`]: (getObjectProperty(___arguments.context.pageData, 'WebpagesArray')),[`AddOnsData`]: ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')) }) )), 'objectId'));
  (function (componentUid, options) { ___arguments.context.getComponentByUid(componentUid).options = options })('f909ce44844fedbdb69d065a5f298471', (await Backendless.Data.of('HP_ProjectQuotes').find(Backendless.DataQueryBuilder.create().setWhereClause((['EmailAddress = \'',(getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('a07ca54c6b28cc871a7bf7d3fbe1687f')), 'value')),'\''].join(''))).setPageSize(25))));

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'disableSaveButton'))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/8c0f937a136917bc4ec4888ebb1ff658/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      if (((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('f909ce44844fedbdb69d065a5f298471')) != null) {
    await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('5204cf72e348720e06c78053428adfcb');
  } else {
  }

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'DeleteBTNEnabled')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/5204cf72e348720e06c78053428adfcb/bundle.js', [], () => ({
  /* content */
  /* handler:onClose */
  async ['onClose'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('5204cf72e348720e06c78053428adfcb');

  },
  /* handler:onClose */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.Data.of('HP_ProjectQuotes').remove( (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f909ce44844fedbdb69d065a5f298471')), 'value')) );
  (function (componentUid, options) { ___arguments.context.getComponentByUid(componentUid).options = options })('f909ce44844fedbdb69d065a5f298471', (await Backendless.Data.of('HP_ProjectQuotes').find(Backendless.DataQueryBuilder.create().setWhereClause((['EmailAddress = \'',(getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('a07ca54c6b28cc871a7bf7d3fbe1687f')), 'value')),'\''].join(''))).setPageSize(25))));
  ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('4f4d3c38f3544e8fb8f5b0cc1629cf5a'))['content'] = (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('a07ca54c6b28cc871a7bf7d3fbe1687f')), 'value'));
  ___arguments.context.pageData['DeleteBTNEnabled'] = false;
  ___arguments.context.pageData['DeleteBTNEnabled'] = false;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b'))['objectId'] = null;
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('5204cf72e348720e06c78053428adfcb');

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/d8db1272c40d27990eaf439e5a0a0fde/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await (async function() {
  	location.reload();
  })();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/07e72da914ac136c8ec298074afaa0ec/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('technicalsupportestimator', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/ca91ad7b4201046c6488ec3a78a37fd3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['EmailAddressEnabled'] = true;

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId')) == 'C1448329-3668-4275-8C4E-866D8C665C4F') {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/ee27e781d6ab1e12b431645218451712/bundle.js', [], () => ({
  /* content */
  /* handler:onTargetClick */
  async ['onTargetClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId')) == 'C1448329-3668-4275-8C4E-866D8C665C4F') {
    await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('ee27e781d6ab1e12b431645218451712', (!___arguments['isOpen']));
  }

  },
  /* handler:onTargetClick */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/ade32a9a8b1cb86a042d7ad24ad49a7f/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var PercentageCompleted, ProjectTotalHours, ApproxHoursRemaining, TotalPagesTotalValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function PercentageCompleted2() {
  TotalPagesTotalValue = (Number((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('62715681cd529f7f7ab9ea847f96537f')), 'content'))));
  ProjectTotalHours = TotalPagesTotalValue / 100;
  ApproxHoursRemaining = ProjectTotalHours - (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e75e2cffafaa06fe83d2037d87289c69'), 'HoursSpent'))));
  PercentageCompleted = (Number.parseFloat(((100 / ProjectTotalHours) * 100)).toFixed(1));
  return PercentageCompleted
}


  ___arguments.context.getComponentDataStoreByUid('e75e2cffafaa06fe83d2037d87289c69')['HoursSpent'] = (Number(___arguments.value));

  },
  /* handler:onChange */
  /* handler:onKeyDown */
  async ['onKeyDown'](___arguments) {
    var PercentageCompleted, ProjectTotalHours, TotalHoursSpent, TotalPagesTotalValue, temp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function PercentageCompleted2() {
  TotalPagesTotalValue = (Number((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('62715681cd529f7f7ab9ea847f96537f')), 'content'))));
  ProjectTotalHours = TotalPagesTotalValue / 100;
  TotalHoursSpent = (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e75e2cffafaa06fe83d2037d87289c69'), 'HoursSpent'))));
  PercentageCompleted = (Number.parseFloat(((TotalHoursSpent / ProjectTotalHours) * 100)).toFixed(1));
  return PercentageCompleted
}


  if (___arguments.keyCode == 13) {
    temp = await PercentageCompleted2();
    ___arguments.context.getComponentDataStoreByUid('e75e2cffafaa06fe83d2037d87289c69')['PercentageCompleted'] = (Number.parseFloat(temp).toFixed(0));
    ___arguments.context.getComponentDataStoreByUid('e75e2cffafaa06fe83d2037d87289c69')['PercentageRemaining'] = (Number.parseFloat((100 - temp)).toFixed(0));
    await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('ee27e781d6ab1e12b431645218451712', false);
  }

  },
  /* handler:onKeyDown */
  /* handler:onBlur */
  async ['onBlur'](___arguments) {
      await ( async function (componentUid, isOpen) {  ___arguments.context.getComponentByUid(componentUid).setIsOpen(isOpen) })('ee27e781d6ab1e12b431645218451712', false);

  },
  /* handler:onBlur */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/6ab9e4db7118fe3d00386be8f7bcdf1d/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e75e2cffafaa06fe83d2037d87289c69'), 'HoursSpent'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/a3991201f38769e6e8682dc6f42b0ddc/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  async ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId')) == 'C1448329-3668-4275-8C4E-866D8C665C4F') {
    return false;
  } else {
    return true;
  }

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/9862f60e4cfa8908aba4dddd853fc2a8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'URL')), true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/projectestimator_6-24_backup/components/3967c99152c77cc5c7a164c71a043b92/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId')) == 'C1448329-3668-4275-8C4E-866D8C665C4F') {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/projectestimatorbackup/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  ['onBeforeEnter'](___arguments) {
    
  },
  /* handler:onBeforeEnter */
  /* handler:onEnter */
  ['onEnter'](___arguments) {
    var templist, tempContent, i;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['WebpagesArray'] = [];
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b'))['DefaultWebpages'] = 10;
  templist = [];
  for (i = 1; i <= 25; i++) {
    if (i <= 10) {
      tempContent = ({ [`pagename`]: String('Webpage ') + String(i),[`difficulty`]: 5,[`visible`]: true,[`pagetotal`]: 625,[`calctotal`]: 625 });
    } else {
      tempContent = ({ [`pagename`]: String('Webpage ') + String(i),[`difficulty`]: 5,[`visible`]: false,[`pagetotal`]: 0,[`calctotal`]: 625 });
    }
    addItemToList(templist, tempContent);
  }
  ___arguments.context.pageData['WebpagesArray'] = templist;
  console.log(getObjectProperty(___arguments.context.pageData, 'WebpagesArray'));
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['BLOG_Amount'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Memberships_Amount'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['ECommerce_BaseAmount'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['ECommerce_ItemsTotal'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['LiveChat_Amount'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Forms_Amount'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Galleries_Amount'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Reservations_Amount'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Search_Amount'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['SocialMedia_Amount'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Analytics_Amount'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Products'] = 250;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['ECommerce_ItemsTotal'] = 0;

  },
  /* handler:onEnter */
  /* handler:onScroll */
  async ['onScroll'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')), 'anchor'))) {
      item['active'] = true;
    } else {
      item['active'] = false;
    }
  ; return item;}))));

  },
  /* handler:onScroll */
  /* content */
}))

define('./pages/projectestimatorbackup/components/a76515983417d25f08444d69ea3d9a98/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    var list;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/projectestimatorbackup/components/e00d715ae43c8e6d51148ba3cb91c607/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/projectestimatorbackup/components/66b6ed079a3dea2425de28b612934885/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var i, tempContent, templist;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  for (i = 1; i <= 25; i++) {
    ((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)])['difficulty'] = (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('244dd3741aed2948c1b54ea6d3e485e7')), 'value'));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/projectestimatorbackup/components/1861fcb150236764dfb74c4243bcd2ac/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.dataModel, 'visible'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/projectestimatorbackup/components/87ee172bc9f37594a21e2d26a6fff7e2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var templist, tempContent, i;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(getObjectProperty(___arguments.context.pageData, 'WebpagesArray'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/projectestimatorbackup/components/66e7b39e17522c23905a8fd3c5466c02/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 1) {
    return 'Simple, static (no database) webpage with minimal graphics.';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 2) {
    return 'Simple, static (no database) webpage with additional graphics.';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 3) {
    return 'Three';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 4) {
    return 'Four';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 5) {
    return 'Five';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 6) {
    return 'Six';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 7) {
    return 'Seven';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 8) {
    return 'Eight';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 9) {
    return 'Extremely complex dynamic (database-driven) webpage layout with many complex integrations.<br>Such as: map integration, credit card processing, etc...<br>For Multiple Targets: Desktop, Tablet, and Mobile';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/projectestimatorbackup/components/244dd3741aed2948c1b54ea6d3e485e7/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['DefaultComplexity'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/projectestimatorbackup/components/f5c9922553f3a7aaf5ef5dfc48680bc9/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var i;

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function stopSetTimeout(timerId) {  const timers = getGlobalEntitiesMap('setTimeouts'); if (timerId && timers[timerId]) {    clearTimeout(timers[timerId]);    delete timers[timerId]; }}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function runSetTimeout(timerId, callback, delay) {  const timers = getGlobalEntitiesMap('setTimeouts'); const timer = setTimeout(callback, delay); if (timerId) {  stopSetTimeout(timerId);  timers[timerId] = timer }}


  ___arguments.context.pageData['DefaultWebpages'] = ___arguments.value;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b'))['DefaultWebpages'] = ___arguments.value;
  stopSetTimeout('lazyload');

  ;(function() {
    const callback = async () => {
        for (i = 1; i <= 25; i++) {
      if (i <= ___arguments.value) {
        ((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)])['visible'] = true;
      } else {
        ((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)])['visible'] = false;
      }
    }

    };

    const timerId = 'lazyload';
    const timerDelay = 1000;

    runSetTimeout(timerId, callback, timerDelay)
  })()

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/projectestimatorbackup/components/4ef79c77e86eaa368d834011e8cda171/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'ECommerce'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/projectestimatorbackup/components/de713ef471a9fec70157b93403f27b04/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['BLOG_Amount'] = 500;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['BLOG_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/projectestimatorbackup/components/29c48cc7a15973fc3109bfcf59ec13e2/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var temp, tempWithCurrency;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  tempWithCurrency = String('$') + String((Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'BLOG_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Memberships_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'ECommerce_BaseAmount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'ECommerce_ItemsTotal')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'LiveChat_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Forms_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Galleries_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Reservations_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'SocialMedia_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Search_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Analytics_Amount')))) + 0);
  temp = String((Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'BLOG_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Memberships_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'ECommerce_BaseAmount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'ECommerce_ItemsTotal')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'LiveChat_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Forms_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Galleries_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Reservations_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'SocialMedia_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Search_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Analytics_Amount')))) + 0);
  ___arguments.context.pageData['AddOnsCosts'] = temp;

  return tempWithCurrency

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/projectestimatorbackup/components/5f83f0e8576882f9c9aaa54469aeb052/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Memberships_Amount'] = 2250;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Memberships_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/projectestimatorbackup/components/58b8f4e9547bfa614e9217690fbab0b2/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['ECommerce_BaseAmount'] = 750;
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['ECommerce_ItemsTotal'] = ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Products')) * 3);
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['ECommerce_BaseAmount'] = 0;
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['ECommerce_ItemsTotal'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/projectestimatorbackup/components/f2b99e3a43a60d1f9ff7b5c2736720fe/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Products')) > 2500) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['ECommerce_ItemsTotal'] = (Math.ceil((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Products')) * 0.45 + 2250));
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['ECommerce_ItemsTotal'] = ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Products')) * 1.3);
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/projectestimatorbackup/components/d45daa93141a6e8e729590a2d24c5751/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['LiveChat_Amount'] = 500;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['LiveChat_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/projectestimatorbackup/components/d497f8d1e18f4973ae0ae619bd1d491e/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['SocialMedia_Amount'] = 750;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['SocialMedia_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/projectestimatorbackup/components/8d1600ecc2fddc59f2b7184ec6085993/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Forms_Amount'] = 750;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Forms_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/projectestimatorbackup/components/95070f3135f6408b3f22eb2c7d8f391f/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Galleries_Amount'] = 750;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Galleries_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/projectestimatorbackup/components/71b49e876d4e88760c3e61d98f1cf277/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Reservations_Amount'] = 750;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Reservations_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/projectestimatorbackup/components/09d66d9e954a65b9a4eef836531d4bba/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Search_Amount'] = 1000;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Search_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/projectestimatorbackup/components/4772888552d3bc287ef141851e8dbc2c/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Analytics_Amount'] = 500;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Analytics_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/projectestimatorbackup/components/d4e71b41b165d6722e88cf32cfda783a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var result;


  result = (await BackendlessUI.Functions.Custom['fn_74ce0ae175a6ebbf849380f73bae038c'](___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/projectestimatorbackup/components/74fc2cfb9f17f2b95eda4e76e85505e4/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) > 1) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/projectestimatorbackup/components/f0da70595adfb9966431bf426d91b3bb/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) > 3) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/projectestimatorbackup/components/e9287055e3ae50d94757d0725eb8a4ca/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) > 4) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/projectestimatorbackup/components/52716bb289c1dbe9ceb14121ca3b975a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) > 2) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/projectestimatorbackup/components/2b458397d6fe02d10a5c067e80d708d5/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) > 6) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/projectestimatorbackup/components/9fb260b6ce540ecb4df4ad7d08594b81/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) > 5) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/projectestimatorbackup/components/e529a6a16bfe2b77355b7137c0cfe1fe/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) > 7) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/projectestimatorbackup/components/e462cd359c3da084f00a14fbaec3ee07/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) > 8) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/projectestimatorbackup/components/a16836d6fd9825e2bfd5f5310ec3adc7/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac')['pagetotal'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) * 125);

  return (String('$') + String((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) * 125))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/projectestimatorbackup/components/fe5e824d2e85eed60054c17d9afe83ac/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var amountToIncrement, runningtotal;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  amountToIncrement = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) * 125;
  ___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac')['calctotal'] = amountToIncrement;

  return amountToIncrement

  },
  /* handler:onContentAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/projectestimatorbackup/components/c56e5f36b9891b340aefee62b778d4a6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var runningTotal, i;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  runningTotal = 0;
  console.log(getObjectProperty(___arguments.context.pageData, 'WebpagesArray'));
  for (i = 1; i <= 25; i++) {
    if ((getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)]), 'visible')) == true) {
      runningTotal = runningTotal + (Number((getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)]), 'pagetotal'))));
    }
  }
  console.log(runningTotal);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/projectestimatorbackup/components/8fe7c65c3cbd3c9f64c6a89dc5e2cded/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var runningTotal, i;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  runningTotal = 0;
  for (i = 1; i <= 25; i++) {
    if ((getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)]), 'visible')) == true) {
      runningTotal = runningTotal + (Number((getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)]), 'pagetotal'))));
    }
  }

  return runningTotal

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/projectestimatorbackup/components/a189e0bea60d20aafe954ed96dd2aefd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/projectestimatorbackup/components/47d3aabe6ac54e4f08768607192adcd6/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b')), 'ProjectName')) == '' || (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b')), 'ProjectName')) == null) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/projectestimatorbackup/components/6dc6677acde33cf33fc997e8c30926d2/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b')), 'EmailAddress')) == '' || (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b')), 'EmailAddress')) == null) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/projectestimatorbackup/components/794280351187cf017ac347edd373ac39/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b')), 'DefaultWebpages'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/projectestimatorbackup/components/08d5a4a4fb56e438a18fe9098cae5d0b/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var runningTotal, i;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  runningTotal = 0;
  for (i = 1; i <= 25; i++) {
    if ((getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)]), 'visible')) == true) {
      runningTotal = runningTotal + (Number((getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)]), 'pagetotal'))));
    }
  }
  ___arguments.context.pageData['DevelopmentCosts'] = runningTotal;

  return (String('$') + String(runningTotal))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/projectestimatorbackup/components/231c50e4532a25916ec2b51900b36dc8/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('$') + String(getObjectProperty(___arguments.context.pageData, 'AddOnsCosts')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/projectestimatorbackup/components/62715681cd529f7f7ab9ea847f96537f/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var temp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((Number((getObjectProperty(___arguments.context.pageData, 'AddOnsCosts')))) + (Number((getObjectProperty(___arguments.context.pageData, 'DevelopmentCosts')))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/projectestimatorbackup/components/706918f750bfc22e2152ed4a577f284e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var checkedelementscount, i, j, lastInList, list, templist, text;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  templist = 'Blog|Memberships/Profiles|E-Commerce|Live Chat|Social Media Integration|Forms/Leads Generation|Image/Video Gallery|Event Scheduling/Reservations|Online Search|Analytics/Tracking'.split('|');
  checkedelementscount = 10;
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Blog')) != true) {
    templist = (removeItemInList(templist, 'Blog', ''));
    checkedelementscount = checkedelementscount - 1;
  }
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Memberships')) != true) {
    templist = (removeItemInList(templist, 'Memberships/Profiles', ''));
    checkedelementscount = checkedelementscount - 1;
  }
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'ECommerce')) != true) {
    templist = (removeItemInList(templist, 'E-Commerce', ''));
    checkedelementscount = checkedelementscount - 1;
  }
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'LiveChat')) != true) {
    templist = (removeItemInList(templist, 'Live Chat', ''));
    checkedelementscount = checkedelementscount - 1;
  }
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'SocialMedia')) != true) {
    templist = (removeItemInList(templist, 'Social Media Integration', ''));
    checkedelementscount = checkedelementscount - 1;
  }
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Forms')) != true) {
    templist = (removeItemInList(templist, 'Forms/Leads Generation', ''));
    checkedelementscount = checkedelementscount - 1;
  }
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Gallery')) != true) {
    templist = (removeItemInList(templist, 'Image/Video Gallery', ''));
    checkedelementscount = checkedelementscount - 1;
  }
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Reservations')) != true) {
    templist = (removeItemInList(templist, 'Event Scheduling/Reservations', ''));
    checkedelementscount = checkedelementscount - 1;
  }
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Search')) != true) {
    templist = (removeItemInList(templist, 'Online Search', ''));
    checkedelementscount = checkedelementscount - 1;
  }
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Analytics')) != true) {
    templist = (removeItemInList(templist, 'Analytics/Tracking', ''));
    checkedelementscount = checkedelementscount - 1;
  }
  console.log(templist.join(', '));

  return (templist.join(', '))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/technicalsupportestimator/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var i, localCurrentUser, tempContent, templist, user;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function LogonOnPageRefreshReturn() {
  Backendless.setCurrentUserToken(((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('HPUserCookie')));
  user = (await Backendless.UserService.getCurrentUser(false));
  ___arguments.context.appData['currentUser'] = user;
  ___arguments.context.appData['companyNamesLIST'] = (getObjectProperty(((await Backendless.Data.of('Users').loadRelations((getObjectProperty((getObjectProperty(___arguments.context.appData, 'currentUser')), 'objectId')), Backendless.LoadRelationsQueryBuilder.create().setRelationName('Linked_Companies').setPageSize(10).setOffset(0)))[0]), 'Company_Name'));
  ___arguments.context.appData['currentCompanyObjectId'] = (getObjectProperty(((await Backendless.Data.of('Users').loadRelations((getObjectProperty((getObjectProperty(___arguments.context.appData, 'currentUser')), 'objectId')), Backendless.LoadRelationsQueryBuilder.create().setRelationName('Linked_Companies').setPageSize(1).setOffset(0)))[0]), 'objectId'));
  return user
}

/**
 * Describe this function...
 */
async function LoginOnPageRefresh() {
  Backendless.setCurrentUserToken(((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('HPUserCookie')));
  user = (await Backendless.UserService.getCurrentUser(false));
  ___arguments.context.appData['currentUser'] = user;
  ___arguments.context.appData['companyNamesLIST'] = (getObjectProperty(((await Backendless.Data.of('Users').loadRelations((getObjectProperty((getObjectProperty(___arguments.context.appData, 'currentUser')), 'objectId')), Backendless.LoadRelationsQueryBuilder.create().setRelationName('Linked_Companies').setPageSize(10).setOffset(0)))[0]), 'Company_Name'));
  ___arguments.context.appData['currentCompanyObjectId'] = (getObjectProperty(((await Backendless.Data.of('Users').loadRelations((getObjectProperty((getObjectProperty(___arguments.context.appData, 'currentUser')), 'objectId')), Backendless.LoadRelationsQueryBuilder.create().setRelationName('Linked_Companies').setPageSize(1).setOffset(0)))[0]), 'objectId'));
}


  if (true) {
    ___arguments.context.pageData['EmailAddressEnabled'] = false;
    ___arguments.context.pageData['WebpagesArray'] = [];
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b'))['DefaultWebpages'] = 10;
    templist = [];
    ___arguments.context.pageData['disableSaveButton'] = true;
    ___arguments.context.pageData['disableDuplicateButton'] = true;
    for (i = 1; i <= 50; i++) {
      if (i <= 10) {
        tempContent = ({ [`pagename`]: String('User/Device:  ') + String(i),[`difficulty`]: 8,[`visible`]: true,[`pagetotal`]: 625,[`calctotal`]: 625 });
      } else {
        tempContent = ({ [`pagename`]: String('User/Device: ') + String(i),[`difficulty`]: 8,[`visible`]: false,[`pagetotal`]: 0,[`calctotal`]: 625 });
      }
      addItemToList(templist, tempContent);
    }
    ___arguments.context.pageData['WebpagesArray'] = templist;
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['SAGE_Amount'] = 0;
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Memberships_Amount'] = 0;
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['ECommerce_BaseAmount'] = 0;
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['ECommerce_ItemsTotal'] = 0;
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['LiveChat_Amount'] = 0;
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Forms_Amount'] = 0;
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Galleries_Amount'] = 0;
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Reservations_Amount'] = 0;
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Search_Amount'] = 0;
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['SocialMedia_Amount'] = 0;
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Analytics_Amount'] = 0;
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Products'] = 0;
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['ECommerce_ItemsTotal'] = 0;
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('4f4d3c38f3544e8fb8f5b0cc1629cf5a'))['content'] = (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('a07ca54c6b28cc871a7bf7d3fbe1687f')), 'value'));
    if (await Backendless.UserService.isValidLogin()) {
      ___arguments.context.pageData['BoundEmailAddress'] = (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'email'));
      ___arguments.context.pageData['EmailAddress'] = (getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'email'));
    } else {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('5f3c6245ceaf0788a2a5562df5fd0917');
    }
  }

  },
  /* handler:onEnter */
  /* handler:onScroll */
  ['onScroll'](___arguments) {
    var item;


  if (___arguments.scrollTop) {
    ___arguments.context.pageData['scrollTop'] = true;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('91c7e66d1eac341848f6fdb6f922e322'))['box-shadow'] = '0px 2px 4px rgba(0, 0, 0, 0.1)';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('91c7e66d1eac341848f6fdb6f922e322'))['height'] = '60px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('91c7e66d1eac341848f6fdb6f922e322'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('91c7e66d1eac341848f6fdb6f922e322'))['color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('91c7e66d1eac341848f6fdb6f922e322'))['padding-top'] = '7px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('91c7e66d1eac341848f6fdb6f922e322'))['padding-bottom'] = '7px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '-5px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '45px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#777777';
  } else {
    ___arguments.context.pageData['scrollTop'] = false;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('91c7e66d1eac341848f6fdb6f922e322'))['box-shadow'] = 'none';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('91c7e66d1eac341848f6fdb6f922e322'))['height'] = '80px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('91c7e66d1eac341848f6fdb6f922e322'))['background-color'] = 'transparent';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('91c7e66d1eac341848f6fdb6f922e322'))['color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('91c7e66d1eac341848f6fdb6f922e322'))['padding-top'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '80px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#ffffff';
  }

  },
  /* handler:onScroll */
  /* content */
}))

define('./pages/technicalsupportestimator/components/a76515983417d25f08444d69ea3d9a98/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    var list;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/technicalsupportestimator/components/e00d715ae43c8e6d51148ba3cb91c607/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/technicalsupportestimator/components/66b6ed079a3dea2425de28b612934885/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var templist, i, tempContent;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  for (i = 1; i <= 50; i++) {
    ((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)])['difficulty'] = (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('244dd3741aed2948c1b54ea6d3e485e7')), 'value'));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/technicalsupportestimator/components/1861fcb150236764dfb74c4243bcd2ac/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.dataModel, 'visible'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/technicalsupportestimator/components/87ee172bc9f37594a21e2d26a6fff7e2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var templist, tempContent, i;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(getObjectProperty(___arguments.context.pageData, 'WebpagesArray'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/technicalsupportestimator/components/66e7b39e17522c23905a8fd3c5466c02/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 1) {
    return 'Very rarely use this device. (Minimum)';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 2) {
    return 'This device is used about 2 hours per day';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 3) {
    return 'This device is used about 3 hours per day';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 4) {
    return 'This device is used about 4 hours per day';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 5) {
    return 'This device is used about 5 hours per day';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 6) {
    return 'This device is used about 6 hours per day';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 7) {
    return 'This device is used about 7 hours per day';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 8) {
    return 'This device is used about 8 hours per day  (A standard shift)';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 9) {
    return 'This device is used about 9 hours per day';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 10) {
    return 'This device is used about 10 hours per day';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 11) {
    return 'This device is used about 11 hours per day';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 12) {
    return 'This device is used about 12 hours per day';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 13) {
    return 'This device is used about 13 hours per day';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 14) {
    return 'This device is used about 14 hours per day';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 15) {
    return 'This device is used about 15 hours per day (aren\'t we the over achiever?)';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 16) {
    return 'This device is used for about two shifts or 16 hours per day';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 24) {
    return 'This device is used around-the-clock, or is a server or a VM';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/technicalsupportestimator/components/244dd3741aed2948c1b54ea6d3e485e7/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['DefaultComplexity'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/technicalsupportestimator/components/f5c9922553f3a7aaf5ef5dfc48680bc9/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var i;

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function stopSetTimeout(timerId) {  const timers = getGlobalEntitiesMap('setTimeouts'); if (timerId && timers[timerId]) {    clearTimeout(timers[timerId]);    delete timers[timerId]; }}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function runSetTimeout(timerId, callback, delay) {  const timers = getGlobalEntitiesMap('setTimeouts'); const timer = setTimeout(callback, delay); if (timerId) {  stopSetTimeout(timerId);  timers[timerId] = timer }}


  ___arguments.context.pageData['DefaultWebpages'] = ___arguments.value;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b'))['DefaultWebpages'] = ___arguments.value;
  stopSetTimeout('lazyload');

  ;(function() {
    const callback = async () => {
        for (i = 1; i <= 50; i++) {
      if (i <= ___arguments.value) {
        ((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)])['visible'] = true;
      } else {
        ((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)])['visible'] = false;
      }
    }

    };

    const timerId = 'lazyload';
    const timerDelay = 1000;

    runSetTimeout(timerId, callback, timerDelay)
  })()

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/technicalsupportestimator/components/4ef79c77e86eaa368d834011e8cda171/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'ECommerce'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/technicalsupportestimator/components/de713ef471a9fec70157b93403f27b04/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['SAGE_Amount'] = 350;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['SAGE_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/technicalsupportestimator/components/29c48cc7a15973fc3109bfcf59ec13e2/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var tempWithCurrency, temp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  tempWithCurrency = String('$') + String((Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'SAGE_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Memberships_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'ECommerce_BaseAmount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'ECommerce_ItemsTotal')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'LiveChat_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Forms_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Galleries_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Reservations_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'SocialMedia_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Search_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Analytics_Amount')))) + 0);
  temp = String((Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'SAGE_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Memberships_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'ECommerce_BaseAmount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'ECommerce_ItemsTotal')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'LiveChat_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Forms_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Galleries_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Reservations_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'SocialMedia_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Search_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Analytics_Amount')))) + 0);
  ___arguments.context.pageData['AddOnsCosts'] = temp;

  return tempWithCurrency

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/technicalsupportestimator/components/5f83f0e8576882f9c9aaa54469aeb052/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Memberships_Amount'] = 25;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Memberships_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/technicalsupportestimator/components/58b8f4e9547bfa614e9217690fbab0b2/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['ECommerce_BaseAmount'] = 0;
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['ECommerce_ItemsTotal'] = (Math.ceil((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Products')) * 17.5));
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['ECommerce_BaseAmount'] = 0;
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['ECommerce_ItemsTotal'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/technicalsupportestimator/components/f2b99e3a43a60d1f9ff7b5c2736720fe/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Products')) > 0) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['ECommerce_ItemsTotal'] = (Math.ceil((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Products')) * 17.5));
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['ECommerce_ItemsTotal'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/technicalsupportestimator/components/d45daa93141a6e8e729590a2d24c5751/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['LiveChat_Amount'] = 35;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['LiveChat_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/technicalsupportestimator/components/d497f8d1e18f4973ae0ae619bd1d491e/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['SocialMedia_Amount'] = 35;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['SocialMedia_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/technicalsupportestimator/components/8d1600ecc2fddc59f2b7184ec6085993/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Forms_Amount'] = 50;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Forms_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/technicalsupportestimator/components/95070f3135f6408b3f22eb2c7d8f391f/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Galleries_Amount'] = 125;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Galleries_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/technicalsupportestimator/components/71b49e876d4e88760c3e61d98f1cf277/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Reservations_Amount'] = 35;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Reservations_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/technicalsupportestimator/components/09d66d9e954a65b9a4eef836531d4bba/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Search_Amount'] = 1000;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Search_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/technicalsupportestimator/components/4772888552d3bc287ef141851e8dbc2c/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Analytics_Amount'] = 500;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Analytics_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/technicalsupportestimator/components/d4e71b41b165d6722e88cf32cfda783a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var result;


  result = (await BackendlessUI.Functions.Custom['fn_74ce0ae175a6ebbf849380f73bae038c'](___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/technicalsupportestimator/components/74fc2cfb9f17f2b95eda4e76e85505e4/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) > 1) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/technicalsupportestimator/components/f0da70595adfb9966431bf426d91b3bb/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) > 3) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/technicalsupportestimator/components/e9287055e3ae50d94757d0725eb8a4ca/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) > 4) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/technicalsupportestimator/components/52716bb289c1dbe9ceb14121ca3b975a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) > 2) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/technicalsupportestimator/components/2b458397d6fe02d10a5c067e80d708d5/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) > 6) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/technicalsupportestimator/components/9fb260b6ce540ecb4df4ad7d08594b81/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) > 5) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/technicalsupportestimator/components/e529a6a16bfe2b77355b7137c0cfe1fe/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) > 7) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/technicalsupportestimator/components/e462cd359c3da084f00a14fbaec3ee07/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) > 8) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/technicalsupportestimator/components/a16836d6fd9825e2bfd5f5310ec3adc7/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac')['pagetotal'] = (Math.ceil((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) * 5));

  return (String('$') + String(Math.ceil((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) * 5)))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/technicalsupportestimator/components/c56e5f36b9891b340aefee62b778d4a6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var runningTotal, i;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(___arguments.context.getComponentDataStoreByUid('a5dd83a5a156273cb16ff67ee3eb96a7'));
  runningTotal = 0;
  console.log(getObjectProperty(___arguments.context.pageData, 'WebpagesArray'));
  for (i = 1; i <= 25; i++) {
    if ((getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)]), 'visible')) == true) {
      runningTotal = runningTotal + (Number((getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)]), 'pagetotal'))));
    }
  }
  console.log(runningTotal);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/technicalsupportestimator/components/8fe7c65c3cbd3c9f64c6a89dc5e2cded/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var runningTotal, i;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  runningTotal = 0;
  for (i = 1; i <= 50; i++) {
    if ((getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)]), 'visible')) == true) {
      runningTotal = runningTotal + (Number((getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)]), 'pagetotal'))));
    }
  }

  return runningTotal

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/technicalsupportestimator/components/a189e0bea60d20aafe954ed96dd2aefd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b'));
  console.log(___arguments.context.getComponentDataStoreByUid('48b946fad1bd282d1d972a021434a96b'));
  console.log(___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/technicalsupportestimator/components/47d3aabe6ac54e4f08768607192adcd6/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b')), 'ProjectName')) == '' || (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b')), 'ProjectName')) == null) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/technicalsupportestimator/components/6dc6677acde33cf33fc997e8c30926d2/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'EmailAddress')) == '' || (getObjectProperty(___arguments.context.pageData, 'EmailAddress')) == null) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['EmailAddressEnabled'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/technicalsupportestimator/components/794280351187cf017ac347edd373ac39/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b')), 'DefaultWebpages'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/technicalsupportestimator/components/08d5a4a4fb56e438a18fe9098cae5d0b/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var runningTotal, i;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  runningTotal = 0;
  for (i = 1; i <= 50; i++) {
    if ((getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)]), 'visible')) == true) {
      runningTotal = runningTotal + (Number((getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)]), 'pagetotal'))));
    }
  }
  ___arguments.context.pageData['DevelopmentCosts'] = runningTotal;

  return (String('$') + String(runningTotal))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/technicalsupportestimator/components/231c50e4532a25916ec2b51900b36dc8/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('$') + String(getObjectProperty(___arguments.context.pageData, 'AddOnsCosts')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/technicalsupportestimator/components/62715681cd529f7f7ab9ea847f96537f/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (Math.ceil((Number((getObjectProperty(___arguments.context.pageData, 'AddOnsCosts')))) + (Number((getObjectProperty(___arguments.context.pageData, 'DevelopmentCosts'))))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/technicalsupportestimator/components/706918f750bfc22e2152ed4a577f284e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var templist, checkedelementscount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  templist = 'SAGE|Peachtree Accounting|Computer Updates|MFA|Sage Estimating|Microsoft SQL Server|Network/Firewall Management|MySQL DB Management|Online Search|Analytics/Tracking'.split('|');
  checkedelementscount = 10;
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Blog')) != true) {
    templist = (removeItemInList(templist, 'SAGE', ''));
    checkedelementscount = checkedelementscount - 1;
  }
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Memberships')) != true) {
    templist = (removeItemInList(templist, 'Peachtree Accounting', ''));
    checkedelementscount = checkedelementscount - 1;
  }
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'ECommerce')) != true) {
    templist = (removeItemInList(templist, 'Quickbooks', ''));
    checkedelementscount = checkedelementscount - 1;
  }
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'LiveChat')) != true) {
    templist = (removeItemInList(templist, 'MFA', ''));
    checkedelementscount = checkedelementscount - 1;
  }
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'SocialMedia')) != true) {
    templist = (removeItemInList(templist, 'Sage Estimating', ''));
    checkedelementscount = checkedelementscount - 1;
  }
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Forms')) != true) {
    templist = (removeItemInList(templist, 'Microsoft SQL Server', ''));
    checkedelementscount = checkedelementscount - 1;
  }
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Gallery')) != true) {
    templist = (removeItemInList(templist, 'Network/Firewall Management', ''));
    checkedelementscount = checkedelementscount - 1;
  }
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Reservations')) != true) {
    templist = (removeItemInList(templist, 'MySQL DB Management', ''));
    checkedelementscount = checkedelementscount - 1;
  }
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Search')) != true) {
    templist = (removeItemInList(templist, 'Online Search', ''));
    checkedelementscount = checkedelementscount - 1;
  }
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Analytics')) != true) {
    templist = (removeItemInList(templist, 'Analytics/Tracking', ''));
    checkedelementscount = checkedelementscount - 1;
  }
  console.log(templist.join(', '));

  return (templist.join(', '))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/technicalsupportestimator/components/6ce50b5e85030ec73d1785f19dce3ce8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('71fa494e0583e28f9d66d2cb18170f33'))['content'] = (getObjectProperty((await Backendless.Data.of('HP_ProjectQuotes').save( ({ [`objectId`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('48b946fad1bd282d1d972a021434a96b'), 'objectId')),[`ProjectName`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('48b946fad1bd282d1d972a021434a96b'), 'ProjectName')),[`EmailAddress`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('48b946fad1bd282d1d972a021434a96b'), 'EmailAddress')),[`MainData`]: ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b')),[`WebpagesData`]: (getObjectProperty(___arguments.context.pageData, 'WebpagesArray')),[`AddOnsData`]: ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')),[`isTechnicalSupport`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('48b946fad1bd282d1d972a021434a96b'), 'isTechnicalSupport')) }) )), 'objectId'));
  (function (componentUid, options) { ___arguments.context.getComponentByUid(componentUid).options = options })('f909ce44844fedbdb69d065a5f298471', (await Backendless.Data.of('HP_ProjectQuotes').find(Backendless.DataQueryBuilder.create().setWhereClause((['EmailAddress = \'',(getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('a07ca54c6b28cc871a7bf7d3fbe1687f')), 'value')),'\' AND isTechnicalSupport = true'].join(''))).setPageSize(25))));

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'disableSaveButton'))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/technicalsupportestimator/components/a07ca54c6b28cc871a7bf7d3fbe1687f/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'EmailAddressEnabled')))

  },
  /* handler:onDisabledStateAssignment */
  /* handler:onBlur */
  async ['onBlur'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log('Lost focus Event:');
  if (await Backendless.UserService.isValidLogin()) {
    if ((getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId')) == 'C1448329-3668-4275-8C4E-866D8C665C4F') {
      ___arguments.context.pageData['EmailAddress'] = (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('a07ca54c6b28cc871a7bf7d3fbe1687f')), 'value'));
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('4f4d3c38f3544e8fb8f5b0cc1629cf5a'))['content'] = (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('a07ca54c6b28cc871a7bf7d3fbe1687f')), 'value'));
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('4f4d3c38f3544e8fb8f5b0cc1629cf5a'))['content'] = (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('a07ca54c6b28cc871a7bf7d3fbe1687f')), 'value'));
      ___arguments.context.pageData['DeleteBTNEnabled'] = false;
      ___arguments.context.pageData['disableSaveButton'] = true;
      if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('a07ca54c6b28cc871a7bf7d3fbe1687f')), 'value')) == null || (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('a07ca54c6b28cc871a7bf7d3fbe1687f')), 'value')) == '') {
        ___arguments.context.pageData['disableSaveButton'] = true;
      } else {
      }
    } else {
    }
  } else {
    await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('5f3c6245ceaf0788a2a5562df5fd0917');
  }

  },
  /* handler:onBlur */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log('On Change Event: ');
  if (await Backendless.UserService.isValidLogin()) {
    if ((getObjectProperty((await Backendless.UserService.getCurrentUser(true)), 'objectId')) == 'C1448329-3668-4275-8C4E-866D8C665C4F') {
      ___arguments.context.pageData['EmailAddress'] = ___arguments.value;
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('4f4d3c38f3544e8fb8f5b0cc1629cf5a'))['content'] = ___arguments.value;
    }
  } else {
    await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('5f3c6245ceaf0788a2a5562df5fd0917');
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/technicalsupportestimator/components/f909ce44844fedbdb69d065a5f298471/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var temp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  temp = (await Backendless.Data.of('HP_ProjectQuotes').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId = \'',___arguments.value,'\''].join(''))).setPageSize(1)))[0];
  ___arguments.context.pageData['EmailAddressEnabled'] = false;
  (function (componentUid, dataModel) { ___arguments.context.setComponentDataStoreByUid(componentUid, dataModel) })('48b946fad1bd282d1d972a021434a96b', (getObjectProperty(temp, 'MainData')));
  (function (componentUid, dataModel) { ___arguments.context.setComponentDataStoreByUid(componentUid, dataModel) })('a5dd83a5a156273cb16ff67ee3eb96a7', (getObjectProperty(temp, 'WebpagesData')));
  (function (componentUid, dataModel) { ___arguments.context.setComponentDataStoreByUid(componentUid, dataModel) })('6ae9afcb4ee4df5cb997b74b63689957', (getObjectProperty(temp, 'AddOnsData')));
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b'))['DefaultWebpages'] = (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f5c9922553f3a7aaf5ef5dfc48680bc9')), 'value'));
  ___arguments.context.pageData['DefaultWebpages'] = (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f5c9922553f3a7aaf5ef5dfc48680bc9')), 'value'));
  ___arguments.context.pageData['WebpagesArray'] = (getObjectProperty(temp, 'WebpagesData'));
  ___arguments.context.pageData['objectId'] = (getObjectProperty(temp, 'objectId'));
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b'))['objectId'] = (getObjectProperty(temp, 'objectId'));
  ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('abd94dd2cfd13fc257fedb299017d9e3'))['content'] = (getObjectProperty(temp, 'ProjectName'));
  if ((await Backendless.Data.of('HP_ProjectQuotes').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((['objectId = \'',___arguments.value,'\''].join(''))))) > 0) {
    ___arguments.context.pageData['disableSaveButton'] = false;
    ___arguments.context.pageData['disableDuplicateButton'] = false;
    ___arguments.context.pageData['DeleteBTNEnabled'] = true;
  } else {
    ___arguments.context.pageData['DeleteBTNEnabled'] = false;
  }

  },
  /* handler:onChange */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'disableProjectIDSelect'))

  },
  /* handler:onDisabledStateAssignment */
  /* handler:onOptionsAssignment */
  async ['onOptionsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('a07ca54c6b28cc871a7bf7d3fbe1687f')), 'value')) == null || (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('a07ca54c6b28cc871a7bf7d3fbe1687f')), 'value')) == '') {
    ___arguments.context.pageData['disableSaveButton'] = true;
    return [];
  } else {
    if ((await Backendless.UserService.isValidLogin()) == true) {
      ___arguments.context.pageData['DeleteBTNEnabled'] = true;
      return (await Backendless.Data.of('HP_ProjectQuotes').find(Backendless.DataQueryBuilder.create().setWhereClause((['EmailAddress = \'',(getObjectProperty(___arguments.context.pageData, 'EmailAddress')),'\' AND isTechnicalSupport = true'].join(''))).setPageSize(25)));
    } else {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('5f3c6245ceaf0788a2a5562df5fd0917');
    }
    ___arguments.context.pageData['disableSaveButton'] = false;
    ___arguments.context.pageData['disableProjectIDSelect'] = false;
  }

  },
  /* handler:onOptionsAssignment */
  /* content */
}))

define('./pages/technicalsupportestimator/components/28cb5c8809e34e2e4b0a8d5a28e4d2f6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['objectId'] = '';
  ___arguments.context.getComponentDataStoreByUid('48b946fad1bd282d1d972a021434a96b')['objectId'] = null;
  ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('71fa494e0583e28f9d66d2cb18170f33'))['content'] = (getObjectProperty((await Backendless.Data.of('HP_ProjectQuotes').save( ({ [`objectId`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('48b946fad1bd282d1d972a021434a96b'), 'objectId')),[`ProjectName`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('48b946fad1bd282d1d972a021434a96b'), 'ProjectName')),[`EmailAddress`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('48b946fad1bd282d1d972a021434a96b'), 'EmailAddress')),[`MainData`]: ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b')),[`WebpagesData`]: (getObjectProperty(___arguments.context.pageData, 'WebpagesArray')),[`AddOnsData`]: ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')),[`isTechnicalSupport`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('48b946fad1bd282d1d972a021434a96b'), 'isTechnicalSupport')) }) )), 'objectId'));
  (function (componentUid, options) { ___arguments.context.getComponentByUid(componentUid).options = options })('f909ce44844fedbdb69d065a5f298471', (await Backendless.Data.of('HP_ProjectQuotes').find(Backendless.DataQueryBuilder.create().setWhereClause((['EmailAddress = \'',(getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('a07ca54c6b28cc871a7bf7d3fbe1687f')), 'value')),'\''].join(''))).setPageSize(25))));

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'disableDuplicateButton'))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/technicalsupportestimator/components/8c0f937a136917bc4ec4888ebb1ff658/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      if (((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('f909ce44844fedbdb69d065a5f298471')) != null) {
    await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('5204cf72e348720e06c78053428adfcb');
  } else {
  }

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'DeleteBTNEnabled')))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/technicalsupportestimator/components/5204cf72e348720e06c78053428adfcb/bundle.js', [], () => ({
  /* content */
  /* handler:onClose */
  async ['onClose'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('5204cf72e348720e06c78053428adfcb');

  },
  /* handler:onClose */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.Data.of('HP_ProjectQuotes').remove( (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f909ce44844fedbdb69d065a5f298471')), 'value')) );
  (function (componentUid, options) { ___arguments.context.getComponentByUid(componentUid).options = options })('f909ce44844fedbdb69d065a5f298471', (await Backendless.Data.of('HP_ProjectQuotes').find(Backendless.DataQueryBuilder.create().setWhereClause((['EmailAddress = \'',(getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('a07ca54c6b28cc871a7bf7d3fbe1687f')), 'value')),'\' AND isTechnicalSupport = true'].join(''))).setPageSize(25))));
  ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('4f4d3c38f3544e8fb8f5b0cc1629cf5a'))['content'] = (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('a07ca54c6b28cc871a7bf7d3fbe1687f')), 'value'));
  ___arguments.context.pageData['DeleteBTNEnabled'] = false;
  ___arguments.context.pageData['DeleteBTNEnabled'] = false;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b'))['objectId'] = null;
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('5204cf72e348720e06c78053428adfcb');

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/technicalsupportestimator/components/d8db1272c40d27990eaf439e5a0a0fde/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await (async function() {
  	location.reload();
  })();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/technicalsupportestimator/components/bd87d42215f4e6c818b69039ec170d9d/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (Number.parseFloat((Math.ceil((Number((getObjectProperty(___arguments.context.pageData, 'AddOnsCosts')))) + (Number((getObjectProperty(___arguments.context.pageData, 'DevelopmentCosts'))))) / (Number('100')))).toFixed(1))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/technicalsupportestimator/components/e347b37adde57936b3269c8fb8cbbc15/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('projectestimator', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/technicalsupportestimator/components/b3e723b758411adedaaea47106c59ff6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['EmailAddressEnabled'] = true;
  ___arguments.context.pageData['BoundEmailAddress'] = '';
  ___arguments.context.pageData['EmailAddress'] = '';

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'objectId')) == 'C1448329-3668-4275-8C4E-866D8C665C4F') {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/technicalsupportestimator/components/a9c749e19a3e8bfe69382fe5a0223a8c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (Math.ceil(((Number((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('bd87d42215f4e6c818b69039ec170d9d')), 'content')))) * 2) / 4))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/technicalsupportestimator/components/da19c337e4d3e1c87cec5b8695ded0a4/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
      return '100';

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/technicalsupportestimator/components/ec6a94994d010034e6134634ee842986/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (Math.ceil((Number((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('bd87d42215f4e6c818b69039ec170d9d')), 'content')))) * 2))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/technicalsupportestimator/components/bc79ceeec94a3aa6f683544a7a569e2c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (Math.ceil((Number((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('bd87d42215f4e6c818b69039ec170d9d')), 'content')))) * 2 * 0.1))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/technicalsupportestimator/components/da5d3263716baf6300d5e6954bf7f625/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var newUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (await Backendless.UserService.isValidLogin()) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5ec75aafe6cb8ec9f504411e4b6691ea', true);
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('4f4d3c38f3544e8fb8f5b0cc1629cf5a'))['content'] = (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('a07ca54c6b28cc871a7bf7d3fbe1687f')), 'value'));
    ___arguments.context.pageData['DeleteBTNEnabled'] = false;
    await new Promise(r => setTimeout(r, 500 || 0));
    await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('5f3c6245ceaf0788a2a5562df5fd0917');
  } else {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
  }
  console.log(newUser);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/temp/components/d1d9de3c08188cf9ff4108b1614be69a/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var AppURL, AppBaseURL;


  AppURL = (await (async function() {
  	return window.location.href
  })());
  AppBaseURL = AppURL.slice(0, 22);
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', [({ 'textLink': 'Home','urlLink': '#home','active': true }), ({ 'textLink': 'Contact','urlLink': '#contact','active': false })]);

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/temp/components/4494147e47463208b1e17aafe36cda16/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var textLink, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  textLink = (getObjectProperty(___arguments.context.dataModel, 'textLink'));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')) == textLink) {
      item['active'] = true;
    } else {
      item['active'] = false;
    }
  ; return item;}))));
  if (textLink == 'Home') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')));
  } else if (textLink == 'Contact') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0ee4f7a2f9f93096442f303878b88406')));
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 990) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 0;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.dataModel, 'active')) {
    if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) == true) {
      return ['link', 'nav__link', 'nav__link_active', null, 'boldmenuitem'];
    } else if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) != true) {
      return ['link', 'nav__link', 'nav__link_active', 'whiteshadowtext', null, 'boldmenuitem'];
    }
  } else if ((getObjectProperty(___arguments.context.dataModel, 'active')) != true) {
    if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) == true) {
      return ['link', 'nav__link', null, null];
    } else if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) != true) {
      return ['link', 'nav__link', 'shadowtext', null];
    }
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/temp/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onResize */
  ['onResize'](___arguments) {
    var leftSlideBy, left, j, half, galleryList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 990) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 0;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 'auto';
  }
  galleryList = (getObjectProperty(___arguments.context.appData, 'galleryList'));
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) >= 1200) {
    ___arguments.context.appData['leftSlideBy'] = 240;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 1199) {
    ___arguments.context.appData['leftSlideBy'] = 200;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 990) {
    ___arguments.context.appData['leftSlideBy'] = 260;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768) {
    ___arguments.context.appData['leftSlideBy'] = 370;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576) {
    ___arguments.context.appData['leftSlideBy'] = 250;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
    ___arguments.context.appData['leftSlideBy'] = 100;
  }
  leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
  half = Math.floor(galleryList.length / 2);
  ___arguments.context.pageData['leftStart'] = (-15 - half * leftSlideBy);
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 576) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 120);
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 480) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 130);
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 15);
  }
  left = (getObjectProperty(___arguments.context.pageData, 'leftStart'));
  var j_list = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('c376c79acf1e16de2463e38439c34656'));
  for (var j_index in j_list) {
    j = j_list[j_index];
    j['opacity'] = 1;
    j['left'] = left;
    if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
      j['active'] = true;
    } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
      j['active'] = true;
    } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
      j['active'] = true;
    } else if (left == 0 && leftSlideBy == 100) {
      j['active'] = true;
    } else {
      j['active'] = false;
    }
    left = (typeof left == 'number' ? left : 0) + leftSlideBy;
  }

  },
  /* handler:onResize */
  /* handler:onScroll */
  async ['onScroll'](___arguments) {
    var a, item, team, gallery, home2, features, testimonials, contact, topOnViewport, header, elem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function topOnViewport2(elem, header) {
  topOnViewport = (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', elem)) - ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight'))) + (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) - ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', header)));
  return topOnViewport
}


  contact = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0ee4f7a2f9f93096442f303878b88406')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  team = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  gallery = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  testimonials = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  features = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  home2 = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  if (___arguments.scrollTop - ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) < home2) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b'))))) < features && ___arguments.scrollTop > home2) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < testimonials && ___arguments.scrollTop > features + home2) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < gallery && ___arguments.scrollTop > testimonials + features + home2) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < team && ___arguments.scrollTop > testimonials + features + home2 + gallery) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < contact && ___arguments.scrollTop > testimonials + features + home2 + gallery + team) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0ee4f7a2f9f93096442f303878b88406')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop > ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) / 3) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('f5f6c87f71d4ee4f6b9b334b92f88f31'))['opacity'] = 0.3;
  } else if (___arguments.scrollTop > ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) / 10) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('f5f6c87f71d4ee4f6b9b334b92f88f31'))['opacity'] = 0.5;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('f5f6c87f71d4ee4f6b9b334b92f88f31'))['opacity'] = 1;
  }
  if (___arguments.scrollTop) {
    ___arguments.context.pageData['scrollTop'] = true;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['box-shadow'] = '0px 2px 4px rgba(0, 0, 0, 0.1)';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['height'] = '60px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-top'] = '7px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '-5px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '45px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#777777';
  } else {
    ___arguments.context.pageData['scrollTop'] = false;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['box-shadow'] = 'none';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['height'] = '80px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['background-color'] = 'transparent';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-top'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '80px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#ffffff';
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 400) {
    a = 150;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 500) {
    a = (typeof a == 'number' ? a : 0) + 200;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 600) {
    a = (typeof a == 'number' ? a : 0) + 300;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 700) {
    a = (typeof a == 'number' ? a : 0) + 400;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 800) {
    a = (typeof a == 'number' ? a : 0) + 500;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 900) {
    a = (typeof a == 'number' ? a : 0) + 600;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 1050) {
    a = (typeof a == 'number' ? a : 0) + 700;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 990 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 1050) {
    if (___arguments.scrollTop >= ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) - a)) {
      ___arguments.context.appData['featuresInFocus'] = true;
    }
    if (___arguments.scrollTop >= ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) - a)) {
      ___arguments.context.appData['testimonialsInFocus'] = true;
    }
    if (___arguments.scrollTop >= ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')))) - a)) {
      ___arguments.context.appData['teamInFocus'] = true;
    }
  } else {
    ___arguments.context.appData['featuresInFocus'] = true;
    ___arguments.context.appData['testimonialsInFocus'] = true;
    ___arguments.context.appData['teamInFocus'] = true;
  }

  },
  /* handler:onScroll */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var teamList, testimonialsList, featuresList, appData, galleryList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await (async function() {
  	  kofiWidgetOverlay.draw('hyperpixels', {
  	    'type': 'floating-chat',
  	    'floating-chat.donateButton.text': 'Donate',
  	    'floating-chat.donateButton.background-color': '#000000',
  	    'floating-chat.donateButton.text-color': '#fff'
  	  });
  })();
  appData = (await (async function() {
  	const data = {
  	  "features": [
  	    {
  	      "id": "f1",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-1.jpg",
  	      "title": "Amazing Experience",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f2",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-2.jpg",
  	      "title": "24/7 Support",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f3",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-3.jpg",
  	      "title": "Real-time",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f4",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-4.jpg",
  	      "title": "Easy To Use",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f5",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-5.jpg",
  	      "title": "Free Updates",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f6",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-6.jpg",
  	      "title": "All Platforms",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    }
  	  ],
  	  "gallery": [
  	    {
  	      "id": "g1",
  	      "image": "https://backendless.com/images/Fresh/dbImg/gallery/item1.jpg"
  	    },
  	    {
  	      "id": "g2",
  	      "image": "https://backendless.com/images/Fresh/dbImg/gallery/item2.jpg"
  	    },
  	    {
  	      "id": "g3",
  	      "image": "https://backendless.com/images/Fresh/dbImg/gallery/item3.jpg"
  	    },
  	    {
  	      "id": "g4",
  	      "image": "https://backendless.com/images/Fresh/dbImg/gallery/item4.jpg"
  	    },
  	    {
  	      "id": "g5",
  	      "image": "https://backendless.com/images/Fresh/dbImg/gallery/item5.jpg"
  	    },
  	    {
  	      "id": "g6",
  	      "image": "https://backendless.com/images/Fresh/dbImg/gallery/item6.jpg"
  	    }
  	  ],
  	  "team": [
  	    {
  	      "id": "t1",
  	      "name": "Joan Doe",
  	      "career": "Founder - CEO",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo1.jpg",
  	      "socialMedia": {
  	        "social": [
  	          {
  	            "url": "#",
  	            "name": "facebook"
  	          },
  	          {
  	            "url": "#",
  	            "name": "github"
  	          },
  	          {
  	            "url": "#",
  	            "name": "linkedin"
  	          },
  	          {
  	            "url": "",
  	            "name": "twitter"
  	          }
  	        ]
  	      }
  	    },
  	    {
  	      "id": "t2",
  	      "name": "John Doe",
  	      "career": "Co-founder",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo2.jpg",
  	      "socialMedia": {
  	        "social": [
  	          {
  	            "url": "#",
  	            "name": "facebook"
  	          },
  	          {
  	            "url": "",
  	            "name": "github"
  	          },
  	          {
  	            "url": "",
  	            "name": "linkedin"
  	          },
  	          {
  	            "url": "#",
  	            "name": "twitter"
  	          }
  	        ]
  	      }
  	    },
  	    {
  	      "id": "t3",
  	      "name": "Joan Doe",
  	      "career": "Co-founder",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo3.jpg",
  	      "socialMedia": {
  	        "social": [
  	          {
  	            "url": "#",
  	            "name": "facebook"
  	          },
  	          {
  	            "url": "#",
  	            "name": "github"
  	          },
  	          {
  	            "url": "#",
  	            "name": "linkedin"
  	          },
  	          {
  	            "url": "",
  	            "name": "twitter"
  	          }
  	        ]
  	      }
  	    },
  	    {
  	      "id": "t4",
  	      "name": "John Doe",
  	      "career": "Financial Manager",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo4.jpg",
  	      "socialMedia": {
  	        "social": [
  	          {
  	            "url": "#",
  	            "name": "facebook"
  	          },
  	          {
  	            "url": "",
  	            "name": "github"
  	          },
  	          {
  	            "url": "#",
  	            "name": "linkedin"
  	          },
  	          {
  	            "url": "",
  	            "name": "twitter"
  	          }
  	        ]
  	      }
  	    }
  	  ],
  	  "testimonials": [
  	    {
  	      "id": "tm1",
  	      "author": "Jane Doe",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/testimonials/auth1.jpg"
  	    },
  	    {
  	      "id": "tm2",
  	      "author": "John Doe",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/testimonials/auth2.jpg"
  	    },
  	    {
  	      "id": "tm3",
  	      "author": "Jane Doe",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/testimonials/auth3.jpg"
  	    },
  	    {
  	      "id": "tm4",
  	      "author": "John Doe",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/testimonials/item4.jpg"
  	    }
  	  ]
  	}

  	return data
  })());
  featuresList = (getObjectProperty(appData, 'features'));
  galleryList = (getObjectProperty(appData, 'gallery'));
  ___arguments.context.appData['galleryList'] = galleryList;
  teamList = (getObjectProperty(appData, 'team'));
  testimonialsList = (getObjectProperty(appData, 'testimonials'));
  ___arguments.context.pageData['MessageSent'] = false;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('31b2f36e83dd37c11b5e73f81d1bfac9', featuresList);
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('153bb1d13492b519dc0717b333c20947', testimonialsList);
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('b6f27fbafe317fe16572dfeaf1fb0698', teamList);

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/temp/components/fefec6b6560f11b1a4c62ed6ad39d36c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52')), 'height')) == 349) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 0;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 349;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/temp/components/d2551aa8d85f0b8224661c4aaaa53f32/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var firstName, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  firstName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName'));
  regExp = (new RegExp('.{2,}', 'g'));
  if (firstName.length <= 50) {
    ___arguments.context.pageData['firstNameValue'] = firstName;
  } else if (firstName.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['firstName'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['firstName'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'firstNameValue')), 0));
  }
  if ((regExp.test(firstName)) == false && firstName != '' || firstName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length < 2 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = false;
  } else if ((regExp.test(firstName)) == false && firstName == '' || firstName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length == 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/temp/components/3ddf6adfe3e580cc962a62234bf2e2d5/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-first-name'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/temp/components/f5bd0a2c6f0bde798ea8df133e9e7e3c/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var lastName, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  lastName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName'));
  regExp = (new RegExp('.{2,}', 'g'));
  if (lastName.length <= 50) {
    ___arguments.context.pageData['lastNameValue'] = lastName;
  } else if (lastName.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['lastName'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['lastName'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'lastNameValue')), 0));
  }
  if ((regExp.test(lastName)) == false && lastName != '' || lastName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length < 2 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = false;
  } else if ((regExp.test(lastName)) == false && lastName == '' || lastName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length == 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/temp/components/557e31a13073b4848ca61837b9e70a38/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-last-name'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/temp/components/cdc2459c0f1ac842997b621a04744c0c/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var email, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  email = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'email'));
  regExp = (new RegExp('[a-zA-Z0-9.\\-_+]{1,}@[^\\W\\.][a-zA-Z0-9\\-]{0,}\\.[a-zA-Z]{1,}', 'g'));
  if (email.length <= 50) {
    ___arguments.context.pageData['emailValue'] = email;
  } else if (email.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['email'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['email'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'emailValue')), 0));
  }
  if ((regExp.test(email)) == false && email != '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = false;
  } else if ((regExp.test(email)) == false && email == '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/temp/components/556317981b853c56d8e8e837e5b16c9e/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-email'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/temp/components/d64247a69fb82b1b932f6a1191167d68/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-phone'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/temp/components/1712009c62182acf71f1e4e777ac98dd/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var phone, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  phone = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone'));
  regExp = (new RegExp('^[0-9+\\(\\)\\-\\s]+$', 'g'));
  if (phone.length <= 50) {
    ___arguments.context.pageData['phoneValue'] = phone;
  } else if (phone.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['phone'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['phone'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'phoneValue')), 0));
  }
  if (phone.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone')).length < 5 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = true;
  } else if ((regExp.test(phone)) && phone.length >= 5) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = false;
  } else if (phone == '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = true;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/temp/components/275519e4f1ff2a5c0d556bd7bfaaf6c5/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-website'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/temp/components/ddb987ac0b1a2e02086b043645e7decd/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var website, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  website = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website'));
  regExp = (new RegExp('[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)', 'gi'));
  if (website.length <= 249) {
    ___arguments.context.pageData['websiteValue'] = website;
  } else if (website.length > 249) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['website'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['website'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'websiteValue')), 0));
  }
  if ((regExp.test(website)) == false && website != '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = true;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = false;
  }
  if ((regExp.test((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website')))) == true && website.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website')).length) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/temp/components/b17c55c60f4dcdf2ae8fc66b95b63932/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var subject, regExp, firstName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  subject = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject'));
  regExp = (new RegExp('.{4,}', 'g'));
  if (subject.length <= 300) {
    ___arguments.context.pageData['subjectValue'] = subject;
  } else if (subject.length > 300) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['subject'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['subject'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'subjectValue')), 0));
  }
  if ((regExp.test(subject)) == false && subject != '' || subject.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length < 4 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = false;
  } else if ((regExp.test(subject)) == false && subject == '' || subject.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length == 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/temp/components/a38dcbb7299151a66062e5a8eaaf984c/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-subject'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/temp/components/101b130a0c5ff2588aa5637fc5fcdb27/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-message'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage']) {
    addItemToList(classes, 'c-form__label_alert');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/temp/components/6eb697a67eb600c29f860957a2ac3b3e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, message, subject, website, phone, email, lastName, firstName, checkStatusMessage, checkStatusSubject, checkStatusWebsite, checkStatusPhone, checkStatusEmail, checkStatusLastName, checkStatusFirstName, statusMessage, statusSubject, statusWebsite, statusPhone, statusEmail, statusLastName, statusFirstName, company, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function ensureArray(items) {
  return Array.isArray(items) ? items : [items]
}

/**
 * Describe this function...
 */
async function firstNameValidation() {
  regExp = (new RegExp('.{2,}', 'g'));
  if (!(regExp.test(firstName)) || firstName == '' || firstName == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusFirsName() {
  if (!(getObjectProperty(statusFirstName, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('d2551aa8d85f0b8224661c4aaaa53f32')));
    if (firstName == '' || firstName == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function lastNameValidation() {
  regExp = (new RegExp('.{2,}', 'g'));
  if (!(regExp.test(lastName)) || lastName == '' || lastName == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusLastName2() {
  if (!(getObjectProperty(statusLastName, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f5bd0a2c6f0bde798ea8df133e9e7e3c')));
    if (lastName == '' || lastName == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function emailValidation() {
  regExp = (new RegExp('[a-zA-Z0-9.\\-_+]{1,}@[^\\W\\.][a-zA-Z0-9\\-]{0,}\\.[a-zA-Z]{1,}', 'g'));
  if (!(regExp.test(email)) || email == '' || email == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function phoneValidation() {
  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'hintPhone')) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusEmail2() {
  if (!(getObjectProperty(statusEmail, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('cdc2459c0f1ac842997b621a04744c0c')));
    if (email == '' || email == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function websiteValidation() {
  regExp = (new RegExp('[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)', 'gi'));
  if (!(regExp.test(website)) && website != '' && website != null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function subjectValidation() {
  regExp = (new RegExp('.{4,}', 'g'));
  if (!(regExp.test(subject)) || subject == '' || subject == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusPhone2() {
  if (!(getObjectProperty(statusPhone, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('1712009c62182acf71f1e4e777ac98dd')));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = true;
    return false;
  }
  return true
}

/**
 * Describe this function...
 */
async function messageValidation() {
  if (message == '' || message == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusWebsite2() {
  if (!(getObjectProperty(statusWebsite, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('ddb987ac0b1a2e02086b043645e7decd')));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = true;
    return false;
  }
  return true
}

/**
 * Describe this function...
 */
async function checkStatusSubject2() {
  if (!(getObjectProperty(statusSubject, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b17c55c60f4dcdf2ae8fc66b95b63932')));
    if (subject == '' || subject == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function checkStatusMessage2() {
  if (!(getObjectProperty(statusMessage, 'status'))) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage'] = true;
    return false;
  }
  return true
}


  firstName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName'));
  lastName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName'));
  email = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'email'));
  phone = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone'));
  company = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'company'));
  website = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website'));
  subject = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject'));
  message = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'message'));
  statusFirstName = await firstNameValidation();
  statusLastName = await lastNameValidation();
  statusEmail = await emailValidation();
  statusPhone = await phoneValidation();
  statusWebsite = await websiteValidation();
  statusSubject = await subjectValidation();
  statusMessage = await messageValidation();
  checkStatusMessage = await checkStatusMessage2();
  checkStatusSubject = await checkStatusSubject2();
  checkStatusWebsite = await checkStatusWebsite2();
  checkStatusPhone = await checkStatusPhone2();
  checkStatusEmail = await checkStatusEmail2();
  checkStatusLastName = await checkStatusLastName2();
  checkStatusFirstName = await checkStatusFirsName();
  if (checkStatusFirstName != false && checkStatusLastName != false && checkStatusEmail != false && checkStatusPhone != false && checkStatusWebsite != false && checkStatusSubject != false && checkStatusMessage != false) {
    await Backendless.Messaging.sendEmail('New contact from the Hyperpixels Website', new Backendless.Bodyparts({ htmlmessage: ([['From: ',firstName,' ',lastName].join(''),'<br>Email: ',email,'<br> Phone: ',phone,'<br> Website: ',website,'<br> Subject: ',subject,'<br> Message: <br>',message].join('')) }), ensureArray('mike@hyperpixels.net'), undefined);
    ___arguments.context.pageData['MessageSent'] = true;
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b65aa795e4d6a994b3ddd6f68533c80d', false);
    try {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('df8b067a885dcda84a91376a3c4c5c7c', true);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7224592bf6676a81e607676ddbc597e1', false);

    } catch (error) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('df8b067a885dcda84a91376a3c4c5c7c', false);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7224592bf6676a81e607676ddbc597e1', true);
      console.log(error);

    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/temp/components/58c7b83b0c67da4ff3bef93451af2162/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var message, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  message = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'message'));
  regExp = (new RegExp('.{1,}', 'g'));
  if (message.length <= 249) {
    ___arguments.context.pageData['messageValue'] = message;
  } else if (message.length > 249) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['message'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['message'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'messageValue')), 0));
  }
  if ((regExp.test(message)) == false && message == '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage'] = true;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/temp/components/dc8e2162b0174608379cfba7549be8db/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/temp/components/251dd247af0d2f6ebe6ccde3866c89b5/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isOpenModal')) {
    return ['modal', 'open'];
  }

  return ['modal']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/temp/components/70dd1dff3e215deab9dcc3e29a6566e6/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var listLength, gallery, item, counter, data;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setIdItem(data) {
  counter = 1;
  return await Promise.all(data.map(async item => {  item['id'] = counter;
  counter = (typeof counter == 'number' ? counter : 0) + 1;
; return item;}))
}


  await new Promise(r => setTimeout(r, 1000 || 0));
  gallery = (getObjectProperty(___arguments.context.appData, 'galleryList'));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', (await setIdItem(gallery)));
  listLength = gallery.length;
  ___arguments.context.pageData['totalSlide'] = listLength;
  ___arguments.context.pageData['currentSlide'] = 1;

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/temp/components/bb37f7327e62a50eb06507a61d6d1fc6/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == (getObjectProperty(___arguments.context.dataModel, 'id'))) {
    return ['modal__slider-item', 'active'];
  }

  return ['modal__slider-item']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/temp/components/831fddd4bb7ced9296de1aa550213577/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) > 1) {
    ___arguments.context.pageData['counterSlide2'] = ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == 1 ? listLength : (getObjectProperty(___arguments.context.pageData, 'counterSlide2')) - 1);
  }
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ___arguments.context.pageData['currentSlide'] = (getObjectProperty(___arguments.context.pageData, 'counterSlide2'));

  },
  /* handler:onClick */
  /* handler:onMouseOut */
  onMouseOut(___arguments) {
    var listLength;


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('831fddd4bb7ced9296de1aa550213577'))['opacity'] = '0';

  },
  /* handler:onMouseOut */
  /* handler:onMouseOver */
  onMouseOver(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == 1) {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('831fddd4bb7ced9296de1aa550213577'))['opacity'] = '0';
  } else {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('831fddd4bb7ced9296de1aa550213577'))['opacity'] = '1';
  }

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./pages/temp/components/dba21793ecfc81f1424a1ce6ad99a8b3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) < listLength) {
    ___arguments.context.pageData['counterSlide2'] = ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == listLength ? 1 : (getObjectProperty(___arguments.context.pageData, 'counterSlide2')) + 1);
  }
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ___arguments.context.pageData['currentSlide'] = (getObjectProperty(___arguments.context.pageData, 'counterSlide2'));

  },
  /* handler:onClick */
  /* handler:onMouseOut */
  onMouseOut(___arguments) {
    var listLength;


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('dba21793ecfc81f1424a1ce6ad99a8b3'))['opacity'] = '0';

  },
  /* handler:onMouseOut */
  /* handler:onMouseOver */
  onMouseOver(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == listLength) {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('dba21793ecfc81f1424a1ce6ad99a8b3'))['opacity'] = '0';
  } else {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('dba21793ecfc81f1424a1ce6ad99a8b3'))['opacity'] = '1';
  }

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./pages/temp/components/31c87ba294176e587b91b87c58e68a82/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/temp/components/16b9cb3fdc9bf1919465a18c77a6e467/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/temp/components/3607af0d8419be43455fc4ac16f9cc33/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var company;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  company = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'company'));
  if (company.length <= 100) {
    ___arguments.context.pageData['companyValue'] = company;
  } else if (company.length > 100) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['company'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['company'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'companyValue')), 0));
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/temp/components/30ea90dd05af67628fd821d5003c3d3d/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  onMounted(___arguments) {
      ___arguments.context.pageData['firstNameValue'] = '';
  ___arguments.context.pageData['lastNameValue'] = '';
  ___arguments.context.pageData['emailValue'] = '';
  ___arguments.context.pageData['phoneValue'] = '';
  ___arguments.context.pageData['companyValue'] = '';
  ___arguments.context.pageData['websiteValue'] = '';
  ___arguments.context.pageData['subjectValue'] = '';
  ___arguments.context.pageData['messageValue'] = '';

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/temp/components/fa5ae4efd4c2dd5b6bfe4d528dd58df2/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'MessageSent'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/topmenu/components/d1d9de3c08188cf9ff4108b1614be69a/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var AppURL, AppBaseURL;


  AppURL = (await (async function() {
  	return window.location.href
  })());
  AppBaseURL = AppURL.slice(0, 22);
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', [({ [`textLink`]: 'Home',[`urlLink`]: '#home',[`active`]: true }), ({ [`textLink`]: 'Services',[`urlLink`]: '#services',[`active`]: false }), ({ [`textLink`]: 'Portfolio',[`urlLink`]: '#portfolio',[`active`]: false }), ({ [`textLink`]: 'Project Estimator',[`urlLink`]: String('?page=webestimator'),[`active`]: false }), ({ [`textLink`]: 'Contact',[`urlLink`]: '#contact',[`active`]: false }), ({ [`textLink`]: 'Login',[`urlLink`]: '#login',[`active`]: false })]);

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/topmenu/components/4494147e47463208b1e17aafe36cda16/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var textLink, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  textLink = (getObjectProperty(___arguments.context.dataModel, 'textLink'));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')) == textLink) {
      item['active'] = true;
    } else {
      item['active'] = false;
    }
  ; return item;}))));
  if (textLink == 'Home') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')));
  } else if (textLink == 'Services') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')));
  } else if (textLink == 'TestimonialsX') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')));
  } else if (textLink == 'Portfolio') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')));
  } else if (textLink == 'Team') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')));
  } else if (textLink == 'Contact') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0ee4f7a2f9f93096442f303878b88406')));
  } else if (textLink == 'Project Estimator') {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('projectestimator', undefined);
  } else if (textLink == 'Login') {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 990) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 0;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.dataModel, 'active')) {
    if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) == true) {
      return ['link', 'nav__link', 'nav__link_active', null, 'boldmenuitem'];
    } else if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) != true) {
      return ['link', 'nav__link', 'nav__link_active', 'whiteshadowtext', null, 'boldmenuitem'];
    }
  } else if ((getObjectProperty(___arguments.context.dataModel, 'active')) != true) {
    if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) == true) {
      return ['link', 'nav__link', null, null];
    } else if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) != true) {
      return ['link', 'nav__link', 'shadowtext', null];
    }
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/topmenu/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onResize */
  ['onResize'](___arguments) {
    var leftSlideBy, left, j, half, galleryList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 990) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 0;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 'auto';
  }
  galleryList = (getObjectProperty(___arguments.context.appData, 'galleryList'));
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) >= 1200) {
    ___arguments.context.appData['leftSlideBy'] = 240;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 1199) {
    ___arguments.context.appData['leftSlideBy'] = 200;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 990) {
    ___arguments.context.appData['leftSlideBy'] = 260;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768) {
    ___arguments.context.appData['leftSlideBy'] = 370;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576) {
    ___arguments.context.appData['leftSlideBy'] = 250;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
    ___arguments.context.appData['leftSlideBy'] = 100;
  }
  leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
  half = Math.floor(galleryList.length / 2);
  ___arguments.context.pageData['leftStart'] = (-15 - half * leftSlideBy);
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 576) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 120);
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 480) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 130);
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 15);
  }
  left = (getObjectProperty(___arguments.context.pageData, 'leftStart'));
  var j_list = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('c376c79acf1e16de2463e38439c34656'));
  for (var j_index in j_list) {
    j = j_list[j_index];
    j['opacity'] = 1;
    j['left'] = left;
    if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
      j['active'] = true;
    } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
      j['active'] = true;
    } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
      j['active'] = true;
    } else if (left == 0 && leftSlideBy == 100) {
      j['active'] = true;
    } else {
      j['active'] = false;
    }
    left = (typeof left == 'number' ? left : 0) + leftSlideBy;
  }

  },
  /* handler:onResize */
  /* handler:onScroll */
  async ['onScroll'](___arguments) {
    var a, item, team, gallery, home2, features, testimonials, contact, topOnViewport, header, elem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function topOnViewport2(elem, header) {
  topOnViewport = (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', elem)) - ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight'))) + (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) - ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', header)));
  return topOnViewport
}


  contact = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0ee4f7a2f9f93096442f303878b88406')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  team = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  gallery = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  testimonials = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  features = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  home2 = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  if (___arguments.scrollTop - ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) < home2) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b'))))) < features && ___arguments.scrollTop > home2) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < testimonials && ___arguments.scrollTop > features + home2) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < gallery && ___arguments.scrollTop > testimonials + features + home2) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < team && ___arguments.scrollTop > testimonials + features + home2 + gallery) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < contact && ___arguments.scrollTop > testimonials + features + home2 + gallery + team) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0ee4f7a2f9f93096442f303878b88406')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop > ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) / 3) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('f5f6c87f71d4ee4f6b9b334b92f88f31'))['opacity'] = 0.3;
  } else if (___arguments.scrollTop > ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) / 10) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('f5f6c87f71d4ee4f6b9b334b92f88f31'))['opacity'] = 0.5;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('f5f6c87f71d4ee4f6b9b334b92f88f31'))['opacity'] = 1;
  }
  if (___arguments.scrollTop) {
    ___arguments.context.pageData['scrollTop'] = true;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['box-shadow'] = '0px 2px 4px rgba(0, 0, 0, 0.1)';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['height'] = '60px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-top'] = '7px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '-5px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '45px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#777777';
  } else {
    ___arguments.context.pageData['scrollTop'] = false;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['box-shadow'] = 'none';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['height'] = '80px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['background-color'] = 'transparent';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-top'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '80px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#ffffff';
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 400) {
    a = 150;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 500) {
    a = (typeof a == 'number' ? a : 0) + 200;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 600) {
    a = (typeof a == 'number' ? a : 0) + 300;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 700) {
    a = (typeof a == 'number' ? a : 0) + 400;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 800) {
    a = (typeof a == 'number' ? a : 0) + 500;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 900) {
    a = (typeof a == 'number' ? a : 0) + 600;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 1050) {
    a = (typeof a == 'number' ? a : 0) + 700;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 990 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 1050) {
    if (___arguments.scrollTop >= ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) - a)) {
      ___arguments.context.appData['featuresInFocus'] = true;
    }
    if (___arguments.scrollTop >= ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) - a)) {
      ___arguments.context.appData['testimonialsInFocus'] = true;
    }
    if (___arguments.scrollTop >= ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')))) - a)) {
      ___arguments.context.appData['teamInFocus'] = true;
    }
  } else {
    ___arguments.context.appData['featuresInFocus'] = true;
    ___arguments.context.appData['testimonialsInFocus'] = true;
    ___arguments.context.appData['teamInFocus'] = true;
  }

  },
  /* handler:onScroll */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var teamList, testimonialsList, appData, galleryList, featuresList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await (async function() {
  	  kofiWidgetOverlay.draw('hyperpixels', {
  	    'type': 'floating-chat',
  	    'floating-chat.donateButton.text': 'Donate',
  	    'floating-chat.donateButton.background-color': '#000000',
  	    'floating-chat.donateButton.text-color': '#fff'
  	  });
  })();
  appData = (await (async function() {
  	const data = {
  	  "features": [
  	    {
  	      "id": "f1",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-1.jpg",
  	      "title": "Amazing Experience",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f2",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-2.jpg",
  	      "title": "24/7 Support",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f3",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-3.jpg",
  	      "title": "Real-time",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f4",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-4.jpg",
  	      "title": "Easy To Use",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f5",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-5.jpg",
  	      "title": "Free Updates",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f6",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-6.jpg",
  	      "title": "All Platforms",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    }
  	  ],
  	  "gallery": [
  	    {
  	      "id": "g1",
  	      "image": "https://app.webmojo.io/api/files/web/Hyperpixels/images/Clay County Seal - Final(Full-Transparent).png"
  	    },
  	    {
  	      "id": "g2",
  	      "image": "https://app.webmojo.io/api/files/web/Hyperpixels/images/Calvary Logo (Full).png"
  	    },
  	    {
  	      "id": "g3",
  	      "image": "https://app.webmojo.io/api/files/web/Hyperpixels/images/Aspen Hill Kennels - Final (Full).png"
  	    },
  	    {
  	      "id": "g4",
  	      "image": "https://app.webmojo.io/api/files/web/Hyperpixels/images/Act Leasing Services Logo (No Box - On White - FINAL).png"
  	    },
  	    {
  	      "id": "g5",
  	      "image": "https://app.webmojo.io/api/files/web/Hyperpixels/images/Titanium Fitness Logo 2 (Final).png"
  	    },
  	    {
  	      "id": "g6",
  	      "image": "https://app.webmojo.io/api/files/web/Hyperpixels/images/ACCO Website.png"
  	    }
  	  ],
  	  "team": [
  	    {
  	      "id": "t1",
  	      "name": "Joan Doe",
  	      "career": "Founder - CEO",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo1.jpg",
  	      "socialMedia": {
  	        "social": [
  	          {
  	            "url": "#",
  	            "name": "facebook"
  	          },
  	          {
  	            "url": "#",
  	            "name": "github"
  	          },
  	          {
  	            "url": "#",
  	            "name": "linkedin"
  	          },
  	          {
  	            "url": "",
  	            "name": "twitter"
  	          }
  	        ]
  	      }
  	    },
  	    {
  	      "id": "t2",
  	      "name": "John Doe",
  	      "career": "Co-founder",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo2.jpg",
  	      "socialMedia": {
  	        "social": [
  	          {
  	            "url": "#",
  	            "name": "facebook"
  	          },
  	          {
  	            "url": "",
  	            "name": "github"
  	          },
  	          {
  	            "url": "",
  	            "name": "linkedin"
  	          },
  	          {
  	            "url": "#",
  	            "name": "twitter"
  	          }
  	        ]
  	      }
  	    },
  	    {
  	      "id": "t3",
  	      "name": "Joan Doe",
  	      "career": "Co-founder",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo3.jpg",
  	      "socialMedia": {
  	        "social": [
  	          {
  	            "url": "#",
  	            "name": "facebook"
  	          },
  	          {
  	            "url": "#",
  	            "name": "github"
  	          },
  	          {
  	            "url": "#",
  	            "name": "linkedin"
  	          },
  	          {
  	            "url": "",
  	            "name": "twitter"
  	          }
  	        ]
  	      }
  	    },
  	    {
  	      "id": "t4",
  	      "name": "John Doe",
  	      "career": "Financial Manager",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo4.jpg",
  	      "socialMedia": {
  	        "social": [
  	          {
  	            "url": "#",
  	            "name": "facebook"
  	          },
  	          {
  	            "url": "",
  	            "name": "github"
  	          },
  	          {
  	            "url": "#",
  	            "name": "linkedin"
  	          },
  	          {
  	            "url": "",
  	            "name": "twitter"
  	          }
  	        ]
  	      }
  	    }
  	  ],
  	  "testimonials": [
  	    {
  	      "id": "tm1",
  	      "author": "Jane Doe",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/testimonials/auth1.jpg"
  	    },
  	    {
  	      "id": "tm2",
  	      "author": "John Doe",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/testimonials/auth2.jpg"
  	    },
  	    {
  	      "id": "tm3",
  	      "author": "Jane Doe",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/testimonials/auth3.jpg"
  	    },
  	    {
  	      "id": "tm4",
  	      "author": "John Doe",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/testimonials/item4.jpg"
  	    }
  	  ]
  	}

  	return data
  })());
  galleryList = (getObjectProperty(appData, 'gallery'));
  ___arguments.context.appData['galleryList'] = galleryList;
  ___arguments.context.pageData['MessageSent'] = false;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('153bb1d13492b519dc0717b333c20947', testimonialsList);

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/topmenu/components/fefec6b6560f11b1a4c62ed6ad39d36c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52')), 'height')) == 349) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 0;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 349;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/topmenu/components/153bb1d13492b519dc0717b333c20947/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
      return ((___arguments.context.appData['testimonialsInFocus']) ? ['testimonials__items-block', 'testimonials__items-block_in-focus'] : ['testimonials__items-block'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/topmenu/components/c376c79acf1e16de2463e38439c34656/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var item, leftSlideBy, left, newGalleryList, half, firstList, galleryList, lastList, idGalleryItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  await new Promise(r => setTimeout(r, 1000 || 0));
  galleryList = (getObjectProperty(___arguments.context.appData, 'galleryList'));
  idGalleryItem = 1;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c376c79acf1e16de2463e38439c34656', (await Promise.all(galleryList.map(async item => {  item['id'] = idGalleryItem;
    idGalleryItem = (typeof idGalleryItem == 'number' ? idGalleryItem : 0) + 1;
  ; return item;}))));
  ___arguments.context.appData['leftSlideBy'] = 240;
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 1199) {
    ___arguments.context.appData['leftSlideBy'] = 200;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 990) {
    ___arguments.context.appData['leftSlideBy'] = 260;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768) {
    ___arguments.context.appData['leftSlideBy'] = 370;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576) {
    ___arguments.context.appData['leftSlideBy'] = 250;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
    ___arguments.context.appData['leftSlideBy'] = 100;
  }
  half = Math.floor(galleryList.length / 2);
  lastList = galleryList.slice(((half + 1) - 1), galleryList.length);
  firstList = galleryList.slice(0, half);
  newGalleryList = (JSON.parse((JSON.stringify(((addItemToList(lastList, galleryList)).concat(firstList))))));
  leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
  ___arguments.context.pageData['leftStart'] = (-15 - half * leftSlideBy);
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 576) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 120);
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 480) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 130);
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 15);
  }
  left = (getObjectProperty(___arguments.context.pageData, 'leftStart'));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c376c79acf1e16de2463e38439c34656', (await Promise.all(newGalleryList.map(async item => {  item['opacity'] = 1;
    item['left'] = left;
    if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
      item['active'] = true;
    } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
      item['active'] = true;
    } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
      item['active'] = true;
    } else if (left == 0 && leftSlideBy == 100) {
      item['active'] = true;
    } else {
      item['active'] = false;
    }
    left = (typeof left == 'number' ? left : 0) + leftSlideBy;
  ; return item;}))));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/topmenu/components/e23154e2b40dc5a8ac7f9a7e34ff980f/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 480) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e23154e2b40dc5a8ac7f9a7e34ff980f'))['left'] = (String(getObjectProperty(___arguments.context.dataModel, 'left')) + String('px'));
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e23154e2b40dc5a8ac7f9a7e34ff980f'))['left'] = (String(getObjectProperty(___arguments.context.dataModel, 'left')) + String('%'));
  }
  ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e23154e2b40dc5a8ac7f9a7e34ff980f'))['opacity'] = (getObjectProperty(___arguments.context.dataModel, 'opacity'));
  if (getObjectProperty(___arguments.context.dataModel, 'active')) {
    return ['g-slider__item', 'g-slider__item_center'];
  }

  return ['g-slider__item']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/topmenu/components/108f6ec347effdbce289a49d625d03af/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['isOpenModal'] = true;
  ___arguments.context.pageData['counterSlide2'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e23154e2b40dc5a8ac7f9a7e34ff980f'), 'id'));
  ___arguments.context.pageData['currentSlide'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e23154e2b40dc5a8ac7f9a7e34ff980f'), 'id'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/topmenu/components/26a1309c25831a8af67d5fe98587c773/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    var leftSlideBy, minValue, card, left, j, maxValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  maxValue = 0;
  minValue = 0;
  leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
  if ((typeof (getObjectProperty(___arguments.context.pageData, 'stopClick')) === 'undefined') || ((new Date()).valueOf()) > (getObjectProperty(___arguments.context.pageData, 'stopClick')) + 300) {
    ___arguments.context.pageData['stopClick'] = ((new Date()).valueOf());
    var j_list = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('c376c79acf1e16de2463e38439c34656'));
    for (var j_index in j_list) {
      j = j_list[j_index];
      j['opacity'] = 1;
      j['left'] = ((getObjectProperty(j, 'left')) + leftSlideBy);
      left = (getObjectProperty(j, 'left'));
      if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
        j['active'] = true;
      } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
        j['active'] = true;
      } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
        j['active'] = true;
      } else if (left == 0 && leftSlideBy == 100) {
        j['active'] = true;
      } else {
        j['active'] = false;
      }
      if (maxValue < left) {
        maxValue = left;
        card = j;
      }
      if (minValue > left) {
        minValue = left;
      }
    }
    card['opacity'] = 0;
    card['left'] = (minValue - leftSlideBy);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/topmenu/components/0ecc200a34ec908bf97bef91588de587/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var leftSlideBy, maxValue, card, left, j, minValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  maxValue = 0;
  minValue = 0;
  leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
  if ((typeof (getObjectProperty(___arguments.context.pageData, 'stopClick')) === 'undefined') || ((new Date()).valueOf()) > (getObjectProperty(___arguments.context.pageData, 'stopClick')) + 300) {
    ___arguments.context.pageData['stopClick'] = ((new Date()).valueOf());
    var j_list = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('c376c79acf1e16de2463e38439c34656'));
    for (var j_index in j_list) {
      j = j_list[j_index];
      j['left'] = ((getObjectProperty(j, 'left')) - leftSlideBy);
      left = (getObjectProperty(j, 'left'));
      j['opacity'] = 1;
      if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
        j['active'] = true;
      } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
        j['active'] = true;
      } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
        j['active'] = true;
      } else if (left == 0 && leftSlideBy == 100) {
        j['active'] = true;
      } else {
        j['active'] = false;
      }
      if (minValue > left) {
        minValue = left;
        card = j;
      }
      if (maxValue < left) {
        maxValue = left;
      }
    }
    card['opacity'] = 0;
    card['left'] = (maxValue + leftSlideBy);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/topmenu/components/4bc8650348b45a9267097b43aff505c6/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('4bc8650348b45a9267097b43aff505c6', (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e5ac9c8ad984738efc349d07b4dc74b1'), 'socialMedia')), 'social')));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/topmenu/components/1fdde5c76ea432e90eb67a18533b2dac/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes, nameSocial;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['team-card__link', 'link'];
  nameSocial = (getObjectProperty(___arguments.context.dataModel, 'name'));
  if ((getObjectProperty(___arguments.context.dataModel, 'url')) == '') {
    addItemToList(classes, 'team-card__link_hide');
  }
  if (nameSocial == 'facebook') {
    addItemToList(classes, 'fab fa-facebook-f');
  }
  if (nameSocial == 'github') {
    addItemToList(classes, 'fab fa-github');
  }
  if (nameSocial == 'linkedin') {
    addItemToList(classes, 'fab fa-linkedin-in');
  }
  if (nameSocial == 'twitter') {
    addItemToList(classes, 'fab fa-twitter');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/topmenu/components/b6f27fbafe317fe16572dfeaf1fb0698/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
      return ((___arguments.context.appData['teamInFocus']) ? ['team__cards-block', 'team__cards-block_in-focus'] : ['team__cards-block'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/topmenu/components/d2551aa8d85f0b8224661c4aaaa53f32/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var firstName, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  firstName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName'));
  regExp = (new RegExp('.{2,}', 'g'));
  if (firstName.length <= 50) {
    ___arguments.context.pageData['firstNameValue'] = firstName;
  } else if (firstName.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['firstName'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['firstName'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'firstNameValue')), 0));
  }
  if ((regExp.test(firstName)) == false && firstName != '' || firstName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length < 2 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = false;
  } else if ((regExp.test(firstName)) == false && firstName == '' || firstName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length == 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/topmenu/components/3ddf6adfe3e580cc962a62234bf2e2d5/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-first-name'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/topmenu/components/f5bd0a2c6f0bde798ea8df133e9e7e3c/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var lastName, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  lastName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName'));
  regExp = (new RegExp('.{2,}', 'g'));
  if (lastName.length <= 50) {
    ___arguments.context.pageData['lastNameValue'] = lastName;
  } else if (lastName.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['lastName'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['lastName'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'lastNameValue')), 0));
  }
  if ((regExp.test(lastName)) == false && lastName != '' || lastName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length < 2 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = false;
  } else if ((regExp.test(lastName)) == false && lastName == '' || lastName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length == 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/topmenu/components/557e31a13073b4848ca61837b9e70a38/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-last-name'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/topmenu/components/cdc2459c0f1ac842997b621a04744c0c/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var email, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  email = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'email'));
  regExp = (new RegExp('[a-zA-Z0-9.\\-_+]{1,}@[^\\W\\.][a-zA-Z0-9\\-]{0,}\\.[a-zA-Z]{1,}', 'g'));
  if (email.length <= 50) {
    ___arguments.context.pageData['emailValue'] = email;
  } else if (email.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['email'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['email'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'emailValue')), 0));
  }
  if ((regExp.test(email)) == false && email != '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = false;
  } else if ((regExp.test(email)) == false && email == '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/topmenu/components/556317981b853c56d8e8e837e5b16c9e/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-email'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/topmenu/components/d64247a69fb82b1b932f6a1191167d68/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-phone'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/topmenu/components/1712009c62182acf71f1e4e777ac98dd/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var phone, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  phone = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone'));
  regExp = (new RegExp('^[0-9+\\(\\)\\-\\s]+$', 'g'));
  if (phone.length <= 50) {
    ___arguments.context.pageData['phoneValue'] = phone;
  } else if (phone.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['phone'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['phone'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'phoneValue')), 0));
  }
  if (phone.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone')).length < 5 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = true;
  } else if ((regExp.test(phone)) && phone.length >= 5) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = false;
  } else if (phone == '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = true;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/topmenu/components/275519e4f1ff2a5c0d556bd7bfaaf6c5/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-website'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/topmenu/components/ddb987ac0b1a2e02086b043645e7decd/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var website, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  website = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website'));
  regExp = (new RegExp('[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)', 'gi'));
  if (website.length <= 249) {
    ___arguments.context.pageData['websiteValue'] = website;
  } else if (website.length > 249) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['website'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['website'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'websiteValue')), 0));
  }
  if ((regExp.test(website)) == false && website != '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = true;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = false;
  }
  if ((regExp.test((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website')))) == true && website.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website')).length) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/topmenu/components/b17c55c60f4dcdf2ae8fc66b95b63932/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var subject, regExp, firstName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  subject = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject'));
  regExp = (new RegExp('.{4,}', 'g'));
  if (subject.length <= 300) {
    ___arguments.context.pageData['subjectValue'] = subject;
  } else if (subject.length > 300) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['subject'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['subject'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'subjectValue')), 0));
  }
  if ((regExp.test(subject)) == false && subject != '' || subject.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length < 4 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = false;
  } else if ((regExp.test(subject)) == false && subject == '' || subject.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length == 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/topmenu/components/a38dcbb7299151a66062e5a8eaaf984c/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-subject'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/topmenu/components/101b130a0c5ff2588aa5637fc5fcdb27/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-message'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage']) {
    addItemToList(classes, 'c-form__label_alert');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/topmenu/components/6eb697a67eb600c29f860957a2ac3b3e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, message, subject, website, phone, email, lastName, firstName, checkStatusMessage, checkStatusSubject, checkStatusWebsite, checkStatusPhone, checkStatusEmail, checkStatusLastName, checkStatusFirstName, statusMessage, statusSubject, statusWebsite, statusPhone, statusEmail, statusLastName, statusFirstName, company, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function ensureArray(items) {
  return Array.isArray(items) ? items : [items]
}

/**
 * Describe this function...
 */
async function firstNameValidation() {
  regExp = (new RegExp('.{2,}', 'g'));
  if (!(regExp.test(firstName)) || firstName == '' || firstName == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusFirsName() {
  if (!(getObjectProperty(statusFirstName, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('d2551aa8d85f0b8224661c4aaaa53f32')));
    if (firstName == '' || firstName == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function lastNameValidation() {
  regExp = (new RegExp('.{2,}', 'g'));
  if (!(regExp.test(lastName)) || lastName == '' || lastName == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusLastName2() {
  if (!(getObjectProperty(statusLastName, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f5bd0a2c6f0bde798ea8df133e9e7e3c')));
    if (lastName == '' || lastName == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function emailValidation() {
  regExp = (new RegExp('[a-zA-Z0-9.\\-_+]{1,}@[^\\W\\.][a-zA-Z0-9\\-]{0,}\\.[a-zA-Z]{1,}', 'g'));
  if (!(regExp.test(email)) || email == '' || email == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function phoneValidation() {
  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'hintPhone')) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusEmail2() {
  if (!(getObjectProperty(statusEmail, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('cdc2459c0f1ac842997b621a04744c0c')));
    if (email == '' || email == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function websiteValidation() {
  regExp = (new RegExp('[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)', 'gi'));
  if (!(regExp.test(website)) && website != '' && website != null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function subjectValidation() {
  regExp = (new RegExp('.{4,}', 'g'));
  if (!(regExp.test(subject)) || subject == '' || subject == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusPhone2() {
  if (!(getObjectProperty(statusPhone, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('1712009c62182acf71f1e4e777ac98dd')));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = true;
    return false;
  }
  return true
}

/**
 * Describe this function...
 */
async function messageValidation() {
  if (message == '' || message == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusWebsite2() {
  if (!(getObjectProperty(statusWebsite, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('ddb987ac0b1a2e02086b043645e7decd')));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = true;
    return false;
  }
  return true
}

/**
 * Describe this function...
 */
async function checkStatusSubject2() {
  if (!(getObjectProperty(statusSubject, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b17c55c60f4dcdf2ae8fc66b95b63932')));
    if (subject == '' || subject == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function checkStatusMessage2() {
  if (!(getObjectProperty(statusMessage, 'status'))) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage'] = true;
    return false;
  }
  return true
}


  firstName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName'));
  lastName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName'));
  email = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'email'));
  phone = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone'));
  company = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'company'));
  website = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website'));
  subject = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject'));
  message = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'message'));
  statusFirstName = await firstNameValidation();
  statusLastName = await lastNameValidation();
  statusEmail = await emailValidation();
  statusPhone = await phoneValidation();
  statusWebsite = await websiteValidation();
  statusSubject = await subjectValidation();
  statusMessage = await messageValidation();
  checkStatusMessage = await checkStatusMessage2();
  checkStatusSubject = await checkStatusSubject2();
  checkStatusWebsite = await checkStatusWebsite2();
  checkStatusPhone = await checkStatusPhone2();
  checkStatusEmail = await checkStatusEmail2();
  checkStatusLastName = await checkStatusLastName2();
  checkStatusFirstName = await checkStatusFirsName();
  if (checkStatusFirstName != false && checkStatusLastName != false && checkStatusEmail != false && checkStatusPhone != false && checkStatusWebsite != false && checkStatusSubject != false && checkStatusMessage != false) {
    await Backendless.Messaging.sendEmail('New contact from the Hyperpixels Website', new Backendless.Bodyparts({ htmlmessage: ([['From: ',firstName,' ',lastName].join(''),'<br>Email: ',email,'<br> Phone: ',phone,'<br> Website: ',website,'<br> Subject: ',subject,'<br> Message: <br>',message].join('')) }), ensureArray('mike@hyperpixels.net'), undefined);
    ___arguments.context.pageData['MessageSent'] = true;
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b65aa795e4d6a994b3ddd6f68533c80d', false);
    try {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('df8b067a885dcda84a91376a3c4c5c7c', true);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7224592bf6676a81e607676ddbc597e1', false);

    } catch (error) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('df8b067a885dcda84a91376a3c4c5c7c', false);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7224592bf6676a81e607676ddbc597e1', true);
      console.log(error);

    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/topmenu/components/58c7b83b0c67da4ff3bef93451af2162/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var message, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  message = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'message'));
  regExp = (new RegExp('.{1,}', 'g'));
  if (message.length <= 249) {
    ___arguments.context.pageData['messageValue'] = message;
  } else if (message.length > 249) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['message'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['message'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'messageValue')), 0));
  }
  if ((regExp.test(message)) == false && message == '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage'] = true;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/topmenu/components/dc8e2162b0174608379cfba7549be8db/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/topmenu/components/251dd247af0d2f6ebe6ccde3866c89b5/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isOpenModal')) {
    return ['modal', 'open'];
  }

  return ['modal']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/topmenu/components/70dd1dff3e215deab9dcc3e29a6566e6/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async onMounted(___arguments) {
    var counter, data, gallery, item, listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setIdItem(data) {
  counter = 1;
  return await Promise.all(data.map(async item => {  item['id'] = counter;
  counter = (typeof counter == 'number' ? counter : 0) + 1;
; return item;}))
}


  await new Promise(r => setTimeout(r, 1000 || 0));
  gallery = (getObjectProperty(___arguments.context.appData, 'galleryList'));
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', (await setIdItem(gallery)));
  listLength = gallery.length;
  ___arguments.context.pageData['totalSlide'] = listLength;
  ___arguments.context.pageData['currentSlide'] = 1;

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/topmenu/components/bb37f7327e62a50eb06507a61d6d1fc6/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == (getObjectProperty(___arguments.context.dataModel, 'id'))) {
    return ['modal__slider-item', 'active'];
  }

  return ['modal__slider-item']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/topmenu/components/831fddd4bb7ced9296de1aa550213577/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) > 1) {
    ___arguments.context.pageData['counterSlide2'] = ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == 1 ? listLength : (getObjectProperty(___arguments.context.pageData, 'counterSlide2')) - 1);
  }
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ___arguments.context.pageData['currentSlide'] = (getObjectProperty(___arguments.context.pageData, 'counterSlide2'));

  },
  /* handler:onClick */
  /* handler:onMouseOut */
  onMouseOut(___arguments) {
    var listLength;


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('831fddd4bb7ced9296de1aa550213577'))['opacity'] = '0';

  },
  /* handler:onMouseOut */
  /* handler:onMouseOver */
  onMouseOver(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == 1) {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('831fddd4bb7ced9296de1aa550213577'))['opacity'] = '0';
  } else {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('831fddd4bb7ced9296de1aa550213577'))['opacity'] = '1';
  }

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./pages/topmenu/components/dba21793ecfc81f1424a1ce6ad99a8b3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) < listLength) {
    ___arguments.context.pageData['counterSlide2'] = ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == listLength ? 1 : (getObjectProperty(___arguments.context.pageData, 'counterSlide2')) + 1);
  }
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ___arguments.context.pageData['currentSlide'] = (getObjectProperty(___arguments.context.pageData, 'counterSlide2'));

  },
  /* handler:onClick */
  /* handler:onMouseOut */
  onMouseOut(___arguments) {
    var listLength;


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('dba21793ecfc81f1424a1ce6ad99a8b3'))['opacity'] = '0';

  },
  /* handler:onMouseOut */
  /* handler:onMouseOver */
  onMouseOver(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == listLength) {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('dba21793ecfc81f1424a1ce6ad99a8b3'))['opacity'] = '0';
  } else {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('dba21793ecfc81f1424a1ce6ad99a8b3'))['opacity'] = '1';
  }

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./pages/topmenu/components/31c87ba294176e587b91b87c58e68a82/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/topmenu/components/16b9cb3fdc9bf1919465a18c77a6e467/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/topmenu/components/3607af0d8419be43455fc4ac16f9cc33/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var company;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  company = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'company'));
  if (company.length <= 100) {
    ___arguments.context.pageData['companyValue'] = company;
  } else if (company.length > 100) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['company'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['company'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'companyValue')), 0));
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/topmenu/components/30ea90dd05af67628fd821d5003c3d3d/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  onMounted(___arguments) {
      ___arguments.context.pageData['firstNameValue'] = '';
  ___arguments.context.pageData['lastNameValue'] = '';
  ___arguments.context.pageData['emailValue'] = '';
  ___arguments.context.pageData['phoneValue'] = '';
  ___arguments.context.pageData['companyValue'] = '';
  ___arguments.context.pageData['websiteValue'] = '';
  ___arguments.context.pageData['subjectValue'] = '';
  ___arguments.context.pageData['messageValue'] = '';

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/topmenu/components/ba557046fb5348f79a8a94c342388c04/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'MessageSent'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/topmenu/components/2d06b571aa5bcd13df8e8426dd1c5849/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2d06b571aa5bcd13df8e8426dd1c5849'))['color'] = 'red';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2d06b571aa5bcd13df8e8426dd1c5849'))['color'] = 'white';

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/topmenu/components/14e3a2a815e855eaa285739b916320ed/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  async ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('2d06b571aa5bcd13df8e8426dd1c5849'))['text'] = 'hello@hyperpixels.net';
  await (async function() {
  	var a = document.getElementsByClassName('emailuslink'); //or grab it by tagname etc
  	a[0].href = "mailto:hello@hyperpixels.net"
  })();

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  async ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('2d06b571aa5bcd13df8e8426dd1c5849'))['text'] = 'Email Us';
  await (async function() {
  	var a = document.getElementsByClassName('emailuslink'); //or grab it by tagname etc
  	a[0].href = "mailto:undefined"
  })();

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/webestimator_backup/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  ['onBeforeEnter'](___arguments) {
    
  },
  /* handler:onBeforeEnter */
  /* handler:onEnter */
  ['onEnter'](___arguments) {
    var templist, tempContent, i;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['WebpagesArray'] = [];
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b'))['DefaultWebpages'] = 10;
  templist = [];
  for (i = 1; i <= 25; i++) {
    if (i <= 10) {
      tempContent = ({ 'pagename': String('Webpage ') + String(i),'difficulty': 5,'visible': true,'pagetotal': 625,'calctotal': 625 });
    } else {
      tempContent = ({ 'pagename': String('Webpage ') + String(i),'difficulty': 5,'visible': false,'pagetotal': 0,'calctotal': 625 });
    }
    addItemToList(templist, tempContent);
  }
  ___arguments.context.pageData['WebpagesArray'] = templist;
  console.log(getObjectProperty(___arguments.context.pageData, 'WebpagesArray'));
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['BLOG_Amount'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Memberships_Amount'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['ECommerce_BaseAmount'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['ECommerce_ItemsTotal'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['LiveChat_Amount'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Forms_Amount'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Galleries_Amount'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Reservations_Amount'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Search_Amount'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['SocialMedia_Amount'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Analytics_Amount'] = 0;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Products'] = 0;

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/webestimator_backup/components/a76515983417d25f08444d69ea3d9a98/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    var list;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/webestimator_backup/components/e00d715ae43c8e6d51148ba3cb91c607/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/webestimator_backup/components/66b6ed079a3dea2425de28b612934885/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var i, tempContent, templist;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  for (i = 1; i <= 25; i++) {
    ((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)])['difficulty'] = (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('244dd3741aed2948c1b54ea6d3e485e7')), 'value'));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/webestimator_backup/components/1861fcb150236764dfb74c4243bcd2ac/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.dataModel, 'visible'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/webestimator_backup/components/87ee172bc9f37594a21e2d26a6fff7e2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var templist, tempContent, i;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(getObjectProperty(___arguments.context.pageData, 'WebpagesArray'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/webestimator_backup/components/66e7b39e17522c23905a8fd3c5466c02/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 1) {
    return 'Simple, static (no database) webpage with minimal graphics.';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 2) {
    return 'Simple, static (no database) webpage with additional graphics.';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 3) {
    return 'Three';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 4) {
    return 'Four';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 5) {
    return 'Five';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 6) {
    return 'Six';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 7) {
    return 'Seven';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 8) {
    return 'Eight';
  }
  if ((getObjectProperty(___arguments.context.pageData, 'DefaultComplexity')) == 9) {
    return 'Extremely complex dynamic (database-driven) webpage layout with many complex integrations.<br>Such as: map integration, credit card processing, etc...<br>For Multiple Targets: Desktop, Tablet, and Mobile';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/webestimator_backup/components/244dd3741aed2948c1b54ea6d3e485e7/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['DefaultComplexity'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/webestimator_backup/components/f5c9922553f3a7aaf5ef5dfc48680bc9/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var i;

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function stopSetTimeout(timerId) {  const timers = getGlobalEntitiesMap('setTimeouts'); if (timerId && timers[timerId]) {    clearTimeout(timers[timerId]);    delete timers[timerId]; }}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function runSetTimeout(timerId, callback, delay) {  const timers = getGlobalEntitiesMap('setTimeouts'); const timer = setTimeout(callback, delay); if (timerId) {  stopSetTimeout(timerId);  timers[timerId] = timer }}


  ___arguments.context.pageData['DefaultWebpages'] = ___arguments.value;
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b'))['DefaultWebpages'] = ___arguments.value;
  stopSetTimeout('lazyload');

  ;(function() {
    const callback = async () => {
        for (i = 1; i <= 25; i++) {
      if (i <= ___arguments.value) {
        ((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)])['visible'] = true;
      } else {
        ((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)])['visible'] = false;
      }
    }

    };

    const timerId = 'lazyload';
    const timerDelay = 1000;

    runSetTimeout(timerId, callback, timerDelay)
  })()

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/webestimator_backup/components/4ef79c77e86eaa368d834011e8cda171/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'ECommerce'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/webestimator_backup/components/de713ef471a9fec70157b93403f27b04/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['BLOG_Amount'] = 2500;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['BLOG_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/webestimator_backup/components/29c48cc7a15973fc3109bfcf59ec13e2/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var temp, tempWithCurrency;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  tempWithCurrency = String('$') + String((Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'BLOG_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Memberships_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'ECommerce_BaseAmount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'ECommerce_ItemsTotal')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'LiveChat_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Forms_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Galleries_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Reservations_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'SocialMedia_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Search_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Analytics_Amount')))) + 0);
  temp = String((Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'BLOG_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Memberships_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'ECommerce_BaseAmount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'ECommerce_ItemsTotal')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'LiveChat_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Forms_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Galleries_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Reservations_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'SocialMedia_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Search_Amount')))) + (Number((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957'), 'Analytics_Amount')))) + 0);
  ___arguments.context.pageData['AddOnsCosts'] = temp;

  return tempWithCurrency

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/webestimator_backup/components/5f83f0e8576882f9c9aaa54469aeb052/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Memberships_Amount'] = 3500;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Memberships_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/webestimator_backup/components/58b8f4e9547bfa614e9217690fbab0b2/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['ECommerce_BaseAmount'] = 750;
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['ECommerce_ItemsTotal'] = ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Products')) * 3);
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['ECommerce_BaseAmount'] = 0;
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['ECommerce_ItemsTotal'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/webestimator_backup/components/f2b99e3a43a60d1f9ff7b5c2736720fe/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['ECommerce_ItemsTotal'] = ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957')), 'Products')) * 3);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/webestimator_backup/components/d45daa93141a6e8e729590a2d24c5751/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['LiveChat_Amount'] = 500;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['LiveChat_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/webestimator_backup/components/d497f8d1e18f4973ae0ae619bd1d491e/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['SocialMedia_Amount'] = 750;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['SocialMedia_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/webestimator_backup/components/8d1600ecc2fddc59f2b7184ec6085993/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Forms_Amount'] = 750;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Forms_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/webestimator_backup/components/95070f3135f6408b3f22eb2c7d8f391f/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Galleries_Amount'] = 750;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Galleries_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/webestimator_backup/components/71b49e876d4e88760c3e61d98f1cf277/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Reservations_Amount'] = 750;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Reservations_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/webestimator_backup/components/09d66d9e954a65b9a4eef836531d4bba/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Search_Amount'] = 1000;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Search_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/webestimator_backup/components/4772888552d3bc287ef141851e8dbc2c/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value == true) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Analytics_Amount'] = 500;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('6ae9afcb4ee4df5cb997b74b63689957'))['Analytics_Amount'] = 0;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/webestimator_backup/components/d4e71b41b165d6722e88cf32cfda783a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var result;


  result = (await BackendlessUI.Functions.Custom['fn_74ce0ae175a6ebbf849380f73bae038c'](___arguments.context.getComponentDataStoreByUid('6ae9afcb4ee4df5cb997b74b63689957')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/webestimator_backup/components/74fc2cfb9f17f2b95eda4e76e85505e4/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) > 1) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/webestimator_backup/components/f0da70595adfb9966431bf426d91b3bb/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) > 3) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/webestimator_backup/components/e9287055e3ae50d94757d0725eb8a4ca/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) > 4) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/webestimator_backup/components/52716bb289c1dbe9ceb14121ca3b975a/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) > 2) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/webestimator_backup/components/2b458397d6fe02d10a5c067e80d708d5/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) > 6) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/webestimator_backup/components/9fb260b6ce540ecb4df4ad7d08594b81/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) > 5) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/webestimator_backup/components/e529a6a16bfe2b77355b7137c0cfe1fe/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) > 7) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/webestimator_backup/components/e462cd359c3da084f00a14fbaec3ee07/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) > 8) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/webestimator_backup/components/a16836d6fd9825e2bfd5f5310ec3adc7/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac')['pagetotal'] = ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) * 125);

  return (String('$') + String((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) * 125))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/webestimator_backup/components/fe5e824d2e85eed60054c17d9afe83ac/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var amountToIncrement, runningtotal;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  amountToIncrement = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'), 'difficulty')) * 125;
  ___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac')['calctotal'] = amountToIncrement;

  return amountToIncrement

  },
  /* handler:onContentAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log(___arguments.context.getComponentDataStoreByUid('1861fcb150236764dfb74c4243bcd2ac'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/webestimator_backup/components/c56e5f36b9891b340aefee62b778d4a6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var runningTotal, i;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  runningTotal = 0;
  console.log(getObjectProperty(___arguments.context.pageData, 'WebpagesArray'));
  for (i = 1; i <= 25; i++) {
    if ((getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)]), 'visible')) == true) {
      runningTotal = runningTotal + (Number((getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)]), 'pagetotal'))));
    }
  }
  console.log(runningTotal);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/webestimator_backup/components/8fe7c65c3cbd3c9f64c6a89dc5e2cded/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var runningTotal, i;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  runningTotal = 0;
  for (i = 1; i <= 25; i++) {
    if ((getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)]), 'visible')) == true) {
      runningTotal = runningTotal + (Number((getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)]), 'pagetotal'))));
    }
  }

  return runningTotal

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/webestimator_backup/components/a189e0bea60d20aafe954ed96dd2aefd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/webestimator_backup/components/47d3aabe6ac54e4f08768607192adcd6/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b')), 'ProjectName')) == '' || (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b')), 'ProjectName')) == null) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/webestimator_backup/components/6dc6677acde33cf33fc997e8c30926d2/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b')), 'EmailAddress')) == '' || (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b')), 'EmailAddress')) == null) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/webestimator_backup/components/794280351187cf017ac347edd373ac39/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('48b946fad1bd282d1d972a021434a96b')), 'DefaultWebpages'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/webestimator_backup/components/08d5a4a4fb56e438a18fe9098cae5d0b/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var runningTotal, i;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  runningTotal = 0;
  for (i = 1; i <= 25; i++) {
    if ((getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)]), 'visible')) == true) {
      runningTotal = runningTotal + (Number((getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'WebpagesArray'))[(i - 1)]), 'pagetotal'))));
    }
  }
  ___arguments.context.pageData['DevelopmentCosts'] = runningTotal;

  return (String('$') + String(runningTotal))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/webestimator_backup/components/231c50e4532a25916ec2b51900b36dc8/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('$') + String(getObjectProperty(___arguments.context.pageData, 'AddOnsCosts')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/webestimator_backup/components/62715681cd529f7f7ab9ea847f96537f/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var temp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((Number((getObjectProperty(___arguments.context.pageData, 'AddOnsCosts')))) + (Number((getObjectProperty(___arguments.context.pageData, 'DevelopmentCosts')))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/websites/components/d1d9de3c08188cf9ff4108b1614be69a/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var AppURL, AppBaseURL;


  AppURL = (await (async function() {
  	return window.location.href
  })());
  AppBaseURL = AppURL.slice(0, 22);
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', [({ 'textLink': 'Home','urlLink': '#home','active': true }), ({ 'textLink': 'Services','urlLink': '#services','active': false }), ({ 'textLink': 'Testimonials','urlLink': '#testimonials','active': false }), ({ 'textLink': 'Gallery','urlLink': '#gallery','active': false }), ({ 'textLink': 'Project Estimator','urlLink': String('?page=webestimator'),'active': false }), ({ 'textLink': 'Contact','urlLink': '#contact','active': false })]);

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/websites/components/4494147e47463208b1e17aafe36cda16/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var textLink, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  textLink = (getObjectProperty(___arguments.context.dataModel, 'textLink'));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')) == textLink) {
      item['active'] = true;
    } else {
      item['active'] = false;
    }
  ; return item;}))));
  if (textLink == 'Home') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')));
  } else if (textLink == 'Services') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')));
  } else if (textLink == 'Testimonials') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')));
  } else if (textLink == 'Gallery') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')));
  } else if (textLink == 'Team') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')));
  } else if (textLink == 'Contact') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0ee4f7a2f9f93096442f303878b88406')));
  } else if (textLink == 'Project Estimator') {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('projectestimator', undefined);
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 990) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 0;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.dataModel, 'active')) {
    if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) == true) {
      return ['link', 'nav__link', 'nav__link_active', null, 'boldmenuitem'];
    } else if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) != true) {
      return ['link', 'nav__link', 'nav__link_active', 'whiteshadowtext', null, 'boldmenuitem'];
    }
  } else if ((getObjectProperty(___arguments.context.dataModel, 'active')) != true) {
    if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) == true) {
      return ['link', 'nav__link', null, null];
    } else if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) != true) {
      return ['link', 'nav__link', 'shadowtext', null];
    }
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/websites/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onResize */
  ['onResize'](___arguments) {
    var leftSlideBy, left, j, half, galleryList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 990) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 0;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 'auto';
  }
  galleryList = (getObjectProperty(___arguments.context.appData, 'galleryList'));
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) >= 1200) {
    ___arguments.context.appData['leftSlideBy'] = 240;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 1199) {
    ___arguments.context.appData['leftSlideBy'] = 200;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 990) {
    ___arguments.context.appData['leftSlideBy'] = 260;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768) {
    ___arguments.context.appData['leftSlideBy'] = 370;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576) {
    ___arguments.context.appData['leftSlideBy'] = 250;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
    ___arguments.context.appData['leftSlideBy'] = 100;
  }
  leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
  half = Math.floor(galleryList.length / 2);
  ___arguments.context.pageData['leftStart'] = (-15 - half * leftSlideBy);
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 576) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 120);
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 480) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 130);
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 15);
  }
  left = (getObjectProperty(___arguments.context.pageData, 'leftStart'));
  var j_list = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('c376c79acf1e16de2463e38439c34656'));
  for (var j_index in j_list) {
    j = j_list[j_index];
    j['opacity'] = 1;
    j['left'] = left;
    if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
      j['active'] = true;
    } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
      j['active'] = true;
    } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
      j['active'] = true;
    } else if (left == 0 && leftSlideBy == 100) {
      j['active'] = true;
    } else {
      j['active'] = false;
    }
    left = (typeof left == 'number' ? left : 0) + leftSlideBy;
  }

  },
  /* handler:onResize */
  /* handler:onScroll */
  async ['onScroll'](___arguments) {
    var a, item, team, gallery, home2, features, testimonials, contact, topOnViewport, header, elem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function topOnViewport2(elem, header) {
  topOnViewport = (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', elem)) - ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight'))) + (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) - ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', header)));
  return topOnViewport
}


  contact = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0ee4f7a2f9f93096442f303878b88406')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  team = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  gallery = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  testimonials = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  features = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  home2 = await topOnViewport2(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')), ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  if (___arguments.scrollTop - ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) < home2) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b'))))) < features && ___arguments.scrollTop > home2) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < testimonials && ___arguments.scrollTop > features + home2) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < gallery && ___arguments.scrollTop > testimonials + features + home2) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < team && ___arguments.scrollTop > testimonials + features + home2 + gallery) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < contact && ___arguments.scrollTop > testimonials + features + home2 + gallery + team) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0ee4f7a2f9f93096442f303878b88406')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop > ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) / 3) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('f5f6c87f71d4ee4f6b9b334b92f88f31'))['opacity'] = 0.3;
  } else if (___arguments.scrollTop > ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) / 10) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('f5f6c87f71d4ee4f6b9b334b92f88f31'))['opacity'] = 0.5;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('f5f6c87f71d4ee4f6b9b334b92f88f31'))['opacity'] = 1;
  }
  if (___arguments.scrollTop) {
    ___arguments.context.pageData['scrollTop'] = true;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['box-shadow'] = '0px 2px 4px rgba(0, 0, 0, 0.1)';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['height'] = '60px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-top'] = '7px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '-5px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '45px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#777777';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#777777';
  } else {
    ___arguments.context.pageData['scrollTop'] = false;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['box-shadow'] = 'none';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['height'] = '80px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['background-color'] = 'transparent';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['padding-top'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dc8e2162b0174608379cfba7549be8db'))['margin-left'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '80px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#ffffff';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#ffffff';
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 400) {
    a = 150;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 500) {
    a = (typeof a == 'number' ? a : 0) + 200;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 600) {
    a = (typeof a == 'number' ? a : 0) + 300;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 700) {
    a = (typeof a == 'number' ? a : 0) + 400;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 800) {
    a = (typeof a == 'number' ? a : 0) + 500;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 900) {
    a = (typeof a == 'number' ? a : 0) + 600;
  } else if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 1050) {
    a = (typeof a == 'number' ? a : 0) + 700;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 990 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 1050) {
    if (___arguments.scrollTop >= ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) - a)) {
      ___arguments.context.appData['featuresInFocus'] = true;
    }
    if (___arguments.scrollTop >= ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) - a)) {
      ___arguments.context.appData['testimonialsInFocus'] = true;
    }
    if (___arguments.scrollTop >= ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + ((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + (((function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')))) - a)) {
      ___arguments.context.appData['teamInFocus'] = true;
    }
  } else {
    ___arguments.context.appData['featuresInFocus'] = true;
    ___arguments.context.appData['testimonialsInFocus'] = true;
    ___arguments.context.appData['teamInFocus'] = true;
  }

  },
  /* handler:onScroll */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var teamList, testimonialsList, featuresList, appData, galleryList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  appData = (await (async function() {
  	const data = {
  	  "features": [
  	    {
  	      "id": "f1",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-1.jpg",
  	      "title": "Amazing Experience",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f2",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-2.jpg",
  	      "title": "24/7 Support",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f3",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-3.jpg",
  	      "title": "Real-time",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f4",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-4.jpg",
  	      "title": "Easy To Use",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f5",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-5.jpg",
  	      "title": "Free Updates",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f6",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-6.jpg",
  	      "title": "All Platforms",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    }
  	  ],
  	  "gallery": [
  	    {
  	      "id": "g1",
  	      "image": "https://backendless.com/images/Fresh/dbImg/gallery/item1.jpg"
  	    },
  	    {
  	      "id": "g2",
  	      "image": "https://backendless.com/images/Fresh/dbImg/gallery/item2.jpg"
  	    },
  	    {
  	      "id": "g3",
  	      "image": "https://backendless.com/images/Fresh/dbImg/gallery/item3.jpg"
  	    },
  	    {
  	      "id": "g4",
  	      "image": "https://backendless.com/images/Fresh/dbImg/gallery/item4.jpg"
  	    },
  	    {
  	      "id": "g5",
  	      "image": "https://backendless.com/images/Fresh/dbImg/gallery/item5.jpg"
  	    },
  	    {
  	      "id": "g6",
  	      "image": "https://backendless.com/images/Fresh/dbImg/gallery/item6.jpg"
  	    }
  	  ],
  	  "team": [
  	    {
  	      "id": "t1",
  	      "name": "Joan Doe",
  	      "career": "Founder - CEO",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo1.jpg",
  	      "socialMedia": {
  	        "social": [
  	          {
  	            "url": "#",
  	            "name": "facebook"
  	          },
  	          {
  	            "url": "#",
  	            "name": "github"
  	          },
  	          {
  	            "url": "#",
  	            "name": "linkedin"
  	          },
  	          {
  	            "url": "",
  	            "name": "twitter"
  	          }
  	        ]
  	      }
  	    },
  	    {
  	      "id": "t2",
  	      "name": "John Doe",
  	      "career": "Co-founder",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo2.jpg",
  	      "socialMedia": {
  	        "social": [
  	          {
  	            "url": "#",
  	            "name": "facebook"
  	          },
  	          {
  	            "url": "",
  	            "name": "github"
  	          },
  	          {
  	            "url": "",
  	            "name": "linkedin"
  	          },
  	          {
  	            "url": "#",
  	            "name": "twitter"
  	          }
  	        ]
  	      }
  	    },
  	    {
  	      "id": "t3",
  	      "name": "Joan Doe",
  	      "career": "Co-founder",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo3.jpg",
  	      "socialMedia": {
  	        "social": [
  	          {
  	            "url": "#",
  	            "name": "facebook"
  	          },
  	          {
  	            "url": "#",
  	            "name": "github"
  	          },
  	          {
  	            "url": "#",
  	            "name": "linkedin"
  	          },
  	          {
  	            "url": "",
  	            "name": "twitter"
  	          }
  	        ]
  	      }
  	    },
  	    {
  	      "id": "t4",
  	      "name": "John Doe",
  	      "career": "Financial Manager",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo4.jpg",
  	      "socialMedia": {
  	        "social": [
  	          {
  	            "url": "#",
  	            "name": "facebook"
  	          },
  	          {
  	            "url": "",
  	            "name": "github"
  	          },
  	          {
  	            "url": "#",
  	            "name": "linkedin"
  	          },
  	          {
  	            "url": "",
  	            "name": "twitter"
  	          }
  	        ]
  	      }
  	    }
  	  ],
  	  "testimonials": [
  	    {
  	      "id": "tm1",
  	      "author": "Jane Doe",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/testimonials/auth1.jpg"
  	    },
  	    {
  	      "id": "tm2",
  	      "author": "John Doe",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/testimonials/auth2.jpg"
  	    },
  	    {
  	      "id": "tm3",
  	      "author": "Jane Doe",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/testimonials/auth3.jpg"
  	    },
  	    {
  	      "id": "tm4",
  	      "author": "John Doe",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/testimonials/item4.jpg"
  	    }
  	  ]
  	}

  	return data
  })());
  galleryList = (getObjectProperty(appData, 'gallery'));
  ___arguments.context.appData['galleryList'] = galleryList;
  testimonialsList = (getObjectProperty(appData, 'testimonials'));
  ___arguments.context.pageData['MessageSent'] = false;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('153bb1d13492b519dc0717b333c20947', testimonialsList);

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/websites/components/fefec6b6560f11b1a4c62ed6ad39d36c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52')), 'height')) == 349) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 0;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 349;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/websites/components/153bb1d13492b519dc0717b333c20947/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
      return ((___arguments.context.appData['testimonialsInFocus']) ? ['testimonials__items-block', 'testimonials__items-block_in-focus'] : ['testimonials__items-block'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/websites/components/c376c79acf1e16de2463e38439c34656/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var item, leftSlideBy, left, newGalleryList, half, firstList, galleryList, lastList, idGalleryItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  await new Promise(r => setTimeout(r, 1000 || 0));
  galleryList = (getObjectProperty(___arguments.context.appData, 'galleryList'));
  idGalleryItem = 1;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c376c79acf1e16de2463e38439c34656', (await Promise.all(galleryList.map(async item => {  item['id'] = idGalleryItem;
    idGalleryItem = (typeof idGalleryItem == 'number' ? idGalleryItem : 0) + 1;
  ; return item;}))));
  ___arguments.context.appData['leftSlideBy'] = 240;
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 1199) {
    ___arguments.context.appData['leftSlideBy'] = 200;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 990) {
    ___arguments.context.appData['leftSlideBy'] = 260;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768) {
    ___arguments.context.appData['leftSlideBy'] = 370;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576) {
    ___arguments.context.appData['leftSlideBy'] = 250;
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
    ___arguments.context.appData['leftSlideBy'] = 100;
  }
  half = Math.floor(galleryList.length / 2);
  lastList = galleryList.slice(((half + 1) - 1), galleryList.length);
  firstList = galleryList.slice(0, half);
  newGalleryList = (JSON.parse((JSON.stringify(((addItemToList(lastList, galleryList)).concat(firstList))))));
  leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
  ___arguments.context.pageData['leftStart'] = (-15 - half * leftSlideBy);
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 576) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 120);
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576 && ((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 480) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 130);
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 15);
  }
  left = (getObjectProperty(___arguments.context.pageData, 'leftStart'));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c376c79acf1e16de2463e38439c34656', (await Promise.all(newGalleryList.map(async item => {  item['opacity'] = 1;
    item['left'] = left;
    if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
      item['active'] = true;
    } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
      item['active'] = true;
    } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
      item['active'] = true;
    } else if (left == 0 && leftSlideBy == 100) {
      item['active'] = true;
    } else {
      item['active'] = false;
    }
    left = (typeof left == 'number' ? left : 0) + leftSlideBy;
  ; return item;}))));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/websites/components/e23154e2b40dc5a8ac7f9a7e34ff980f/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  async onClassListAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 480) {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('e23154e2b40dc5a8ac7f9a7e34ff980f'))['left'] = (String(getObjectProperty(___arguments.context.dataModel, 'left')) + String('px'));
  } else {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('e23154e2b40dc5a8ac7f9a7e34ff980f'))['left'] = (String(getObjectProperty(___arguments.context.dataModel, 'left')) + String('%'));
  }
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('e23154e2b40dc5a8ac7f9a7e34ff980f'))['opacity'] = (getObjectProperty(___arguments.context.dataModel, 'opacity'));
  if (getObjectProperty(___arguments.context.dataModel, 'active')) {
    return ['g-slider__item', 'g-slider__item_center'];
  }

  return ['g-slider__item']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/websites/components/108f6ec347effdbce289a49d625d03af/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['isOpenModal'] = true;
  ___arguments.context.pageData['counterSlide2'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e23154e2b40dc5a8ac7f9a7e34ff980f'), 'id'));
  ___arguments.context.pageData['currentSlide'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e23154e2b40dc5a8ac7f9a7e34ff980f'), 'id'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/websites/components/26a1309c25831a8af67d5fe98587c773/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    var leftSlideBy, minValue, card, left, j, maxValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  maxValue = 0;
  minValue = 0;
  leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
  if ((typeof (getObjectProperty(___arguments.context.pageData, 'stopClick')) === 'undefined') || ((new Date()).valueOf()) > (getObjectProperty(___arguments.context.pageData, 'stopClick')) + 300) {
    ___arguments.context.pageData['stopClick'] = ((new Date()).valueOf());
    var j_list = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('c376c79acf1e16de2463e38439c34656'));
    for (var j_index in j_list) {
      j = j_list[j_index];
      j['opacity'] = 1;
      j['left'] = ((getObjectProperty(j, 'left')) + leftSlideBy);
      left = (getObjectProperty(j, 'left'));
      if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
        j['active'] = true;
      } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
        j['active'] = true;
      } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
        j['active'] = true;
      } else if (left == 0 && leftSlideBy == 100) {
        j['active'] = true;
      } else {
        j['active'] = false;
      }
      if (maxValue < left) {
        maxValue = left;
        card = j;
      }
      if (minValue > left) {
        minValue = left;
      }
    }
    card['opacity'] = 0;
    card['left'] = (minValue - leftSlideBy);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/websites/components/0ecc200a34ec908bf97bef91588de587/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var leftSlideBy, maxValue, card, left, j, minValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  maxValue = 0;
  minValue = 0;
  leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
  if ((typeof (getObjectProperty(___arguments.context.pageData, 'stopClick')) === 'undefined') || ((new Date()).valueOf()) > (getObjectProperty(___arguments.context.pageData, 'stopClick')) + 300) {
    ___arguments.context.pageData['stopClick'] = ((new Date()).valueOf());
    var j_list = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('c376c79acf1e16de2463e38439c34656'));
    for (var j_index in j_list) {
      j = j_list[j_index];
      j['left'] = ((getObjectProperty(j, 'left')) - leftSlideBy);
      left = (getObjectProperty(j, 'left'));
      j['opacity'] = 1;
      if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
        j['active'] = true;
      } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
        j['active'] = true;
      } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
        j['active'] = true;
      } else if (left == 0 && leftSlideBy == 100) {
        j['active'] = true;
      } else {
        j['active'] = false;
      }
      if (minValue > left) {
        minValue = left;
        card = j;
      }
      if (maxValue < left) {
        maxValue = left;
      }
    }
    card['opacity'] = 0;
    card['left'] = (maxValue + leftSlideBy);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/websites/components/4bc8650348b45a9267097b43aff505c6/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('4bc8650348b45a9267097b43aff505c6', (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e5ac9c8ad984738efc349d07b4dc74b1'), 'socialMedia')), 'social')));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/websites/components/1fdde5c76ea432e90eb67a18533b2dac/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes, nameSocial;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['team-card__link', 'link'];
  nameSocial = (getObjectProperty(___arguments.context.dataModel, 'name'));
  if ((getObjectProperty(___arguments.context.dataModel, 'url')) == '') {
    addItemToList(classes, 'team-card__link_hide');
  }
  if (nameSocial == 'facebook') {
    addItemToList(classes, 'fab fa-facebook-f');
  }
  if (nameSocial == 'github') {
    addItemToList(classes, 'fab fa-github');
  }
  if (nameSocial == 'linkedin') {
    addItemToList(classes, 'fab fa-linkedin-in');
  }
  if (nameSocial == 'twitter') {
    addItemToList(classes, 'fab fa-twitter');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/websites/components/b6f27fbafe317fe16572dfeaf1fb0698/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
      return ((___arguments.context.appData['teamInFocus']) ? ['team__cards-block', 'team__cards-block_in-focus'] : ['team__cards-block'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/websites/components/d2551aa8d85f0b8224661c4aaaa53f32/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var firstName, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  firstName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName'));
  regExp = (new RegExp('.{2,}', 'g'));
  if (firstName.length <= 50) {
    ___arguments.context.pageData['firstNameValue'] = firstName;
  } else if (firstName.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['firstName'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['firstName'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'firstNameValue')), 0));
  }
  if ((regExp.test(firstName)) == false && firstName != '' || firstName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length < 2 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = false;
  } else if ((regExp.test(firstName)) == false && firstName == '' || firstName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length == 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/websites/components/3ddf6adfe3e580cc962a62234bf2e2d5/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-first-name'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/websites/components/f5bd0a2c6f0bde798ea8df133e9e7e3c/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var lastName, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  lastName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName'));
  regExp = (new RegExp('.{2,}', 'g'));
  if (lastName.length <= 50) {
    ___arguments.context.pageData['lastNameValue'] = lastName;
  } else if (lastName.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['lastName'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['lastName'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'lastNameValue')), 0));
  }
  if ((regExp.test(lastName)) == false && lastName != '' || lastName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length < 2 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = false;
  } else if ((regExp.test(lastName)) == false && lastName == '' || lastName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length == 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/websites/components/557e31a13073b4848ca61837b9e70a38/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-last-name'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/websites/components/cdc2459c0f1ac842997b621a04744c0c/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var email, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  email = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'email'));
  regExp = (new RegExp('[a-zA-Z0-9.\\-_+]{1,}@[^\\W\\.][a-zA-Z0-9\\-]{0,}\\.[a-zA-Z]{1,}', 'g'));
  if (email.length <= 50) {
    ___arguments.context.pageData['emailValue'] = email;
  } else if (email.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['email'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['email'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'emailValue')), 0));
  }
  if ((regExp.test(email)) == false && email != '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = false;
  } else if ((regExp.test(email)) == false && email == '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/websites/components/556317981b853c56d8e8e837e5b16c9e/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-email'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/websites/components/d64247a69fb82b1b932f6a1191167d68/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-phone'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/websites/components/1712009c62182acf71f1e4e777ac98dd/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var phone, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  phone = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone'));
  regExp = (new RegExp('^[0-9+\\(\\)\\-\\s]+$', 'g'));
  if (phone.length <= 50) {
    ___arguments.context.pageData['phoneValue'] = phone;
  } else if (phone.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['phone'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['phone'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'phoneValue')), 0));
  }
  if (phone.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone')).length < 5 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = true;
  } else if ((regExp.test(phone)) && phone.length >= 5) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = false;
  } else if (phone == '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = true;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/websites/components/275519e4f1ff2a5c0d556bd7bfaaf6c5/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-website'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/websites/components/ddb987ac0b1a2e02086b043645e7decd/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var website, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  website = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website'));
  regExp = (new RegExp('[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)', 'gi'));
  if (website.length <= 249) {
    ___arguments.context.pageData['websiteValue'] = website;
  } else if (website.length > 249) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['website'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['website'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'websiteValue')), 0));
  }
  if ((regExp.test(website)) == false && website != '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = true;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = false;
  }
  if ((regExp.test((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website')))) == true && website.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website')).length) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/websites/components/b17c55c60f4dcdf2ae8fc66b95b63932/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var subject, regExp, firstName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  subject = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject'));
  regExp = (new RegExp('.{4,}', 'g'));
  if (subject.length <= 300) {
    ___arguments.context.pageData['subjectValue'] = subject;
  } else if (subject.length > 300) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['subject'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['subject'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'subjectValue')), 0));
  }
  if ((regExp.test(subject)) == false && subject != '' || subject.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length < 4 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = false;
  } else if ((regExp.test(subject)) == false && subject == '' || subject.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length == 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/websites/components/a38dcbb7299151a66062e5a8eaaf984c/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-subject'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/websites/components/101b130a0c5ff2588aa5637fc5fcdb27/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-message'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage']) {
    addItemToList(classes, 'c-form__label_alert');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/websites/components/6eb697a67eb600c29f860957a2ac3b3e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, message, subject, website, phone, email, lastName, firstName, checkStatusMessage, checkStatusSubject, checkStatusWebsite, checkStatusPhone, checkStatusEmail, checkStatusLastName, checkStatusFirstName, statusMessage, statusSubject, statusWebsite, statusPhone, statusEmail, statusLastName, statusFirstName, company, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function ensureArray(items) {
  return Array.isArray(items) ? items : [items]
}

/**
 * Describe this function...
 */
async function firstNameValidation() {
  regExp = (new RegExp('.{2,}', 'g'));
  if (!(regExp.test(firstName)) || firstName == '' || firstName == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusFirsName() {
  if (!(getObjectProperty(statusFirstName, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('d2551aa8d85f0b8224661c4aaaa53f32')));
    if (firstName == '' || firstName == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function lastNameValidation() {
  regExp = (new RegExp('.{2,}', 'g'));
  if (!(regExp.test(lastName)) || lastName == '' || lastName == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusLastName2() {
  if (!(getObjectProperty(statusLastName, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('f5bd0a2c6f0bde798ea8df133e9e7e3c')));
    if (lastName == '' || lastName == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function emailValidation() {
  regExp = (new RegExp('[a-zA-Z0-9.\\-_+]{1,}@[^\\W\\.][a-zA-Z0-9\\-]{0,}\\.[a-zA-Z]{1,}', 'g'));
  if (!(regExp.test(email)) || email == '' || email == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function phoneValidation() {
  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'hintPhone')) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusEmail2() {
  if (!(getObjectProperty(statusEmail, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('cdc2459c0f1ac842997b621a04744c0c')));
    if (email == '' || email == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function websiteValidation() {
  regExp = (new RegExp('[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)', 'gi'));
  if (!(regExp.test(website)) && website != '' && website != null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function subjectValidation() {
  regExp = (new RegExp('.{4,}', 'g'));
  if (!(regExp.test(subject)) || subject == '' || subject == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusPhone2() {
  if (!(getObjectProperty(statusPhone, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('1712009c62182acf71f1e4e777ac98dd')));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = true;
    return false;
  }
  return true
}

/**
 * Describe this function...
 */
async function messageValidation() {
  if (message == '' || message == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusWebsite2() {
  if (!(getObjectProperty(statusWebsite, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('ddb987ac0b1a2e02086b043645e7decd')));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = true;
    return false;
  }
  return true
}

/**
 * Describe this function...
 */
async function checkStatusSubject2() {
  if (!(getObjectProperty(statusSubject, 'status'))) {
    (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b17c55c60f4dcdf2ae8fc66b95b63932')));
    if (subject == '' || subject == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function checkStatusMessage2() {
  if (!(getObjectProperty(statusMessage, 'status'))) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage'] = true;
    return false;
  }
  return true
}


  firstName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName'));
  lastName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName'));
  email = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'email'));
  phone = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone'));
  company = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'company'));
  website = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website'));
  subject = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject'));
  message = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'message'));
  statusFirstName = await firstNameValidation();
  statusLastName = await lastNameValidation();
  statusEmail = await emailValidation();
  statusPhone = await phoneValidation();
  statusWebsite = await websiteValidation();
  statusSubject = await subjectValidation();
  statusMessage = await messageValidation();
  checkStatusMessage = await checkStatusMessage2();
  checkStatusSubject = await checkStatusSubject2();
  checkStatusWebsite = await checkStatusWebsite2();
  checkStatusPhone = await checkStatusPhone2();
  checkStatusEmail = await checkStatusEmail2();
  checkStatusLastName = await checkStatusLastName2();
  checkStatusFirstName = await checkStatusFirsName();
  if (checkStatusFirstName != false && checkStatusLastName != false && checkStatusEmail != false && checkStatusPhone != false && checkStatusWebsite != false && checkStatusSubject != false && checkStatusMessage != false) {
    await Backendless.Messaging.sendEmail('New contact from the Hyperpixels Website', new Backendless.Bodyparts({ htmlmessage: ([['From: ',firstName,' ',lastName].join(''),'<br>Email: ',email,'<br> Phone: ',phone,'<br> Website: ',website,'<br> Subject: ',subject,'<br> Message: <br>',message].join('')) }), ensureArray('mike@hyperpixels.net'), undefined);
    ___arguments.context.pageData['MessageSent'] = true;
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b65aa795e4d6a994b3ddd6f68533c80d', false);
    try {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('df8b067a885dcda84a91376a3c4c5c7c', true);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7224592bf6676a81e607676ddbc597e1', false);

    } catch (error) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('df8b067a885dcda84a91376a3c4c5c7c', false);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7224592bf6676a81e607676ddbc597e1', true);
      console.log(error);

    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/websites/components/58c7b83b0c67da4ff3bef93451af2162/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var message, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  message = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'message'));
  regExp = (new RegExp('.{1,}', 'g'));
  if (message.length <= 249) {
    ___arguments.context.pageData['messageValue'] = message;
  } else if (message.length > 249) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['message'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['message'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'messageValue')), 0));
  }
  if ((regExp.test(message)) == false && message == '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage'] = true;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/websites/components/dc8e2162b0174608379cfba7549be8db/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/websites/components/251dd247af0d2f6ebe6ccde3866c89b5/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isOpenModal')) {
    return ['modal', 'open'];
  }

  return ['modal']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/websites/components/70dd1dff3e215deab9dcc3e29a6566e6/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async onMounted(___arguments) {
    var counter, data, gallery, item, listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setIdItem(data) {
  counter = 1;
  return await Promise.all(data.map(async item => {  item['id'] = counter;
  counter = (typeof counter == 'number' ? counter : 0) + 1;
; return item;}))
}


  await new Promise(r => setTimeout(r, 1000 || 0));
  gallery = (getObjectProperty(___arguments.context.appData, 'galleryList'));
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', (await setIdItem(gallery)));
  listLength = gallery.length;
  ___arguments.context.pageData['totalSlide'] = listLength;
  ___arguments.context.pageData['currentSlide'] = 1;

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/websites/components/bb37f7327e62a50eb06507a61d6d1fc6/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == (getObjectProperty(___arguments.context.dataModel, 'id'))) {
    return ['modal__slider-item', 'active'];
  }

  return ['modal__slider-item']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/websites/components/831fddd4bb7ced9296de1aa550213577/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) > 1) {
    ___arguments.context.pageData['counterSlide2'] = ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == 1 ? listLength : (getObjectProperty(___arguments.context.pageData, 'counterSlide2')) - 1);
  }
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ___arguments.context.pageData['currentSlide'] = (getObjectProperty(___arguments.context.pageData, 'counterSlide2'));

  },
  /* handler:onClick */
  /* handler:onMouseOut */
  onMouseOut(___arguments) {
    var listLength;


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('831fddd4bb7ced9296de1aa550213577'))['opacity'] = '0';

  },
  /* handler:onMouseOut */
  /* handler:onMouseOver */
  onMouseOver(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == 1) {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('831fddd4bb7ced9296de1aa550213577'))['opacity'] = '0';
  } else {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('831fddd4bb7ced9296de1aa550213577'))['opacity'] = '1';
  }

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./pages/websites/components/dba21793ecfc81f1424a1ce6ad99a8b3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) < listLength) {
    ___arguments.context.pageData['counterSlide2'] = ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == listLength ? 1 : (getObjectProperty(___arguments.context.pageData, 'counterSlide2')) + 1);
  }
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ___arguments.context.pageData['currentSlide'] = (getObjectProperty(___arguments.context.pageData, 'counterSlide2'));

  },
  /* handler:onClick */
  /* handler:onMouseOut */
  onMouseOut(___arguments) {
    var listLength;


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('dba21793ecfc81f1424a1ce6ad99a8b3'))['opacity'] = '0';

  },
  /* handler:onMouseOut */
  /* handler:onMouseOver */
  onMouseOver(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == listLength) {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('dba21793ecfc81f1424a1ce6ad99a8b3'))['opacity'] = '0';
  } else {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('dba21793ecfc81f1424a1ce6ad99a8b3'))['opacity'] = '1';
  }

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./pages/websites/components/31c87ba294176e587b91b87c58e68a82/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/websites/components/16b9cb3fdc9bf1919465a18c77a6e467/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/websites/components/3607af0d8419be43455fc4ac16f9cc33/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var company;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  company = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'company'));
  if (company.length <= 100) {
    ___arguments.context.pageData['companyValue'] = company;
  } else if (company.length > 100) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['company'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['company'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'companyValue')), 0));
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/websites/components/30ea90dd05af67628fd821d5003c3d3d/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  onMounted(___arguments) {
      ___arguments.context.pageData['firstNameValue'] = '';
  ___arguments.context.pageData['lastNameValue'] = '';
  ___arguments.context.pageData['emailValue'] = '';
  ___arguments.context.pageData['phoneValue'] = '';
  ___arguments.context.pageData['companyValue'] = '';
  ___arguments.context.pageData['websiteValue'] = '';
  ___arguments.context.pageData['subjectValue'] = '';
  ___arguments.context.pageData['messageValue'] = '';

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/websites/components/ba557046fb5348f79a8a94c342388c04/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'MessageSent'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_11f44a373cdbb9594ae6459eae3e53d4/logic/dc8e2162b0174608379cfba7549be8db/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')));

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_11f44a373cdbb9594ae6459eae3e53d4/logic/d1d9de3c08188cf9ff4108b1614be69a/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeMount */
  async ['onBeforeMount'](___arguments) {
    var AppURL, AppBaseURL;


  AppURL = (await (async function() {
  	return window.location.href
  })());
  AppBaseURL = AppURL.slice(0, 22);
  if (await Backendless.UserService.isValidLogin()) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', [({ [`textLink`]: 'Home',[`urlLink`]: '?page=home',[`active`]: false }), ({ [`textLink`]: 'Services',[`urlLink`]: '?page=home&view=services',[`active`]: false }), ({ [`textLink`]: 'Clients',[`urlLink`]: '?page=home&view=clients',[`active`]: false }), ({ [`textLink`]: 'Contact',[`urlLink`]: '?page=home&view=contact',[`active`]: false }), ({ [`textLink`]: 'Web Project Estimator',[`urlLink`]: String('?page=webestimator'),[`active`]: false }), ({ [`textLink`]: 'Tech Support Estimator',[`urlLink`]: String('?page=webestimator'),[`active`]: true }), ({ [`textLink`]: 'Dashboard',[`urlLink`]: String('?page=webestimator'),[`active`]: false }), ({ [`textLink`]: 'Log Out',[`urlLink`]: String('?page=webestimator'),[`active`]: false })]);
  } else {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', [({ [`textLink`]: 'Home',[`urlLink`]: '#home',[`active`]: false }), ({ [`textLink`]: 'Services',[`urlLink`]: '#services',[`active`]: false }), ({ [`textLink`]: 'Clients',[`urlLink`]: '#clients',[`active`]: false }), ({ [`textLink`]: 'Contact',[`urlLink`]: '#contact',[`active`]: false }), ({ [`textLink`]: 'Web Project Estimator',[`urlLink`]: String('?page=webestimator'),[`active`]: false }), ({ [`textLink`]: 'Tech Support Estimator',[`urlLink`]: String('?page=webestimator'),[`active`]: true }), ({ [`textLink`]: 'Login',[`urlLink`]: '#login',[`active`]: false })]);
  }

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./components/reusable/r_11f44a373cdbb9594ae6459eae3e53d4/logic/4494147e47463208b1e17aafe36cda16/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var textLink, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  textLink = (getObjectProperty(___arguments.context.dataModel, 'textLink'));
  if (true) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')) == textLink) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
    if (textLink == 'Home') {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', ({ [`view`]: 'home' }));
    } else if (textLink == 'Services') {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', ({ [`view`]: 'services' }));
    } else if (textLink == 'Clients') {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', ({ [`view`]: 'clients' }));
    } else if (textLink == 'Portfolio') {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', ({ [`view`]: 'portfolio' }));
    } else if (textLink == 'Team') {
    } else if (textLink == 'Contact') {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('index.html', false, '?page=home&view=contact');
    } else if (textLink == 'Web Project Estimator') {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('projectestimator', undefined);
    } else if (textLink == 'Tech Support Estimator') {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('technicalsupportestimator', undefined);
    } else if (textLink == 'Login') {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
    } else if (textLink == 'Dashboard') {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dash', undefined);
    } else if (textLink == 'Log Out') {
      await Backendless.UserService.logout();
      await (async function() {
      	window.location.reload();
      })();
    }
    if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 990) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 0;
    }
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.dataModel, 'active')) {
    if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) == true) {
      return ['link', 'nav__link', 'nav__link_active', null, 'boldmenuitem'];
    } else if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) != true) {
      return ['link', 'nav__link', 'nav__link_active', 'whiteshadowtext', null, 'boldmenuitem'];
    }
  } else if ((getObjectProperty(___arguments.context.dataModel, 'active')) != true) {
    if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) == true) {
      return ['link', 'nav__link', null, null];
    } else if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) != true) {
      return ['link', 'nav__link', 'shadowtext', null];
    }
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_11f44a373cdbb9594ae6459eae3e53d4/logic/fefec6b6560f11b1a4c62ed6ad39d36c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52')), 'height')) == 349) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 0;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 349;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_11f44a373cdbb9594ae6459eae3e53d4/logic/d6eb2689f2c259f3fd671877d34cc756/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await Backendless.UserService.logout();
  await (async function() {
  	window.location.reload();
  })();

  },
  /* handler:onClick */
  /* handler:onTooltipTextAssignment */
  async ['onTooltipTextAssignment'](___arguments) {
    var error, temp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (await Backendless.UserService.isValidLogin()) {
    return (String('Logout Current User: ') + String(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'email')));
  }

  },
  /* handler:onTooltipTextAssignment */
  /* content */
}))

define('./components/reusable/r_4fc632952ff5837f5ed586b41d277eb1/logic/dc8e2162b0174608379cfba7549be8db/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')));

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_4fc632952ff5837f5ed586b41d277eb1/logic/d1d9de3c08188cf9ff4108b1614be69a/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeMount */
  async ['onBeforeMount'](___arguments) {
    var AppURL, AppBaseURL;


  AppURL = (await (async function() {
  	return window.location.href
  })());
  AppBaseURL = AppURL.slice(0, 22);
  if (await Backendless.UserService.isValidLogin()) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', [({ [`textLink`]: 'Home',[`urlLink`]: '?page=home',[`active`]: false }), ({ [`textLink`]: 'Services',[`urlLink`]: '?page=home&view=services',[`active`]: false }), ({ [`textLink`]: 'Clients',[`urlLink`]: '?page=home&view=clients',[`active`]: false }), ({ [`textLink`]: 'Contact',[`urlLink`]: '?page=home&view=contact',[`active`]: false }), ({ [`textLink`]: 'Web Project Estimator',[`urlLink`]: String('?page=webestimator'),[`active`]: true }), ({ [`textLink`]: 'Tech Support Estimator',[`urlLink`]: String('?page=webestimator'),[`active`]: false }), ({ [`textLink`]: 'Dashboard',[`urlLink`]: String('?page=webestimator'),[`active`]: false }), ({ [`textLink`]: 'Log Out',[`urlLink`]: String('?page=webestimator'),[`active`]: false })]);
  } else {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', [({ [`textLink`]: 'Home',[`urlLink`]: '#home',[`active`]: false }), ({ [`textLink`]: 'Services',[`urlLink`]: '#services',[`active`]: false }), ({ [`textLink`]: 'Clients',[`urlLink`]: '#clients',[`active`]: false }), ({ [`textLink`]: 'Contact',[`urlLink`]: '#contact',[`active`]: false }), ({ [`textLink`]: 'Web Project Estimator',[`urlLink`]: String('?page=webestimator'),[`active`]: true }), ({ [`textLink`]: 'Tech Support Estimator',[`urlLink`]: String('?page=webestimator'),[`active`]: false }), ({ [`textLink`]: 'Login',[`urlLink`]: '#login',[`active`]: false })]);
  }

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./components/reusable/r_4fc632952ff5837f5ed586b41d277eb1/logic/4494147e47463208b1e17aafe36cda16/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var textLink, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  textLink = (getObjectProperty(___arguments.context.dataModel, 'textLink'));
  if (true) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')) == textLink) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
    if (textLink == 'Home') {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', ({ [`view`]: 'home' }));
    } else if (textLink == 'Services') {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', ({ [`view`]: 'services' }));
    } else if (textLink == 'Clients') {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', ({ [`view`]: 'clients' }));
    } else if (textLink == 'Portfolio') {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', ({ [`view`]: 'portfolio' }));
    } else if (textLink == 'Team') {
    } else if (textLink == 'Contact') {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('index.html', false, '?page=home&view=contact');
    } else if (textLink == 'Web Project Estimator') {
    } else if (textLink == 'Tech Support Estimator') {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('technicalsupportestimator', undefined);
    } else if (textLink == 'Login') {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
    } else if (textLink == 'Dashboard') {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dash', undefined);
    } else if (textLink == 'Log Out') {
      await Backendless.UserService.logout();
      await (async function() {
      	window.location.reload();
      })();
    }
    if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 990) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 0;
    }
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.dataModel, 'active')) {
    if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) == true) {
      return ['link', 'nav__link', 'nav__link_active', null, 'boldmenuitem'];
    } else if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) != true) {
      return ['link', 'nav__link', 'nav__link_active', 'whiteshadowtext', null, 'boldmenuitem'];
    }
  } else if ((getObjectProperty(___arguments.context.dataModel, 'active')) != true) {
    if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) == true) {
      return ['link', 'nav__link', null, null];
    } else if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) != true) {
      return ['link', 'nav__link', 'shadowtext', null];
    }
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_4fc632952ff5837f5ed586b41d277eb1/logic/fefec6b6560f11b1a4c62ed6ad39d36c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52')), 'height')) == 349) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 0;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 349;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_4fc632952ff5837f5ed586b41d277eb1/logic/d6eb2689f2c259f3fd671877d34cc756/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await Backendless.UserService.logout();
  await (async function() {
  	window.location.reload();
  })();

  },
  /* handler:onClick */
  /* handler:onTooltipTextAssignment */
  async ['onTooltipTextAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (await Backendless.UserService.isValidLogin()) {
    return (String('Logout Current User: ') + String(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'email')));
  }

  },
  /* handler:onTooltipTextAssignment */
  /* content */
}))

define('./components/reusable/r_71ea28cbc023fb570ee895c8d0e8af4b/logic/dc8e2162b0174608379cfba7549be8db/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')));

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_71ea28cbc023fb570ee895c8d0e8af4b/logic/d1d9de3c08188cf9ff4108b1614be69a/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var AppURL, AppBaseURL;


  AppURL = (await (async function() {
  	return window.location.href
  })());
  AppBaseURL = AppURL.slice(0, 22);
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', [({ 'textLink': 'Home','urlLink': '#home','active': true }), ({ 'textLink': 'Features','urlLink': '#features','active': false }), ({ 'textLink': 'Testimonials','urlLink': '#testimonials','active': false }), ({ 'textLink': 'Gallery','urlLink': '#gallery','active': false }), ({ 'textLink': 'Team','urlLink': '#team','active': false }), ({ 'textLink': 'Project Estimator','urlLink': String('?page=webestimator'),'active': false }), ({ 'textLink': 'Contact','urlLink': '#contact','active': false })]);

  },
  /* handler:onMounted */
  /* content */
}))

define('./components/reusable/r_71ea28cbc023fb570ee895c8d0e8af4b/logic/4494147e47463208b1e17aafe36cda16/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var textLink, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  textLink = (getObjectProperty(___arguments.context.dataModel, 'textLink'));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')) == textLink) {
      item['active'] = true;
    } else {
      item['active'] = false;
    }
  ; return item;}))));
  if (textLink == 'Home') {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')));
  } else if (textLink == 'Features') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')));
  } else if (textLink == 'Testimonials') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')));
  } else if (textLink == 'Gallery') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')));
  } else if (textLink == 'Team') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')));
  } else if (textLink == 'Contact') {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0ee4f7a2f9f93096442f303878b88406')));
  } else if (textLink == 'Project Estimator') {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('projectestimator', undefined);
  }
  if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 990) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 0;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.dataModel, 'active')) {
    if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) == true) {
      return ['link', 'nav__link', 'nav__link_active', null, 'boldmenuitem'];
    } else if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) != true) {
      return ['link', 'nav__link', 'nav__link_active', 'whiteshadowtext', null, 'boldmenuitem'];
    }
  } else if ((getObjectProperty(___arguments.context.dataModel, 'active')) != true) {
    if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) == true) {
      return ['link', 'nav__link', null, null];
    } else if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) != true) {
      return ['link', 'nav__link', 'shadowtext', null];
    }
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_71ea28cbc023fb570ee895c8d0e8af4b/logic/fefec6b6560f11b1a4c62ed6ad39d36c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52')), 'height')) == 349) {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 0;
  } else {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 349;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_2f0980b7c0c30d678d9d67dd79ba49bc/logic/dc8e2162b0174608379cfba7549be8db/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')));

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_2f0980b7c0c30d678d9d67dd79ba49bc/logic/d1d9de3c08188cf9ff4108b1614be69a/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var AppURL, AppBaseURL;


  AppURL = (await (async function() {
  	return window.location.href
  })());
  AppBaseURL = AppURL.slice(0, 22);
  if (await Backendless.UserService.isValidLogin()) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', [({ [`textLink`]: 'Home',[`urlLink`]: '#home',[`active`]: true }), ({ [`textLink`]: 'Services',[`urlLink`]: '#services',[`active`]: false }), ({ [`textLink`]: 'Clients',[`urlLink`]: '#clients',[`active`]: false }), ({ [`textLink`]: 'Contact',[`urlLink`]: '#contact',[`active`]: false }), ({ [`textLink`]: 'Web Project Estimator',[`urlLink`]: String('?page=webestimator'),[`active`]: false }), ({ [`textLink`]: 'Tech Support Estimator',[`urlLink`]: String('?page=webestimator'),[`active`]: false }), ({ [`textLink`]: 'Dashboard',[`urlLink`]: String('?page=dash'),[`active`]: false }), ({ [`textLink`]: 'Log Out',[`urlLink`]: String('?page=dash'),[`active`]: false })]);
  } else {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', [({ [`textLink`]: 'Home',[`urlLink`]: '#home',[`active`]: true }), ({ [`textLink`]: 'Services',[`urlLink`]: '#services',[`active`]: false }), ({ [`textLink`]: 'Clients',[`urlLink`]: '#clients',[`active`]: false }), ({ [`textLink`]: 'Contact',[`urlLink`]: '#contact',[`active`]: false }), ({ [`textLink`]: 'Web Project Estimator',[`urlLink`]: String('?page=webestimator'),[`active`]: false }), ({ [`textLink`]: 'Tech Support Estimator',[`urlLink`]: String('?page=webestimator'),[`active`]: false }), ({ [`textLink`]: 'Login',[`urlLink`]: '#login',[`active`]: false })]);
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./components/reusable/r_2f0980b7c0c30d678d9d67dd79ba49bc/logic/4494147e47463208b1e17aafe36cda16/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var textLink, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  textLink = (getObjectProperty(___arguments.context.dataModel, 'textLink'));
  if (true) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')) == textLink) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
    if (textLink == 'Home') {
      (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')));
    } else if (textLink == 'Services') {
      (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')));
    } else if (textLink == 'Clients') {
      (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')));
    } else if (textLink == 'Portfolio') {
      (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')));
    } else if (textLink == 'Team') {
    } else if (textLink == 'Contact') {
      (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('0ee4f7a2f9f93096442f303878b88406')));
    } else if (textLink == 'Web Project Estimator') {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('projectestimator', undefined);
    } else if (textLink == 'Tech Support Estimator') {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('technicalsupportestimator', undefined);
    } else if (textLink == 'Login') {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
    } else if (textLink == 'Dashboard') {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dash', undefined);
    } else if (textLink == 'Log Out') {
      await Backendless.UserService.logout();
      await (async function() {
      	window.location.reload();
      })();
    }
    if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 990) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 0;
    }
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.dataModel, 'active')) {
    if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) == true) {
      return ['link', 'nav__link', 'nav__link_active', null, 'boldmenuitem'];
    } else if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) != true) {
      return ['link', 'nav__link', 'nav__link_active', 'whiteshadowtext', null, 'boldmenuitem'];
    }
  } else if ((getObjectProperty(___arguments.context.dataModel, 'active')) != true) {
    if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) == true) {
      return ['link', 'nav__link', null, null];
    } else if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) != true) {
      return ['link', 'nav__link', 'shadowtext', null];
    }
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_2f0980b7c0c30d678d9d67dd79ba49bc/logic/fefec6b6560f11b1a4c62ed6ad39d36c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52')), 'height')) == 349) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 0;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 349;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_2f0980b7c0c30d678d9d67dd79ba49bc/logic/41e14c3372411207ecaa3922eb3c07f2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dash', undefined);

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    
  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_2f0980b7c0c30d678d9d67dd79ba49bc/logic/2c19ee9f0b76537bcadd613a3e5c3095/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await Backendless.UserService.logout();
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
  await (async function() {
  	window.location.reload();
  })();

  },
  /* handler:onClick */
  /* handler:onTooltipTextAssignment */
  ['onTooltipTextAssignment'](___arguments) {
    
  },
  /* handler:onTooltipTextAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    
  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_05c22eecf6f25a2906f2c2bb9ce99f1d/logic/dc8e2162b0174608379cfba7549be8db/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')));

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_05c22eecf6f25a2906f2c2bb9ce99f1d/logic/d1d9de3c08188cf9ff4108b1614be69a/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async ['onMounted'](___arguments) {
    var AppURL, AppBaseURL;


  AppURL = (await (async function() {
  	return window.location.href
  })());
  AppBaseURL = AppURL.slice(0, 22);
  if (await Backendless.UserService.isValidLogin()) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', [({ [`textLink`]: 'Home',[`urlLink`]: '#home',[`active`]: false }), ({ [`textLink`]: 'Project Estimator',[`urlLink`]: String('?page=webestimator'),[`active`]: false }), ({ [`textLink`]: 'Technical Support Estimator',[`urlLink`]: String('?page=technicalsupportestimator'),[`active`]: false }), ({ [`textLink`]: 'Contact',[`urlLink`]: '#contact',[`active`]: false }), ({ [`textLink`]: 'Dashboard',[`urlLink`]: '#contact',[`active`]: true }), ({ [`textLink`]: 'Log Out',[`urlLink`]: '#contact',[`active`]: false })]);
  } else {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', [({ [`textLink`]: 'Home',[`urlLink`]: '#home',[`active`]: false }), ({ [`textLink`]: 'Project Estimator',[`urlLink`]: String('?page=webestimator'),[`active`]: false }), ({ [`textLink`]: 'Technical Support Estimator',[`urlLink`]: String('?page=technicalsupportestimator'),[`active`]: false }), ({ [`textLink`]: 'Contact',[`urlLink`]: '#contact',[`active`]: false }), ({ [`textLink`]: 'Login',[`urlLink`]: '#login',[`active`]: false })]);
  }

  },
  /* handler:onMounted */
  /* content */
}))

define('./components/reusable/r_05c22eecf6f25a2906f2c2bb9ce99f1d/logic/4494147e47463208b1e17aafe36cda16/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var textLink, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  textLink = (getObjectProperty(___arguments.context.dataModel, 'textLink'));
  if (true) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')) == textLink) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
    if (textLink == 'Home') {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
    } else if (textLink == 'Services') {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', ({ [`view`]: 'services' }));
    } else if (false) {
    } else if (textLink == 'Portfolio') {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', ({ [`view`]: 'portfolio' }));
    } else if (textLink == 'Technical Support Estimator') {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('technicalsupportestimator', undefined);
    } else if (textLink == 'Contact') {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', ({ [`view`]: 'contact' }));
    } else if (textLink == 'Project Estimator') {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('projectestimator', undefined);
    } else if (textLink == 'Login') {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
    } else if (textLink == 'Dashboard') {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('dash', undefined);
    } else if (textLink == 'Log Out') {
      await Backendless.UserService.logout();
      await (async function() {
      	window.location.reload();
      })();
    }
    if (((function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 990) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 0;
    }
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.dataModel, 'active')) {
    if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) == true) {
      return ['link', 'nav__link', 'nav__link_active', null, 'boldmenuitem'];
    } else if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) != true) {
      return ['link', 'nav__link', 'nav__link_active', null, null, 'boldmenuitem'];
    }
  } else if ((getObjectProperty(___arguments.context.dataModel, 'active')) != true) {
    if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) == true) {
      return ['link', 'nav__link', null, null];
    } else if ((getObjectProperty(___arguments.context.pageData, 'scrollTop')) != true) {
      return ['link', 'nav__link', null, null];
    }
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_05c22eecf6f25a2906f2c2bb9ce99f1d/logic/fefec6b6560f11b1a4c62ed6ad39d36c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52')), 'height')) == 349) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 0;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 349;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_05c22eecf6f25a2906f2c2bb9ce99f1d/logic/root/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  ['onMounted'](___arguments) {
    
  },
  /* handler:onMounted */
  /* content */
}))

define('./components/reusable/r_05c22eecf6f25a2906f2c2bb9ce99f1d/logic/cd824c35d49aebd9781cb7a55dee5ec1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await Backendless.UserService.logout();
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);

  },
  /* handler:onClick */
  /* handler:onTooltipTextAssignment */
  async ['onTooltipTextAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('Logout Current User: ') + String(getObjectProperty((await Backendless.UserService.getCurrentUser(false)), 'email')))

  },
  /* handler:onTooltipTextAssignment */
  /* content */
}))

